import React from 'react'
import Worldmap from '../../Components/LiveData/Worldmap/Worldmap';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import "./livedata.css";
import { useEffect } from 'react';

const LiveDataWorldmapPage = () => {

    useEffect(() => {
        document.title = "Kreditz | Admin portal - Live Data Worldmap";
    }, []);

    return (
        <div className="wrapper">
            <Header />
            <div className="main-container">
                <Sidebar />
                <Worldmap />
            </div>
        </div>
    );
};

export default LiveDataWorldmapPage