import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { getinvoiceDetails } from '../../Redux/Actions/InvoicesLogs';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import backIcon from '../../assets/images/icon_back.png';
import "./invoicingList.scss";
import PdfInvoice from './pdfInvoice';
import ReactToPrint from 'react-to-print';
import moment from 'moment/moment';
let date = new Date();
let DEFAULTMONTH = date.toLocaleString('en-us', { month: 'long' });
const InvoicingDetailsPage = (props) => {
    const componentRef = useRef();
    const childRef = useRef([]);
    const summaryRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [smsData, setSmsData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(props.month || DEFAULTMONTH);
    const [selectedYear, setSelectedYear] = useState(props.year || moment().year());
    const [show, setShow] = useState(false);
    const [parentResult, setParentResult] = useState([]);
    const [parentSmsResult, setParentSmsResult] = useState([]);
    const [childResult, setChildResult] = useState([]);
    const [summaryResult, setSummaryResult] = useState([]);
    const [summarySms, setSummarySms] = useState([]);
    let yearArray = Array.from({ length: 7 }, (_, i) => new Date().getFullYear() - i);
    let monthArray = moment.months();

    useEffect(() => {
        getinvoiceDetailsAPI();
    }, []);

    const getParams = () => {
        return "?org_id=" + props.parentOrgId + "&year=" + selectedYear + "&month=" + selectedMonth
    }

    const getinvoiceDetailsAPI = async () => {
        await dispatch(setLoaderSpinner(true))
        const { status, data = null } = await dispatch(getinvoiceDetails(getParams()));
        if (status) {
            const { sent_sms_count } = data
            const { parent_client, child_clients } = data?.new_data
            setSmsData(sent_sms_count)

            // Parent
            const parent = parent_client.length > 0 ? parent_client[0] : { result: [] }
            setParentSmsResult(parent.sent_sms_count || [])
            const tempArray = []
            const finalArray = []
            const finalSms = []

            parent?.result.map(function (key) {
                finalArray.push({ product: key.column[0], type: key.column[1], country: key.column[2], count: key.count })
                return tempArray.push({ product: key.column[0], type: key.column[1], country: key.column[2], count: key.count })
            });
            setResult(tempArray)
            setParentResult(tempArray)
            finalSms.push(sent_sms_count)

            // Child
            const childTemp = []
            child_clients && child_clients.map(function (key) {
                const temp = []
                key.result.map(function (nestkey) {
                    temp.push({ product: nestkey.column[0], type: nestkey.column[1], country: nestkey.column[2], count: nestkey.count })
                    finalArray.push({ product: nestkey.column[0], type: nestkey.column[1], country: nestkey.column[2], count: nestkey.count })
                    return nestkey
                })
                finalSms.push(key.sent_sms_count)
                childTemp.push({ organization_id: key.organization.id, organization_name: key.organization.name, result: temp, smsData: key.sent_sms_count })
                return childTemp
            })
            setChildResult(childTemp)

            // final sms Summary
            let temp = [].concat.apply([], finalSms)
            const sms = []
            temp.map((key) => {
                const element = sms.find(i => (Object.keys(i)[0] === Object.keys(key)[0]))
                if (element) {
                    element[Object.keys(element)[0]] = element[Object.keys(element)[0]] + key[Object.keys(key)[0]]
                } else {
                    sms.push(key)
                }
                return key
            })
            setSummarySms((sms[0] === undefined) ? [] : sms)

            // final record Summary
            const r = finalArray.map(i => i).reduce((acc, itm) => {
                const element = acc.find(i => (i.product === itm.product && i.type === itm.type && i.country === itm.country))
                if (element) {
                    element.count = element.count + itm.count
                } else {
                    acc.push(itm)
                }
                return acc
            }, [])

            setSummaryResult(r);
            setShow(true);
        } else {
            history.push('/login')
        }
        await dispatch(setLoaderSpinner(false))
    }

    const getProduct = (product) => {
        switch (product) {
            case "web":
                return "Inquiry web";
            case "api":
                return "API";
            default:
                return "";
        }

    }

    const renderParentClientTableData = (parentResultData) => parentResultData.map((item) => {
        return (<tr key={Math.random()}>
            <td>{getProduct(item.product)}</td>
            <td>{item.type === "corporate" ? "Business" : "Consumer"}</td>
            <td className="capitalize">{item.country}</td>
            <td className="text-right">-</td>
            <td>{item.count}</td>
            <td className="text-right"><strong>-</strong></td>
        </tr>)
    });

    const renderParentClientSmsData = (smsResult) => smsResult && smsResult.map((item) =>
        <tr key={Math.random()}>
            <td>SMS</td>
            <td>SMS</td>
            <td className="capitalize">{item && Object.keys(item || {})}</td>
            <td className="text-right">-</td>
            <td>{item && Object.values(item)}</td>
            <td className="text-right"><strong>-</strong></td>
        </tr>)

    const handleSearch = () => {
        setShow(false)
        getinvoiceDetailsAPI()
    }

    return (
        <div className="page-container">
            <h3 className="basic-head01">
                <div>
                    <Link to={{ pathname: `/invoicing` }} >
                        <img src={backIcon} alt="backIcon" style={{ cursor: 'pointer' }} />
                    </Link> Invoice Details
                </div>
            </h3>
            <div className="bg-white-invoices bg-20-top">
                {/* === Parent section == */}
                <div className="head-section">
                    <div className="switch-search">
                        <div className="tab-date">
                            <h2 className="basic-head02">{props.parentOrgName}</h2>
                        </div>
                        <div className="select-search tab-date">
                            <div className="search-date">
                                <div className="form-group">
                                    <select className="form-control" onChange={(e) => setSelectedYear(e.target.value)}>
                                        {yearArray.map(yearEle => <option value={yearEle}>{yearEle}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select className="form-control" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                        {monthArray.map(month => <option value={month}>{month}</option>)}
                                    </select>
                                </div>
                                <button type="button" className="search-button" onClick={() => handleSearch()}>Search</button>
                            </div>
                            {
                                show ?
                                    <ReactToPrint
                                        trigger={() => <button className="custom-button td-none">Download PDF</button>}
                                        content={() => componentRef.current}
                                        documentTitle="invoice"
                                    />
                                    : <button href="#!" className="custom-button">Download PDF</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="table-content table-content-invoice">

                    <form className="invoice-details-table">
                        <table>
                            <thead>
                                <tr>
                                    <td>Product</td>
                                    <td>Type</td>
                                    <td>Market</td>
                                    <td className="text-right">Price</td>
                                    <td>Unit</td>
                                    <td className="text-right">Sum</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderParentClientTableData(parentResult)}
                                {parentSmsResult.length > 0 && renderParentClientSmsData(parentSmsResult)}
                            </tbody>
                            <tfoot>
                                <tr className="bg-white-td">
                                    <td colSpan="4"></td>
                                    <td>Total</td>
                                    <td className="text-right"><strong>-</strong></td>
                                </tr>
                                <tr className="bg-white-td">
                                    <td colSpan="4"></td>
                                    <td>Tax</td>
                                    <td className="text-right"><strong>-</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="4"></td>
                                    <td>Grand Total</td>
                                    <td className="text-right"><strong>-</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>

                <div className="more-details-btn" data-url="/invoices/more_details?org_id=10">
                    <Link className="custom-button" to={{ pathname: `/invoicing/more_details`, state: { parentOrgId: props.parentOrgId, parentOrgName: props.parentOrgName, orgId: props.parentOrgId, orgName: props.parentOrgName, year: selectedYear, month: selectedMonth, summary: false } }}>More details</Link>
                </div>

                {/* ===Child Section === */}
                {childResult.map((item) =>
                    <div key={Math.random()}>
                        <div className="head-section">
                            <div className="switch-search">
                                <div className="tab-date">
                                    <h2 className="basic-head02">{item.organization_name}</h2>
                                </div>
                                <div className="select-search tab-date">
                                    {
                                        show ?
                                            <ReactToPrint
                                                trigger={() => <button className="custom-button td-none">Download PDF</button>}
                                                content={() => childRef.current[item.organization_id]}
                                                documentTitle="invoice"
                                            />
                                            : <button href="#!" className="custom-button">Download PDF </button>
                                    }
                                    <div className="hidden" >
                                        <PdfInvoice ref={(el) => (childRef.current[item.organization_id] = el)} result={item.result} name={item.organization_name} month={selectedMonth} year={selectedYear} smsData={item.smsData} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-content table-content-invoice">
                            <form className="invoice-details-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Product</td>
                                            <td>Type</td>
                                            <td>Market</td>
                                            <td className="text-right">Price</td>
                                            <td>Unit</td>
                                            <td className="text-right">Sum</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderParentClientTableData(item.result)}
                                        {item.smsData.length > 0 && renderParentClientSmsData(item.smsData)}
                                    </tbody>
                                    <tfoot>
                                        <tr className="bg-white-td">
                                            <td colSpan="4"></td>
                                            <td>Total</td>
                                            <td className="text-right"><strong>-</strong></td>
                                        </tr>
                                        <tr className="bg-white-td">
                                            <td colSpan="4"></td>
                                            <td>Tax</td>
                                            <td className="text-right"><strong>-</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4"></td>
                                            <td>Grand Total</td>
                                            <td className="text-right"><strong>-</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </form>
                        </div>
                        <div className="more-details-btn" data-url="/invoices/more_details?org_id=10">
                            <Link className="custom-button" to={{ pathname: `/invoicing/more_details`, state: { parentOrgId: props.parentOrgId, parentOrgName: props.parentOrgName, orgId: item.organization_id, orgName: item.organization_name, year: selectedYear, month: selectedMonth, summary: false } }}>More details</Link>
                        </div>
                    </div>
                )}

                {/* ====== Summary===== */}
                {childResult.length !== 0 &&
                    <div key={Math.random()}>
                        <div className="head-section">
                            <div className="switch-search">
                                <div className="tab-date">
                                    <h2 className="basic-head02">Summary</h2>
                                </div>
                                <div className="select-search tab-date">
                                    {
                                        show ?
                                            <ReactToPrint
                                                trigger={() => <button className="custom-button td-none">Download PDF</button>}
                                                content={() => summaryRef.current}
                                                documentTitle="invoice"
                                            />
                                            : <button href="#!" className="custom-button">Download PDF </button>
                                    }
                                    <div className="hidden" >
                                        <PdfInvoice ref={summaryRef} result={summaryResult} name={"Summary"} month={selectedMonth} year={selectedYear} smsData={summarySms} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-content table-content-invoice">
                            <form className="invoice-details-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Product</td>
                                            <td>Type</td>
                                            <td>Market</td>
                                            <td className="text-right">Price</td>
                                            <td>Unit</td>
                                            <td className="text-right">Sum</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderParentClientTableData(summaryResult)}
                                        {renderParentClientSmsData(summarySms)}
                                    </tbody>
                                    <tfoot>
                                        <tr className="bg-white-td">
                                            <td colSpan="4"></td>
                                            <td>Total</td>
                                            <td className="text-right"><strong>-</strong></td>
                                        </tr>
                                        <tr className="bg-white-td">
                                            <td colSpan="4"></td>
                                            <td>Tax</td>
                                            <td className="text-right"><strong>-</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4"></td>
                                            <td>Grand Total</td>
                                            <td className="text-right"><strong>-</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </form>
                        </div>
                        <div className="more-details-btn" data-url="/invoices/more_details?org_id=10">
                            <Link className="custom-button" to={{ pathname: `/invoicing/more_details`, state: { parentOrgId: props.parentOrgId, parentOrgName: props.parentOrgName, orgId: props.parentOrgId, orgName: props.parentOrgName, year: selectedYear, month: selectedMonth, summary: true } }}>More details</Link>
                        </div>
                    </div>
                }
            </div>
            <div className="hidden" >
                <PdfInvoice ref={componentRef} result={result} name={props.orgName} month={selectedMonth} year={selectedYear} smsData={smsData} />
            </div>
        </div >

    )
}

export default InvoicingDetailsPage;