import React, { useState, useEffect } from 'react';
import ToggleOrgWithKey from '../ToggleOrgWithKeys/ToggleOrgWithKeys';
import _ from 'lodash';
function AnalyzingTab({
  setClientDetails,
  country,
  clientDetails,
  handleSetting,
  submitHandle,
}) {

  const [isAnalyzingMarkets, setAnalyzingMarkets] = useState(false)

  const handleAnalyzingMarketsIds = (event, name) => {
    let setting = clientDetails.setting
    if (name === 'all') {
      setAnalyzingMarkets(event.target.checked)
      let Markets = country.map(temp => {
        return temp.id.toString()
      })
      setting.analyzing_markets = event.target.checked ? Markets : []
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    } else {
      setAnalyzingMarkets(false)
      if (event.target.checked) {
        let setting = clientDetails.setting
        if (!_.includes(setting.analyzing_markets, name.toString())) {
          let _markets = []
          _markets = setting.analyzing_markets ? setting.analyzing_markets : []
          _markets.push(name.toString())
          setting.analyzing_markets = _markets
          setClientDetails(prevState => ({
            ...prevState,
            setting: setting
          }))
        }
      } else {
        let setting = clientDetails.setting
        if (_.includes(setting.analyzing_markets, name.toString())) {
          let _markets = setting.analyzing_markets
          _markets = _.filter(_markets, function (f) { return f !== name.toString() })
          setting.analyzing_markets = _markets
          setClientDetails(prevState => ({
            ...prevState,
            setting: setting
          }))
        }
      }
    }
  }

  useEffect(() => {
    if (clientDetails?.setting?.analyzing_markets?.length === country.length) {
      setAnalyzingMarkets(true)
    } else {
      setAnalyzingMarkets(false)
    }
  }, [clientDetails])

  return (
    <div id="analyzing_details" className="tab-pane fade show hide" role="tabpanel" aria-labelledby="analyzing-tab">
      <div className="detail-two-box">
        <div className="details-left details-full-box">
          <h3 className="headeing-switch sub-tab">
            Analyzing
            <div className="adjust-switcher">
              ALL
              <div className="select-switch">
                <label className="switch-box">
                  <input
                    type="checkbox"
                    checked={isAnalyzingMarkets}
                    onChange={(e) => handleAnalyzingMarketsIds(e, 'all')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </h3>
          {country &&
            country.map((keys, index) => (
              <ToggleOrgWithKey
                keys={keys.name}
                id={keys.id.toString()}
                checked={_.includes(clientDetails.setting.analyzing_markets, keys.id.toString())}
                toggleFunction={handleAnalyzingMarketsIds}
                key={"country-" + keys.id}
              />
            ))}
        </div>
        <div className="details-left details-full-box">
          <h3 className="headeing-switch sub-tab">Options</h3>
          <ToggleOrgWithKey
            keys={"Accuracy threshold check"}
            id={"accuracy_check_for_analyzing"}
            checked={clientDetails.setting.accuracy_check_for_analyzing}
            toggleFunction={handleSetting}
          />
        </div>
      </div>
    </div>
  );
}

export default AnalyzingTab;
