import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import SideBar from "../../Components/Sidebar/sidebar";
import Comparison from "../../Components/ComparisonReport/Comparison";

import "./comparisonReport.css";

const ComparisonReport = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Comparison Report";
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <SideBar />
        <Comparison />
      </div>
    </div>
  );
};

export default ComparisonReport;
