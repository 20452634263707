import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";
import {
  SET_CONVERSION_RATE_LIST,
  SET_CONVERSION_RATE_FILTER,
  SET_CONVERSION_BANK_FILTER
} from "./Types/Types";

export function getConversionRate(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.Conversion_rate.LIST()}` + data;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function getConversionRateFilter(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.Conversion_rate.FILTER()}`;
  return (dispatch) =>
    axios
      .get(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function getConversionRateXlsx(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.Conversion_rate.GENERATE_XLSX()}` + data;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function getConversionRateBankFilter(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.Conversion_rate.BANKS_FILTER()}` + data;
  return (dispatch) =>
    axios
      .get(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function setStatisticsList(response) {
  return {
    type: SET_CONVERSION_RATE_LIST,
    conversion_rate: response,
  };
}

export function setConversionRateFilter(response) {
  return {
    type: SET_CONVERSION_RATE_FILTER,
    conversion_rate: response,
  };
}

export function setConversionRateBankFilter(response) {
  return {
    type: SET_CONVERSION_BANK_FILTER,
    conversion_rate: response,
  };
}