import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ConsumerRequestsChart = (props) => {

  let consumerRequestsArr = [];

  if (props.consumerReqsArray.length > 10) {
    consumerRequestsArr = props.consumerReqsArray.slice(0, 10);

    let lastReqs = props.consumerReqsArray.slice(10, (props.consumerReqsArray.length));
    let otherSum = 0;

    lastReqs.forEach((x) => {
      otherSum += x.y
    })
    consumerRequestsArr.push({ name: "Other", y: otherSum })

  }
  else {
    props.consumerReqsArray.forEach((x) => {
      consumerRequestsArr.push({ name: x.name, y: x.y })
    })
  }
  let consumerReqsSum = 0;

  consumerRequestsArr.forEach((x) => {
    consumerReqsSum += x.y
  })

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: '500',
      type: 'pie',
      spacing: [0, 50, 20, 40],
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
    },
    legend: {
      margin: 20,
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#596980",
        fontWeight: "regular",
      },
      symbolRadius: 0
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: 200,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.2f} %',

        }
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
        },
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    xAxis: {
      categories: "test",
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "",
        x: -20,
        style: {
          color: "#8591A6",
        },
      },
      labels: {
        style: {
          color: "#8591A6",
          formatter: function () {
            return Highcharts.numberFormat(this.value, 0, ' ');
          },
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "test",
        data: consumerRequestsArr,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        showInLegend: true,
        tooltip: {
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
      },
    ],
  };
  return (
    <div>
      <h5 className="active-customers-chart-heading">Consumer: <span className="total-requests-style">{numberWithSpaces(consumerReqsSum)}</span></h5>
      {consumerRequestsArr.length > 0 &&
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />}
    </div>
  )
}
export default ConsumerRequestsChart;