import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { getDynamicLiveData, getDynamicLiveDataFilter } from "../../../Redux/Actions/DynamicLiveData";
import "./scss/livedataList.scss";
import CompareGraph from "./CompareGraph";
import DatePicker from "react-datepicker";
import ReactSelect from "../../ReactSelect/ReactSelect";
import Spinner from 'react-bootstrap/Spinner';

const DynamicLiveData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRequestType, setSelectedRequestType] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [countries, setcountries] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [requestType, setrequestType] = useState({});
  const [comparingYear, setComparingYear] = useState({});
  const [currentYear, setCurrentYear] = useState("");
  const [updatedNumber, setUpdatedNumber] = useState(1);
  const [acceptedCurrentYear, setAcceptedCurrentYear] = useState({});
  const [acceptedComparingYear, setAcceptedComparingYear] = useState({});
  const [loadingGraphData, setLoadingGraphData] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  
  let minDate = new Date("January 1 , 2019 23:15:30");
  const d = new Date();
  d.setFullYear(d.getFullYear() - 1);
  let prevYear = d.toString();
  const [selectedYear, setSelectedYear] = useState(new Date(prevYear));

  useEffect(() => {
    fetchDynamicLiveDataFilter();
  }, []);

  useEffect(() => {
    fetchDynamicLiveData();
  }, [
    selectedYear,
    selectedRequestType,
    selectedCountry,
    selectedCustomer,
    updatedNumber,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedNumber(Math.random());
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const fetchDynamicLiveDataFilter = async () => {
    const { status, message, data } = await dispatch(
      getDynamicLiveDataFilter()
    );
    if (status) {
      setcountries(data.countries);
      setcustomers(data.customers);
      setrequestType(data.request_type);
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      }
    }
  };

  const fetchDynamicLiveData = async () => {
    let params = `?year=${selectedYear.getFullYear()}&request_type=${selectedRequestType}&country_id=${selectedCountry}&organization_id=${selectedCustomer}`;
    setLoadingGraphData(true);
    try {
      const { status, data } = await dispatch(getDynamicLiveData(params));
      const { comparing_year, current_year } = data;
      setComparingYear(comparing_year.year);
      setCurrentYear(current_year.year);
      setAcceptedCurrentYear(current_year.accepted_requests);
      setAcceptedComparingYear(comparing_year.accepted_requests);
      if (!status) {
        history.push("/login");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoadingGraphData(false);
      setInitialLoad(false);
    }
  };
  
  const currentYearAccepted = Object.entries(acceptedCurrentYear).sort(
    (a, b) => {
      return new Date(b.month) - new Date(a.month);
    }
  );

  const comparingYearAccepted = Object.entries(acceptedComparingYear).sort(
    (a, b) => {
      return new Date(b.month) - new Date(a.month);
    }
  );

  const currentYearAcceptedArray = [];

  currentYearAccepted.map((x) => {
    return currentYearAcceptedArray.push({
      month: `${x[0]}`,
      accepted_requests: x[1],
    });
  });

  const comparingYearAcceptedArray = [];

  comparingYearAccepted.map((x) => {
    return comparingYearAcceptedArray.push({
      month: `${x[0]}`,
      accepted_requests: x[1],
    });
  });

  const handleSelectedCountry = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleSelectedRequestType = (event) => {
    setSelectedRequestType(event.target.value);
  };
  const handleSelectedCustomer = (event) => {
    setSelectedCustomer(event.target.value);
  };
  const handleSelectedYear = (date) => {
    setSelectedYear(date);
  };

  return (
    <div className="page-container live-data-container">
      <div className="ld-container">
        <h3 className="ld-header">Live Data</h3>
        <div className="ld-search-container">
          <form className="ld-form-container" id="ld-form-container">
            <div className="custom-select">
              <select
                className="ld-form-input-field capitalize"
                id="ld-select"
                onChange={handleSelectedRequestType}
              >
                <option className="ld-option" id="ld-option" value="">
                  Request
                </option>
                <option
                  value={requestType.customer}
                  key={requestType.customer}
                  className="ld-option"
                  id="ld-option"
                >
                  Consumer
                </option>
                <option
                  value={requestType.corporate}
                  key={requestType.corporate}
                  className="ld-option"
                  id="ld-option"
                >
                  Corporate
                </option>
              </select>
            </div>
            <div className="custom-select">
              <ReactSelect
                orgList={customers}
                pageRender={"customers"}
                handleChange={handleSelectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
              />
            </div>
            <div className="custom-select">
              <select
                className="ld-form-input-field capitalize"
                onChange={handleSelectedCountry}
              >
                <option value="">Country</option>
                {countries &&
                  countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {" "}
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="date-picker-container">
              <DatePicker
                selected={selectedYear}
                placeholderText="Choose year"
                showYearPicker
                dateFormat="yyyy"
                value={selectedYear}
                onChange={(date) => handleSelectedYear(date)}
                className="live-data-daterange"
                maxDate={new Date(prevYear)}
                minDate={minDate}
                yearItemNumber={8}
              />
              <div className="ld-calendar-icon">
                <FontAwesomeIcon icon={faCalendar} color="#e8edfa" />
              </div>
            </div>
          </form>
        </div>
        {initialLoad ? (
          <>
            <div className="live-data-graph">
            <Spinner animation="border" className="live-data-graph-spinner" />
            </div>
          </>
      ) : (
        <CompareGraph
          currentYear={currentYear}
          comparingYear={comparingYear}
          currentYearAccepted={currentYearAcceptedArray}
          comparingYearAccepted={comparingYearAcceptedArray}
        />
      )}
      </div>
    </div>
  );
};

export default DynamicLiveData;
