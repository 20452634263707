import { useState, useEffect } from "react";
import ReqeustTableBanks from "./RequestTableBanks";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { getConversionRateBankFilter, getConversionRate } from "../../../Redux/Actions/ConversionRate";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactSelect from "../../ReactSelect/ReactSelect";

const ConversionRateBanks = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const [countryId, setCountryId] = useState(1);
  const [serviceId, setServiceId] = useState(5);
  const [type, setType] = useState("private");
  const [selectedBank, setSelectedBank] = useState("");
  const [filterData, setFilterData] = useState();
  const [bankDataSent, setBankDataSent] = useState([]);
  const [bankDataAccepted, setBankDataAccepted] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("sweden");
  const [selectedSupplier, setSelectedSupplier] = useState("enable_banking");
  const [selectedType, setSelectedType] = useState("customer");
  const [bankList, setBankList] = useState([]);

  const getStartDate = (d) => {
    return moment(
      new Date(d.getFullYear(), d.getMonth(), d.getDate())
    ).subtract(1, 'days').format('DD/MM/YYYY');
  }
  function getEndDate(d) {
    return moment(new Date(d.getFullYear(), d.getMonth(), d.getDate())
    ).subtract(1, 'days').format('DD/MM/YYYY');
  }
  const startDate = getStartDate(new Date());
  const endDate = getEndDate(new Date());
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate}-${endDate}`);

  useEffect(() => {
    fetchConversionBankFilter()
    handleSelectedType()
    handleSelectedMarket()
    handleSelectedSupplier()
  }, [countryId, serviceId, type]);

  useEffect(() => {
    fetchConversion()
  }, [selectedDateRange]);

  const fetchConversion = async () => {
    const params =
      "?market_id=" + selectedMarket +
      "&certificate_type=" + selectedType +
      "&supplier_type=" + selectedSupplier +
      "&date_range=" + selectedDateRange +
      "&bank_name=" + selectedBank

    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getConversionRate(params));
    const { bank_data_sent_results, bank_data_accepted_results } = data;
    setBankDataSent(bank_data_sent_results);
    setBankDataAccepted(bank_data_accepted_results)

    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };
  const fetchConversionBankFilter = async () => {
    const params =
      "?country_id=" + countryId +
      "&type=" + type +
      "&service_id=" + serviceId;

    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getConversionRateBankFilter(params));
    setFilterData(data);
    setBankList(data.banks)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  let allAccepted = [];
  let noAccepted = [];
  let noSent = [];

  for (let x = 0; x < bankDataSent.length; x++) {
    for (let y = 0; y < bankDataAccepted.length; y++) {
      if (bankDataSent[x].date === bankDataAccepted[y].date && bankDataSent[x].bank_name === bankDataAccepted[y].bank_name) {
        allAccepted.push({
          date: bankDataSent[x].date,
          bank_name: bankDataSent[x].bank_name,
          total_requests: bankDataSent[x].sum,
          accepted_requests: bankDataAccepted[y].sum,
          success_rate:
            ((bankDataAccepted[y].sum / bankDataSent[x].sum) * 100).toFixed(
              2
            ) + "%",
        });
      }
    }
  }

  for (let i = 0; i < bankDataSent.length; i++) {
    let match = false;
    for (let j = 0; j < bankDataAccepted.length; j++) {
      if (bankDataSent[i].date === bankDataAccepted[j].date && bankDataSent[i].bank_name === bankDataAccepted[j].bank_name) {
        match = true;
        break;
      }
    }
    if (!match) {
      noAccepted.push({
        date: bankDataSent[i].date,
        bank_name: bankDataSent[i].bank_name,
        total_requests: bankDataSent[i].sum,
        accepted_requests: 0,
        success_rate: ((0 / bankDataSent[i].sum) * 100).toFixed(2) + "%",
      });
    }
  }

  for (let i = 0; i < bankDataAccepted.length; i++) {
    let match = false;
    for (let j = 0; j < bankDataSent.length; j++) {
      if (bankDataAccepted[i].date === bankDataSent[j].date && bankDataAccepted[i].bank_name === bankDataSent[j].bank_name) {
        match = true;
        break;
      }
    }
    if (!match) {
      noSent.push({
        date: bankDataAccepted[i].date,
        bank_name: bankDataAccepted[i].bank_name,
        accepted_requests: bankDataAccepted[i].sum,
        total_requests: 0,
        success_rate: ((bankDataAccepted[i].sum / 1) * 100).toFixed(2) + "%",
      });
    }
  }

  const allRequests = allAccepted.concat(noAccepted, noSent);

  const addedReqs = [];
  const acceptedGrouped = allRequests.reduce((all, { bank_name: c, accepted_requests: a }) =>
    ({ ...all, [c]: (all[c] || 0) + a }), {});
  const acceptedResult = Object.keys(acceptedGrouped).map(k => ({ bank_name: k, accepted_requests: acceptedGrouped[k] }));
  const totalGrouped = allRequests.reduce((all, { bank_name: c, total_requests: a }) =>
    ({ ...all, [c]: (all[c] || 0) + a }), {});
  const totalResult = Object.keys(totalGrouped).map(k => ({ bank_name: k, total_requests: totalGrouped[k] }));

  for (let x = 0; x < totalResult.length; x++) {
    for (let y = 0; y < acceptedResult.length; y++) {
      if (totalResult[x].bank_name === acceptedResult[y].bank_name && totalResult[x].total_requests !== 0) {
        addedReqs.push({
          bank_name: totalResult[x].bank_name,
          total_requests: totalResult[x].total_requests,
          accepted_requests: acceptedResult[y].accepted_requests,
          success_rate:
            ((acceptedResult[y].accepted_requests / totalResult[x].total_requests) * 100).toFixed(
              2
            ) + "%",
        });
      }
      if (totalResult[x].bank_name === acceptedResult[y].bank_name && totalResult[x].total_requests === 0) {
        addedReqs.push({
          bank_name: totalResult[x].bank_name,
          total_requests: totalResult[x].total_requests,
          accepted_requests: acceptedResult[y].accepted_requests,
          success_rate:
            ((acceptedResult[y].accepted_requests / 1) * 100).toFixed(2) + "%"
        });
      }
    }
  }

  const allRequestsSorted = addedReqs.sort((a, b) => {
    return parseFloat(b.success_rate.slice(0, 5)) - parseFloat(a.success_rate.slice(0, 5))
  });

  const handleSupplier = ((id) => {
    let idNum = Number(id)
    setServiceId(idNum)
  })

  const handleCountry = ((id) => {
    setCountryId(id)
  })

  const handleType = ((id) => {
    setType(id)
  })

  const handleSelectedType = () => {
    if (filterData) {
      Object.entries(filterData.types).forEach(([key, value]) => {
        if (type === value) {
          setSelectedType(key)
        }
        else if (type === "") {
          setSelectedType("")
        }
      })
    }
  }

  const handleSelectedMarket = () => {
    if (filterData) {
      filterData.market.forEach((market) => {
        if (countryId === market.id) {
          setSelectedMarket(market.name);
        }
        else if (countryId === "") {
          setSelectedMarket("");
        }
      })
    }
  }

  const handleSelectedSupplier = () => {
    if (filterData) {
      filterData.suppliers.forEach((supplier) => {
        if (serviceId === supplier.id) {
          setSelectedSupplier(supplier.name)
        }
        else if (serviceId === "") {
          setSelectedSupplier("")
        }
      })
    }
  }

  const applyFilters = () => {
    fetchConversion();
  }

  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format('DD/MM/YYYY');
    const endDateValue = moment(end._d).format('DD/MM/YYYY');
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
  }

  return (
    <div className="conversion-container page-container">
      <div className="lower-header-conversion">
        <h3>Banks</h3>
        <div className="flex-header-conversion">
          <div className="input-sets">
            <div className="input-group date k-datepicker">
              <DateRangePicker
                onCallback={handleCallback}
                initialSettings={{ startDate: getStartDate(new Date()), endDate: getEndDate(new Date()), locale: { format: "DD/MM/YYYY" } }}
              >
                <input
                  type="text"
                  name="sms_date_range"
                  id="form-date-field"
                />
              </DateRangePicker>
              <div className="calendar-icon-conversion">
                <FontAwesomeIcon
                  icon={faCalendar}
                  size="1x"
                  color="#e8edfa"
                  className="conversion-calendar-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-container-conversion">
        <div className="conversion-filter-container">
          <label>Supplier</label>
          <select className="select-filter-conversion capitalize" onChange={(e) => handleSupplier(e.target.value)} value={serviceId}>
            <option value="">All</option>
            {filterData && filterData.suppliers.map((supplier) => (
              <option value={supplier.id} key={supplier.id}>
                {supplier.name.replaceAll("_", " ")}
              </option>
            ))}
          </select>
        </div>
        <div className="conversion-filter-container">
          <label>Market</label>
          <select className="select-filter-conversion capitalize" onChange={(e) => handleCountry(e.target.value)} value={countryId}>
            <option value="">All</option>
            {filterData && filterData.market.map((market, i) => (
              <option value={market.id} key={i}>
                {market.name}
              </option>
            ))}
          </select>
        </div>
        <div className="conversion-filter-container">
          <label>Certificate type</label>
          <select className="select-filter-conversion capitalize" onChange={(e) => handleType(e.target.value)} value={type} name={selectedSupplier}>
            <option value="">All</option>
            {filterData && Object.entries(filterData.types).map(([key, value], index) => (
              <option value={value} key={index}>
                {key === "customer" ? "Consumer" : key}
              </option>
            ))}
          </select>
        </div>
        <div className="conversion-filter-container overflow-inherit">
          <label>Banks</label>
          <ReactSelect
            orgList={bankList}
            pageRender={"banks"}
            setSelectedBank={setSelectedBank}
          />
        </div>
        <div className="conversion-filter-container">
          <button className="apply-filter-button" onClick={applyFilters}>
            Apply
          </button>
        </div>
      </div>
      <div className="table-wrapper-conversion">
        <h2 className="conversion-table-h2">Request Log</h2>
        {allRequestsSorted && <ReqeustTableBanks data={allRequestsSorted} />}
      </div>
    </div>
  )
}
export default ConversionRateBanks;