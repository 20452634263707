import React from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import NewClientsThisMonth from "../../Components/NewClientsMonth/NewClientsMonth";


const NewClientMonth = () => {
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <NewClientsThisMonth />
      </div>
    </div>
  );
};

export default NewClientMonth;