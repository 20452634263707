import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import backIcon from '../../assets/images/icon_back.png';
import { getMoreDetails, generateXlsxAPI, getInvoiceXilsxUrl } from '../../Redux/Actions/InvoicesLogs';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import ReactPaginate from 'react-paginate';
import "./invoicingList.scss";
import NewFlash from '../FlashMessage/NewFlash';

const MoreDetailsPage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [firstDay, setFirstDay] = useState("");
    const [lastDay, setLastDay] = useState("");
    const [result, setResult] = useState([]);
    const [country, setCountry] = useState([]);
    const [isXlsx, setIsXlsx] = useState(false);
    const [flashMessage, setFlashMessage] = useState(false);
    const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })
    const [paginationData, setpaginationData] = useState({ current_page: 1, total_pages: '', total_count: '', next_page: '', prev_page: '', sort_column: 'requests_count', sort_order: 'asc' })
    useEffect(() => {
        var date = new Date(props.month + props.year);
        setFirstDay(moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD'))
        setLastDay(moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD'))
    }, [props.month, props.year])

    useEffect(() => {
        getMoreDetailsData(false);
    }, [page])

    const getParams = () => {
        return "?org_id=" + props.orgId + "&year=" + props.year + "&month=" + props.month + "&page=" + page + "&summary=" + props.summary
    }

    const getMoreDetailsData = async () => {
        await dispatch(setLoaderSpinner(true))
        const { status, data = null } = await dispatch(getMoreDetails(getParams()));
        if (status) {
            setResult(data.result)
            setIsXlsx(data.is_xlsx)
            setCountry(data.countries)
            setpaginationData(prevState => ({
                ...prevState,
                current_page: data.current_page,
                total_count: data.total_count,
                total_pages: data.total_pages,
                next_page: data.next_page,
                prev_page: data.prev_page
            }))
            await dispatch(setLoaderSpinner(false))
        } else {
            history.push('/login')
        }
        await dispatch(setLoaderSpinner(false))
    }

    const getCountry = (item) => country.filter(c => c.id === item.country_id).map(filteredCountry => (
        <td className="capitalize" key={Math.random()}>
            {filteredCountry.name}
        </td>
    ))
    const renderTable = result.map((item) =>
        <tr key={Math.random()}>
            <td>{moment(item.created_at).format("YYYY-MM-DD")}</td>
            <td>{item.id}</td>
            <td>{item.type === "customer" ? "Consumer" : "Business"}</td>
            <td>{item.sms_logs.length === 0 ? (item.api ? "API" : "Inquiry web") : item.api ? "API + " : "Inquiry web + " + (item.sms_logs[0].num_segments + " SMS")}</td>
            {getCountry(item)}
            <td>-</td>
        </tr>
    )

    const downloadFile = async () => {
        await dispatch(setLoaderSpinner(true))
        const { status, data = null } = await dispatch(getInvoiceXilsxUrl(getParams()));
        if (status) {
            await dispatch(setLoaderSpinner(false))
            window.location.href = data.url
        }
    }

    const generateXlsxFile = async () => {
        await dispatch(setLoaderSpinner(true))
        const { status, message } = await dispatch(generateXlsxAPI(getParams()));
        if (status) {
            await dispatch(setLoaderSpinner(false))
            setFlashMessage(true);
            setFlashData(prevState => ({
                ...prevState,
                iconImage: "tick",
                firstMsg: "Success",
                secondMsg: message
            }))
        }

    }

    const closeFlash = () => {
        setFlashMessage(false);
    }
    return (
        <div className="page-container">
            {flashMessage &&
                <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
                    <NewFlash
                        data={flashData}
                        closeFlash={() => closeFlash()}
                    />
                </div>
            }
            <h3 className="basic-head01">
                <div>
                    <Link to={{ pathname: `/invoicing/details`, state: { parentOrgId: props.parentOrgId, parentOrgName: props.parentOrgName, orgId: props.orgId, orgName: props.orgName, month: props.month, year: props.year } }} >
                        <img src={backIcon} alt="backIcon" style={{ cursor: 'pointer' }} />
                    </Link> More Details
                </div>
            </h3>


            <div className="bg-white-invoices bg-20-top">
                <div className="head-section">
                    <div className="switch-search">
                        <div className="tab-date">
                            <h2 className="basic-head02">{props.orgName}</h2><br />
                        </div>
                        {isXlsx ?
                            <button className="custom-button td-none" onClick={() => downloadFile()}>Download Xlsx</button>
                            :
                            (result.length !== 0) && <button className="custom-button td-none" onClick={() => generateXlsxFile()}>Generate Xlsx</button>
                        }
                    </div>
                    <p className="basic-head03">Displays specification for selected period <b>{firstDay}</b> to <b>{lastDay}</b></p>
                </div>
                <div className="table-content">
                    <table>
                        <thead>
                            <tr>
                                <td>Date</td>
                                <td>Certificate ID</td>
                                <td>Certificate Type</td>
                                <td>Product</td>
                                <td>Market</td>
                                <td>Price</td>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTable}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-wrapper">
                    {paginationData.next_page &&
                        <ReactPaginate
                            previousLabel="Previous"
                            activePage={Math.ceil(paginationData.current_page)}
                            nextLabel="Next"
                            breakLabel={"..."}
                            pageCount={Math.ceil(paginationData.total_pages)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            onPageChange={(e) => setPage(e.selected + 1)}
                        />
                    }
                </div>
            </div>
        </div >
    )
}

export default MoreDetailsPage;


