import React from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import "./scss/report.scss";

const ReportFilter = ({
    handleCallback = () => { },
    getStartDate = () => { },
    getEndDate = () => { },
    handleSubmit = () => { },
    setSelectedReportType = () => { },
    setSearchKey = () => { },
    reportTypes = [],
    searchKey = ""
}) => {
    return (
        <div className="df-main">
            <div className="df-left capitalize">
                <div>Reports</div>
            </div>
            <div className="df-right">
                <div className="ld-search-container">
                    <form className="df-form-container" id="df-form-container">
                        <div className='section-search'>
                            <input
                                id='search'
                                placeholder='Search'
                                autoComplete='off'
                                type='text'
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                        </div>
                        <div className="custom-select">
                            <select
                                className="ld-form-input-field"
                                onChange={(e) => setSelectedReportType(e.target.value)}
                            >
                                <option value="">Request Type</option>
                                {Object.keys(reportTypes).map((k) => (
                                    <option key={k} value={reportTypes[k]}>{reportTypes[k]}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <DateRangePicker
                                onCallback={handleCallback}
                                initialSettings={{
                                    startDate: getStartDate(new Date()),
                                    endDate: getEndDate(new Date()),
                                    locale: { format: 'DD/MM/YYYY' },
                                }}
                                onApply={handleSubmit}
                            >
                                <input
                                    type="text"
                                    name="sms_date_range"
                                    id="form-date-field"
                                />
                            </DateRangePicker>
                        </div>
                        <div className="ld-calendar-icon">
                            <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReportFilter;