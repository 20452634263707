import React, { useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCreateSubscriber } from '../../Redux/Actions/TestCenter';
import { getdisplayFlash, setdisplayFlash } from '../../Utils/Common';
import FlashMessage from '../FlashMessage/FlashMessage';

// LIBRARIES
// import moment from 'moment';
import moment from 'moment-timezone';
import DatePicker, { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// ASSETS
import arrowIcon from './assets/kreditz-arrow-icon.svg';
import calendarIcon from './assets/kreditz-calendar-icon.png';
import timeIcon from './assets/kreditz-time-icon.png';
import dropdownIcon from './assets/kreditz-dropdown-icon.png';

// STYLES
import './styles/CreateSubscriber.css';
import 'react-datepicker/dist/react-datepicker.css';

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#4CD964;',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const formatMarketType = (country) => {
  return (
    country.name
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ')
  );
};

const CreateSubscriber = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  registerLocale("sv", sv);

  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedSSN, setSelectedSSN] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [selectedCertificateType, setSelectedCertificateType] = useState('');
  const [selectedCountryID, setSelectedCountryID] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedRequest, setSelectedRequest] = useState('');
  const [selectedTime, setSelectedTime] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: ''
  });
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [isContinuous, setIsContinuous] = useState(false);
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState('alert');
  const [firstMsg, setFirstMsg] = useState('Error!');
  const [secondMsg, setSecondMsg] = useState('Incorrect or already existing parameters');

  const certificates = useSelector(state => state.test_centers.types);

  const certificateTypes = Object.values(certificates);
  const countries = useSelector(state => state.test_centers.countries);
  const languageTypes = useSelector(state => state.test_centers.language);
  const products = useSelector(state => state.test_centers.product_types);
  const productTypes = Object.values(products);

  const times = Object.values(selectedTime)
    .filter((time) => !!time)
    .map((time) => {
      return moment(time).format('hh:mm A');
    })
    .join(', ');


  const createSubHandler = async (e) => {
    e && e.preventDefault();
    const parameters = {
      fullName: selectedFullName,
      ssn: selectedSSN,
      email: selectedEmail,
      phoneNumber: selectedPhoneNumber,
      certificateType: selectedCertificateType,
      countryID: selectedCountryID,
      language: selectedLanguage,
      product: selectedProduct,
      request: selectedRequest,
      time: times,
      startDate: moment(selectedStartDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(selectedEndDate).format('YYYY-MM-DDTHH:mm:ss'),
      continuously: isContinuous,
      timezone: moment.tz.guess()
    };

    const { requestSuccess, message } = await dispatch(getCreateSubscriber(parameters));
    if (requestSuccess) {
      setdisplayFlash(true);
      history.push({ pathname: '/testCenter' });
    } else {
      openFlash('alert', 'Error!', message);
    };
  };

  const closeFlash = () => {
    setdisplayFlash(false);
    setFlashMessage(false);
  };

  const openFlash = () => {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  };



  const CustomDateInput = forwardRef((props, ref) => {
    return (
      <main className='cs-calendar'>
        <p
          className='cs-custom-input-p'
          onClick={props.onClick}
          ref={ref}>
          {props.value || props.placeholder}
          <span className='cs-clock-icon-container'>
            <img
              className='cs-calendar-icon'
              alt='calendar icon'
              src={calendarIcon}
              onClick={props.onClick} />
          </span>
        </p>
      </main>
    );
  });

  const CustomTimeInput = forwardRef((props, ref) => {
    return (
      <main className='cs-time'>
        <p
          className='cs-custom-input-p'
          onClick={props.onClick}
          ref={ref}>
          {props.value || props.placeholder}
          <span className='cs-calendar-icon-container' >
            <img
              className='cs-clock-icon'
              alt='clock icon'
              src={timeIcon}
              onClick={props.onClick} />
          </span>
        </p>
      </main>
    );
  });

  const requestTimes = [];
  const request = parseInt(selectedRequest, 10);
  for (let i = 0; i < request; i++) {
    requestTimes.push(
      <div className='cs-times-position' key={`time${i + 1}`}>
        <p className='cs-p'>Time {i + 1}</p>
        <div>
          <DatePicker
            selected={selectedTime[i + 1]}
            onChange={(time) => setSelectedTime({ ...selectedTime, [i + 1]: time })}
            showTimeSelect
            showTimeSelectOnly
            placeholderText='Choose'
            timeIntervals={1}
            timeCaption='Time'
            dateFormat='hh:mm'
            customInput={<CustomTimeInput />}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='page-container'>
      <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className='cs-heading-container'>
        <div onClick={() => history.push('/testCenter')} className='cs-icon-wrapper'>
          <img src={arrowIcon} className='cs-arrow-icon' alt='arrow icon' />
        </div>
        <h3 className='cs-h3'>Create a new subscriber</h3>
      </div>
      <main className='cs-grid-container'>
        <div className='cs-name-grid-wrapper'>
          <p className='cs-p'>Full name</p>
          <input
            value={selectedFullName}
            onChange={(e) => setSelectedFullName(e.target.value)}
            className='cs-input inherit'
            type='text'
            placeholder='Enter full name'
          />
        </div>
        <div className='cs-ssn-grid-wrapper'>
          <p className='cs-p'>SSN</p>
          <input
            value={selectedSSN}
            onChange={(e) => setSelectedSSN(e.target.value)}
            className='cs-input inherit'
            type='text'
            placeholder='Enter SSN'
          />
        </div>
        <div className='cs-email-grid-wrapper'>
          <p className='cs-p'>Email address</p>
          <input
            value={selectedEmail}
            onChange={(e) => setSelectedEmail(e.target.value)}
            className='cs-input inherit'
            type='text'
            placeholder='Enter email address'
          />
        </div>
        <div className='cs-phone-grid-wrapper'>
          <p className='cs-p'>Phone number</p>
          <input
            value={selectedPhoneNumber}
            onChange={(e) => setSelectedPhoneNumber(e.target.value)}
            className='cs-input inherit'
            type='text'
            placeholder='Enter phone number'
          />
        </div>
        <div className='cs-certificate-grid-row'>
          <p className='cs-p'>Certificate type</p>
          <select
            value={selectedCertificateType}
            onChange={(e) => setSelectedCertificateType(e.target.value)}
            id='cs-select'
            className='cs-input capitalize'>
            <option value='Select'>Select</option>
            {Object.keys(certificateTypes).map((k) => (
              <option key={k} value={certificateTypes[k]}>{certificateTypes[k] === "customer" ? "Consumer" : certificateTypes[k]}</option>
            ))}
          </select>
          <img src={dropdownIcon} className='cs-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='cs-market-grid-wrapper'>
          <p className='cs-p'>Market</p>
          <select
            value={selectedCountryID}
            onChange={(e) => setSelectedCountryID(e.target.value)}
            id='cs-select'
            className='cs-input'>
            <option value='Select'>Select</option>
            {countries.map((country, index) =>
              <option
                label={formatMarketType(country)}
                value={country.id}
                key={`country - option - ${index + 1}`}>
                {country.name}
              </option>
            )}
          </select>
          <img src={dropdownIcon} className='cs-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='cs-language-grid-wrapper'>
          <p className='cs-p'>Language</p>
          <select
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            id='cs-select'
            className='cs-input capitalize'>
            <option value='Select'>Select</option>
            {Object.keys(languageTypes).map((k) => (
              <option key={k} value={k}>{languageTypes[k]}</option>
            ))}
          </select>
          <img src={dropdownIcon} className='cs-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='cs-product-grid-wrapper'>
          <p className='cs-p'>Product</p>
          <select
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            id='cs-select'
            className='cs-input capitalize'>
            <option value='Select'>Select</option>
            {Object.keys(productTypes).map((k) => (
              <option key={k} value={productTypes[k]}>{productTypes[k]}</option>
            ))}
          </select>
          <img src={dropdownIcon} className='cs-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='cs-request-grid-container'>
          <div>
            <p className='cs-p'>Request per day</p>
            <select
              value={selectedRequest}
              onChange={(e) => setSelectedRequest(e.target.value)}
              className='cs-input-mini'>
              <option value=''>Select</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
            </select>
            <img src={dropdownIcon} className='cs-dropdown-icon-alternative' alt='dropdown icon' />
          </div>
          {requestTimes}
        </div>
        <div className='cs-calendars-grid-wrapper'>
          <div className='cs-from-calendar'>
            <p className='cs-p'>From</p>
            <DatePicker
              selected={selectedStartDate}
              onChange={(date) => setSelectedStartDate(date)}
              placeholderText='Choose Date'
              customInput={<CustomDateInput />}
              dateFormat="dd/MM/yyyy"
              locale="sv"
            />
          </div>
          <div>
            <p className='cs-p'>To</p>
            <DatePicker
              selected={selectedEndDate}
              onChange={(date) => setSelectedEndDate(date)}
              placeholderText='Choose Date'
              customInput={<CustomDateInput />}
              dateFormat="dd/MM/yyyy"
              locale="sv"
            />
          </div>
        </div>
        <div className='cs-switch-grid-wrapper'>
          <FormControlLabel
            value={isContinuous}
            onChange={(event) => setIsContinuous(event.target.checked)}
            className='cs-switch'
            control={<CustomSwitch sx={{ m: 1 }} />}
            label='Continuously'
          />
        </div>
        <div className='cs-btns-grid-container'>
          <div className='cs-cancel-btn-wrapper'>
            <button className='cs-cancel-btn' onClick={() => history.push('/testCenter')}>Cancel</button>
          </div>
          <div className='cs-create-btn-wrapper'>
            <button className='cs-create-btn' type='button' onClick={createSubHandler}>Create</button>
          </div>
        </div>
      </main >
    </div >
  );
};

export default CreateSubscriber;
