import { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import close from '../../assets/images/icon_close.png';
const PreViewPopup = ({ apiKey, content, closePreview }) => {
  const contentData = { content }
  const [editorContent, setEditorContent] = useState(contentData)

  const handleHeaderChange = (content, editor) => {
    if (content !== editorContent.content) {
      setEditorContent(prev => ({ ...prev, content: content }));
    }
  };
  return (
    <div className='DeletePopup'>
      <div className='previewPopup_inner'>
        <img
          className='close'
          src={close}
          onClick={closePreview}
          alt='close'
        />
        <Editor
          apiKey={apiKey}
          value={editorContent.content}
          onEditorChange={handleHeaderChange}
          init={{
            height: '100%',
            width: '100%',
            image_advtab: true,
            menubar: false,
            toolbar: ``,
          }}
        />
      </div>
    </div>
  )
}

export default PreViewPopup;