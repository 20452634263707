import { SET_LIVE_DATA_SIDEBAR } from '../Actions/Types/Types';

const initialState = {
  toggleLiveDataSidebar: false,
};

const live_data_sidebar = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIVE_DATA_SIDEBAR:
      return {
        toggleLiveDataSidebar: action.value,
      };
    default:
      return state;
  }
};

export default live_data_sidebar;