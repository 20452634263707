import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getOrganizationProvider() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.ORGANIZATION_PROVIDERS_LIST()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}


export function editOrganizationProviderIcon(imgUrl, data, status, banking_authentication_type = '') {
  let urlParams = banking_authentication_type === '' || banking_authentication_type === null ? `active=${status}` : `active=${status}&banking_authentication_type=${banking_authentication_type}`
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.EDIT_ORGANIZATION_PROVIDER_ICON() + data.id}?${urlParams}`;
  return dispatch => axios.put(apiURL, imgUrl)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getKreditzProviderList() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.DISPLAY_KREDITZ_PROVIDER()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getBankPriorityUpdate(bank) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.BANK_PRIORITY_UPDATE()}`;
  return dispatch => axios.put(apiURL, bank)
    .then(res => {
      return { status: true, message: res.data.message };
    }).catch(err => {
      (err?.response?.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err?.response?.data?.error || err?.response?.data?.message }
    })
}

export function OrganizationProvider(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.UPDATE()}${data.id}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function OrganizationProviderFilter(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.DATAFILTER()}` + data;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function createOrganizationProvider(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.CREATE()}`;
  return (dispatch) =>
    axios
      .post(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function deleteOrganizationProvider(id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_PROVIDERS.DELETE()}${id}`;
  return (dispatch) =>
    axios
      .delete(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return { status: false, message: err.response.data.message };
      });
}

// export function getOrganizationProviderFilter(data) {
//   const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.organization_providers.FILTER()}`;
//   return (dispatch) =>
//     axios
//       .get(apiURL)
//       .then((res) => {
//         return { status: true, message: res.data.status, data: res.data.data };
//       })
//       .catch((err) => {
//         err.response.status === 401 && removeHeaderWithLocalstorage();
//         return {
//           status: false,
//           message: err.response.data.error || err.response.data.message,
//         };
//       });
// }