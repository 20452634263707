import React, { useEffect } from "react";
import DashboardList from "../../Components/Dashboard/dashboardList";
import ClientPage from "../../Components/Dashboard/clientPage";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import "./style.css";

const Dashboard = (props) => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Dashboard";
  }, []);
  const { orgId } = props.location?.state || "";
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        {orgId ? <ClientPage orgId={orgId} /> : <DashboardList />}
      </div>
    </div>
  );
};

export default Dashboard;
