import React, {useState, useEffect, useCallback} from 'react';
import './OrganizationsBrokerFlow.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from 'react-router-dom';
import backIcon from '../../assets/images/icon_back.png';
import BrokerFlowChart from './misc/BrokerFlowChart';
import moment from 'moment';
import BrokerFlowTable from './misc/BrokerFlowTable';
import { getOrganizationBrokerDetails } from '../../Redux/Actions/OrganizationManagement';
import { useDispatch } from 'react-redux'

function OrganizationsBrokerFlowDetails() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { broker } = location.state;
  const [dateRange, setDateRange] = useState(() => {
    const savedRange = sessionStorage.getItem('dateRange');
    if (savedRange) {
      const { startDate, endDate } = JSON.parse(savedRange);
      return { startDate: moment(startDate, 'YYYY/MM/DD'), endDate: moment(endDate, 'YYYY/MM/DD') };
    }
    return { startDate: moment(), endDate: moment() }; 
  });
  const [pickerKey, setPickerKey] = useState(0); 
  const [chartType, setChartType] = useState('pie');
  const [filteredPartnersData, setFilteredPartnersData] = useState([]);
  const sortOrder = 'asc';
  const initialBroker = location.state.broker;
  const [brokerDetails, setBrokerDetails] = useState({
    name: initialBroker.name,
    partners_count: initialBroker.partners_count,
    total_shared_certificate: initialBroker.total_shared_certificate,
  });
  const [isTotalRequestsActive, setIsTotalRequestsActive] = useState(() => {
    const savedTotalActive = sessionStorage.getItem('totalActive');
    return savedTotalActive === 'true';
  });
  
 const fetchOrganizationBrokerDetails = useCallback(async (customDateRange) => {
    const params = { ...(customDateRange && { date_range: customDateRange }), ...(initialBroker && initialBroker.id && { broker_id: initialBroker.id }) };
    
    if (customDateRange && customDateRange.startDate && customDateRange.endDate) {
      const formattedStartDate = moment.isMoment(customDateRange.startDate) ? customDateRange.startDate.format('YYYY/MM/DD') : moment(customDateRange.startDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
      const formattedEndDate = moment.isMoment(customDateRange.endDate) ? customDateRange.endDate.format('YYYY/MM/DD') : moment(customDateRange.endDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
      params.date_range = `${formattedStartDate}-${formattedEndDate}`;
    }

    dispatch(getOrganizationBrokerDetails(params))
      .then(response => {
        if (response && response.status === true) {
          const orgData = response.data.organizations.find(org => org.id === initialBroker.id);
          const sortedData = orgData ? sortPartnersData(orgData.partners_data) : [];
          setFilteredPartnersData(sortedData);

          setBrokerDetails({
            name: orgData.name,
            partners_count: orgData.partners_count,
            total_shared_certificate: orgData.total_shared_certificate,
          });
        } else {
          console.error('No data or unauthorized', response.message);
          if (response.message === 'Unauthorized') {
            history.push('/login');
          }
        }
      })
      .catch(error => {
        console.error('Error fetching organization broker details:', error);
      });
  }, [dispatch, history, initialBroker, dateRange]);


  const sortPartnersData = useCallback((data, sortOrder) => {
    return [...data].sort((a, b) => {
        if (sortOrder === 'desc') {
            return a.requests_shared - b.requests_shared; 
        } else {
            return b.requests_shared - a.requests_shared; 
        }
    });
}, []);

useEffect(() => {
  if (broker && broker.partners_data) {
    const sortedData = sortPartnersData(broker.partners_data, sortOrder);
    setFilteredPartnersData(sortedData);
  }
}, [broker, sortOrder]);


const handleDateRangeChange = (event, picker) => {
  const startDate = picker.startDate.format('YYYY/MM/DD');
  const endDate = picker.endDate.format('YYYY/MM/DD');
  const dateRange = `${startDate}-${endDate}`;

  sessionStorage.setItem('dateRange', JSON.stringify({ startDate, endDate }));

  setIsTotalRequestsActive(false);
  setDateRange({ startDate: picker.startDate, endDate: picker.endDate });
  fetchOrganizationBrokerDetails(dateRange);
};

const resetDateRange = () => {
  const today = moment();
  sessionStorage.setItem('dateRange', JSON.stringify({ startDate: today.format('YYYY/MM/DD'), endDate: today.format('YYYY/MM/DD') }));
  sessionStorage.removeItem('totalActive');
  setIsTotalRequestsActive(false);
  setDateRange({ startDate: today, endDate: today });
  setPickerKey(prevKey => prevKey + 1);
  fetchOrganizationBrokerDetails({ startDate: today, endDate: today });
};

useEffect(() => {
  if (!dateRange.startDate && !dateRange.endDate) fetchOrganizationBrokerDetails(null);
}, [dateRange.startDate, dateRange.endDate, fetchOrganizationBrokerDetails]);

const showResetIcon = isTotalRequestsActive || (dateRange.startDate && dateRange.endDate && (
  moment().format('YYYY/MM/DD') !== moment(dateRange.startDate).format('YYYY/MM/DD') ||
  moment().format('YYYY/MM/DD') !== moment(dateRange.endDate).format('YYYY/MM/DD')));

  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Partner', accessor: 'name' },
    { Header: 'Request received', accessor: 'requests_shared' },
  ];

  return (
    <div className="broker-flow-container">
      <div className="broker-flow-ld-container">
      <span
            onClick={() => history.push("/org_broker_flow")}
            className="esu-icon-wrapper"
          >
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} />
          </span>
        <h3 className="ld-header-broker-flow">Organizations - Broker flow</h3>
      </div>
      <div className="broker-flow-table-top bg-white">
        <div className="broker-flow-details-flex-header customer-activity-header">
          <div className="flex-item">
            <p style={{textDecorationLine: 'none', color: '#8791A4', fontSize: '16px'}}>{brokerDetails.name}</p>
          </div>
          <div className="flex-item">
            <p style={{textDecorationLine: 'none', color: '#8791A4', fontSize: '16px'}}>Total partners: {brokerDetails.partners_count}</p>
          </div>
          <div className="flex-item">
            <p style={{textDecorationLine: 'none', color: '#8791A4', fontSize: '16px'}}>Shared requests: {brokerDetails.total_shared_certificate}</p>
          </div>
          <div className="flex-item">
            <div className="broker-flow-date-picker-wrapper">
            <DateRangePicker 
              key={pickerKey} 
              onApply={handleDateRangeChange} 
              startDate={dateRange.startDate ? dateRange.startDate.format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')} 
              endDate={dateRange.endDate ? dateRange.endDate.format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')}
              initialSettings={{
                startDate: dateRange.startDate ? dateRange.startDate.toDate() : moment().toDate(),
                endDate: dateRange.endDate ? dateRange.endDate.toDate() : moment().toDate(),
                locale: { format: 'YYYY/MM/DD' },
              }}>
                <input
                  type="text"
                  className="date-form-box"
                  id="date-range-picker"
                  value={isTotalRequestsActive ? '-/-/-' : (dateRange.startDate && dateRange.endDate) ? `${dateRange.startDate.format('YYYY/MM/DD')} - ${dateRange.endDate.format('YYYY/MM/DD')}` : ''}
                  readOnly
                  style={{ cursor: "pointer"}}
                />
            </DateRangePicker>
              {showResetIcon && (
              <button onClick={resetDateRange} className="broker-flow-reset-date-button">
                <FontAwesomeIcon icon={faTimes} />
              </button>
              )}
              <div className="broker-flow-customer-activity-icon">
                <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
              </div>
            </div>
          </div>
        </div>
          <div className='broker-flow-chart-select'>
            <select
                className="ld-form-input-field capitalize"
                id="ld-select"
                value={chartType}
                onChange={(e) => setChartType(e.target.value)}
                style={{ width: '14.3%' }}
              >
             <option value="pie">Pie Chart</option>
             <option value="bar">Bar Chart</option>
             <option value="column">Column Chart</option>
            </select>
          </div>
        <div className="broker-flow-chart-details">
        <BrokerFlowChart data={filteredPartnersData} chartType={chartType} />
      </div>
        <div className="broker-flow-thead-scroll-y">
        <BrokerFlowTable columns={columns} data={filteredPartnersData} />
        </div>
      </div>
    </div>
  );
}

export default OrganizationsBrokerFlowDetails;
