const GenerateApiAccessCode = (props) => {
  const regex = /\S+@\S+\.\S+/; //email format validation check
  let accessCodeData = props.accessCodeData;
  let isDisabled = props.isDisabled;
  let newAccessCode = props.newAccessCode;

  return (
    <div className="left-boxes-access">
      <div className="send-access-code-box">
        <h5 className="generate-header generate-background">Generate one time password code for <br /> API - Documentation</h5>
        <h5 className="access-code-enter-email-text">Generate a code and enter email to send it</h5>
        <div className="sendcode-div">
          <div className="input-access-email-box">
            <input className="api-access-input" defaultValue={newAccessCode} disabled={true}></input>
            <button
              className="generate-access-code-button"
              onClick={() => {
                props.generateNewAccessCode();
              }}
            > Generate code
            </button>
            <label className="send-access-code-label">Email Address</label><br></br>
            <input
              type="text"
              className="send-access-code-form"
              placeholder="Enter email address"
              value={accessCodeData.email}
              name="email"
              onChange={(e) => {
                props.handleChange(e);
              }}
            />
          </div>
          {isDisabled || !regex.test(accessCodeData.email) ? (
            <button
              className="send-button-access"
              style={{ backgroundColor: "#8D96A9" }}
            >Send
            </button>
          ) : (
            <button
              className="send-button-access"
              onClick={() => {
                props.openPopupHandler()
              }}>Send</button>
          )}
        </div>
      </div>
    </div>
  )
}

export default GenerateApiAccessCode;