import React from 'react'
import { useHistory } from 'react-router-dom';

const ConfirmationMessage = ({ setShowConfirmDialog }) => {
  const history = useHistory();

  const redirectToReports = () => {
    history.push('/reports');
  };

  return (
    <div className="broker-flow-confirmation-message-container">
    <div className="broker-flow-confirmation-message-successpopup_inner">
      <div className="broker-flow-flashNameHead">
        <p>Do you want to download the Excel file from the Reports page now?</p>
      </div>
      <div className='broker-flow-confirmation-message-buttons'>
        <button className='broker-flow-confirmation-message-button' onClick={() => { redirectToReports(); setShowConfirmDialog(false); }}>Yes</button>
        <button className='broker-flow-confirmation-message-button' onClick={() => setShowConfirmDialog(false)}>No</button>
      </div>
    </div>
  </div>
  )
}

export default ConfirmationMessage