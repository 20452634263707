export const processDataForMap = (backendData, countriesList) => {
  // Building a mapping of ISO codes to country names for easy lookup
  const isoCodeToCountryName = countriesList.reduce((acc, country) => {
    acc[country.code.toUpperCase()] = country.name;
    return acc;
  }, {});

  const requestCounts = {};
  const processedData = [];

  backendData.forEach(item => {
    const country = countriesList.find(c => c.id === item.country_id);
    const isoCode = country ? country.code.toUpperCase() : null;

    if (isoCode) {

      if (!requestCounts[isoCode]) {
        requestCounts[isoCode] = { count: 0, lastProcessedId: -1 };
      }

      requestCounts[isoCode].count += 1;
      requestCounts[isoCode].lastProcessedId = item.id;

      processedData.push({
        'iso-a2': isoCode,
        z: requestCounts[isoCode].count,
        name: isoCodeToCountryName[isoCode],
      });
    }
  });

  return processedData;
};
