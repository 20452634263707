import axios from 'axios';
import { API_CONFIG } from "../../config/api";

export function getBanners(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANNERS.LIST()}`;
    return (dispatch) =>
        axios
            .get(apiURL)
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}
export function getBannerById(id) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANNERS.LIST()}/` + id;
    return (dispatch) =>
        axios
            .get(apiURL)
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}
export function updateBanner(data) {

    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANNERS.UPDATE()}/${data.id}`;
    return (dispatch) =>
        axios
            .put(apiURL, data)
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}
export function addBanner(data) {

    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANNERS.CREATE()}`;
    return (dispatch) =>
        axios
            .post(apiURL, data)
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}
export function deleteBanner(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANNERS.DELETE()}/${data.id}`;
    return (dispatch) =>
        axios
            .delete(apiURL, { data })
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}