import React, { useState } from "react";
import { left } from "@popperjs/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from "moment";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns'

var curr = new Date();
export const WEEK = `${moment(startOfWeek(curr, { weekStartsOn: 1 })).format('DD/MM/YYYY')}-${moment(endOfWeek(curr, { weekStartsOn: 1 })).format('DD/MM/YYYY')}`;
export const MONTH = `${moment(startOfMonth(curr)).format('DD/MM/YYYY')}-${moment(endOfMonth(curr)).format('DD/MM/YYYY')}`;
export const YEAR = `${moment(startOfYear(curr)).format('DD/MM/YYYY')}-${moment(endOfYear(curr)).format('DD/MM/YYYY')}`;

const ClientViewGraph = ({
  accepted_requests = {},
  organization = {},
  sent_requests = {},
  getClientViewDetails = (id, date) => { },
  dateFilter = false,
  setDateFilter = () => { }
}) => {
  const [activeTab, setActiveTab] = useState('month');
  const sent_requests_months = Object.keys(sent_requests)
  const sent_requests_values = Object.values(sent_requests)
  const accepted_requests_values = Object.values(accepted_requests)

  function setDuration(type) {
    switch (type) {
      case 'week': return WEEK;
      case 'month': return MONTH;
      case 'year': return YEAR;
      default: return MONTH
    }
  }

  const changeRecord = (type) => {
    setDateFilter(false)
    setActiveTab(type)
    getClientViewDetails(setDuration(type))
  }

  const options = {
    chart: {
      type: "column",
      height: 330,
      style: {
        fontFamily: 'SF Pro Display'
      }
    },
    title: {
      text: "Sent requests vs accepted requests for " + setDuration(activeTab),
      align: left,
      margin: 20,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 10
      },
      series: {
        minPointLength: 2
      }
    },
    colors: ["#3e85f9", "#43BF58"],
    xAxis: {
      categories: sent_requests_months,
      title: {
        text: activeTab.charAt(0).toUpperCase() + activeTab.slice(1),
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total counts",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Sent Requests",
        data: sent_requests_values,
        borderRadiusTopLeft: '20px',
        borderRadiusTopRight: '20px'
      },
      {
        name: "Accepted Requests",
        data: accepted_requests_values,
        borderRadiusTopLeft: '20px',
        borderRadiusTopRight: '20px'
      }
    ],
  };
  return (
    <div className="ld-main-data-container cv-mr-right">
      <ul className="switch-map">
        <li className={dateFilter ? 'week-btn' : activeTab === 'week' ? "week-btn active-table" : "week-btn"} id="graph_week_btn" onClick={() => changeRecord("week")}>This Week</li>
        <li className={dateFilter ? 'month-btn' : activeTab === 'month' ? "month-btn active-table" : "month-btn"} id="graph_month_btn" onClick={() => changeRecord("month")}>This Month</li>
        <li className={dateFilter ? 'year-btn' : activeTab === 'year' ? "year-btn active-table" : "year-btn"} id="graph_year_btn" onClick={() => changeRecord("year")}>This Year</li>
      </ul>
      <div className="ld-requests-container">
        <div className="ld-request-chart-container ">
          <div className="ld-request-chart yearGraph">
            <HighchartsReact
              containerProps={{ style: { height: "310px" } }}
              highcharts={Highcharts}
              options={options}
            ></HighchartsReact>
          </div>
        </div>
      </div>
    </div>
  )

}
export default ClientViewGraph;