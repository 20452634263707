import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getHealthcheck(page, searchTerm = '', selectedMarket = '', selectedProvider = '', selectedStatus = '', missingValues = 'false') {
  let apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.HEALTH_CHECK.LIST()}?page=${page}&search=${encodeURIComponent(searchTerm)}`;

  if (selectedMarket) {
    apiURL += `&market=${encodeURIComponent(selectedMarket)}`;
  }
  if (selectedProvider) {
    apiURL += `&provider=${encodeURIComponent(selectedProvider)}`;
  }
  if (selectedStatus) {
    apiURL += `&status=${encodeURIComponent(selectedStatus)}`;
  }

  if (missingValues !== 'false') {
    apiURL += `&missing_values=${encodeURIComponent(missingValues)}`;
  }

  return dispatch => axios.get(apiURL)
    .then(res => {
      if (res.status === 200) {
        return {
          status: res.status,
          data: res.data.data.all_statuses,
          pagination: res.data.pagination
        };
      } else {
        console.error("Non-200 status code received", res.status);
        return { status: res.status };
      }
    }).catch(err => {
      if (err.response) {
        console.error("API Error with status:", err.response.status, err.response.data);
        return {
          status: err.response.status,
          message: err.message
        };
      } else {
        console.error("API Error:", err);
        return { status: 500, message: "Internal Server Error" };
      }
    })
}

export function updateHealthcheck(id, healthcheckData) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.HEALTH_CHECK.UPDATE()}/${id}`;
  return dispatch => axios.put(apiURL, healthcheckData)
    .then(res => {
      return { status: true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.status === 401) {
        removeHeaderWithLocalstorage();
      }
      return { status: false, message: errorResponse ? errorResponse.data.error || errorResponse.data.message : "An unknown error occurred" }
    });
}