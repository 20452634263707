import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { DASHBOARD_DATA } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function dashboardGraphData(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DASHBOARD.INDEX()}${API_CONFIG.END_POINTS.DASHBOARD.GRAPH_DATA()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.message, data: res.data.data }
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message }
        })
}

export function dashboardClientsData(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DASHBOARD.INDEX()}${API_CONFIG.END_POINTS.DASHBOARD.CLIENTS_DATA()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.message, data: res.data.data }
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message }
        })
}

export function getOrganizationDetails(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DASHBOARD.INDEX()}${API_CONFIG.END_POINTS.DASHBOARD.CLIENT_DETAILS()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            const success = res.data.status !== 'error'
            if (success) {
                dispatch(setGraphData(res.data.data))
            }
            return { status: true, message: res.data.message, data: res.data.data }
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message }
        })
}

export function setGraphData(data) {
    return {
        type: DASHBOARD_DATA,
        data: data
    }
}