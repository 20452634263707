import React, { useState, forwardRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editSubscriber, getBasicValues } from '../../Redux/Actions/TestCenter';
import { getdisplayFlash, setdisplayFlash } from '../../Utils/Common';
import FlashMessage from '../FlashMessage/FlashMessage';

// LIBRARIES
// import moment from 'moment';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//ASSETS
import arrowIcon from './assets/kreditz-arrow-icon.svg';
import calendarIcon from './assets/kreditz-calendar-icon.png';
import timeIcon from './assets/kreditz-time-icon.png';
import dropdownIcon from './assets/kreditz-dropdown-icon.png';

// STYLES
import './styles/CreateSubscriber.css';
import './styles/EditSubscriber.css';
import 'react-datepicker/dist/react-datepicker.css';


const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#4CD964;',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const formatMarketType = (country) => {
  return country.name
    .replace('denmark', 'Denmark')
    .replace('finland', 'Finland')
    .replace('germany', 'Germany')
    .replace('india', 'India')
    .replace('netherland', 'Netherland')
    .replace('norway', 'Norway')
    .replace('portugal', 'Portugal')
    .replace('spain', 'Spain')
    .replace('united kingdom', 'United Kingdom')
    .replace('united states', 'United States')
    .replace('sweden', 'Sweden')
};

const EditSubscriber = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const getSubscriber = history.location.state.subscriber;
  const [selectedFullName, setSelectedFullName] = useState(getSubscriber.full_name);
  const [selectedSSN, setSelectedSSN] = useState(getSubscriber.ssn);
  const [selectedEmail, setSelectedEmail] = useState(getSubscriber.email);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(getSubscriber.phone);
  const [selectedCertificateType, setSelectedCertificateType] = useState(getSubscriber.request_type);
  const [selectedCountryID, setSelectedCountryID] = useState(getSubscriber.country_id);
  const [selectedLanguage, setSelectedLanguage] = useState(getSubscriber.language);
  const [selectedProduct, setSelectedProduct] = useState(getSubscriber.product);
  const [selectedRequest, setSelectedRequest] = useState(getSubscriber.request_per_day);
  const [selectedTime, setSelectedTime] = useState(getSubscriber.request_times);
  const [selectedStartDate, setSelectedStartDate] = useState(getSubscriber.start_date ? new Date(getSubscriber.start_date) : new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(getSubscriber.end_date ? new Date(getSubscriber.end_date) : new Date());
  const [isContinuous, setIsContinuous] = useState(getSubscriber.continuously);
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState('');
  const [firstMsg, setFirstMsg] = useState('');
  const [secondMsg, setSecondMsg] = useState('');

  const certificates = useSelector(state => state.test_centers.types);
  const certificateTypes = Object.values(certificates);
  const countries = useSelector(state => state.test_centers.countries);
  const languageTypes = useSelector(state => state.test_centers.language);
  // const languageTypes = Object.values(languages);
  const products = useSelector(state => state.test_centers.product_types);
  const productTypes = Object.values(products);

  const CustomDateInput = forwardRef((props, ref) => {
    return (
      <main className='es-calendar'>
        <p
          className='es-custom-input-p'
          onClick={props.onClick}
          ref={ref}>
          {props.value || props.placeholder}
          <span className='es-calendar-icon-container'>
            <img
              className='es-calendar-icon'
              alt='calendar icon'
              src={calendarIcon}
              onClick={props.onClick} />
          </span>
        </p>
      </main>
    );
  });

  const CustomTimeInput = forwardRef((props, ref) => {
    return (
      <main className='es-time'>
        <p
          className='es-custom-input-p'
          onClick={props.onClick}
          ref={ref}>
          {props.value || props.placeholder}
          <span className='es-calendar-icon-container'>
            <img
              className='es-clock-icon'
              alt='clock icon'
              src={timeIcon}
              onClick={props.onClick} />
          </span>
        </p>
      </main>
    );
  });


  const editSubscriberHandler = async (e) => {
    e.preventDefault();
    const parameters = {
      subscribers:
      {
        fullName: selectedFullName,
        ssn: selectedSSN,
        email: selectedEmail,
        phoneNumber: selectedPhoneNumber,
        certificateType: selectedCertificateType,
        countryID: selectedCountryID,
        language: selectedLanguage,
        product: selectedProduct,
        request: selectedRequest,
        time: Object.values(selectedTime).map((time) => moment(time, "hh:mm A").format("hh:mm A")).join(', '),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        continuously: isContinuous,
        timezone: moment.tz.guess()
      }
    };

    if (isContinuous) {
      delete parameters.subscribers.startDate;
      delete parameters.subscribers.endDate;
    }

    const { requestSuccess, message } = await dispatch(editSubscriber(parameters, getSubscriber.id));
    if (requestSuccess) {
      openFlash('tick', 'Success', message);
      history.push({ pathname: '/activeSubscribers' });
    }
    else {
      openFlash('alert', 'Error!', message);
    };
  };

  const closeFlash = () => {
    setdisplayFlash(false);
    setFlashMessage(false);
  };

  const openFlash = (iconImage, firstMsg, secondMsg) => {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  };

  const fetchBasicValues = async () => {
    await dispatch(getBasicValues({}));
  };

  useEffect(() => {
    fetchBasicValues()
  }, []);

  const requestTimes = [];
  const request = parseInt(selectedRequest, 10);
  for (let i = 0; i < request; i++) {
    const selected = selectedTime[i + 1];
    let newTime = null
    if ((new Date(selected) !== "Invalid Date") && !isNaN(new Date(selected))) {
      newTime = selected
    } else {
      newTime = selected ? new Date('2022-01-01T' + selected.trim() + ':00') : undefined
    }
    requestTimes.push(
      <div className='es-times-position' key={`time${i + 1}`}>
        <p className='es-p'>Time {i + 1}</p>
        <div>
          <DatePicker
            selected={newTime}
            onChange={(time) => setSelectedTime({ ...selectedTime, [i + 1]: time })}
            showTimeSelect
            showTimeSelectOnly
            placeholderText='Choose'
            timeIntervals={1}
            timeCaption='Time'
            dateFormat='hh:mm'
            customInput={<CustomTimeInput />}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='page-container'>
      <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className='es-heading-container'>
        <div
          onClick={() => history.push({ pathname: '/activeSubscribers' })}
          className='es-icon-wrapper'>
          <img
            src={arrowIcon}
            className='es-arrow-icon'
            alt='arrow icon' />
        </div>
        <h3 className='es-h3'>Edit subscriber</h3>
      </div>
      <main className='es-grid-container'>
        <div className='es-name-grid-wrapper'>
          <p className='es-p'>Full name</p>
          <input
            required
            value={selectedFullName}
            onChange={(e) => setSelectedFullName(e.target.value)}
            className='es-input inherit'
            type='text'
            placeholder='Enter full name'
          />
        </div>
        <div className='es-ssn-grid-wrapper'>
          <p className='es-p'>SSN</p>
          <input
            required
            value={selectedSSN}
            onChange={(e) => setSelectedSSN(e.target.value)}
            className='es-input inherit'
            type='text'
            placeholder='Enter SSN'
          />
        </div>
        <div className='es-email-grid-wrapper'>
          <p className='es-p'>Email address</p>
          <input
            required
            value={selectedEmail}
            onChange={(e) => setSelectedEmail(e.target.value)}
            className='es-input inherit'
            type='text'
            placeholder='Enter email address'
          />
        </div>
        <div className='es-phone-grid-wrapper'>
          <p className='es-p'>Phone number</p>
          <input
            required
            value={selectedPhoneNumber}
            onChange={(e) => setSelectedPhoneNumber(e.target.value)}
            className='es-input inherit'
            type='text'
            placeholder='Enter phone number'
          />
        </div>
        <div className='es-certificate-grid-row'>
          <p className='es-p'>Certificate type</p>
          <select
            value={selectedCertificateType}
            onChange={(e) => setSelectedCertificateType(e.target.value)}
            id='es-select'
            className='es-input'>
            <option value='Select'>Select</option>
            {Object.keys(certificateTypes).map((k) => (
              <option key={k} value={certificateTypes[k]}>{certificateTypes[k] === "customer" ? "Consumer" : certificateTypes[k]}</option>
            ))}
          </select>
          <img src={dropdownIcon} className='es-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='es-market-grid-wrapper'>
          <p className='es-p'>Market</p>
          <select
            value={selectedCountryID}
            onChange={(e) => setSelectedCountryID(e.target.value)}
            id='es-select'
            className='es-input'>
            <option value='Select'>Select</option>
            {countries.map((country, index) =>
              <option
                label={formatMarketType(country)}
                value={country.id}
                key={`country - option - ${index + 1}`}>
                {country.name}
              </option>
            )}
          </select>
          <img src={dropdownIcon} className='es-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='es-language-grid-wrapper'>
          <p className='es-p'>Language</p>
          <select
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            id='es-select'
            className='es-input'>
            <option value='Select'>Select</option>
            {Object.keys(languageTypes).map((k) => (
              <option key={k} value={k}>{languageTypes[k]}</option>
            ))}
          </select>
          <img src={dropdownIcon} className='es-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='es-product-grid-wrapper'>
          <p className='es-p'>Product</p>
          <select
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            id='es-select'
            className='es-input'>
            <option value='Select'>Select</option>
            {Object.keys(productTypes).map((k) => (
              <option key={k} value={productTypes[k]}>{productTypes[k]}</option>
            ))}
          </select>
          <img src={dropdownIcon} className='es-dropdown-icon' alt='dropdown icon' />
        </div>
        <div className='es-request-grid-container'>
          <div>
            <p className='es-p'>Request per day</p>
            <select
              value={selectedRequest}
              onChange={(e) => setSelectedRequest(e.target.value)}
              className='es-input-mini'>
              <option value=''>Select</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
            </select>
            <img src={dropdownIcon} className='es-dropdown-icon-alternative' alt='dropdown icon' />
          </div>
          {requestTimes}
        </div>
        <div className='es-calendars-grid-wrapper'>
          <div className='es-from-calendar'>
            <p className='es-p'>From</p>
            <DatePicker
              selected={selectedStartDate}
              onChange={(date) => setSelectedStartDate(date)}
              placeholderText='Choose Date'
              customInput={<CustomDateInput />}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div>
            <p className='es-p'>To</p>
            <DatePicker
              selected={selectedEndDate}
              onChange={(date) => setSelectedEndDate(date)}
              placeholderText='Choose Date'
              customInput={<CustomDateInput />}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className='es-switch-grid-wrapper'>
          <FormControlLabel
            checked={isContinuous}
            onChange={(event) => setIsContinuous(event.target.checked)}
            className='es-switch'
            control={<CustomSwitch sx={{ m: 1 }} />}
            label='Continuously'
          />
        </div>
        <div className='es-btns-grid-container'>
          <div className='es-cancel-btn-wrapper'>
            <button className='es-cancel-btn' onClick={() => history.push({ pathname: '/activeSubscribers' })}>Cancel</button>
          </div>
          <div className='es-create-btn-wrapper'>
            <button className='es-create-btn' type='button' onClick={editSubscriberHandler}>Save</button>
          </div>
        </div>
      </main >
    </div>
  )
};

export default EditSubscriber;
