import { SET_EMAIL_LIST } from '../Actions/Types/Types';

const initialState = {
  email_logs_response: {},
  email_logs: [],
  pagination_data: {},
}

const EmailLogs = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_EMAIL_LIST:
      return {
        ...state,
        email_logs_response: action.data,
        email_logs: action.data.email_logs,
        pagination_data: action.data.pagination_data,
      }
    default: return state;
  }
}

export default EmailLogs;