import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

const API_URL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}`;
const ADMIN_LOGS_URL = `${API_URL}${API_CONFIG.END_POINTS.ADMIN_LOGS.LIST()}`;

export function getAdminLogsList(data) {
  const query = data && Object.entries(data).map(([key, value]) => {
    if (!value) {
      return '';
    }
    if (Array.isArray(value)) {
      return value.length ? encodeURI(`${key}=["${value.join(',')}"]`) : '';
    }
    return encodeURI(`${key}=${value}`);
  }).filter(param => !!param).join('&');

  return dispatch => axios.post(`${ADMIN_LOGS_URL}?${query || ''}`)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return {
          requestSuccess: true,
          message: res.data.status,
          data: res.data.data
        }
      }
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return {
        requestSuccess: false,
        message: err.response?.data.message
      }
    });
}