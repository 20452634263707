import React, { useState, useEffect } from 'react';
import "./BackgroundJobs.scss";
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { getAvailableJobs } from "../../Redux/Actions/BackgroundJobs";
import { getusermanagementList } from "../../Redux/Actions/UserManagement";
import JobsTable from "./misc/JobsTable";

const BackgroundJobs = () => {
  const [availableJobs, setAvailableJobs] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const itemsPerPage = 5;

  const fetchBackgroundJobs = async (page = 1, onStatusCheck = null) => {
    dispatch(setLoaderSpinner(true));
    try {
      const response = await dispatch(getAvailableJobs(page, itemsPerPage));
      if (response.status) {
        setAvailableJobs(response.data.available_jobs);
        setPageCount(response.data.total_pages);
        if (onStatusCheck) onStatusCheck(response.data.available_jobs);
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  };

  useEffect(() => {
    let intervalId = null;

    const startPolling = () => {
      if (intervalId) clearInterval(intervalId);
      intervalId = setInterval(() => {
        fetchBackgroundJobs(currentPage + 1, (jobs) => {
          const isAnyJobRunning = jobs.some(job => job.status === 'running');
          if (!isAnyJobRunning) {
            clearInterval(intervalId);
          }
        });
      }, 10000);
    };

    const checkAndStartPolling = () => {
      fetchBackgroundJobs(currentPage + 1, (jobs) => {
        const isAnyJobRunning = jobs.some(job => job.status === 'running');
        if (isAnyJobRunning) {
          startPolling();
        }
      });
    };

    checkAndStartPolling();

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currentPage, dispatch]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchBackgroundJobs(currentPage + 1);
  }, [currentPage]);

  const fetchUserManagementList = async () => {
    dispatch(setLoaderSpinner(true));
    try {
      const query = `?page=${currentPage}&without_pagination=true`;
      const response = await dispatch(getusermanagementList(query));
      if (response.status) {
        setUserData(response.data.users);
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch user data', error);
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  };

  useEffect(() => {
    fetchUserManagementList();
  }, [dispatch]);

  useEffect(() => {
    fetchBackgroundJobs(currentPage + 1);
  }, [currentPage]);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} / ${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  };

  return (
    <div className="background-jobs-container">
      <div className="lower-header-backgroundJobs">
        <h3>Background jobs</h3>
      </div>
      <div className="backgroundJobs-table-top bg-white" >
        <div className="backgroundJobs-thead-scroll-y">
          <JobsTable
            availableJobs={availableJobs}
            userData={userData}
            formatDateTime={formatDateTime}
          />
        </div>
        <div className="pagination-box">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(e) => handlePageClick(e)}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BackgroundJobs;
