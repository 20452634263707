const KreditzProviderUpdated = (props) => {
  const adminLog = props.adminLog;
  let id = adminLog.object_id;
  let newIcon = adminLog.column_affected.kreditz_provider.icon_new;
  let oldIcon = adminLog.column_affected.kreditz_provider.icon_old.url;
  let providerName = adminLog.column_affected.kreditz_provider.name;
  return (
    <div key={Math.random()} >
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>Kreditz Provider</strong>
      </p>
      <p className="al-column-affected-p">
        Name: {" " + providerName}<br />
        Icon: {` ${oldIcon} → ${newIcon.slice(0, 20)}`}<br />
      </p>
    </div>
  )
}

export default KreditzProviderUpdated;