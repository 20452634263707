import React from 'react';
import './DeleteModal.css'
import trash from '../../assets/images/delete_icon.svg';

const DeleteModal = (props) => {
    return (
      <div className='DeletePopup'>
        <div className='DeletePopup_inner'>
          <img src={trash} className='trash' alt="trash"></img>
          { 
            props?.organizationProvider ? (
              <p className='firstText'>{props.firstTextUser} {props.secondTextUser} <strong> {props?.organizationProvider?.kreditz_provider_display_name}</strong> from <strong>{props?.organizationProvider?.organization_name}</strong> in <strong>{props?.organizationProvider?.country_name}</strong> market</p>
              ) : (
                <>
                <p className='firstText'>{props.firstTextUser}</p>
                <p className='secondText'>{props.secondTextUser}</p>
                </>
            )
          }
          <button className='closeDelete' style={{ marginLeft: '60px' }} onClick={props.closeDelete}>Cancel</button>
          <button className='confirm' onClick={props.confirm}>Yes, Delete</button>
        </div>
      </div>
    )
}
export default DeleteModal;