import React, { useState, useEffect } from 'react';
import '../OrganizationsEmailSms/OrganizationsEmailSms.scss';
import { Editor } from '@tinymce/tinymce-react';
import { createOrganizationConsentTexts, editOrganizationConsentTexts, getOrganizationConsentTextsFilters, getOrganizationConsentTextsShow } from '../../Redux/Actions/OrganizationConsentTexts';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FlashMessage from '../FlashMessage/FlashMessage';
import './OrganizationConsentTexts.css';
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import PreViewPopup from './PreviewPopup';
import arrow from '../../assets/images/greater_than2x.png';
import ReactSelect from 'react-select';
import { getServiceSettingsFilter } from '../../Redux/Actions/ServiceSettings';



const CreateOrgConsentTexts = () => {
  const location = useLocation();
  const { identifier, editData } = location.state || {};
  const { editFromDiff } = location.state || false;
  const { default_text, text } = location.state || '';
  const { textPage, showBreadcrumb } = location.state || '';
  const [selectedOrgId, setSelectedOrgId] = useState(editData?.organization?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationsList, setOrganizationsList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [editorContent, setEditorContent] = useState({ content: editData?.content, services_id: editData?.service?.id, locale: editData?.locale, key: editData?.key, display_name: editData?.display_name, default: editData?.default?.toString() || false, organizations: editData?.organizations || [] });
  const apiKey = process.env.REACT_APP_TINY_MCE_API_KEY;
  const [serviceList, setServiceList] = useState([])
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState('');
  const [firstMsg, setFirstMsg] = useState('');
  const [secondMsg, setSecondMsg] = useState('');
  const [preViewData, setPreViewData] = useState('')
  const [locales, setLocales] = useState([])
  const [keys, setKeys] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedOrganizations, setSelectedOrganizations] = useState(editData?.organizations || [])
  const [disabledOrg, setDisabledOrg] = useState(false)

  useEffect(() => {
    fetchServiceSettingFilter()
    fetchOrgconsentTextFilters();
  }, [dispatch, history]);

  useEffect(() => {
    const organizationHandler = () => {
      if (editorContent?.default?.toString() === "true") {
        setSelectedOrganizations([])
        return true
      } else {
        return false
      }
    }
    setDisabledOrg(organizationHandler())
  }, [editorContent?.default])

  const fetchOrgconsentTextFilters = async () => {
    try {
      const response = await dispatch(getOrganizationConsentTextsFilters());
      const { locales, keys } = response.data;
      if (response.status) {
        let sortedLocales = [...locales].sort()
        setLocales(sortedLocales)
        setKeys(keys)
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch organizations consent text filter list', error);
    }
  }

  const fetchServiceSettingFilter = async () => {
    const { data } = await dispatch(getServiceSettingsFilter());
    const { organizations_list, services_list, countries_list } = data;
    setServiceList(services_list)
    setOrganizationsList(organizations_list)
  };

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  const handleHeaderChange = (content, editor) => {
    if (content !== editorContent.content) {
      setEditorContent(prev => ({ ...prev, content: content }));
    }
  };

  const handleDataChange = (key, data) => {
    setEditorContent(prev => ({ ...prev, [key]: data }));
  }


  const handlePreviewClick = () => {
    let previewShow = `${editorContent?.content}` !== 'undefined' ? `${editorContent?.content}` : '';
    setPreViewData(previewShow)
    setIsModalOpen(true);
  };


  const showTextsHandler = async (showData) => {
    try {
      const response = await dispatch(getOrganizationConsentTextsShow(showData.id));
      const { text, default_text } = response.data;
      history.push({ pathname: '/organization_consent_texts_difference', state: { default_text: default_text, text: text, textPage: textPage } })
      if (response.status) {
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch organizations', error);
    }
  }

  const afterUpdateHandler = () => {
    if (editFromDiff) {
      showTextsHandler(editData)
    } else if (textPage) {
      history.push('/texts')
    } else {
      history.push('/organization_texts')
    }
  }

  const createConsentTextsHandler = async () => {
    const content = {
      text: {
        content: editorContent.content,
        service_id: editorContent.services_id,
        locale: editorContent.locale,
        key: editorContent.key,
        display_name: editorContent.display_name,
        default: editorContent.default,
        organization_ids: selectedOrganizations
      },
    }
    if (!content.text.display_name || !content.text.locale || !content.text.key) {
      openFlash("alert", "Error!", 'Please enter Name, Key and Locale');
    }
    else {
      if (identifier === "create") {
        try {
          const response = await dispatch(createOrganizationConsentTexts(content));
          const { status, message } = response?.data
          if (status === "success") {
            openFlash("tick", "Done!", message);
            setTimeout(() => {
              history.push('/texts')
            }, [1000])
          } else {
            openFlash("alert", "Error!", message);
          }

        } catch (error) {
          console.error('Error fetching the template preview:', error);
        }
      } else {
        try {
          const response = await dispatch(editOrganizationConsentTexts(editData, content));
          const { status, message } = response?.data
          if (status === "success") {
            openFlash("tick", "Done!", message);
            setTimeout(() => {
              afterUpdateHandler()
            }, [1000])
          } else {
            openFlash("alert", "Error!", message);
          }

        } catch (error) {
          console.error('Error fetching the template preview:', error);
        }
      }
    }
  }

  function humanize(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  const cloasePreviewPopup = () => {
    setIsModalOpen(false)
  }

  const orgOptions = organizationsList.map(org => ({
    value: org.id,
    label: `${org.name}`
  }));

  const handleOrgChange = (selectedOption) => {
    const selectedOrgIds = selectedOption.map(org => org.value)
    setSelectedOrganizations(selectedOrgIds)
  };

  const selectStyles = {
    container: (base) => ({
      ...base,
      width: '16rem',
      fontFamily: '"SF Pro Display"',
      fontSize: '12px',
      color: 'white',
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: '1px solid #ccc',
      padding: '10px',
      backgroundColor: state.isSelected ? '#3e85f9' : 'white',
      color: state.isSelected ? 'white' : '#596980',
      ':hover': {
        backgroundColor: '#3e85f9',
        color: 'white',
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    control: (base) => ({
      ...base,
      fontFamily: '"SF Pro Display"',
      fontSize: '12px',
      color: '#596980',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#596980',
    })
  };

  const showEditOrgData = () => {
    const defaultEditOrg = editorContent?.organizations?.map(org => ({ value: org.id, label: org.name }))
    return defaultEditOrg || [];
  }


  return (
    <div className="email-sms-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>

      {isModalOpen &&
        <PreViewPopup
          apiKey={apiKey}
          content={preViewData}
          closePreview={cloasePreviewPopup}
        />
      }
      <div className="org-breadcumb-button">
        <h3 className="basic-head01">
          <div className="add-breadcrum">{`${identifier === "edit" ? 'Edit' : 'Create New'} Text`}<p><a href={textPage ? '/texts' : '/organization_texts'}>{textPage ? 'Texts' : 'Organization - Texts'}</a> {showBreadcrumb && <a className='cursor-pointer' onClick={() => showTextsHandler(editData)}><img src={arrow} alt="arrow" />{editData?.display_name}</a>} <img src={arrow} alt="arrow" />{`${identifier === "edit" ? 'Edit' : 'Create New'} Text`}</p>
          </div>
        </h3>
      </div>
      <div className='email-sms-template-custom-form'>
        <>
          <div className='row'>
            <div className="col-md-6 form-group">
              <div className="organization-provider-filter-container">
                <label>Organizations</label>
                <ReactSelect
                  value={orgOptions.find(option => option.value === selectedOrgId)}
                  styles={selectStyles}
                  onChange={handleOrgChange}
                  isMulti
                  closeMenuOnSelect={false}
                  options={orgOptions}
                  placeholder="Select organization"
                  isClearable={true}
                  noOptionsMessage={() => "No organizations found"}
                  className='email-sms-select-org-dropdown'
                  defaultValue={() => showEditOrgData()}
                  isDisabled={disabledOrg}
                />
              </div>
            </div>
            <div className="col-md-6 form-group">
              <div className="organization-provider-filter-container">
                <label>Name</label>
                <input type='text' className="form-control" value={editorContent?.display_name} onChange={(e) => handleDataChange('display_name', e.target.value)} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="organization-provider-filter-container">
                <label>Service</label>
                <select className="select-filter-organization-provider capitalize" onChange={(e) => handleDataChange("services_id", e.target.value)} value={editorContent?.services_id}>
                  <option value="">Select</option>
                  {
                    serviceList && serviceList.map((service, index) => (
                      <option value={service.id}
                        key={`serviceId-option-${index + 1}`}>
                        {humanize(service.name)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="organization-provider-filter-container">
                <label>default</label>
                <select className="select-filter-organization-provider capitalize" onChange={(e) => handleDataChange("default", e.target.value)} value={editorContent?.default}>
                  <option value="">Select</option>
                  <option value='true'>True</option>
                  <option value='false'>False</option>
                </select>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className="organization-provider-filter-container">
                <label>Key</label>
                <select className="select-filter-organization-provider capitalize" onChange={(e) => handleDataChange("key", e.target.value)} value={editorContent?.key}>
                  <option value="">Select</option>
                  {
                    keys?.map((keyData, index) => (
                      <option value={keyData}
                        key={`serviceId-option-${index + 1}`}>
                        {humanize(keyData)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="organization-provider-filter-container">
                <label>Locale</label>
                <select className="select-filter-organization-provider capitalize" onChange={(e) => handleDataChange("locale", e.target.value)} value={editorContent?.locale}>
                  <option value="">Select</option>
                  {
                    locales?.map((locale, index) => (
                      <option value={locale}
                        key={`serviceId-option-${index + 1}`}>
                        {locale.toUpperCase()}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>
        </>
        <div className='email-sms-editor-container'>
          <>
            <Editor
              apiKey={apiKey}
              value={editorContent.content}
              onEditorChange={handleHeaderChange}
              init={{
                height: 300,
                width: '100%',
                image_advtab: true,
                plugins: [
                  'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'markdown',
                  'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                  'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'wordcount'
                ],
                toolbar: `undo redo | casechange blocks | bold italic code| 
                  alignleft aligncenter alignright alignjustify | 
                  bullist numlist checklist outdent indent`,
              }}
            />
          </>
        </div>
        <div className='email-sms-template-btn'>
          <div className='email-sms-template-two-btn'>
            <div className='preview-button-wrapper'>
              <button className='email-sms-button-preview' onClick={handlePreviewClick}>Preview</button>
            </div>
          </div>
          <button className='email-sms-button-create' onClick={createConsentTextsHandler}>
            {identifier === "edit" ? 'Update' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateOrgConsentTexts;
