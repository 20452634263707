import React from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import moment from "moment";
import arrow from '../../assets/images/greater_than2x.png';
import { Link } from 'react-router-dom';
import "./Scss/dashboard.scss";

const DashboardFilter = ({
  selectedDateRange = "",
  organization = {},
  liveDataOrgId = 0,
  setDateRange = () => { },
  handleSubmit = () => { },
}) => {
  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format('DD/MM/YYYY');
    const endDateValue = moment(end._d).format('DD/MM/YYYY');
    setDateRange(`${startDateValue}-${endDateValue}`);
  }

  function getStartDate(d) {
    return moment(new Date(d.getFullYear(), d.getMonth(), 1)).format('DD/MM/YYYY');
  }
  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  function getEndDate(d) {
    var lastDay = new Date(d.getFullYear(),
      d.getMonth(), daysInMonth(d.getMonth() + 1,
        d.getFullYear()));

    return moment(lastDay).format('DD/MM/YYYY');
  }
  return (
    <div className="df-main">
      <div className="df-left capitalize">
        {((organization && organization.name) || (liveDataOrgId > 0)) ?
          <div>
            <div className="upper">
              Client Statistics - {organization.name}
            </div>
            <div className="bottom">
              <Link to={{ pathname: `/dashboard` }}>Client Statistics
              </Link>
              <img className="arrow" src={arrow} alt="flag" /> {organization.name}
            </div>
          </div>
          :
          <div>Dashboard</div>
        }
      </div>
      <div className="df-right">
        <div className="ld-search-container">
          <form className="df-form-container" id="df-form-container">
            <div>
              <DateRangePicker
                onCallback={handleCallback}
                initialSettings={{ startDate: getStartDate(new Date()), endDate: getEndDate(new Date()), locale: { format: "DD/MM/YYYY" } }}
                onApply={handleSubmit}
              >
                <input
                  type="text"
                  name="sms_date_range"
                  id="form-date-field"
                />
              </DateRangePicker>
            </div>
            <div className="ld-calendar-icon">
              <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default DashboardFilter;