import React from 'react'
import { useEffect } from 'react';
import LiveDataOrganisations from '../../Components/LiveData/LiveDataOrganisations/LiveDataOrganisations';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import "./livedata.css";

const LiveDataOrganisationsPage = () => {
    useEffect(() => {
        document.title = "Kreditz | Admin portal - Live Data Organisations";
    }, []);

    return (
        <div className="wrapper">
            <Header />
            <div className="main-container">
                <Sidebar />
                <LiveDataOrganisations />
            </div>
        </div>
    );
};

export default LiveDataOrganisationsPage