import React, { useState } from 'react';
import '../InviteModal/InviteModal.css';
import invitation from '../../assets/images/invitation.svg';
import copyIcon from '../../assets/images/copy.svg';
import closeIconInvite from '../../assets/images/icon_close.png'
import { useDispatch } from 'react-redux';
import { sendInvitationViaEmail } from '../../Redux/Actions/OrganizationManagement';
import moment from 'moment/moment';

const InviteOrgUser = (props) => {
  const [enableInvite, setEnableInvite] = useState(false)
  const [copiedEnabled, setCopiedEnabled] = useState(false)
  const dispatch = useDispatch()

  const checkBoxHandler = (e) => {
    e.target.checked ? setEnableInvite(true) : setEnableInvite(false)
  }

  const copyLinkHandler = (linkText) => {
    navigator.clipboard.writeText(linkText)
      .then(() => {
        setCopiedEnabled(true)
        setTimeout(() => {
          setCopiedEnabled(false)
        }, 1500)
      })
      .catch((err) => {
        setCopiedEnabled(false)
      });
  }

  const inviteHandler = async () => {
    let query = "?send_via_email=" + enableInvite + "&email=" + props.inviteData?.user_email
    const { status, message } = await dispatch(sendInvitationViaEmail(query))
    props.closeDelete()
    if (status) {
      props.openFlash("tick", "Done!", message)
    } else {
      props.openFlash("alert", "Error!", message);
    }
  }



  return (
    <div className='DeletePopup invitaionModal'>
      <div className='DeletePopup_inner'>
        <img src={closeIconInvite} onClick={props.closeDelete} className='closeIconInvite' alt='closeIconInvite' />
        <img src={invitation} className='invitation' alt="invitation" />
        <p className='firstText'>Invite an organization</p>
        <p className='secondText'>Do you want to send an invite to this user?</p>
        <div className='inviteInnerDetails'>
          <div className='invi-org-details'>
            <p>Organization: <span>{props.inviteData.organization_name}</span></p>
            <p>Email: <span>{props.inviteData.user_email}</span></p>
          </div>
          <div className='invitationLink'>
            <label>invitation link</label>
            <div className='inviteInput'>
              <input type='text' value={props.inviteData.link} />
              {copiedEnabled ? <span className='copyLink copyLink-text'>Copied</span> : <img src={copyIcon} onClick={() => copyLinkHandler(props.inviteData.link)} className='copyLink' alt="copy" />}
            </div>
          </div>
          <div className='custom-checkbox'>
            <input onChange={(e) => checkBoxHandler(e)} type='checkbox' id='sendInvite' />
            <label>Send an invitation via system over email? <br />{props.inviteData?.last_invitation_sent_at && <small>{`Last invite on ${moment(props.inviteData?.last_invitation_sent_at).format("D MMM, YYYY h:mm:ss A")}`}</small>}</label>
            {enableInvite && <button className='closeDelete' onClick={() => inviteHandler()}>{props.inviteData?.last_invitation_sent_at ? "Invite again" : "Invite"}</button>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default InviteOrgUser;