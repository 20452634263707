import { SET_CONVERSION_SIDEBAR } from '../Actions/Types/Types'

const initialState = {
  toggleConversionSidebar: false
}

const conversion_sidebar = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CONVERSION_SIDEBAR:
      return {
        toggleConversionSidebar: action.value
      }
    default: return state;
  }
}

export default conversion_sidebar;