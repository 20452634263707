import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import logo_new from "../../assets/images/logo_new.svg";
import lefttopcloud from "../../assets/images/left-top-cloud.png";
import cloudrightotp from "../../assets/images/cloud_rightotp.png";
import Menleft from "../../assets/images/Men_left.png";
import righttopuse from "../../assets/images/right_top_use.png";
import leftwoman from "../../assets/images/left_bottom.png";
import rightbottomman from "../../assets/images/Men_Right_Bottom.png";
import bottpmleaves from "../../assets/images/right_bottom_leaves.png";
import { login } from "../../Redux/Actions/Auth";
import { setdisplayFlash } from "../../Utils/Common";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import Homeicon from "../../assets/images/icon_arrow_home.svg";
import "./authentication.css";
import { get_otp_via } from "../../Redux/Actions/Auth"
import { Link } from "react-router-dom";
import _ from "lodash";
import useCookie from "../../Utils/useCookie";

const Authentication = (props) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [minutes, setMinutes] = useState(9);
  const [seconds, setSeconds] = useState(59);
  const [verifyButton, setVerifyButton] = useState(false);
  const dispatch = useDispatch();
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [cookie, updateCookie] = useCookie("user_id", "kreditz-admin");
  const [flashMessage, setFlashMessage] = useState(false);
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const [checkedIsRemembered, setCheckedIsRemembered] = useState(false)

  let userEmail = localStorage.email
  let type = Number(localStorage.device);
  let resendTime = false

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    let combineOTP = otp.map((d, indx) => (indx === index) ? element.value : d)
    setOtp([...combineOTP]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
    let isOtp = _.includes(combineOTP, "")
    if (!isOtp) {
      setVerifyButton(true)
    } else {
      setVerifyButton(false)
    }
  };

  const onPaste = (event) => {
    let pasted = event.clipboardData.getData("text/plain")
    let newLength = 6 - pasted.length
    if (pasted.length <= 6) {
      let newArr
      pasted = pasted.split("");
      newArr = pasted
      for (let i = 0; i < newLength; i++) {
        pasted.push(" ");
      }
      pasted = newArr.join("");
    }
    setOtp(pasted.split("").slice(0, otp.length))
    setVerifyButton(true)
  }

  useEffect(() => {
    localStorage.removeItem('authToken')
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, resendTime]);

  const handleSubmit = async (e) => {
    const data = {
      email: userEmail,
      otp: otp,
    };
    const response = await dispatch(login(data));
    localStorage.setItem('authToken', response.authToken)
    if (response.status) {
      if (checkedIsRemembered) {
        let userId = localStorage.id;
        updateCookie(userId, 15);
      }
      props.history.push({ pathname: "/dashboard" });
    } else {
      setInvalidOtp(true);
    }
  };
  const resendHandleSubmit = async (e) => {
    setMinutes(9);
    setSeconds(59);
    resendTime = true
    var type = Number(localStorage.getItem("device"));
    const loginData = {
      type: type === 1 ? "email" : "phone"
    };
    const { status } = await dispatch(get_otp_via(loginData));
    if (status) {
      openFlash("tick", "Done!", `A One-Time Password has been sent to your ${type === 1 ? 'email' : 'phone number'} .`);
      setdisplayFlash(true);
      setInvalidOtp(false)
      setOtp(new Array(6).fill(""))
    }
  };

  const handleRemember = (event) => {
    event.target.checked ? setCheckedIsRemembered(true) : setCheckedIsRemembered(false)
  };

  const UserEmailShow = userEmail.replace(/(?<=.)(.+?)(?=.@)/gi, '*'.repeat(userEmail.split('@')[0].length - 2));

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }
  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  return (
    <div>
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className="login_form">
        <div className="login-logo">
          <img src={logo_new} alt="logo_new" style={{ width: "162" }} />
        </div>
        <div className="left-cloud">
          <img src={lefttopcloud} alt="left-top-cloud" />
        </div>
        <div className="right-cloud">
          <img src={cloudrightotp} alt="cloud_rightotp" />
        </div>
        <div className="left-man">
          <img src={Menleft} alt="Men_left" />
        </div>
        <div className="right-man">
          <img src={righttopuse} alt="right_top_use" />
        </div>
        <div className="login_form_set">
          <div className="left-woman">
            <img src={leftwoman} alt="left_bottom" />
          </div>
          <div className="right-bottom-man">
            <img src={rightbottomman} alt="Men_Right_Bottom" />
          </div>
          <div className="bottom-leaves">
            <img src={bottpmleaves} alt="right_bottom_leaves" />
          </div>
          <div>
            <div className="arrow-back-home">
              <Link to="/login">
                <img src={Homeicon} alt="" />
                Back
              </Link>
            </div>
            <h2 className="welcomeHeader">
              Verify your <span>{type === 1 ? "email address" : 'phone number '}</span>
              <p className="plzLogin">A One-Time Password has been sent to<span>{type === 1 ? UserEmailShow : 'phone'}</span></p>
            </h2>
            <div className="otp-event">
              <div className="single-label-inputs">
                {
                  otp.map((data, index) => {
                    return (
                      <input
                        type="text"
                        maxLength="1"
                        otp="otp"
                        autoComplete="off"
                        className="otp-number-input"
                        key={index}
                        value={data}
                        onPaste={onPaste}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select}
                        required
                      />
                    );
                  })
                }
              </div>
              {
                invalidOtp && <span className="invalid" id="timer">Invalid OTP</span>
              }
              <label className="label-check">
                <input type="checkbox" name="rememberme" onClick={(e) => handleRemember(e)} />
                Remember this device
              </label>
              <div className="otp-submition">
                <div className="resend-otp-timer">
                  <div className="resend_button resend-otp-timer resend-button-active" onClick={() => resendHandleSubmit()}>Resend OTP</div>
                  <span className="timer-set" id="timer">{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                </div>
                <button type="submit" className={verifyButton ? "login_submit otp-submit" : "login_submit login_submit_disabled otp-submit"} id="submit" onClick={() => handleSubmit()} >Verify</button>
              </div>
            </div>
          </div>
        </div>
          <div className="copyright_bottom">&copy; {new Date().getFullYear()} Kreditz</div>
        </div>
    </div>
  );
};

export default Authentication;