import React, { useState } from 'react';
import moment from 'moment';
import CertificateLogsViewer from './certificateLogsViewer';

const EventLogTable = ({ columns, data }) => {
  const [showCertificateLogs, setShowCertificateLogs] = useState(false);
  const [certificateId, setCertificateId] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  const formatEventType = (eventType) => {
    if (!eventType) return '';
    return eventType.replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatKey = (key) => {
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const handleCertificateClick = (item) => {
    setCertificateId(item.object_id);
    setOrganizationId(item.organization_id); 
    setShowCertificateLogs(true);
  };

  const handleCloseModal = () => {
    setShowCertificateLogs(false);
  };

  const formatColumnAffected = (item) => {
    let changesText = '';
  
    if (item.event_type.includes("certificate")) {
      changesText = (
        <div className='certificateValue' onClick={() => handleCertificateClick(item)}>
          Certificate #{item.object_id}
        </div>
      );
      return changesText;
    } else if (item.column_affected && Object.keys(item.column_affected).length > 0) {
      changesText = '**Settings changed**\n';
      Object.entries(item.column_affected).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length >= 2) {
          const [oldValue, newValue] = value;
          if (key !== 'updated_at') {
            const readableKey = formatKey(key);
            const formattedOldValue = typeof oldValue === 'object' ? JSON.stringify(oldValue, null, 2) : oldValue;
            const formattedNewValue = typeof newValue === 'object' ? JSON.stringify(newValue, null, 2) : newValue;
            changesText += `${readableKey} --> ${formattedOldValue} => ${formattedNewValue}\n`;
          }
        }
      });
    } else {
      changesText = '-';
    }
    return changesText !== '-' ? `ID: ${item.object_id}, Name: ${item.user_name}\n${changesText}` : changesText;
  };
  
  return (
    <div className='eventlogs-tbody'>
    <div className="organizations-thead-scroll-y org-no-activity">
      <div className="bg-white-organizations bg-20-top">
        <table className="table" width="100%">
          <thead className="organizations-thead">
            <tr>
              {columns.map((column) => (
                <td key={column.accessor || column.id} className="org-head">{column.Header}</td>
              ))}
            </tr>
          </thead>
          <tbody className="organizations-tbody">
            {data.map((item, index) => (
              <tr key={`event-log-${item.id}-${index}`}>
                {columns.map((column) => (
                  <td key={`${column.accessor || column.id}-${index}`} className="org-td">
                    {
                      column.id === 'entity_affected'
                        ? formatColumnAffected(item)
                        : column.accessor === 'created_at'
                          ? moment(item[column.accessor]).format("DD-MM-YYYY HH:mm:ss")
                          : column.accessor === 'event_type'
                            ? formatEventType(item[column.accessor])
                            : item[column.accessor]
                    }
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={showCertificateLogs ? "modalBackdrop show" : "modalBackdrop"} onClick={handleCloseModal}>
        {showCertificateLogs && (
          <div className="certificateModal" onClick={e => e.stopPropagation()}>
            <CertificateLogsViewer certificateId={certificateId} organizationId={organizationId} />
            <button className="certificateModalCloseBtn" onClick={handleCloseModal}>Close</button>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default EventLogTable;


