import { SET_ACTIVE_SIDEBAR } from '../Actions/Types/Types'

const initialState = {
  activeSidebar: ""
}

const sidebar = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ACTIVE_SIDEBAR:
      return {
        activeSidebar: action.value
      }
    default: return state;
  }
}

export default sidebar;
