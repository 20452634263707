import React, { useState, useEffect } from 'react';
import '../OrganizationsEmailSms/OrganizationsEmailSms.scss';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import FlashMessage from '../FlashMessage/FlashMessage';
import arrow from '../../assets/images/greater_than2x.png';
import { Diff, Hunk, parseDiff } from 'react-diff-view';
import { diffLines, formatLines } from 'unidiff';
import 'react-diff-view/style/index.css';
import { diffChars } from "diff";

const DiffView = ({ diffViewData }) => {
  const oldTextParts = [];
  const newTextParts = [];

  diffViewData?.forEach((part, index) => {
    if (part.added) {
      newTextParts.push(
        <span key={index} className="char-added">
          {part.value}
        </span>
      );
    } else if (part.removed) {
      oldTextParts.push(
        <span key={index} className="char-removed">
          {part.value}
        </span>
      );
    } else {
      oldTextParts.push(<span key={index}>{part.value}</span>);
      newTextParts.push(<span key={index}>{part.value}</span>);
    }
  });

  return (
    <div className="diff-view">
      <div className="diff-column remove-text-highlight">
        <pre className='pre-difference'>{oldTextParts}</pre>
      </div>
      <div className="diff-column new-text-highlight">
        <pre className='pre-difference'>{newTextParts}</pre>
      </div>
    </div>
  );
};


const OrganizationConsentTextsDiff = () => {
  const location = useLocation();
  const { text } = location.state || [];
  const { default_text } = location.state || {};
  const { textPage } = location.state || '';
  const { dataForShow, serviceList, locales, keys, displayTexts, editSentData } = location.state || ''
  const [text1, setText1] = useState(default_text?.content || '');
  const [text2, setText2] = useState(text?.content || '');
  const history = useHistory();
  const [noDiff, setNoDiff] = useState(false)
  const [openUpdateText, setOpenUpdateText] = useState(false)
  const dispatch = useDispatch()
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const EMPTY_HUNKS = [];

  const diff = diffChars(text1, text2);

  const stripHtmlTags = (str) => {
    return str.replace(/<[^>]*>/g, '');
  };

  const textWithoutHtml1 = stripHtmlTags(text1)
  const textWithoutHtml2 = stripHtmlTags(text2)

  const diffWithoutHtml = diffChars(textWithoutHtml1, textWithoutHtml2);


  useEffect(() => {
    handleCompare()
  }, [])

  const handleCompare = () => {
    if (text1 === text2) {
      setNoDiff(true)
    }
  };

  function humanize(str) {
    var i, frags = str?.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
    }
    return frags?.join(' ');
  }

  const partsForLeft = [
    default_text?.display_name !== undefined ? `Name: ${default_text.display_name}` : '',
    default_text?.key !== undefined ? `Key: ${humanize(default_text.key)}` : '',
    default_text?.locale !== undefined ? `Locale: ${default_text.locale.toUpperCase()}` : '',
    default_text?.service?.name !== undefined ? `Service Name: ${humanize(default_text?.service?.name)}` : ''
  ];

  const displayStringLeft = partsForLeft.filter(part => part !== '').join(' | ');

  const partsForRight = [
    text?.display_name !== undefined ? `Name: ${text.display_name}` : '',
    text?.key !== undefined ? `Key: ${humanize(text.key)}` : '',
    text?.locale !== undefined ? `Locale: ${text.locale.toUpperCase()}` : '',
    text?.service?.name !== undefined ? `Service Name: ${humanize(text?.service?.name)}` : ''
  ];

  const displayStringRight = partsForRight.filter(part => part !== '').join(' | ');

  const togglePopup = () => {
    setOpenUpdateText(false)
  }

  const editDataHandler = () => {
    setOpenUpdateText(true)
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  const editHandler = () => {
    history.push('manage_organization_consent_texts', { identifier: 'edit', editData: text ? text : default_text, editFromDiff: true, default_text: default_text, text: text, textPage: textPage, showBreadcrumb: true })
  }

  return (
    <div className="email-sms-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className="ld-container">
        <div className="org-breadcumb-button">
          <h3 className="basic-head01">
            <div className="add-breadcrum">{textPage ? 'Texts Difference' : 'Organizations - Texts Difference'}<p><a href={textPage ? '/texts' : '/organization_texts'}>{textPage ? 'Texts' : 'Organizations - Texts'}</a> <img src={arrow} alt="arrow" />{text?.display_name ? text?.display_name : default_text?.display_name}</p>
            </div>
          </h3>
          <button className='service-button' onClick={() => editHandler()}>Edit Text</button>
        </div>
        <div>
          <div>
            <div>
              {
                text?.organizations?.length > 0 &&
                <ul className='associated-org-details'>
                  {
                    text?.organizations.map(org => <li>{org.name}</li>)
                  }
                </ul>
              }
            </div>
            <div className='diff-titles'>
              <p className='diff-titles-left'>{displayStringLeft}</p>
              <p className='diff-titles-right'>{displayStringRight}</p>
            </div>
            <div>
              <DiffView diffViewData={diff} />
            </div>
            <h3>Texts without HTML</h3>
            <div>
              <DiffView diffViewData={diffWithoutHtml} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationConsentTextsDiff;