const EditDropdown = ({ options, selected, onSelect, className, placeholder }) => {
  const handleChange = (event) => {
    onSelect(event.target.value === "" ? null : event.target.value);
  };

  return (
    <div className={`healthedit-ld-search-container ${className}`}>
      <div className="healthedit-custom-select">
        <select
          className="ld-form-input-field capitalize"
          id="ld-select"
          value={selected}
          onChange={handleChange}
        >
          <option value="">
            {placeholder}
          </option>
          {options.map((option, index) => (
            <option key={index} className="ld-option" value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default EditDropdown;
