import React, { useState } from 'react';

const EditServiceProvider = ({ confirm, closeDelete, editData, setEditData }) => {

  const handleSubmit = (e) => {
    e.preventDefault()
    confirm(editData)
  }

  const editChangeHandler = (name, value) => {
    setEditData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <h3>Edit Service Provider</h3>
        <form onSubmit={handleSubmit}>
          <div className="setting-filter-container input-credentials">
            <div className="provider-input-file">
              <div className="row">
                <div className="col-md-6">
                  <div className="organization-provider-filter-container">
                    <label>Status</label>
                    <select className="select-filter-organization-provider" value={editData?.status} onChange={(e) => editChangeHandler('status', e.target.value)}>
                      <option value="">Select</option>
                      <option value="active">Active</option>
                      <option value="inactive">inactive</option>
                      <option value="unverified">Unverified</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="organization-provider-filter-container">
                    <label>Clear Credential</label>
                    <select className="select-filter-organization-provider" value={editData?.clear_credentials} onChange={(e) => editChangeHandler('clear_credentials', e.target.value)}>
                      <option value="">Select</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="organization-provider-filter-container">
                    <label>Sandbox Enabled</label>
                    <select className="select-filter-organization-provider" value={editData?.sandbox_enabled} onChange={(e) => editChangeHandler('sandbox_enabled', e.target.value)}>
                      <option value="">Select</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="organization-provider-filter-container">
                    <label>Allow Re-fetch Transactions</label>
                    <select className="select-filter-organization-provider" value={editData?.allow_refetch_transactions} onChange={(e) => editChangeHandler('allow_refetch_transactions', e.target.value)}>
                      <option value="">Select</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' onClick={closeDelete} style={{ marginLeft: '0px' }}>Cancel</button>
            <button className='confirm-update' type='submit'>Update</button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default EditServiceProvider;