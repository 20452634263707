import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getConfigurationSettings() {
  const apiUrl = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONFIGURATION_SETTINGS.LIST()}`;

  return axios.get(apiUrl)
    .then(response => {
      if (response.status === 200 && response.data) {
        return { status: true, data: response.data };
      } else {
        return { status: false, message: "Unexpected response structure" };
      }
    })
    .catch(error => {
      (error.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: "Something went wrong", error: error };
    });
}