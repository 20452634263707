import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const BackgroundJobsHistory = ({ historyData, userData, job, fetchBackgroundJobs, pageCount, currentPage, onUpdateParametersFromHistory }) => {
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [loadingStates, setLoadingStates] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);

  const checkIfJobsAreRunning = () => {
    return historyData.some(
      (job) => job.status === 'pending' || job.status === 'running'
    );
  };

  useEffect(() => {
    if (lastFetchedPage !== currentPage) {
      setLoadingStates(
        historyData.reduce((acc, item) => {
          acc[item.history_id] = !item.processed_at;
          return acc;
        }, {})
      );
      
      fetchBackgroundJobs(currentPage).then((response) => {
        const updatedLoadingStates = { ...loadingStates };
        response.data.background_jobs.forEach(job => {
          job.job_histories.forEach((item) => {
            updatedLoadingStates[item.history_id] = false;
          });
        });
        setLoadingStates(updatedLoadingStates);
      });
      setLastFetchedPage(currentPage);
    }
  }, [currentPage, fetchBackgroundJobs, historyData, lastFetchedPage, loadingStates]);

  useEffect(() => {
    let interval;
    if (checkIfJobsAreRunning()) {
      interval = setInterval(() => {
        fetchBackgroundJobs(currentPage);
      }, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentPage, historyData, fetchBackgroundJobs]);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handlePageClick = (data) => {
    fetchBackgroundJobs(data.selected + 1);
  };

  const parseParameters = (parameters) => {
    if (typeof parameters === 'string') {
      try {
        return JSON.parse(parameters);
      } catch (error) {
        console.error('Error parsing parameters', error);
        return {};
      }
    }
    return parameters;
  };

  return (
    <div className="backgroundJobs-history-container">
      <h3>
        History{' '}
        <span
          className="info-icon-wrapper"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          style={{backgroundColor: 'white', borderRadius: '50%', width: '20px', height: '20px'}}
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#3e85f9' }} />
          {showTooltip && (
            <div className="tooltip-container">
              <p>
                Click on a row to update the parameters of the job with the
                parameters from that history.
              </p>
            </div>
          )}
        </span>
      </h3>

      <table className="backgroundJobs-table" width="100%">
        <thead className="backgroundJobs-thead">
          <tr>
            <th className="backgroundJobs-head">Name</th>
            <th className="backgroundJobs-head">Date</th>
            <th className="backgroundJobs-head">Started by</th>
            <th className="backgroundJobs-head">Parameters</th>
            <th className="backgroundJobs-head">Last duration</th>
            <th className="backgroundJobs-head">Result</th>
          </tr>
        </thead>
        <tbody className="backgroundJobs-tbody">
        {historyData.map((historyItem, index) => {
            const jobHistoryExists = historyItem.job_histories && historyItem.job_histories.length > 0;
            const jobParameters = parseParameters(historyItem.parameters);
            const startedByUser = userData.find((user) => user.id === historyItem.started_by);
            const formattedDate = formatDateTime(historyItem.started_at);

            const lastHistoryItem = jobHistoryExists
              ? historyItem.job_histories[historyItem.job_histories.length - 1]
              : null;

            return (
              <tr
                key={index}
                onClick={() =>jobHistoryExists && onUpdateParametersFromHistory(historyItem.parameters)}
                style={{ cursor: 'pointer' }}
              >
                <td>{job && job.name}</td>
                <td>{formattedDate}</td>
                <td>{startedByUser ? startedByUser.name : 'N/A'}</td>
                <td>
                  {Object.entries(jobParameters).map(([key, value], paramIndex) => (
                    <div key={paramIndex}>
                      {key}: {value}
                    </div>
                  ))}
                </td>
                <td>
                  {lastHistoryItem
                    ? `${lastHistoryItem.execution_time}s`
                    : 'N/A'}
                </td>
                <td>
                  {historyItem.status === 'running' ? (
                    <div className="spinner-border text-primary" role="status"></div>
                  ) : historyItem.status === 'completed' ? (
                    <span className="text-success">Completed</span>
                  ) : historyItem.status === 'failed' ? (
                    <span className="text-danger">Failed</span>
                  ) : (
                    <div className="spinner-border text-primary" role="status"></div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-box">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination justify-content-center'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
};

export default BackgroundJobsHistory;
