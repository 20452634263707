import { combineReducers } from "redux";
import auth from "./Auth";
import admin_logs from "./AdminLogs";
import paginated_orgs from "./InvoicesLogs";
import users from "./UserManagement";
import statistics_logs from "./Statistics";
import demo_access_response from "./DemoAggregation";
import live_data from "./LiveData";
import test_centers from "./TestCenter";
import sms_logs from "./SmsList";
import email_logs from "./EmailList";
import conversion_rate from "./ConversionRate";
import api_doumentation from "./ApiDocumentation"

import event_logs from "./CertificateLogs";
import organization_user from "./CertificateLogs";
import request from "./CertificateLogs";
import consent_credentials from "./CertificateLogs";
import service_token from "./CertificateLogs";
import organization from "./CertificateLogs";
import bank from "./CertificateLogs";
import transaction from "./CertificateLogs";
import status from "./CertificateLogs";
import column_affected from "./CertificateLogs";
import dashboard from "./Dashboard";
import organization_management from "./OrganizationManagement";
import loader_spin from "./LoaderSpinner";
import it_sidebar from "./ItSidebar";
import livedata_sidebar from "./LiveDataSidebar";
import organization_management_sidebar from "./OrganizationManagementSidebar";
import conversion_sidebar from "./ConversionSidebar";
import statistics_sidebar from "./StatisticsSidebar";
import certificates_sidebar from "./CertificatesSidebar";
import bank_connection_sidebar from "./BankConnectionSidebar";
import sidebar from "./Sidebar";

export default combineReducers({
  auth,
  admin_logs,
  paginated_orgs,
  users,
  statistics_logs,
  demo_access_response,
  live_data,
  test_centers,
  sms_logs,
  event_logs,
  email_logs,
  organization_user,
  organization,
  bank,
  transaction,
  request,
  consent_credentials,
  service_token,
  status,
  column_affected,
  dashboard,
  organization_management,
  loader_spin,
  conversion_rate,
  it_sidebar,
  organization_management_sidebar,
  conversion_sidebar,
  statistics_sidebar,
  certificates_sidebar,
  livedata_sidebar,
  bank_connection_sidebar,
  sidebar,
  api_doumentation
});
