import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import AccessCodes from "../../Components/DemoAggregation/AccessCodes";

import "./demoAggregation.css";
const AccessCodesPage = () => {
    useEffect(() => {
        document.title = "Kreditz | Admin portal - Demo Access Codes";
    }, []);
    return (
        <div className="wrapper">
            <Header />
            <div className="main-container">
                <Sidebar />
                <AccessCodes />
            </div>
        </div>
    );
};

export default AccessCodesPage;