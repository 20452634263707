import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";


export function getOrganizationUserData(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.SUPER_ADMIN_PORTAL}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_USER.FILTER()}` + data
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getOrganizationUserEdit(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.SUPER_ADMIN_PORTAL}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_USER.UPDATE()}` + data
  return dispatch => axios.put(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getDeactivateUser(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.SUPER_ADMIN_PORTAL}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_USER.DEACTIVATE()}` + data
  return dispatch => axios.put(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getActivateUser(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.SUPER_ADMIN_PORTAL}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_USER.ACTIVATE()}` + data
  return dispatch => axios.put(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function createOrganizationUser(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_USER.CREATE()}` + data
  return dispatch => axios.post(apiURL)
    .then(res => {
      return { status: true, message: res.data.message };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getInviteOrgUserData(query) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.SUPER_ADMIN_PORTAL}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_USER.INVITEUSER()}` + query;
  return dispatch => axios.post(apiURL)
    .then(res => {
      return { status: res.data.status, message: res.data.message, data:res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

