const ServiceSettingCreated = (props) => {

  const adminLog = props.adminLog;
  let id = adminLog.object_id;
  let brokerApplication = adminLog.column_affected.service_setting_created.broker_application;
  let certificateType = adminLog.column_affected.service_setting_created.service_info?.certificate_type ? adminLog.column_affected.service_setting_created.service_info?.certificate_type[1] : "";
  let env = adminLog.column_affected.service_setting_created.env;
  let labelization = adminLog.column_affected.service_setting_created.labelization;
  let country = adminLog.column_affected.service_setting_created.country;
  let orgId = adminLog.column_affected.service_setting_created.service_info?.organization_id ? adminLog.column_affected.service_setting_created.service_info?.organization_id[1] : "";
  let orgName = adminLog.column_affected.service_setting_created.organization_name;
  let serviceId = adminLog.column_affected.service_setting_created.service_info?.service_id ? adminLog.column_affected.service_setting_created.service_info?.service_id[1] : "";
  let serviceName = adminLog.column_affected.service_setting_created.service_name;
  let bankAuthenticationApproach = adminLog.column_affected.service_setting_created.bank_authentication_approach;
  let active = adminLog.column_affected.service_setting_created.active;

  return (
    <div
      key={Math.random()}
    >
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>Service Setting Created</strong>
      </p>
      <p className="al-column-affected-p">
        Organization ID: {" " + orgId}<br />
        Organization name: {" " + orgName}<br />
        Service ID: {" " + serviceId}<br />
        Service name: {" " + serviceName}<br />
        Broker application: {" " + brokerApplication}<br />
        Certificate type: {" " + certificateType}<br />
        ENV: {" " + env}<br />
        Labelization: {" " + labelization}<br />
        Active: {" " + active}<br />
        Bank authentication approach: {" " + bankAuthenticationApproach}<br />
        Country: {" " + country}<br />
      </p>
    </div>
  )
}
export default ServiceSettingCreated;