const DemoAccessCodeSent = (props) => {
  const adminLog = props.adminLog;
  let id = adminLog.object_id;
  let email = adminLog.column_affected.demo_access_code.email;
  let phone = adminLog.column_affected.demo_access_code.phone;
  let sentBy = adminLog.column_affected.demo_access_code.sent_by;
  return (
    <div key={Math.random()}>
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>Demo Access Codes</strong>
      </p>
      <p className="al-column-affected-p">
        {email !== null && <span>Receiver email: {" " + email}<br /></span>}
        {phone !== null && <span>Receiver phone number: {" " + phone}<br /></span>}
        Sent by: {typeof sentBy === "string" ? <>{sentBy}</> : <>{`${sentBy[0]}, ${sentBy[1]}`}</>}
      </p>
    </div>
  )
};

export default DemoAccessCodeSent;