import React from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import DynamicLiveData from "../../Components/LiveData/LiveDataOverview/DynamicLiveData";
import "./livedata.css";
import { useEffect } from "react";

const LiveDataOverview = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Live Data";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <DynamicLiveData />
      </div>
    </div>
  );
};

export default LiveDataOverview;
