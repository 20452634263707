const ServiceSettingUpdated = (props) => {
  const adminLog = props.adminLog;
  let id = adminLog.object_id;
  let orgName = adminLog.column_affected.service_setting_updated.organization_name;
  let country = adminLog.column_affected.service_setting_updated.country;
  let serviceName = adminLog.column_affected.service_setting_updated.service_name;
  return (
    <div key={Math.random()}>
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>Service setting updated</strong>
      </p>
      <p className="al-column-affected-p">
        Organization name: {" " + orgName}<br />
        Service name: {" " + serviceName}<br />
        Country: {" " + country}<br />
      </p>
      {Object.entries(adminLog.column_affected.service_setting_updated?.service_info).length > 0 && Object.entries(adminLog.column_affected.service_setting_updated?.service_info).map((x) => (
        <p className="al-column-affected-p" key={Math.random()}>
          {x[0]}: {x[1][0] === true ? "true" : x[1][0] === false ? "false" : x[1][0]} → {x[1][1] === true ? "true" : x[1][1] === false ? "false" : x[1][1]}
        </p>
      ))}
    </div>
  )
}

export default ServiceSettingUpdated;