import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getcertlogssearchList } from "../../../Redux/Actions/CertificateLogs";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";

const CertificateLogsViewer = ({ certificateId, organizationId }) => {
  const dispatch = useDispatch();
  const [certificateLogs, setCertificateLogs] = useState(null);

  useEffect(() => {
    const fetchCertificateLogs = () => {
      const query = `?find_by_param=id&find_by_value=${certificateId}&organization_id=${organizationId}`;
      dispatch(setLoaderSpinner(true));
      dispatch(getcertlogssearchList(query))
        .then(res => {
          if (res.status) {
            setCertificateLogs(res.data);
          }
          dispatch(setLoaderSpinner(false));
        });
    };

    fetchCertificateLogs();
  }, [certificateId, organizationId, dispatch]);

  console.log(certificateLogs);
  return (
    <div className="certificatePaper">
      <h2 className='headerCertLog'>Certificate {certificateId} </h2>
      {certificateLogs ? (
        <div>
          <h3>Organization</h3>
          <p>Name: {certificateLogs.organization.name}</p>
          <p>Email: {certificateLogs.organization.email}</p>

          <h3>Request</h3>
          <p>Email: {certificateLogs.request.email}</p>
          <p>Status: {certificateLogs.request.status}</p>
          <p>Case ID: {certificateLogs.request.case_id}</p>

          <h3>Event Logs</h3>
          {certificateLogs.event_logs.map((log, index) => (
            <div key={index}>
              <p>Event: {log.event_type.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}</p>
            </div>
          ))}
          </div>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
};

export default CertificateLogsViewer;