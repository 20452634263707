import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getSmsLogsList(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SMS_LOGS.LIST()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}