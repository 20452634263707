import { SET_DEMO_AGGREGATION } from '../Actions/Types/Types'

const initialState = {
  demo_list: [],
}
const DemoAggregationData = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DEMO_AGGREGATION:
      return {
        ...state,
        demo_list: action.demo_list,
      }
    default: return state;
  }
}

export default DemoAggregationData;