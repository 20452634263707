import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import BackgroundJobsDetail from "../../Components/BackgroundJobs/BackgroundJobsDetail";

const BackgroundJobsDetailPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Background Jobs Detail";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <BackgroundJobsDetail />
      </div>
    </div>
  );
};

export default BackgroundJobsDetailPage;
