import React, { useState, useEffect } from 'react';
import './OrganizationsEmailSms.scss';
import { Editor } from '@tinymce/tinymce-react';
import PreviewViewer from './misc/previewViewer';
import ViewDefaultViewer from './misc/viewDefaultTemplateViewer';
import { getAllSmsTemplates, getAllEmailTemplates, fetchEmailTemplateByName, fetchEmailTemplatePreview, fetchSmsTemplateByName, fetchSmsTemplatePreview, createSmsTemplate, updateSmsTemplate, createEmailTemplate, updateEmailTemplate } from '../../Redux/Actions/OrganizationEmailSms';
import { getOrganizationListCertificates } from "../../Redux/Actions/ItMenu";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FlashMessage from '../FlashMessage/FlashMessage';
import ReactSelect from 'react-select';
import { templatesName } from './utils/templatesName';

const OrganizationsEmailSms = () => {
    const [active, setActive] = useState('');
    const [selectedOrg, setSelectedOrg] = useState('');
    const [selectedOrgId, setSelectedOrgId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDefaultViewerOpen, setIsDefaultViewerOpen] = useState(false);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState('');
    const [smsTemplates, setSmsTemplates] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [organizationsList, setOrganizationsList] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [editorContent, setEditorContent] = useState({ subject: '', body: '', invite_link_text: '' });
    const [defaultTemplateData, setDefaultTemplateData] = useState('');
    const [defaultTemplateLoaded, setDefaultTemplateLoaded] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [flash, setFlash] = useState({ show: false, type: '', message: '' });
    const apiKey = process.env.REACT_APP_TINY_MCE_API_KEY;
    const { smsTemplateNameBase, templateDisplayNameBase } = templatesName();

    useEffect(() => {
        fetchTemplatesList();
        fetchOrganizationList();
    }, [dispatch, history]);

    useEffect(() => {
        if (active === 'Email') {
            setEditorContent({ subject: '', body: '', invite_link_text: '' });
            setDefaultTemplateData({ subject: '', body: '', invite_link_text: '' });
        } else if (active === 'SMS') {
            setEditorContent('');
            setDefaultTemplateData('');
        }
    }, [active]);

    const fetchOrganizationList = async () => {
        try {
            const response = await dispatch(getOrganizationListCertificates());
            if (response.status) {
                setOrganizationsList(response?.result?.data?.organizations_list);
            } else if (response.message === 'Unauthorized') {
                history.push('/login');
            }
        } catch (error) {
            console.error('Failed to fetch organizations', error);
        }
    };

    const fetchTemplatesList = async () => {
        try {
            const smsResponse = await dispatch(getAllSmsTemplates());
            if (smsResponse.status) {
                const localizedSmsTemplates = smsResponse.data.map(template => ({
                    ...template,
                    displayName: getLocalizedDisplayName(template.name, 'SMS')
                }));
                setSmsTemplates(localizedSmsTemplates);
            } else if (smsResponse.message === 'Unauthorized') {
                history.push('/login');
            }
        } catch (error) {
            console.error('Failed to fetch SMS templates', error);
        }

        try {
            const emailResponse = await dispatch(getAllEmailTemplates());
            if (emailResponse.status) {
                const localizedEmailTemplates = emailResponse.data.map(template => ({
                    ...template,
                    displayName: getLocalizedDisplayName(template.name, 'Email')
                }));
                setEmailTemplates(localizedEmailTemplates);
            } else if (emailResponse.message === 'Unauthorized') {
                history.push('/login');
            }
        } catch (error) {
            console.error('Failed to fetch Email templates', error);
        }
    };

    const handleOrgChange = (selectedOption) => {
        if (selectedOption) {
            const selectedOrgId = selectedOption.value.toString();
            const selectedOrganization = organizationsList.find(org => org.id.toString() === selectedOrgId);

            if (selectedOrganization) {
                setSelectedOrg(selectedOrganization.name);
                setSelectedOrgId(selectedOrgId);
            } else {
                setSelectedOrg('');
                setSelectedOrgId('');
            }
        } else {
            setSelectedOrg('');
            setSelectedOrgId('');
        }

        setEditorContent('');
        setSelectedTemplateIndex('');
    };

    const handleEditorChange = (content, editor) => {
      if (content !== editorContent.body) {
          setEditorContent(prev => ({ ...prev, body: content }));
      }
  };

    const handleSubjectChange = (event) => {
        const newSubject = event.target.value;
        setEditorContent(prev => ({ ...prev, subject: newSubject }));
    };

    const handleInviteLinkChange = (event) => {
        const newInviteLinkText = event.target.value;
        setEditorContent(prev => ({ ...prev, invite_link_text: newInviteLinkText }));
    };

    const handleTemplateSelect = async (event) => {
      const selectedIndex = event.target.value;
      setSelectedTemplateIndex(selectedIndex);
      setDefaultTemplateLoaded(false);
  
      setEditorContent({ subject: '', body: '', invite_link_text: '' });
  
      const isSmsTemplate = active === 'SMS';
      const selectedTemplate = isSmsTemplate ? smsTemplates[selectedIndex] : emailTemplates[selectedIndex];
  
      if (!selectedTemplate || !selectedOrgId) {
          setEditorContent({ subject: '', body: '', invite_link_text: '' });
          return;
      }
  
      let templateName = selectedTemplate.name;
      if ((isSmsTemplate || emailTemplates) && templateName.endsWith('_sw')) {
          templateName = templateName.substring(0, templateName.lastIndexOf('_sw'));
      }
  
      await fetchTemplateData(templateName, isSmsTemplate, selectedOrgId);
  };

    const fetchTemplateData = async (templateName, isSmsTemplate, orgId) => {
        const fetchAction = isSmsTemplate ? fetchSmsTemplateByName : fetchEmailTemplateByName;

        try {
            const response = await dispatch(fetchAction(templateName, orgId));
            if (response.status && response.data) {
                setEditorContent({
                    subject: response.data.subject || '',
                    body: response.data.body || '',
                    invite_link_text: response.data.invite_link_text || '',
                });
                setTemplateId(response.data.id);
                return true;
            } else {
                setEditorContent('');
                setTemplateId(null);
                return false;
            }
        } catch (error) {
            console.error('Error fetching the template:', error);
            setEditorContent('Error fetching template. Please try again.');
            return false;
        }
    };

    const fetchAndPreviewTemplate = async () => {
        if (!selectedTemplateIndex || !selectedOrgId) {
            console.error('No template or organization selected');
            return;
        }

        const isSmsTemplate = active === 'SMS';
        const selectedTemplate = isSmsTemplate ? smsTemplates[selectedTemplateIndex] : emailTemplates[selectedTemplateIndex];

        let templateName = selectedTemplate.name;
        if (templateName.endsWith('_sw')) {
            templateName = templateName.substring(0, templateName.lastIndexOf('_sw'));
        }

        await fetchTemplateData(templateName, isSmsTemplate, selectedOrgId);
    };

    const openDefaultViewer = async () => {
        setIsDefaultViewerOpen(true);

        let templateName = active === 'SMS' ? smsTemplates[selectedTemplateIndex]?.name : emailTemplates[selectedTemplateIndex]?.name;

        if ((active === 'SMS' || active === 'Email') && templateName.endsWith('_sw')) {
            templateName = templateName.substring(0, templateName.lastIndexOf('_sw'));
        }
        if (!templateName || !selectedOrgId) {
            console.error('Missing template name or organization ID for preview');
            return;
        }
        try {
            if (active === 'Email') {
                const templateData = await dispatch(fetchEmailTemplatePreview(templateName, selectedOrgId));
                setDefaultTemplateData({
                    subject: templateData.data.subject || '',
                    body: templateData.data.body || '',
                    invite_link_text: templateData.data.invite_link_text || '',
                });
            } else if (active === 'SMS') {
                const templateData = await dispatch(fetchSmsTemplatePreview(templateName, selectedOrgId));
                setDefaultTemplateData(templateData);
            }
        } catch (error) {
            console.error('Error fetching the template preview:', error);
        }
    };

    const handleLoadDefaultTemplate = () => {
        if (active === 'SMS') {
            setEditorContent({ body: defaultTemplateData || '' });
        } else {
            setEditorContent(defaultTemplateData);
        }
        setIsDefaultViewerOpen(false);
        setIsModalOpen(false);
        setDefaultTemplateLoaded(true);
    };

    const handlePreviewClick = () => {
        setIsModalOpen(true);
    };

    const closeDefaultViewer = () => {
        setIsDefaultViewerOpen(false);
    };

    const handleModalClose = (answer) => {
        setIsModalOpen(false);
        if (answer) {
            setEditorContent('Default content...');
        }
    };

    const handleSwitch = (type) => {
        setActive(type);
        setSelectedTemplateIndex('');
        setEditorContent('');
    };

    const handleCreateOrUpdateTemplate = async () => {
      const selectedTemplate = active === 'SMS' ? smsTemplates[selectedTemplateIndex] : emailTemplates[selectedTemplateIndex];
      let templateData;
  
      if (!selectedTemplate) {
          console.error("No template selected");
          setFlash({ show: true, type: 'error', message: 'No template selected.' });
          return;
      }
  
      let templateName = selectedTemplate.name;
      if ((active === 'SMS' || active === 'Email') && templateName.endsWith('_sw')) {
          templateName = templateName.substring(0, templateName.lastIndexOf('_sw'));
      }
  
      if (active === 'Email') {
          templateData = {
              current_organization_id: parseInt(selectedOrgId, 10),
              email_template: {
                  name: templateName,
                  subject: editorContent.subject,
                  body: editorContent.body,
                  invite_link_text: editorContent.invite_link_text,
                  active: true
              }
          };
      } else if (active === 'SMS') {
          templateData = {
              current_organization_id: parseInt(selectedOrgId, 10),
              sms_template: {
                  name: templateName,
                  body: editorContent.body,
                  active: true
              }
          };
      } else {
          console.error("Invalid template type");
          setFlash({ show: true, type: 'error', message: 'Invalid template type.' });
          return;
      }
    
      try {
          let response;
          if (templateId) {
              response = active === 'SMS'
                  ? await dispatch(updateSmsTemplate(templateId, templateData))
                  : await dispatch(updateEmailTemplate(templateId, templateData));
          } else {
              response = active === 'SMS'
                  ? await dispatch(createSmsTemplate(templateData))
                  : await dispatch(createEmailTemplate(templateData));
              
              if (response.status && response.data && response.data.id) {
                  setTemplateId(response.data.id);
              }
          }
  
          if (response.status) {
              setFlash({ show: true, type: 'success', message: 'Template successfully ' + (templateId ? 'updated' : 'created') + '!' });
          } else {
              setFlash({ show: true, type: 'error', message: 'Failed to ' + (templateId ? 'update' : 'create') + ' template: ' + response.message });
          }
      } catch (error) {
          console.error('An error occurred:', error);
          setFlash({ show: true, type: 'error', message: 'An error occurred: ' + error.message });
      }
  };
  
    const getLocalizedDisplayName = (templateName, type) => {
        const templateBase = templateName.split('_').slice(0, -1).join('_');
        const localeSuffix = templateName.split('_').pop();
        const baseDisplayName = type === 'SMS'
            ? smsTemplateNameBase[templateBase] || templateBase
            : templateDisplayNameBase[templateBase] || templateBase;
        return `${baseDisplayName} - ${localeSuffix.toLowerCase()}`;
    };

    useEffect(() => {
        if (flash.show) {
            const timeoutId = setTimeout(() => {
                setFlash({ ...flash, show: false });
            }, 3000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [flash]);

    const orgOptions = organizationsList.map(org => ({
        value: org.id,
        label: `${org.name}: ${org.id}`
    }));
  
    const selectStyles = {
      container: (base) => ({
        ...base,
        width: '16rem',
        fontFamily: '"SF Pro Display"',
        fontSize: '12px',
        color: 'white',
      }),
      option: (base, state) => ({
        ...base,
        borderBottom: '1px solid #ccc',
        padding: '10px',
        backgroundColor: state.isSelected ? '#3e85f9' : 'white',
        color: state.isSelected ? 'white' : '#596980',
        ':hover': {
          backgroundColor: '#3e85f9',
          color: 'white',
        },
      }),
      menu: (base) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
      }),
      control: (base) => ({
        ...base,
        fontFamily: '"SF Pro Display"',
        fontSize: '12px',
        color: '#596980',
      }),
      singleValue: (base) => ({
        ...base,
        color: '#596980',
      })
    };

    return (
      <div className="email-sms-container">
        {flash.show && (
          <FlashMessage
            iconImage={flash.type === 'success' ? 'tick' : 'alert'}
            firstMsg={flash.type === 'success' ? 'Success' : 'Error'}
            secondMsg={flash.message}
            closeFlash={() => setFlash({ ...flash, show: false })}
          />
        )}
        <div className="ld-container">
          <h3 className="ld-header-email-sms">Organizations - Email/SMS</h3>
        </div>
        <div className="email-sms-table-top bg-white">
          <div className="email-sms-flex-header customer-activity-header">
            <div className='email-sms-template-name'>
              <p className='email-sms-text-header'>
                {active} Templates - {selectedOrg} - Org id: {selectedOrgId}
              </p>
            </div>
            <div className="email-sms-selects-container">
              <div className='email-sms-select'>
                <ReactSelect
                  value={orgOptions.find(option => option.value.toString() === selectedOrgId)}
                  styles={selectStyles}
                  onChange={handleOrgChange}
                  options={orgOptions}
                  placeholder="Select organization"
                  isClearable={true}
                  noOptionsMessage={() => "No organizations found"}
                  className='email-sms-select-org-dropdown'
                />
                <ul className="email-sms-switch-map">
                  <li
                    className={`icon-month ${active === 'SMS' ? 'active' : ''}`}
                    onClick={() => handleSwitch('SMS')}
                    id='email-sms-switch-id'
                  >SMS</li>
                  <li
                    className={`icon-year ${active === 'Email' ? 'active' : ''}`}
                    onClick={() => handleSwitch('Email')}
                  >Email</li>
                </ul>
              </div>
              {active && (
                <div className='email-sms-container-template-select'>
                  <p style={{ marginBottom: "0.1rem" }}>Select {active.toLowerCase()} template</p>
                  <div className={`sms-email-select-wrapper ${!selectedOrgId ? 'disabled' : ''}`}>
                    <ReactSelect
                      className='email-sms-template-select'
                      styles={selectStyles}
                      value={(active === 'SMS' ? smsTemplates : emailTemplates)
                        .map((template, index) => ({
                          value: index,
                          label: template.displayName
                        }))
                        .find(option => option.value.toString() === selectedTemplateIndex)}
                      onChange={(selectedOption) => {
                        handleTemplateSelect({
                          target: {
                            value: selectedOption ? selectedOption.value.toString() : ''
                          }
                        });
                      }}
                      options={(active === 'SMS' ? smsTemplates : emailTemplates)
                        .map((template, index) => ({
                          value: index,
                          label: template.displayName
                        }))}
                      placeholder="Select a template"
                      isDisabled={!selectedOrgId}
                    />
    
                    {!selectedOrgId && (
                      <span className="sms-email-tooltip">
                        Please select an organization
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {selectedTemplateIndex && (
            <div className='email-sms-template-custom-form'>
              {active === 'Email' && (
                <>
                  <div className='email-sms-template-input'>
                    <label className="labelFormUser">Subject</label>
                    <input style={{ fontSize: '14px' }} type="text" className="form-control" placeholder="Subject" value={editorContent.subject || ''} onChange={handleSubjectChange} />
                  </div>
                  <div className='email-sms-template-input'>
                    <label className="labelFormUser">Invite link text</label>
                    <input style={{ fontSize: '14px' }} type="text" className="form-control" placeholder="CTA Button Text" value={editorContent.invite_link_text || ''} onChange={handleInviteLinkChange} />
                  </div>
                </>
              )}
              <div className='email-sms-editor-container'>
                {active === 'Email' ? (
                  <Editor
                    apiKey={apiKey}
                    value={editorContent.body}
                    onEditorChange={handleEditorChange}
                    init={{
                      height: 700,
                      width: '100%',
                      image_advtab: true,
                      plugins: [
                        'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'markdown',
                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                        'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'wordcount'
                      ],
                      toolbar: `undo redo | casechange blocks | bold italic code| 
                      alignleft aligncenter alignright alignjustify | 
                      bullist numlist checklist outdent indent`,
                    }}
                  />
                ) : (
                  <textarea
                    className="email-sms-textarea"
                    value={editorContent.body || ''}
                    onChange={e => setEditorContent(prev => ({ ...prev, body: e.target.value }))}
                    placeholder="Enter SMS template content here..."
                  ></textarea>
                )}
                {active === 'SMS' && (
                  <div className='sms-instructions'>
                    <p>- Variables must be used in SMS text- [invite_link]</p>
                    <p>- Other variables can be used in SMS text- [recipient_name], [end_user_display_name]</p>
                    <p>- Note: Once this SMS Template is activated the variables used within, will be replaced with their actual values and sent to customer.</p>
                  </div>
                )}
              </div>
              <div className='email-sms-template-btn'>
                <div className='email-sms-template-two-btn'>
                  <div className={`preview-button-wrapper ${!defaultTemplateLoaded ? 'disabled' : ''}`}>
                    <button className='email-sms-button-preview' onClick={handlePreviewClick}>Preview</button>
                  </div>
                  <button className='email-sms-button' onClick={openDefaultViewer}>View Default Template</button>
                </div>
                <button className='email-sms-button-create' onClick={handleCreateOrUpdateTemplate}>
                  {templateId ? 'Update' : 'Create'}
                </button>              
              </div>
            </div>
          )}
          <PreviewViewer isOpen={isModalOpen} onClose={handleModalClose} onConfirm={fetchAndPreviewTemplate} />
          <ViewDefaultViewer isOpen={isDefaultViewerOpen} onClose={closeDefaultViewer} onLoadDefaultTemplate={handleLoadDefaultTemplate} templateBody={defaultTemplateData} active={active} />
        </div>
      </div>
    );    
};

export default OrganizationsEmailSms;
