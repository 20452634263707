import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { SET_USER_MANAGEMENT_LIST } from "../../Redux/Actions/Types/Types";
import setRequestHeader from "../../Utils/setRequestHeader";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";

export function getusermanagementList(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${
    API_CONFIG.MIDDLE_PATH
  }${
    API_CONFIG.API_VERSION_PATH
  }${API_CONFIG.END_POINTS.USER_MANAGEMENT.LIST()}`;
  const newUrl = apiURL + data;
  return (dispatch) =>
    axios
      .get(newUrl)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return { status: false, message: err.response.data.message };
      });
}

export function createUser(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${
    API_CONFIG.MIDDLE_PATH
  }${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CREATE_USER.CREATE()}`;
  return (dispatch) =>
    axios
      .post(apiURL, data)
      .then((res) => {
        const success = res.data.status !== "error";
        if (success) {
          return {
            status: true,
            message: res.data.status,
            data: res.data.data,
          };
        } else {
          return { status: false, message: res.data.status };
        }
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return { status: false, message: err.res.data.message };
      });
}

export function editUser(data, id) {
  const apiURL =
    `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${
      API_CONFIG.MIDDLE_PATH
    }${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.EDIT_USER.EDIT()}` +
    id;
  return (dispatch) =>
    axios
      .put(apiURL, data)
      .then((res) => {
        const success = res.data.status !== "error";
        if (success) {
          if (id === Math.ceil(localStorage.getItem("id"))) {
            setRequestHeader(res.data.data);
          }
        }
        return { status: true, message: res.data.status };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return { status: false, message: err.response.data.message };
      });
}

export function deleteUser(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${
    API_CONFIG.MIDDLE_PATH
  }${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DELETE_USER.DELETE()}`;
  const apiURLComplete = apiURL + data;
  return (dispatch) =>
    axios
      .delete(apiURLComplete)
      .then((res) => {
        return { status: true, message: res.data.status };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return { status: false, message: err.response.data.message };
      });
}

export function setUsermanagementList(response) {
  return {
    type: SET_USER_MANAGEMENT_LIST,
    users_list: response,
  };
}
