import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import SmsList from "../../Components/Sms/smsList";

import "./style.css";

const Sms = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Sms";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <SmsList />
      </div>
    </div>
  );
};

export default Sms;
