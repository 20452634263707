import React from 'react';
import { useForm } from 'react-hook-form';

const EditUploadFilesPopup = ({ h3, createUploadFiles, directories, ...props }) => {
  const { register, handleSubmit, reset } = useForm();

  const ApiData = async (data) => {
    const formData = new FormData();
    const post = { ...data, attachment: data.file_name[0] }
    formData.append('attachment', post.attachment)
    props.confirm(formData)
    reset()
  }

  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner EditPopupUploads'>
        <h3>{h3}</h3>
        <form onSubmit={handleSubmit(ApiData)}>
          <div className="row">
            <div className="col-md-6">
              <div className="setting-filter-container input-credentials">
                <div className="provider-input-file">
                  <input type="file" accept="image/png, image/gif, image/jpeg" {...register('file_name')} required />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' onClick={props.closeDelete} style={{ marginLeft: '0px' }}>Cancel</button>
            <button className='confirm-update' type='submit'>Update</button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default EditUploadFilesPopup;