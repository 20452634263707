import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";

export function getServiceProviderData(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SERVICE_PROVIDER.FILTER()}` + data
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function editServiceProviderData(id, editData) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SERVICE_PROVIDER.UPDATE()}` + `${id}/update_status?status=${editData.status}&allow_refetch_transactions=${editData.allow_refetch_transactions}&clear_credentials=${editData.clear_credentials}&sandbox_enabled=${editData.sandbox_enabled}`
  return dispatch => axios.post(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

