import React, { useState } from 'react';
import '../EditModalBank/editModalBank.css'
import ReactSelect from '../ReactSelect/ReactSelect';

const CreateUserModal = ({ closeDelete, orgUser, orgList, createUserHandler }) => {

  const [createUser, setCreateUser] = useState({ organization_id: '', name: '', email: '', role: '' })

  const handleSubmit = (e) => {
    e.preventDefault()
    createUserHandler(createUser)
  }

  const changeHandler = (type, value) => {
    setCreateUser((createUser) => ({
      ...createUser,
      [type]: value
    }))
  }

  return (
    <div className='EditPopup org-create-user-pop'>
      <div className='EditPopup_inner organization-edit-user'>
        <div className="deactivate-header">
          <h3>Create User</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="setting-filter-container input-credentials">
            <div className="deactivate-user-data table-responsive">
              <div className="form-group">
                <label>Organizations</label>
                <ReactSelect
                  orgList={orgList}
                  handleChange={changeHandler}
                  pageRender={'organization user create'}
                />
              </div>
              <div className="form-group">
                <label>Name </label>
                <input required type="text" value={orgUser?.name} onChange={(e) => changeHandler('name', e.target.value)} />
              </div>
              <div className="form-group">
                <label>Email </label>
                <input required type='email' defaultValue={orgUser?.email} onChange={(e) => changeHandler('email', e.target.value)} />
              </div>
              <div className="form-group">
                <label>Role </label>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                <select required className="select-filter-service-settings capitalize" value={orgUser?.role} onChange={(e) => changeHandler("role", e.target.value)}>
                  <option value="">Select</option>
                  <option value="admin">Administrator</option>
                  <option value="employee">Administrative Officer</option>
                </select>
              </div>
            </div>
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' onClick={closeDelete} style={{ marginLeft: '0px' }}>Cancel</button>
            <button className='confirm-update' type='submit'>Create</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateUserModal;