import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import CertificateInfoView from "../../Components/CertificateInformation/CertificateInfoView/CertificateInfoView";
const CertificateInfoViewPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Certificate Information view";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <CertificateInfoView />
      </div>
    </div>
  );
};

export default CertificateInfoViewPage;