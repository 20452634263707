import { Link, useHistory } from 'react-router-dom';
import './style.scss'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addBanner, updateBanner } from '../../Redux/Actions/Banners';
import icon_warning from '../../assets/images/Vector.svg'
import icon_info from '../../assets/images/info.svg'
const BannersForm = (props) => {
  const [bannerData, setBannerData] = useState({
    id: null,
    enabled: true,
    product: "Select",
    template: "",
    title: "",
    message: "",
    color: "blue",
    icon: "",
    link: "",
    link_text: ""
  });
  const [actionButton, setActionButton] = useState(props.actionString);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setActionButton(props.actionString)
    if (props.data) {

      setBannerData(props.data);

    }
  }, [props])
  const sendDataToApi = async () => {
    if (bannerData.id !== null) {
      return await dispatch(updateBanner(bannerData));
    } else {
      return await dispatch(addBanner(bannerData));
    }
  }
  const sendBanner = async (e) => {
    e.preventDefault();
    setActionButton(<div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>)
    sendDataToApi().then((res) => {
      if (res.status) {

        navigateToOverview();
      } else {
        setActionButton(props.actionString);
      }
    });
  }

  const navigateToOverview = () => {
    history.push("/banners")
  }

  const showIcon = () => {
    switch (bannerData.icon) {
      case "warning":
        return icon_warning;
      case "info":
        return icon_info;
      default:
        return null;
    }
  }

  const checkValidity = () => {
    if (bannerData.product !== "Select" && bannerData.template.length && bannerData.title.length && bannerData.message.length) {
      return true;
    }
    return false;
  }
  return (

    <>

      <div className="container bg-white mw-100 p-4">
        <form action="" className="d-flex flex-column w-100 flex-fill text-black-50">
          <div className="flex-grow-0 m-2 w-25">
            <label className="form-label" htmlFor="product">Product</label>
            <select className="form-select" name="product"
              disabled={props?.data}
              value={bannerData.product}
              placeholder={bannerData.product ?? "Select"}
              onChange={(e) => {
                setBannerData({ ...bannerData, product: e.target.value })
              }}
            >
              <option disabled hidden>Select</option>
              <option value="vista">Vista</option>
              <option value="vista_request_log">Vista - Request log</option>
              <option value="public_website">Public Website</option>
            </select>
          </div>
          <div className="m-2">
            <label className="form-label" htmlFor="template">Template name</label>
            <input className="form-control" type="text" name="template" id="" placeholder="Enter template name"
              value={bannerData.template}
              onChange={(e) => {
                setBannerData({ ...bannerData, template: e.target.value })
              }}
            />
          </div>
          <div className="m-2">
            <label className="form-label" htmlFor="title">Title</label>
            <input className="form-control" type="text" name="title" id="" placeholder="Enter title"
              value={bannerData.title}
              onChange={(e) => {
                setBannerData({ ...bannerData, title: e.target.value })
              }}
            />
          </div>
          <div className="m-2">
            <label className="form-label" htmlFor="message">Message</label>
            <textarea className="form-control banners-form-inputs" name="message" id="" rows="4" placeholder="Enter message"
              value={bannerData.message}
              onChange={(e) => {
                setBannerData({ ...bannerData, message: e.target.value })
              }}
            ></textarea>
          </div>
          <div className="d-flex flex-row justify-content-end">
            <div className='d-flex align-items-center'>
              <label htmlFor="color" className='form-label m-2'>Color</label>
              <select value={bannerData.color} name="color" className='form-select' id="" onChange={(e) => {
                setBannerData({ ...bannerData, color: e.target.value })
              }}>
                <option value="blue">Blue</option>
                <option value="red">Red</option>
                <option value="yellow">Yellow</option>
              </select>
              <div className={`form-color-preview form-color-preview-${bannerData.color} m-2`}></div>
            </div>
            <div className='d-flex align-items-center'>
              <label htmlFor="icon" className='form-label m-2'>Icon</label>
              <select value={bannerData.icon} name="icon" className='form-select' id="" onChange={(e) => {
                setBannerData({ ...bannerData, icon: e.target.value })
              }}>
                <option value="">none</option>
                <option value="warning">Warning</option>
                <option value="info">Info</option>
              </select>
              <div className={`form-icon-preview m-2`}>{showIcon() != null ? <img src={showIcon()} width={28} height={28} alt="showIcon" /> : ""}</div>
            </div>
            <div className='d-flex align-items-center me-auto'>
              <label htmlFor="link" className='form-label m-2'>Link</label>
              <input value={bannerData.link} className='form-control me-1' type="text" name="link" id="" placeholder='href'
                onChange={(e) => {
                  setBannerData({ ...bannerData, link: e.target.value })
                }} />
              <input value={bannerData.link_text} className='form-control' type="text" name="link" id="" placeholder='link text'
                onChange={(e) => {
                  setBannerData({ ...bannerData, link_text: e.target.value })
                }} />
            </div>
            <div className="form-check form-switch d-flex align-items-center m-3">
              <label className="form-check-label align-middle m-1" htmlFor="EnabledToggle">Enable</label>
              <input className="form-check-input align-middle m-1 banners-checkbox-switch" type="checkbox" name='EnabledToggle' id="EnabledToggle"
                checked={bannerData.enabled}
                onChange={(e) => {
                  setBannerData({ ...bannerData, enabled: e.target.checked })
                }}
              />
            </div>
            <Link to="/banners">
              <button className="btn btn-outline-primary m-3 banners-button">Cancel</button>
            </Link>
            <button className="btn btn-primary ms-3 mt-3 mb-3 me-2 banners-button" disabled={!checkValidity()} onClick={sendBanner}>{actionButton}</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default BannersForm;