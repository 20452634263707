import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import FlashMessage from '../FlashMessage/FlashMessage';
import YearlyGraph from "./yearlyGraph";
import MonthlyGraph from "./monthlyGraph";
import { getdisplayFlash, setdisplayFlash } from '../../Utils/Common';
import "./Scss/dashboard.scss";
import Summary from "./summary";
import { dashboardGraphData } from '../../Redux/Actions/Dashboard';
import moment from "moment";
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import ListSpinner from '../LoaderSpinner/listspinner';
import CustomersYearlyGraph from "./customersYearlyGraph";
import CustomersMonthlyGraph from "./customersMonthlyGraph";
import DashboardHeader from "./dashboardHeader";
import ModesChart from "./modesPieChart";
import ProvidersChart from "./providersPieChart";

const date = new Date();
function daysInMonth(month, year) {
	return new Date(year, month, 0).getDate();
}
const START_DATE = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('DD/MM/YYYY');
const END_DATE = moment(new Date(date.getFullYear(), date.getMonth(), daysInMonth(date.getMonth() + 1, date.getFullYear()))).format('DD/MM/YYYY')

const DashboardList = (props) => {
	const history = useHistory();
	const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
	const [iconImage, setIconImage] = useState("tick")
	const [firstMsg, setFirstMsg] = useState("Done!")
	const [secondMsg, setSecondMsg] = useState("Logged in successfully")
	const dispatch = useDispatch();
	const [graphDataCount, setgraphDataCount] = useState({});
	const [accepted_requests_month, setaccepted_requests_month] = useState({})
	const [activeCustomer, setActivecustomer] = useState({})
	const [acceptedRequestDay, setAccepted_requests_day] = useState({})
	const [activeCustomerDay, setActiveCustomerDay] = useState({})
	const selectedDateRange = `${START_DATE}-${END_DATE}`;
	const [acceptedRequestsLastYear, setAcceptedRequestsLastYear] = useState({});
	const [acceptedRequestLastMonth, setAcceptedRequestsLastMonth] = useState({});
	const [activeCustomersLastMonth, setActiveCustomersLastMonth] = useState({});
	const [activeCustomersLastYear, setActiveCustomersLastYear] = useState({});
	const [manualRequest, setManualRequest] = useState({});
	const [apiRequest, setApiRequest] = useState({});
	const [iframeRequest, setIframeRequest] = useState({});
	const [externalPayloadRequest, setExternalPayloadRequest] = useState({});
	const [smsReq, setSmsReq] = useState({});
	const [emailReq, setEmailReq] = useState({});
	const [services, setServices] = useState({});

	const listLoader = false

	const closeFlash = () => {
		setdisplayFlash(false);
		setFlashMessage(false);
	}
	const openFlash = () => {
		setdisplayFlash(true);
		setFlashMessage(true);
		setIconImage(iconImage);
		setFirstMsg(firstMsg);
		setSecondMsg(secondMsg);
	}

	useEffect(() => {
		getGraphData();
	}, []);

	const getGraphData = async () => {
		const data = "?date_range=" + selectedDateRange
		await dispatch(setLoaderSpinner(true))
		const res = await dispatch(dashboardGraphData(data));

		if (res.status) {
			const {
				total_clients,
				new_clients_yearly,
				new_clients_weekly,
				new_clients_monthly,
				accepted_requests_month,
				accepted_requests_day,
				active_customers_day,
				active_customers_month,
				accepted_requests_last_year,
				accepted_requests_last_month,
				active_customers_last_month,
				active_customers_last_year,
				api_requests,
				manual_requests,
				iframe_requests,
				external_payload_monthly,
				sms_request_monthly,
				email_request_monthly,
				tink_provider_monthly,
				enable_banking_provider_monthly,
				klarna_provider_monthly,
				services,
				consumer,
				corporate,
			} = res.data.graph_data
			setgraphDataCount({ total_clients: total_clients, new_clients_yearly: new_clients_yearly, new_clients_monthly: new_clients_monthly, new_clients_weekly: new_clients_weekly, api_requests: api_requests, manual_requests: manual_requests, iframe_requests: iframe_requests, external_payload_monthly: external_payload_monthly, sms_request_monthly: sms_request_monthly, email_request_monthly: email_request_monthly, tink_provider_monthly: tink_provider_monthly, enable_banking_provider_monthly: enable_banking_provider_monthly, klarna_provider_monthly: klarna_provider_monthly, services: services, consumer: consumer, corporate: corporate })
			setaccepted_requests_month(accepted_requests_month)
			setActivecustomer(active_customers_month)
			setAccepted_requests_day(accepted_requests_day)
			setActiveCustomerDay(active_customers_day)
			setAcceptedRequestsLastYear(accepted_requests_last_year);
			setAcceptedRequestsLastMonth(accepted_requests_last_month);
			setActiveCustomersLastMonth(active_customers_last_month);
			setActiveCustomersLastYear(active_customers_last_year);
			setManualRequest(manual_requests);
			setApiRequest(api_requests);
			setIframeRequest(iframe_requests);
			setExternalPayloadRequest(external_payload_monthly);
			setSmsReq(sms_request_monthly);
			setEmailReq(email_request_monthly);
			setServices(services);
			await dispatch(setLoaderSpinner(false))
		} else {
			if (res.message === "Unauthorize") {
				history.push("/login")
			}
		}
		await dispatch(setLoaderSpinner(false))
	}


	const manualReq = Object.values(manualRequest);
	const iframeReq = Object.values(iframeRequest);
	const apiReq = Object.values(apiRequest);
	const externalReq = Object.values(externalPayloadRequest);
	const smsReqs = Object.values(smsReq);
	const emailReqs = Object.values(emailReq);

	return (
		<div className="dashboard-page-container">
			<div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
				<FlashMessage
					iconImage={iconImage}
					firstMsg={firstMsg}
					secondMsg={secondMsg}
					openFlash={() => openFlash()}
					closeFlash={() => closeFlash()}
				/>
			</div>
			{/* <DashboardFilter selectedDateRange={selectedDateRange} setDateRange={(value) => setSelectedDateRange(value)} handleSubmit={() => getDataByDate()} /> */}
			<DashboardHeader />
			<div className="ds-container">
				<div className="column1">
					<YearlyGraph accepted_requests_month={accepted_requests_month} accepted_requests_last_year={acceptedRequestsLastYear} />
					<br></br>
					{/* <div className="ds-section1-container"> */}
					<div className="ds-month-main"><MonthlyGraph acceptedRequestDay={acceptedRequestDay} acceptedRequestLastMonth={acceptedRequestLastMonth} /></div>
					{/* <div className="ds-week-main"><WeeklyGraph acceptedRequestDay={acceptedRequestDay} acceptedRequestLastWeek={acceptedRequestLastWeek} activeCustomersWeekly={activeCustomersWeekly} /></div> */}
					{/* </div> */}
					<br></br>
					<CustomersYearlyGraph activeCustomer={activeCustomer} activeCustomersLastYear={activeCustomersLastYear} />
					<br></br>
					{/* <div className="ds-section1-container"> */}
					<div className="ds-month-main"><CustomersMonthlyGraph activeCustomerDay={activeCustomerDay} activeCustomersLastMonth={activeCustomersLastMonth} /></div>
					{/* <div className="ds-week-main"><CustomersWeeklyGraph activeCustomersWeekly={activeCustomersWeekly} activeCustomersLastWeek={activeCustomersLastWeek} /></div> */}
					{/* </div> */}
					<br></br>
					<ModesChart graphDataCount={graphDataCount} manualReq={manualReq} apiReq={apiReq} iframeReq={iframeReq} externalReq={externalReq} smsReqs={smsReqs} emailReqs={emailReqs} />
					<br></br>
					{services.length >= 0 &&
						<ProvidersChart services={services} />
					}
					<br></br>
					{listLoader &&
						<>
							<br />
							<ListSpinner loading={listLoader} />
						</>}
				</div>
				<div className="column2">
					<Summary graphDataCount={graphDataCount} acceptedRequestDay={acceptedRequestDay} externalReq={externalPayloadRequest} />
				</div>
			</div>
		</div>
	)
}
export default DashboardList;