import React, { useState } from 'react';
import sortIcon from "../../../assets/images/sort.svg";
import editIcon from "../../../assets/images/edit.svg";
import { useHistory } from "react-router-dom";
import "../Healthcheck.scss";
import Flag from 'react-world-flags'

const StatusTable = ({ statusData, searchKey }) => {
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const history = useHistory();

  const sortData = (data) => {
    if (!sortKey) return data;

    return data.slice().sort((a, b) => {
      const valA = (a[sortKey] || "").toString().toLowerCase();
      const valB = (b[sortKey] || "").toString().toLowerCase();
      return valA.localeCompare(valB) * (sortOrder === 'asc' ? 1 : -1);
    });
  };

  const sortedData = sortData(statusData);

  const applySorting = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  const editSelectedStatus = (statusToEdit) => {
    history.push({
      pathname: "/healthcheck_edit",
      state: { user: statusToEdit }
    });
  };

  const countryCodeMapping = {
    "sweden": "SE",
    "norway": "NO",
    "finland": "FI",
    "denmark": "DK",
    "united kingdom": "GB",
    "united states": "US",
    "india": "IN",
    "germany": "DE",
    "spain": "ES",
    "portugal": "PT",
    "netherland": "NL",
    "malta": "MT",
    "belgium": "BE",
  };

  return (
    <div className="healthcheck-users-container">
      <div className="healthcheck-organizationList-thead-scroll-y">
        <table className="table healthcheck-org-list-table">
          <thead className="healthcheck-organizations-list-thead">
            <tr className="healthcheck-category-thread">
              <th className="healthcheck-org-list-head name-header" onClick={() => applySorting("bankName")}>
                Name
                <img className="sort-icon-hc" src={sortIcon} alt="sort" />
              </th>
              <th className="healthcheck-org-list-head status-header" onClick={() => applySorting("status")}>
                Status
                <img className="sort-icon-hc" src={sortIcon} alt="sort" />
              </th>
              <th className="healthcheck-org-list-head service-header" onClick={() => applySorting("identifier")}>
                Identifier
                <img className="sort-icon-hc" src={sortIcon} alt="sort" />
              </th>
              <th className="healthcheck-org-list-head service-header" onClick={() => applySorting("Type")}>
                Type
                <img className="sort-icon-hc" src={sortIcon} alt="sort" />
              </th>
              <th className="healthcheck-org-list-head service-header" onClick={() => applySorting("service")}>
                Service
                <img className="sort-icon-hc" src={sortIcon} alt="sort" />
              </th>
              <th className="healthcheck-org-list-head service-header" onClick={() => applySorting("country")}>
                Market
                <img className="sort-icon-hc" src={sortIcon} alt="sort" />
              </th>

              <th className="healthcheck-org-list-head service-header" onClick={() => applySorting("service")}>
                Last Successful Check
                <img className="sort-icon-hc" src={sortIcon} alt="sort" />
              </th>

              <th className="healthcheck-category-head action-header">Action
              </th>
            </tr>
          </thead>
          <tbody className="healthcheck-organizations-list-tbody healthcheck-organizations-tbody">
            {sortedData.map((status) => (
              <tr key={status.id} className={`healthcheck-user-box ${status.status.toLowerCase() === 'error' ? 'error-row' : ''}`}>
                <td className="healthcheck-org-list-td name-data">{status.bankName || status.type}  <span className='statusIdtext'>({status.id})</span></td>
                <td className={`healthcheck-org-list-td status-data ${status.status.toLowerCase() === 'error' ? 'status-error' :
                  status.status.toLowerCase() === 'ok' ? 'status-ok' : ''
                  }`}>
                  {status.status ? status.status.charAt(0).toUpperCase() + status.status.slice(1).toLowerCase() : 'N/A'}
                </td>
                <td className="healthcheck-org-list-td status-data">
                  {status.identifier ? status.identifier : 'N/A'}
                </td>
                <td className="healthcheck-org-list-td status-type">
                  {status.statustype ? status.statustype : 'N/A'}
                </td>
                <td className="healthcheck-org-list-td service-data">
                  {status.serviceName.charAt(0).toUpperCase() + status.serviceName.slice(1)}
                </td>
                <td className="healthcheck-org-list-td country-data">
                  {status.country && countryCodeMapping[status.country.toLowerCase()] ? (
                    <span className="invoice-tooltip-wrapper hover-text">
                      <Flag code={countryCodeMapping[status.country.toLowerCase()]} height="16" className='invoicing-flag' />
                    </span>
                  ) : status.country || 'N/A'}
                </td>
               <td className="healthcheck-org-list-td service-data last-successful-check">
                  {status.lastCheck ? new Date(status.lastCheck).toLocaleString() : 'N/A'}
                </td>
                <td className="healthcheck-org-list-td action-data">
                  <div className="edit-icon" onClick={() => editSelectedStatus(status)}>
                    <img src={editIcon} alt="edit" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default StatusTable;
