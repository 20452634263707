import { useEffect, useState } from 'react';
import './BankPopup.scss'
import arrow from './border-arrow.svg'
const BankStatusHoverPopup = (props) => {
  const [visible, setVisible] = useState(props.visible);
  const [errorMessages, setErrorMessages] = useState(props.errorMessages)
  const [loadingError, setLoadingError] = useState(props.loadingError)
  const [errorsPresent, setErrorsPresent] = useState(false)
  var translatePosition = "translate(-50%, 100%)";
  var topBorderLeftWidth = "calc(50% - 9px)";
  var topBorderRightWidth = "calc(50% - 9px)";
  var arrowPosition = "0";
  const arrayTrimNull = (array) => {
    var newArray = []
    if (!Array.isArray(array)) {
      return []
    }
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        newArray.push(array[i])
      }
    }
    return newArray;
  }
  const offsetPosition = () => {
    if (props.position < 3) {
      translatePosition = "translate(-80%, 100%)"
      topBorderLeftWidth = "calc(80% - 9px)";
      topBorderRightWidth = "calc(20% - 9px)";
      arrowPosition = "calc(20% + 13px)";
    }

    if (props.position > 87) {
      translatePosition = "translate(-20%, 100%)"
      topBorderLeftWidth = "calc(20% - 9px)";
      topBorderRightWidth = "calc(80% - 9px)";
      arrowPosition = "calc(-20% - 13px)";
    }
  }
  offsetPosition();
  useEffect(() => {
    setErrorMessages(arrayTrimNull(props.errorMessages));
    setVisible(props.visible);
    setLoadingError(props.loadingError);
  }, [props.visible, props.errorMessages, props.loadingError])

  if (!visible || !props.data) {
    return <></>
  }
  return (
    <>
      <div className='bank-status-hover-popup-container' style={{ transform: translatePosition }}>
        <img className='bank-status-hover-arrow' src={arrow} alt="" width={20} height={20} style={{ left: arrowPosition }} />
        <div className="bank-status-hover-border">
          <span className="bank-status-hover-border-top bank-status-hover-border-top-left" style={{ width: topBorderLeftWidth }}></span>
          <span className="bank-status-hover-border-top bank-status-hover-border-top-right" style={{ width: topBorderRightWidth }}></span>
          <p className='bank-status-hover-popup-text'>{props.data.time}</p>
          <p className='bank-status-hover-popup-text'>Requests: {props.data.count}</p>
          {props.data.alert >= 1 && <p className='bank-status-hover-popup-text'>Processing: {props.data.alert}</p>}
          <div className={`bank-status-hover-popup-errors ${errorsPresent ? "bank-status-hover-popup-errors-messages" : ""} ${!errorsPresent && !loadingError ? "bank-status-hover-popup-no-errors" : ""}`}>
            {errorMessages && !loadingError && errorMessages.map((message, index) => {
              if (message) {
                if (!errorsPresent) {
                  setErrorsPresent(true);
                }
                if (index < 2) {
                  return (
                    <div key={index} className='bank-status-hover-popup-error-box'>
                      <p className='bank-status-hover-popup-text bank-status-hover-popup-error-text'>{message.error_type}</p>
                      <p className='bank-status-hover-popup-text bank-status-hover-popup-error-text text-muted'>{message.error_message}</p>

                    </div>)

                } else if (index === 2) {
                  return (
                    <p key={index} className='bank-status-hover-popup-text m-2'>and {errorMessages.length - 2} more...</p>)

                }
              }

            }

            )}
            {errorMessages && !loadingError && <p className='bank-status-hover-popup-text'>Click to view more.</p>}
            {loadingError && <>
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </>}
          </div>
        </div>
      </div>
    </>
  )
}

export default BankStatusHoverPopup;