import { useEffect, useState } from "react";
import BankStatusTopBanks from "./BankStatusTopBanks";
import BankStatusList from "./BankStatusList";
import BankStatusFilter from "./utilComponents/BankStatusQuery";
import BankStatusSearchedBanks from "./BankStatusSearchedBanks";

const BankStatusContainer = () => {

  const [queryParams, setQueryParams] = useState({ country_id: 1, search_string: "", page: 1 });
  const [timeFrame, setTimeFrame] = useState(1);
  useEffect(() => {
    setQuery({ country_id: 1, search_string: "" });
    fetchBankStatus();
  }, [])
  const timeFrameHandler = ({ time_frame }) => {
    setTimeFrame(time_frame)
  }
  const setQuery = ({ country_id, query_string }) => {
    setQueryParams({
      country_id: country_id ? country_id : 1,
      query_string: query_string ? query_string : "",
    });
  }


  const fetchBankStatus = async () => {

  }

  return (<>
    <div className="bank-status-container p-1 mw-100">
      <BankStatusFilter updateQuery={setQuery} timeFrameHandler={timeFrameHandler} />
      <BankStatusSearchedBanks query={queryParams} timeFrame={timeFrame} />
      <BankStatusTopBanks query={queryParams} timeFrame={timeFrame} />
      <BankStatusList query={queryParams} timeFrame={timeFrame} />
    </div>
  </>)
}

export default BankStatusContainer;