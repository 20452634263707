import { useState } from "react"
import './BankBar.scss'
import BankStatusHoverPopup from "./BankStatusHoverPopup";
import { useDispatch } from "react-redux";
import { geterrorLogs } from "../../../Redux/Actions/BankStatus";
import ErrorModal from "./BankStatusErrorModal";
import { filterError } from "../utilities/dataFormatting";
const Bar = (props) => {
  const [visible, setVisible] = useState(false);
  const [serverErrors, setServerErrors] = useState();
  const [loadingError, setLoadingError] = useState(false);
  const [errorModalVisibility, setErrorModalVisibility] = useState(false);
  const dispatch = useDispatch();
  const toggleErrorModal = () => {
    setErrorModalVisibility(!errorModalVisibility);
  }
  const toggleVisibility = () => {
    setVisible(!visible);
  }
  const getErrors = async () => {
    if (!serverErrors && props.data.organization_request_id.length > 0) {
      try {
        await setLoadingError(true);
        const res = await dispatch(geterrorLogs(props.data.organization_request_id, props.timeFrame));
        const logs = filterError(res.data.bank_logs)
        await setServerErrors(logs);
        await setLoadingError(false);

      } catch (error) {
        console.log(error);
      } finally {

      }

    }
  }
  return (<>

    <span className="bank-data-node-container">
      {errorModalVisibility && <ErrorModal blockData={props.data} errorLogs={serverErrors} toggleVisibility={toggleErrorModal} />}
      <BankStatusHoverPopup position={props.index} visible={visible} data={props.data} errorMessages={serverErrors} loadingError={loadingError} />
      <span className="bank-data-node-inner">
        <span
          onMouseOver={() => { toggleVisibility(); getErrors() }}
          onMouseLeave={toggleVisibility}
          onClick={toggleErrorModal}
          className={`bank-data-node bank-data-node-color-${props.data.status}`}>
        </span>
      </span>
    </span>
  </>
  )
}




const BankStatusBar = (props) => {

  const filler = () => {
    return Array(91).fill({
      status: "loading",
      message: null,
      count: 0,
      processing: 0,
      organization_request_id: [],
      time: null,
    })

  }

  const blocks = props.blocks || filler();

  if (!blocks) {
    return <>
      <div>
        loading data...
      </div>
    </>
  }

  const bar = blocks.map((node, key) => {
    return <Bar data={node} key={key} index={key} timeFrame={props.timeFrame} />

  })
  return (
    <>
      <div className="bank-status-bar">
        {bar}
      </div>
    </>
  )
}

export default BankStatusBar;