import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { newPasswordSet } from "../../Redux/Actions/Auth";
import FlashMessage from "../FlashMessage/FlashMessage";
import logo from "../../assets/images/new_kreditz_logo.png";
import "../ResetPassword/resetPassword.css";
import "./newPassword.scss";

const schema = yup.object({
  password: yup
    .string()
    .min(12, "Your password must be at least 12 characters long")
    .max(64, "The password can't be longer than 64 characters")
    .matches(/[a-z]/, "Your password must contain a minimum of one lower case letter")
    .matches(/[A-Z]/, "Your password must contain one capital letter")
    .matches(/[0-9]/, "Your password must contain at least one number")
    .matches(/^\S*$/, "The password can't contain any blank spaces")
    .matches(/^[aA-zZ0-9#!%&/()åÅäÄöÖ\s]+$/, "The password can't contain other special characters than: #!%&/()")
    .required("Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
}).required();

const NewPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('activation_token');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [flash, setFlash] = useState({ show: false, type: '', message: '' });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { password, passwordConfirmation } = data;
    if (password === passwordConfirmation) {
      const response = await dispatch(newPasswordSet({ token, password }));
      if (response.status) {
        setShowSuccessModal(true);
      } else {
        setFlash({ show: true, type: 'error', message: response.message });
      }
    } else {
      setFlash({ show: true, type: 'error', message: 'Passwords do not match' });
    }
  };

  return (
    <div>
      {flash.show && (
        <FlashMessage
          iconImage={flash.type === 'success' ? 'tick' : 'alert'}
          firstMsg={flash.type === 'success' ? 'Success' : 'Error'}
          secondMsg={flash.message}
          closeFlash={() => setFlash({ ...flash, show: false })}
        />
      )}
      <div className="login_form">
        <div className="outer-login">
          <div className="inner-login">
            <div className="logo">
              <img src={logo} alt="logo_new" style={{ width: "150px" }} />
            </div>
            <h4 className="new-password-h4">Change your password</h4>
            <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register("password")}
                type="password"
                className="form-control"
                placeholder="Enter password"
              />
              {errors.password && (
                <p className="error-msg">{errors.password.message}</p>
              )}
              <input
                {...register("passwordConfirmation")}
                type="password"
                className="form-control"
                placeholder="Confirm password"
              />
              {errors.passwordConfirmation && (
                <p className="error-msg">{errors.passwordConfirmation.message}</p>
              )}
              <button type="submit">Change password</button>
              <div className="forget-link">
                <a href="/login">Login page</a>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <div className="new-password-modal">
          <div className="new-password-modal-content">
            <h2>Password changed!</h2>
            <p>You can use your new password to login to Admin portal</p>
            <button onClick={() => history.push("/login")}>Go to Login</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewPassword;