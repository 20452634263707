import {
  SET_CREATE_SUBSCRIBER,
  SET_SUBSCRIBERS_LIST,
  SET_BASIC_VALUES
} from '../Actions/Types/Types';

const initialState = {
  subscribers_list: [],
  statistics: {},
  subscribers_list_response: {},
  countries: [],
  language: {},
  product_types: {},
  types: {},
  request_times: {},
  request_per_day: 0,
  total_pages: 0,
  total_count: 0,
  current_page: 0,
  prev_page: null,
  next_page: null,
};

const TestCenter = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CREATE_SUBSCRIBER:
      return {
        ...state,
        request_per_day: action.create_subscriber.request_per_day,
        request_times: action.create_subscriber.request_times,
        create_subscriber_response: action.create_subscriber_response,
      }
    case SET_SUBSCRIBERS_LIST:
      return {
        ...state,
        subscribers_list: action.subscribers_list.subscribers.reverse(),
        statistics: action.subscribers_list.statistics,
        total_pages: action.total_pages?.total_pages,
        total_count: action.total_count,
        current_page: action.current_page,
        prev_page: action.prev_page,
        next_page: action.next_page,
        subscribers_list_response: action.subscribers_list_response
      }
    case SET_BASIC_VALUES:
      return {
        ...state,
        countries: action.basic_values.countries,
        language: action.basic_values.language,
        product_types: action.basic_values.product_types,
        types: action.basic_values.types
      }
    default: return state;
  }
}

export default TestCenter;
