const OrganizationProviderDeleted = (props) => {
  const adminLog = props.adminLog;
  let id = adminLog.object_id;
  let country = adminLog.column_affected.organization_provider_deleted.country;
  let orgId = adminLog.column_affected.organization_provider_deleted.organization_id;
  let orgName = adminLog.column_affected.organization_provider_deleted.organization_name;
  let serviceId = adminLog.column_affected.organization_provider_deleted.service_id;
  let serviceName = adminLog.column_affected.organization_provider_deleted.service_name;
  let kreditzProviderName = adminLog.column_affected.organization_provider_deleted.service_provider_details?.name;
  let serviceProviderId = adminLog.column_affected.organization_provider_deleted.service_provider_id;
  let type = serviceProviderId = adminLog.column_affected.organization_provider_deleted.type;
  return (
    <div key={Math.random()}>
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>Organization Provider Deleted</strong>
      </p>
      <p className="al-column-affected-p">
        Organization ID: {" " + orgId}<br />
        Organization name: {" " + orgName}<br />
        Service ID: {" " + serviceId}<br />
        Service name: {" " + serviceName}<br />
        Kreditz provider name: {" " + kreditzProviderName}<br />
        Service provider ID: {" " + serviceProviderId}<br />
        Type: {" " + type}<br />
        Country: {" " + country}<br />
      </p>
    </div>
  )
}
export default OrganizationProviderDeleted;