import { errorFilterList } from "./settings.js";
export const blockSorting = (requestArray, time_frame) => {
  const blocks = []
  const currentTime = new Date();
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const gracePeriod = 0 * minute;
  const recentRequestCutoff = 15 * minute;
  const time = new Date(currentTime - gracePeriod);
  const localTimeZone = time.getTimezoneOffset();
  const BLOCK_COUNT = 90

  const blockTimeFrameLength = (day / (BLOCK_COUNT / time_frame));
  const dateOptions = { year: "2-digit", day: "numeric", weekday: "short", month: "numeric", hour: "numeric", minute: "numeric" }
  for (let i = 0; i <= BLOCK_COUNT; i++) {
    const timeOffset = time - i * blockTimeFrameLength;
    blocks.push({
      status: "",
      count: 0,
      processing: 0,
      organization_request_id: [],
      message: null,
      time: new Date(timeOffset).toLocaleDateString("en-GB", dateOptions)
    })
  }
  const recentRequests = []


  requestArray.map((req) => {
    const request = {
      id: req[0],
      created_at: req[1],
      kreditz_provider_id: req[2],
      status: req[3]
    };
    const requestTimezone = new Date(request.created_at).getTimezoneOffset();
    const requestTime = Number(Date.parse(request.created_at) - (localTimeZone - requestTimezone) * minute);
    const time_diff = Math.floor((Number(time) - requestTime) / blockTimeFrameLength);

    if (time_diff > BLOCK_COUNT || time_diff < 0 || requestTime > time - recentRequestCutoff) {
      recentRequests.push(request);
      return;
    }
    if (request.status === "pending" || request.status === "cancelled") {
      return;
    }
    blocks[time_diff].status = "green";
    blocks[time_diff].count++;
    if (request.status === "processing") {
      blocks[time_diff].processing++
      blocks[time_diff].organization_request_id.push(request.id);
    }
    if (blocks[time_diff].processing >= 1) {
      blocks[time_diff].status = "yellow";
    }
    if (blocks[time_diff].processing >= blocks[time_diff].count) {
      blocks[time_diff].status = "red";
    }
  })
  return { blocks, recentRequests };
}

export const CalculateUptime = (blocks = []) => {
  var total = 0;
  var fails = 0;
  blocks.forEach((block) => {
    total = total + block.count;
    fails = fails + block.processing;
  })
  return (1 - (fails / total)) * 100
}


export const currentStatus = (requestArray = []) => {
  const obj = {
    status: "green",
    count: 0,
    processing: 0,
    pending: 0,
    organization_request_id: [],
    message: null
  }
  requestArray.forEach((request) => {
    obj.count++;
    if (request.status === "processing") {
      obj.processing++;
      obj.organization_request_id.push(request.id);
    }
    if (request.status === "pending") {
      obj.pending++;
    }
  });
  obj.status = statusControl(obj);

  return obj;
}

const statusControl = (requestObject) => {

  // Potentially add to settings page.
  // Either locally, using redux and localstorage, or on the backend, using the database.
  // Using frontend would be more customizable for each user
  // using backend means you can set everything globally
  // However you might want to consider adding a check of user permissions to the backend, so that only eligable users can change the settings.
  // There's also the option of using both, and having a global setting, and a user setting, where the user setting overrides the global setting.

  const START_OF_DAY = 7;
  const END_OF_DAY = 22;
  const WARNING_PROCESSING_THRESHOLD = 0.8;
  const WARNING_PENDING_THRESHOLD = 0.9;
  const WARNING_COUNT_THRESHOLD = 10;
  const ALERT_PROCESSING_THRESHOLD = 0.9;
  const ALERT_PENDING_THRESHOLD = 0.95;
  const ALERT_COUNT_THRESHOLD = 1;


  let status = "green";

  // check if there is no requests, if within working hours, set status to yellow
  if (requestObject.count === 0) {
    const time = new Date();
    if (time.getHours() > START_OF_DAY && time.getHours() < END_OF_DAY) {
      status = "yellow";
    } else {
      status = "green";
    }
    return status;
  }
  /*
    current checks:
    processing requests >= 90% of total requests
    pending requests >= 90% of total requests
    total requests < 10
  */
  if (
    requestObject.processing >= Math.ceil(requestObject.count * WARNING_PROCESSING_THRESHOLD) ||
    requestObject.pending >= Math.ceil(requestObject.count * WARNING_PENDING_THRESHOLD) ||
    requestObject.count < WARNING_COUNT_THRESHOLD
  ) {
    status = "yellow";
  }
  if (
    requestObject.processing >= Math.ceil(requestObject.count * ALERT_PROCESSING_THRESHOLD) ||
    requestObject.pending >= Math.ceil(requestObject.count * ALERT_PENDING_THRESHOLD) ||
    requestObject.count < ALERT_COUNT_THRESHOLD
  ) {
    status = "red";
  }
  // possibly add function afterwards that checks for errors from organization_request_id array, and sets status to red if there is an error
  return status;
}

export const filterError = (errorLogs = []) => {
  const errorArray = errorLogs.map((log) => (!errorFilterList.includes(log.error_message)) ? log : [])
  return errorArray;
}