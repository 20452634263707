import BackgroundJobsHistory from "./misc/BackgroundJobsHistory";
import backIcon from '../../assets/images/icon_back.png';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { postBackgroundJobs, getBackgroundJobs } from "../../Redux/Actions/BackgroundJobs";
import React, { useEffect, useState, useRef, useCallback } from 'react';
import FlashMessage from '../FlashMessage/FlashMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle as farCheckCircle, faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons';
import BackgroundJobsDescription from "./misc/BackgroundJobsDescription";

const BackgroundJobsDetail = () => {
  const location = useLocation();
  const { job, userData } = location.state || {};
  const { jobId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [backgroundJobs, setBackgroundJobs] = useState([]);
  const [jobParameters, setJobParameters] = useState(job.expected_params.map(param => ({key: param,value: '',})));
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const [flash, setFlash] = useState({ show: false, type: '', message: '' });
  const [runningJobs, setRunningJobs] = useState([]);
  const timeoutRef = useRef(null);
  const [jobDetails, setJobDetails] = useState(job);

  const fetchBackgroundJobs = useCallback(async (page = 1) => {
    try {
      const response = await dispatch(getBackgroundJobs(jobId, page, itemsPerPage));
      if (response.status) {
        setBackgroundJobs(response.data.background_jobs);
        setPageCount(response.data.total_pages);
  
        if (response.data.background_jobs.length > 0) {
          const latestJob = response.data.background_jobs.reduce((prev, current) => {
            return new Date(prev.completed_at || prev.started_at) > new Date(current.completed_at || current.started_at) ? prev : current;
          });
          updateParametersFromLatestJob(latestJob.parameters);
        }
  
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch background jobs:', error);
    }
  }, [jobId, dispatch, history]);
  
  useEffect(() => {
    if (jobId) {
      fetchBackgroundJobs(1);
    }
  }, [jobId]);

  const updateParametersFromLatestJob = (latestJobParameters) => {
    let paramsObject;
    try {
        paramsObject = JSON.parse(latestJobParameters);
    } catch (e) {
        console.error('Failed to parse parameters:', latestJobParameters, e);
        return;
    }
    const updatedParameters = jobParameters.map(param => {
        const foundValue = paramsObject[param.key];
        return foundValue !== undefined ? { ...param, value: foundValue } : param;
    });

    setJobParameters(updatedParameters);
};

  const handleParamChange = (index, field, value) => {
    const newParams = [...jobParameters];
    newParams[index][field] = value;
    setJobParameters(newParams);
  };

  const handleRunJob = () => {
    const params = jobParameters.reduce((acc, { key, value }) => {
      if (key && value) acc[key] = value;
      return acc;
    }, {});
    
    const formattedJobName = job.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
  
    dispatch(postBackgroundJobs(formattedJobName, "execute", params))
      .then(response => {
        if (response.status) {
          setFlash({
            show: true,
            type: 'success',
            message: "Job posted successfully!",
          });
          fetchBackgroundJobs();
        } else {
          setFlash({
            show: true,
            type: 'error',
            message: "Failed to post job: " + response.message,
          });
        }
      })
      .catch(error => {
        setFlash({
          show: true,
          type: 'error',
          message: "An error occurred: " + error.message,
        });
      });
  };
  
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (flash.show) {
      const timeoutId = setTimeout(() => {
        setFlash({ ...flash, show: false });
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [flash]);

  const updateParametersFromHistory = (parametersStr) => {
    let parameters;
    try {
      parameters = JSON.parse(parametersStr);
    } catch (error) {
      console.error('Failed to parse parameters:', error);
      return;
    }
  
    const updatedParameters = job.expected_params.map(expectedParam => {
      const value = parameters[expectedParam];
      return { key: expectedParam, value: value || '' };
    });
  
    setJobParameters(updatedParameters);
  };

  useEffect(() => {
    if (job && job.expected_params) {
      const initialParameters = job.expected_params.map(paramName => ({
        key: paramName,
        value: '',
      }));
      setJobParameters(initialParameters);
    }
  }, [job]);

  const onDescriptionUpdate = (newDescription) => {
    setJobDetails(prevJobDetails => ({
      ...prevJobDetails,
      description: newDescription
    }));
  };

  return (
    <div className="background-jobs-container">
      <h3 className="basic-head01 basic-flex-start">
        <div className="back-heading">
          <span
            onClick={() => history.push("/background_jobs")}
            className="esu-icon-wrapper"
          >
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer", marginRight: '-4rem', marginBottom: '0.5rem', marginLeft:'-0.8rem  ' }} />
          </span>
          <h3>
            {job?.name}&nbsp;
            {job?.status === "completed" ? (
              <FontAwesomeIcon icon={farCheckCircle} />
            ) : job?.status === "Failed" || job?.status === null ? (
              <FontAwesomeIcon icon={farTimesCircle} />
            ) : null}
          </h3>
        </div>
      </h3>
          <div className='backgroundJobs-detail-page'>
            <div className="details-flex-container">
                <div className="parameters-container">
          <h2 className="mb-4">Parameters:</h2>
            {jobParameters.map((param, index) => (
              <div className="parameter-group" key={index}>
                <div className="form-control-background-jobs" style={{ background: 'none', border: 'none', color: "#596980" }}>
                  {param.key}:
                </div>
                <input
                  type="text"
                  placeholder={param.key}
                  value={param.value}
                  onChange={(e) => handleParamChange(index, 'value', e.target.value)}
                  className="form-control-background-jobs"
                  style={{ width: '140%' }}
                />
              </div>
          ))}
          </div>
          <BackgroundJobsDescription
            jobId={jobId}
            description={jobDetails.description}
            onDescriptionUpdate={onDescriptionUpdate}
          />
        </div>
        <div className="runjob-btn">
          <button className="button-container" onClick={handleRunJob}>Run job</button>
        </div>
      </div>
    <div className='backgroundJobs-detail-history'>
        <BackgroundJobsHistory
          historyData={[...runningJobs, ...backgroundJobs]}
          userData={userData}
          job={job}
          fetchBackgroundJobs={fetchBackgroundJobs}
          pageCount={pageCount}
          currentPage={currentPage}
          onUpdateParametersFromHistory={updateParametersFromHistory}
        />
    </div>
      {flash.show && (
        <FlashMessage
          iconImage={flash.type === 'success' ? 'tick' : 'alert'}
          firstMsg={flash.type === 'success' ? 'Success' : 'Error'}
          secondMsg={flash.message}
          closeFlash={() => setFlash({ ...flash, show: false })}
        />
      )}
    </div>
  );
};
export default BackgroundJobsDetail;