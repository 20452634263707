import OptionTabTooltip from "../OptionTab/OptionTabTooltip";

const OptionOrgSelect = ({ keys, name, value, options, resetToDefault, changeHandler, showIcon, description }) => {
  return (
    <h3 className="headeing-switch-light">
      <div className="keys-and-icon">
        {keys}
        {showIcon && (<OptionTabTooltip showIcon={showIcon} description={description} />)}
      </div>
      <div className="form-group" style={{ width: "216px" }}>
        <select className="form-control w-100 org-excludeDropdown" id="sel1" name={name} value={value} onChange={(e) => changeHandler(e)}>
          {resetToDefault === "" ? options.map(item => item[1]) : <option className='admin-option' value=''>Reset to default</option>}
          {options.map((resList, index) =>
            <option
              className='admin-option'
              key={`admin - option - ${index + 1}`}
              value={resList[1]}>
              {resList[0]}
            </option>
          )};
        </select>
      </div>
    </h3>
  )
}

export default OptionOrgSelect