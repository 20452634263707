import { useState, useEffect } from "react";
import { getOrganizationListNoActivity } from "../../Redux/Actions/OrganizationManagement";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import moment from "moment";
const OrgNoActivity = () => {
  const [noActivityOrg, setNoActivityOrg] = useState([])
  const dispatch = useDispatch()


  const noActivityHandler = async () => {
    await dispatch(setLoaderSpinner(true))
    const { data } = await dispatch(getOrganizationListNoActivity())
    setNoActivityOrg(data)
    await dispatch(setLoaderSpinner(false))
  }

  useEffect(() => {
    noActivityHandler()
  }, [])

  const capitalizeName = (key) => {
    switch (key) {
      case 'main_account':
        return 'Main account'
      case 'sub_account':
        return 'Sub account'
      default:
        return key;
    }
  }

  return (
    <div className="kreditz-provider-container page-container" id="kreditz_provider_page">
      <div className="lower-header-kreditz-provider">
        <h3 style={{ margin: "0px 0 20px 0" }}>Organizations-No activity</h3>
      </div>
      <div className="organizations-thead-scroll-y org-no-activity">
        <div className="bg-white-organizations bg-20-top">
          <table className="table" width="100%">
            <thead className="organizations-thead">
              <tr>
                <td className="org-head org-no-activity">ID activity</td>
                <td className="org-head">Client Name</td>
                <td className="org-head">Main/Sub account</td>
                <td className="org-head">Main account</td>
                <td className="org-head">Over 3 months since last</td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                noActivityOrg && noActivityOrg.length > 0 && noActivityOrg.map((item) => {
                  return (
                    <tr key={`kreditz-providers-${item.id + 1} `}
                      id={`${item.id}`}>
                      <td className="org-td org-no-activity">{item.id === null ? '-' : item.id}</td>
                      <td className="org-td">{item.name === null ? '-' : item.name}</td>
                      <td className="org-td">{item.account_type === null ? '-' : capitalizeName(item.account_type)}</td>
                      <td className="org-td">{item.parent_name === null ? '-' : item.parent_name}</td>
                      <td className="org-td">{item.last_created_at === null ? '-' : moment(item.last_created_at).format("DD-MM-YYYY")}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default OrgNoActivity;