import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";
import { SET_CERTIFICATE_INFORMATION, SET_CERTIFICATE_INFORMATION_FILTER } from "./Types/Types";

export function getCertificateInformation(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.CERTIFICATE_INFORMATION.LIST()}` + data;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function getCertificateInformationFilter() {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.CERTIFICATE_INFORMATION.FILTER()}`;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function setCertificateInformation(response) {
  return {
    type: SET_CERTIFICATE_INFORMATION,
    certificate_information: response,
  };
}

export function setCertificateInformationFilter(response) {
  return {
    type: SET_CERTIFICATE_INFORMATION_FILTER,
    certificate_information: response,
  };
}