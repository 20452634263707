import React from 'react';
import searchIcon from "../../../assets/images/search_icon.svg";

const SearchField = ({ searchData, onChange, searchOrgSubmit, placeholder = "Search by Bank Name" }) => {
  return (
    <div className="section-search tab-date">
      <div className="search-input org-search-input">
        <input
          className="search-input"
          name="search"
          type="text"
          style={{ fontSize: "15px" }}
          value={searchData}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              searchOrgSubmit();
            }
          }}
        />
        <img
          className="search-icon"
          src={searchIcon}
          alt="Search Icon"
          onClick={searchOrgSubmit}
        />
      </div>
    </div>
  );
};

export default SearchField;
