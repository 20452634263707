import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import SelectedUser from "../../Components/AuditReport/selectedUser";
import AuditReport from "../../Components/AuditReport/auditReport";
import "./auditReport.css";

const AuditReportPage = (props) => {
  const { pathname } = props.location;

  useEffect(() => {
    document.title = "Kreditz | Admin portal - Audit Report";
  }, []);

  const renderSwitch = () => {
    const path = pathname.split("/");
    switch (path[2]) {
      case `details`:
        return (
          <SelectedUser
            userId={path[path.length - 1]}
          />
        );
      default:
        return <AuditReport />;
    }
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        {renderSwitch()}
      </div>
    </div>
  );
};

export default AuditReportPage;
