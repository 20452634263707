import React from "react";
import logoutIcon from "../../assets/images/logout_icon_gray.svg";
import { Link } from 'react-router-dom';

import "./sidebar.scss";
import { logout } from "../../Redux/Actions/Auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Menu } from "./SubMenu";
import { sideBarData } from "./SidebarData";

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutUser = async (e) => {
    e.preventDefault();
    await dispatch(logout());
    history.push("/");
  };

  return (
    <aside className="aside">
      <div className="side-bar">
        <div className="section-left-side">
          <ul className="sidebar-ul">
            {sideBarData.map((item, index) => {
              return <Menu menuItem={item} key={index} />;

            }
            )}
            <li onClick={logoutUser}>
              <Link to="javascript:void(0)" className="logoutdiv" >
                <img
                  src={logoutIcon}
                  alt="logoutIcon"
                  className="imgIconSidebar"
                  width="18"
                />
                <span className="side-bar-link">Logout</span>
              </Link>
            </li>
          </ul >
        </div >
      </div >
    </aside >
  );
};

export default Sidebar;