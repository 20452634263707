import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { newPasswordEmailValidation, newPasswordTokenValidation } from "../../Redux/Actions/Auth";
import FlashMessage from "../FlashMessage/FlashMessage";
import logo from "../../assets/images/new_kreditz_logo.png";
import "../ResetPassword/resetPassword.css";
import "./newPassword.scss";
import { useForm } from "react-hook-form";

const NewPasswordEmailValidation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('activation_token');
  const [email, setEmail] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [flash, setFlash] = useState({ show: false, type: '', message: '' });

  useEffect(() => {
    const validateToken = async (token) => {
      const response = await dispatch(newPasswordTokenValidation({ token }));
      const { status, message } = response;
      if (!status) {
        setFlash({ show: true, type: 'error', message: `${message} - Contact Kreditz Administrators` });
      }
    };
    if (token) {
      validateToken(token);
    } else {
      setFlash({ show: true, type: 'error', message: 'No token provided' });
    }
  }, [token, dispatch]);

  const onSubmit = async (data) => {
    const email = data.email;
    if (email && email.includes("@") && email.includes(".") && email.length < 256) {
      try {
        const { status } = await dispatch(newPasswordEmailValidation({ token, email }));
        if (status) {
          history.push(`/new_password?activation_token=${token}&email=${email}`);
        } else {
          setFlash({ show: true, type: 'error', message: "Invalid token or email" });
        }
      } catch (error) {
        setFlash({ show: true, type: 'error', message: 'Unable to validate token' });
      }
    } else {
      setFlash({ show: true, type: 'error', message: 'Invalid email format' });
    }
  };

  useEffect(() => {
    if (flash.show) {
      const timeoutId = setTimeout(() => {
        setFlash({ ...flash, show: false });
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [flash]);

  return (
    <div>
      {flash.show && (
        <FlashMessage
          iconImage={flash.type === 'success' ? 'tick' : 'alert'}
          firstMsg={flash.type === 'success' ? 'Success' : 'Error'}
          secondMsg={flash.message}
          closeFlash={() => setFlash({ ...flash, show: false })}
        />
      )}
      <div className="login_form">
        <div className="outer-login">
          <div className="inner-login">
            <div className="logo">
              <img src={logo} alt="logo_new" style={{ width: "150px" }} />
            </div>
            <p className="new-password-p">You are invited to Kreditz Admin portal</p>
            <p className="new-password-p">Please enter your email below to continue</p>
            <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register("email", { required: "Email is required" })}
                type="email"
                className="form-control"
                placeholder="example@kreditz.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <p className="error-msg">{errors.email.message}</p>
              )}
              <button type="submit">Continue</button>
            </form>
            <div className="forget-link">
              <a href="/login">Login page</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordEmailValidation;