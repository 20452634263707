const ReqeustTableBanks = (props) => {

  const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div>
      <table
        id="table-conversion-request"
        className="table table-conversion"
        width="100%"
      >
        <thead
          className="conversion-thead"
        >
          <tr className="conversion-request-table-row">
            <th className="table-head-conversion ">
              Bank
            </th>

            <th className="table-head-conversion conversion-table-right">
              Total requests
            </th>
            <th className="table-head-conversion conversion-table-right conversion-accepted-table">
              Accepted requests
            </th>

            <th className="table-head-conversion conversion-table-right">
              Success rate
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((data) => (
            <tr
              className="conversion-table-row-container-request"
              key={Math.random()}
            >
              <td className="conversion-td conversion-month">
                {data.bank_name}
              </td>
              <td className="conversion-td td-total conversion-table-right">
                {numberWithSpaces(data.total_requests)}
              </td>
              <td className="conversion-td conversion-accepted-table conversion-table-right">
                {numberWithSpaces(data.accepted_requests)}
              </td>
              <td className="conversion-td conversion-table-right ">
                {data.success_rate}
              </td>
            </tr>
          ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default ReqeustTableBanks;