import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_INVOICES_LIST } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getinvoiceslogsList(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.INVOICES_LOGS.LIST()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false };
        });
}

export function getinvoiceDetails(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.INVOICES_LOGS.DETAILS()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false };
        });
}

export function getMoreDetails(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.INVOICES_LOGS.MORE_DETAILS()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false };
        });
}

export function generateXlsxAPI(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.INVOICES_LOGS.GENERATE_REPORT()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.message };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false };
        });
}


export function getInvoiceXilsxUrl(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.INVOICES_LOGS.INVOICES_DOWNLOAD_URL()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, data: res.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false };
        });
}

export function getInvoiceXlsx(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.INVOICES_LOGS.INVOICE_EXPORT()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, data: res.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false };
        });
}

export function setInvoicesList(response) {
    return {
        type: SET_INVOICES_LIST,
        data: response
    }
}