import { SET_API_DOCUMENTATION_LIST } from "../Actions/Types/Types";

const initialState = {
    documentation: []

};
const ApiDocumentationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_API_DOCUMENTATION_LIST:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default ApiDocumentationReducer;