import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";

export function createUploadFiles(data, imgUrl) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.UPLOADOBJECTSTOAWS.CREATE_UPLOAD_FILES()}` + data
  return dispatch => axios.post(apiURL, imgUrl)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getUploadFiles(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.UPLOADOBJECTSTOAWS.LIST()}` + data;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function updateUploadFiles(id, file) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.UPLOADOBJECTSTOAWS.UPDATE()}` + id;
  return dispatch => axios.put(apiURL, file)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}