import { SET_ADMIN_LIST } from '../Actions/Types/Types';

const initialState = {
  admin_logs: [],
  users_list: [],
  event_types: [],
  total_pages: 0,
  total_count: 0,
  current_page: 0,
  prev_page: null,
  next_page: 0,
  admin_logs_response: {}
}

const AdminLogs = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ADMIN_LIST:
      return {
        admin_logs: action.admin_list.admin_logs,
        users_list: action.admin_list.users_list.length > state.users_list ? action.admin_list.users_list : state.users_list,
        event_types: action.admin_list.event_types,
        total_pages: action.admin_list.total_pages,
        total_count: action.admin_list.total_count,
        current_page: action.admin_list.current_page,
        prev_page: action.admin_list.prev_page,
        next_page: action.admin_list.next_page,
        admin_logs_response: action.admin_list
      }
    default: return state;
  }
}

export default AdminLogs;