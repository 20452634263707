import './BankLabel.scss'
import { useEffect, useState } from 'react'
import { CalculateUptime, currentStatus } from '../utilities/dataFormatting'
import BankBlob from './BankCurrentStatusBlob'
const BankStatusLabel = (props) => {
  const [blocks, setBlocks] = useState()
  const [currentBlock, setCurrentBlock] = useState();
  const [uptime, setUptime] = useState();
  const [bank, setBank] = useState(props.bank);
  useEffect(() => {
    setCurrentBlock(currentStatus(props.recentRequests));
    if (props.status) {
      if (props.showUptime) {
        const percentage = CalculateUptime(props.status);
        setUptime(`${percentage ? Math.round(percentage) : 0}%`)
      }
    } else {
      setUptime(null);
    }
  }, [props.status, props.recentRequests]);

  useEffect(() => {
    setBank(props.bank)
    setBlocks(props.status)
  }, [props.bank, props.status, props.timeFrame])

  if (!props.bank) {
    return <>...</>
  }
  return (<>
    <span className="bank-status-bank-label">
      <span className="bank-status-bank-label-icon">{bank.details.images?.icon ? <img className='bank-status-bank-label-img' src={`${bank.details.images?.icon}`} alt="" /> : ""}</span>
      <span className="bank-status-bank-label-text">{bank.details.displayName}</span>
      {uptime && <span className='bank-status-bank-label-uptime' >Uptime {props.timeFrame && (props.timeFrame === 1 ? "24 hours" : `${props.timeFrame.toString()} days`)} - {uptime}</span>}
      <div className='bank-status-bank-label-current-status-container'>
        <BankBlob currentBlock={currentBlock} timeFrame={props.timeFrame} />
      </div>
    </span>
  </>)
}

export default BankStatusLabel; 