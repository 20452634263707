import { React, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getAuditReportDetails, getAuditReportPdf } from '../../Redux/Actions/AuditReport';
import { getAllCountry } from '../../Redux/Actions/Countries'
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import FlashMessage from "../FlashMessage/FlashMessage";

import ReactPaginate from 'react-paginate';
import QueryUserHeader from './SubComponents/AuditReportEventLogQuery';

import backIcon from '../../assets/images/icon_back.png';

import './scss/selectedUser.scss';


const SelectedUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { queryData } = location.state || {
    queryData: {
      queryString: '',
      selectedDateRange: `${moment().week(1).format('DD/MM/YYYY')}-${moment().endOf('isoWeek').format('DD/MM/YYYY')}`
    }
  };
  const eventType = location.state ? location.state.eventType : 0;
  const [error, setError] = useState();
  const user_id = props.userId;
  const [countries, setCountries] = useState();
  const [userDetails, setUserDetails] = useState();
  const [paginationData, setPaginationData] = useState({ total_pages: '', total_count: '', current_page: 1, prev_page: '', next_page: '' });
  const [page, setPage] = useState(1);
  const [userEvents, setUserEvents] = useState()
  const [query, setQuery] = useState();
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const user_headers = [
    ['Certificates viewed'],
    ['Certificates deleted'],
    ['Files Downloaded']
  ]
  const table_headers = [
    ['Created by', 'Opened at'],
    ['Created by', 'Deleted at'],
    ['Downloaded by', 'Downloaded at']
  ]
  useEffect(() => {
    if (!query || !countries) {
      return;
    }
    fetchAuditReportDetails();
  }, [page, query, countries])

  useEffect(() => {
    if (!countries) {
      fetchCountries();
    }
    if (!query) {
      setQuery(queryData);
    }

  }, [])

  const updateQuery = (query) => {
    setPage(1);
    if (queryData && !query) {
      setQuery(queryData);
      return;
    }
    setQuery(query);
  }

  const fetchCountries = async () => {
    try {
      const res = await dispatch(getAllCountry());
      await setCountries(res.data.countries_list);
    } catch (error) {
      console.error("caught", error);
    }
  }

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }
  const generateAuditReport = async (file_type) => {
    createAuditReport(file_type);
  }
  const createAuditReport = async (fileType) => {
    let params = `?user_id=${user_id}&event_type=${eventType}&query=${query.queryString}&audit_date_range=${query.selectedDateRange}&file_type=${fileType}`
    const res = await dispatch(getAuditReportPdf(params));
    if (res.status) {
      openFlash("tick", "Done!", res.message);
    } else {
      openFlash("alert", "Error!", res.message);
    }
  }
  const fetchAuditReportDetails = async () => {
    await setIsLoading(true);
    try {
      const params = `?event_type=${eventType}&query=${query.queryString}&page=${page}&audit_date_range=${query.selectedDateRange}&sort_order=${paginationData.sort_order}`
      const res = await dispatch(getAuditReportDetails(user_id, params));
      if (res.status === 200) {
        setUserEvents(res.data.event_logs);
        setUserDetails(res.data.user);
        setPaginationData((prevState) => ({
          ...prevState,
          total_pages: res.data.pagination_data.total_pages,
          total_count: res.data.pagination_data.total_count,
          current_page: res.data.pagination_data.current_page,
          prev_page: res.data.pagination_data.prev_page,
          next_page: res.data.pagination_data.next_page,
        }));
      } else {
        setError(res.message);
      }
    } catch (error) {
      console.error("caught", error);
    } finally {
      await setIsLoading(false);
    }
  }

  const getCountryName = (market_id) => {
    if (!countries) {
      return '';
    }
    const country = countries.find(country => country.id === market_id);
    if (country) {
      return country.name;
    } else {
      return 'Deleted';
    }
  }

  const renderEventTable = () => {
    if (!userEvents || userEvents.length === 0) {
      return <></>
    }
    return userEvents.map((event, key) =>
      <tr className="reports-tr" key={key}>
        <td>{event.object_id}</td>
        <td>{event.organization_name}</td>
        <td>{event.created_by}</td>
        <td>{moment(event.created_at).format('DD/MM/YYYY')}</td>
        <td>{event.reason ? event.reason : "Owner of the certificate"}</td>
        <td className='capitalize'>{getCountryName(event.market)}</td>
      </tr>
    )
  }

  return (
    <div className='page-container'>
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className='reports-container'>
        <header className='reports-header'>
          <Link to={{ pathname: `/audit_report`, state: { originalDateRange: queryData.selectedDateRange, eventType: eventType } }} >
            <img src={backIcon} alt="backIcon" style={{ cursor: 'pointer' }} />
          </Link>
          <h3 className='capitalize'>{userDetails && userDetails.name}</h3>
          <QueryUserHeader updateQuery={updateQuery} initialQuery={queryData} generateAuditReport={generateAuditReport} />
        </header>
        <div className='reports-table-container container-upper-row'>
          {error ?
            <>
              <h5 className='text-center text-muted'>Error: {error}</h5>
            </>
            :
            <>
              <table className="reports-table">
                <thead className="upper-row-th">
                  <tr className="upper-row-tr">
                    <th>Email</th>
                    {query && user_headers[eventType || 0].map((title, index) => {
                      if (!title) {
                        return <th></th>;
                      }
                      return (
                        <th key={index}>
                          {title}
                        </th>
                      )
                    })
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr className="upper-row-tr bold">
                    {userDetails &&
                      <><td>{userDetails.email}</td>
                        {userDetails.events.map((count, index) => {
                          return (
                            <td key={index}>
                              {count}
                            </td>
                          )
                        })
                        }</>}
                  </tr>
                </tbody>
              </table>
            </>}
        </div>

        <div className='reports-table-container'>
          <table className="reports-table">
            <thead className="reports-th">
              <tr className="reports-tr">
                <th> Certificate </th>
                <th> Organization </th>
                {query && table_headers[queryData.eventType || 0].map((title, index) => {
                  if (!title) {
                    return <th></th>;
                  }
                  return (
                    <th key={index}>
                      {title}
                    </th>
                  )
                })
                }
                <th>Reason</th>
                <th> Market</th>
              </tr>
            </thead>
            <tbody className="audit-report-tbody">
              {renderEventTable()}

            </tbody>
          </table>
          {isLoading &&
            <div className={userEvents ? "audit-report-table-skeleton-overlay" : "audit-report-table-skeleton"}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>}
          <div className="reports-pagination-box">
            {paginationData.total_pages > 1 &&
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                breakLabel={"..."}
                activePage={paginationData.current_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(paginationData.total_pages)}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(e) => setPage(e.selected + 1)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectedUser;