import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const TotalAcceptedRequestsChart = (props) => {

  let totalRequestsArr = [];

  if (props.totalAcceptedRequestsArray.length > 10) {
    totalRequestsArr = props.totalAcceptedRequestsArray.slice(0, 10);

    let lastReqs = props.totalAcceptedRequestsArray.slice(10, (props.totalAcceptedRequestsArray.length));
    let otherSum = 0;

    lastReqs.forEach((x) => {
      otherSum += x.y
    })
    totalRequestsArr.push({ name: "Other", y: otherSum })

  }
  else {
    props.totalAcceptedRequestsArray.forEach((x) => {
      totalRequestsArr.push({ name: x.name, y: x.y })
    })
  }
  let totalAcceptedSum = 0;

  totalRequestsArr.forEach((x) => {
    totalAcceptedSum += x.y
  })

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      height: '500',
      plotShadow: false,
      type: 'pie',
      spacing: [0, 40, 0, 40],
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
    },
    legend: {
      margin: 45,
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#596980",
        fontWeight: "regular",
      },
      symbolRadius: 0
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        size: 230,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.2f} %',

        }
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
        },
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    xAxis: {
      categories: "test",
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "",
        x: -20,
        style: {
          color: "#8591A6",
        },
      },
      labels: {
        style: {
          color: "#8591A6",
          formatter: function () {
            return Highcharts.numberFormat(this.value, 0, ' ');
          },
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "test",
        data: totalRequestsArr,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        showInLegend: true,
      },
    ],
  };
  return (
    <div>
      <h5 className="active-customers-chart-heading">
        Total accepted requests: <span className="total-requests-style">{numberWithSpaces(totalAcceptedSum)}</span>
      </h5>{totalRequestsArr.length > 0 &&
        < HighchartsReact
          highcharts={Highcharts}
          options={options}
        />}
    </div>
  )
}

export default TotalAcceptedRequestsChart;