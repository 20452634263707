import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

function changePassword(data) {
    const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CHANGE_PASSWORD.UPDATE_PASSWORD()}`;
    return dispatch => axios.put(apiURL, data)
        .then(res => {
            return { status: true, message: res.data.message };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}
export default changePassword;




