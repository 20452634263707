import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { SET_API_ACCESS_CODES } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getApiAccessCodes() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.API_ACCESS_CODES()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
        dispatch(setApiAccessCodes(res.data.data));
      }
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function sendApiAccessCode(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.API_ACCESS_CODES()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
        dispatch(setApiAccessCodes(res.data.data));
      }
      return { status: true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function setApiAccessCodes(response) {
  return {
    type: SET_API_ACCESS_CODES,
    api_access_codes_list: response
  }
}
