import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { getStatisticsData } from "../../../Redux/Actions/Statistics";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import CustomerActivityMtdGraph from "./CustomerActivityMtdGraph";

const CustomerActivityMtd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [acceptedRequestsFirst, setAcceptedRequestsFirst] = useState([]);
  const [acceptedRequestsLast, setAcceptedRequestsLast] = useState([]);
  const [graphType, setGraphType] = useState("column");

  function getStartDate(d) {
    return moment(d).startOf('month').format("DD/MM/YYYY")
  }

  function getEndDate(d) {
    return moment(d).format("DD/MM/YYYY");
  }

  function getLastStartDate(d) {
    return moment(d).subtract(1, 'months').startOf('month').format('DD/MM/YYYY')
  }

  function getLastEndDate(d) {
    return moment(new Date(d.setMonth(d.getMonth() - 1))).format("DD/MM/YYYY");
  }

  const firstStartDate = getStartDate(new Date())
  const firstEndDate = getEndDate(new Date())
  const lastStartDate = getLastStartDate()
  const lastEndDate = getLastEndDate(new Date())
  const [selectedDateRange, setSelectedDateRange] = useState(`${firstStartDate}-${firstEndDate}`);

  const [selectedCompareDateRange, setSelectedCompareDateRange] = useState(`${lastStartDate}-${lastEndDate}`);

  let dayFirst = selectedDateRange.slice(11, 13)
  const monthFirst = selectedDateRange.slice(14, 16)
  const yearFirst = selectedDateRange.slice(17, 21)
  let dayLast = selectedCompareDateRange.slice(0, 2)
  const monthLast = selectedCompareDateRange.slice(3, 5)
  const yearLast = selectedCompareDateRange.slice(6, 10)

  const selectedMonth = new Date(`${yearFirst}, ${monthFirst}, ${dayFirst} `).toLocaleString('default', { month: 'short' }).slice(0, 3);
  const selectedPrevMonth = new Date(`${yearLast}, ${monthLast}, ${dayLast} `).toLocaleString('default', { month: 'short' }).slice(0, 3);

  if (dayFirst < 10) {
    dayFirst = dayFirst.slice(1, 2)
  }

  if (dayLast < 10) {
    dayLast = dayLast.slice(1, 2)
  }

  let dateEndingFirst = "";
  if (dayFirst === "1" || dayFirst === "21" || dayFirst === "31") {
    dateEndingFirst = "st"
  }
  else if (dayFirst === "2" || dayFirst === "22") {
    dateEndingFirst = "nd"
  }
  else if (dayFirst === "23" || dayFirst === "3") {
    dateEndingFirst = "rd"
  }
  else {
    dateEndingFirst = "th"
  }

  let dateEndingLast = "";

  if (dayLast === "1" || dayLast === "21" || dayLast === "31") {
    dateEndingLast = "st"
  }
  else if (dayLast === "2" || dayLast === "22") {
    dateEndingLast = "nd"
  }
  else if (dayLast === "23" || dayLast === "3") {
    dateEndingLast = "rd"
  }
  else {
    dateEndingLast = "th"
  }

  useEffect(() => {
    fetchStatisticsData()
    fetchCompareStatisticsData()
  }, [])

  const fetchStatisticsData = async () => {
    const params =
      "?statistics_date_range=" + selectedDateRange;
    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getStatisticsData(params));
    const { accepted_perday_count } = data;
    setAcceptedRequestsFirst(accepted_perday_count)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  const fetchCompareStatisticsData = async () => {
    const params =
      "?statistics_date_range=" + selectedCompareDateRange;
    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getStatisticsData(params));
    const { accepted_perday_count } = data;
    setAcceptedRequestsLast(accepted_perday_count)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    const lastStartDateValue = moment(new Date(start._d.setMonth(start._d.getMonth() - 1))).format("DD/MM/YYYY")
    const lastEndDateValue = moment(new Date(end._d.setMonth(end._d.getMonth() - 1))).format("DD/MM/YYYY")
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
    setSelectedCompareDateRange(`${lastStartDateValue}-${lastEndDateValue}`);
  };

  const handleSubmit = (event) => {
    fetchStatisticsData();
    fetchCompareStatisticsData()
  };

  return (
    <div className="statistics-container">
      <div className="lower-header">
        <h3>Customer activity MTD</h3>
        <div className="flex-header customer-activity-header">
          <DateRangePicker
            onCallback={handleCallback}
            initialSettings={{ startDate: getStartDate(new Date()), endDate: getEndDate(new Date()), locale: { format: "DD/MM/YYYY" } }}
            onApply={handleSubmit}>
            <input
              type="text"
              id="date-range-picker"
              className="date-form-box"
            />
          </DateRangePicker>
          <div className="customer-activity-icon">
            <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
          </div>
        </div>
      </div>
      <h5>Overall - Accepted Requests (Daily {`${dayLast + dateEndingLast}-${dayFirst + dateEndingFirst} ${selectedPrevMonth}-${selectedMonth} ${yearFirst}`})</h5>
      <section className="accepted-requests-section">
        {Object.keys(acceptedRequestsLast).length > 0 && <CustomerActivityMtdGraph acceptedRequestsLast={acceptedRequestsLast} acceptedRequestsFirst={acceptedRequestsFirst} selectedCurrentMonth={selectedMonth} selectedPrevMonth={selectedPrevMonth} currentYear={yearFirst} yearLast={yearLast} graphType={graphType} />}
      </section>
      <div className="graph-btns">
        <div>
          <span>
            <button onClick={() => setGraphType("column")} className={graphType === "column" ? 'actve-graph-type graph-btn-first graph-btn' : 'graph-btn-first graph-btn'}>
              Column
            </button>
            <button onClick={() => setGraphType("spline")} className={graphType === "spline" ? 'actve-graph-type graph-btn graph-btn-middle' : 'graph-btn graph-btn-middle'}>
              Line
            </button>
            <button onClick={() => setGraphType("bar")} className={graphType === "bar" ? 'actve-graph-type graph-btn graph-btn-last' : 'graph-btn graph-btn-last'}>
              Bar
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

export default CustomerActivityMtd;