import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const BankConnectionGraph = (props) => {
  let requestsAccepted = props.requestsAccepted;
  let requestsCreated = props.requestsCreated;

  let totalRequestsAccepted = 0;
  for (let sum of Object.values(requestsAccepted)) {
    totalRequestsAccepted += sum;
  }

  let totalRequestsCreated = 0;
  for (let sum of Object.values(requestsCreated)) {
    totalRequestsCreated += sum;
  }

  let orgName = [];

  props.requestData.map((x) => {
    return orgName.push(x.organizationName);
  });

  let acceptedRequests = [];

  props.requestData.map((x) => {
    return acceptedRequests.push(x.requestsAccepted);
  });

  let createdRequests = [];

  props.requestData.map((x) => {
    return createdRequests.push(x.requestsCreated);
  });

  const options = {
    chart: {
      type: "column",
      spacing: [15, 0, 15, 15],
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
      margin: 40,
    },

    legend: {
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#596980",
        fontWeight: "regular",
      },
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
      },
      line: {
        dashStyle: "LongDash",
      },
      series: {
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    colors: ["#3e85f9", "#43BF58"],
    xAxis: {
      categories: orgName,
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "Total counts",
        x: -20,
        style: {
          color: "#8591A6",
        },
      },
      labels: {
        style: {
          color: "#8591A6",
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        name: "Created Requests",
        data: createdRequests,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
      },
      {
        name: "Accepted Requests",
        data: acceptedRequests,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
      },
    ],
  };
  return (
    <section>
      <div className="bank-connection-graph-header">
        <div className="bank-connection-date">
          <p>{props.createdAt} : Vista in last 30 min</p>
        </div>
        <div className="bank-connection-requests">
          <p>
            Total accepted requests:{" "}
            <span className="bank-connection-total">
              {totalRequestsAccepted}
            </span>
          </p>
          <p>
            Total created requests:{" "}
            <span className="bank-connection-total">
              {totalRequestsCreated}
            </span>
          </p>
        </div>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      ></HighchartsReact>
    </section>
  );
};

export default BankConnectionGraph;
