import React from "react";
import "./Scss/dashboard.scss";

const DashboardHeader = () => {
  return (
    <div className="df-main">
      <div className="df-left capitalize">
        <div>Dashboard</div>
      </div>
    </div>
  )
}
export default DashboardHeader;