import ApiDocumentationList from "./ApiDocumentationList";
import { useDispatch } from "react-redux";
import { getApiDocumentation, getApiDocsSettings } from "../../Redux/Actions/ApiDocumentation";
import { useState, useEffect } from "react";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import "./apiDocumentation.scss"
import { useHistory } from "react-router-dom";
const ApiDocumentation = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [enableSetting, setEnableSetting] = useState(false);
  const [enableId, setEnableId] = useState();
  const [sortData, setSortData] = useState({
    sort_column: "id",
    sort_order: "desc",
    sort_executed: false,
  });

  const fetchApiDocumentation = async () => {
    dispatch(setLoaderSpinner(true))
    let query = "";
    query = "?sort_column=" +
      sortData.sort_column +
      "&sort_order=" +
      sortData.sort_order;

    const data = await dispatch(getApiDocumentation(query));
    await setData(data);
  }

  const fetchApiDocumentationSetting = async () => {
    dispatch(setLoaderSpinner(true))
    const data = await dispatch(getApiDocsSettings());
    await setEnableSetting(data?.data.enable_api_documenation)
    setEnableId(data?.data.id)
  }

  useEffect(() => {
    fetchApiDocumentation()
    fetchApiDocumentationSetting()
    setUpdate(false)
  }, [update, sortData.sort_column, sortData.sort_order])

  if (data) {
    dispatch(setLoaderSpinner(false))
  }

  const applySorting = (type) => {
    const sortOrder =
      sortData.sort_column === type
        ? sortData.sort_order === "asc"
          ? "desc"
          : "asc"
        : "asc";
    setSortData((prevState) => ({
      ...prevState,
      sort_column: type,
      sort_order: sortOrder,
      sort_executed: true,
    }));
  };
  const spec = require("./assets/exampleData.json");

  const newVersion = () => {
    localStorage.removeItem("swagger-editor-content");
    localStorage.setItem(
      "swagger-editor-content",
      JSON.stringify(spec, null, 2)
    );
    history.push({
      pathname: "/api_new",
      state: {
        documentation: data,
        update: update
      },
    })
  };

  return (
    <div className="service-settings-container page-container">
      <div className="lower-header-it-certificates ">
        <div className="lower-header-service-settings service-settings-head-bg lower-header-it-certificates">
          <h3>API Documentation</h3>
          <div className="btn-container btn-api-list">
            <button
              onClick={newVersion}
              className="update-api-btn">
              Add a new API documentation
            </button>
          </div>
        </div>
      </div>
      {data && data?.data?.documentations?.length > 0 &&
        <ApiDocumentationList
          data={data.data.documentations}
          update={update}
          setUpdate={setUpdate}
          applySorting={applySorting}
          enableSetting={enableSetting}
          setEnableSetting={setEnableSetting}
          enableId={enableId}
        />
      }
    </div>
  )
}
export default ApiDocumentation;