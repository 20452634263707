import React from 'react';
import './transactionModal.scss'

const UpdateTransactionModal = (props) => {
  return (
    <div className='transaction'>
      <div className='transaction_inner update_transaction'>
        <div className="main">
          <p className='title'><b>{props.newTransactionCount} </b>  transactions was fetched Do you want to update certificate</p>
          <p className='certificate-no'>{props.certificateId}</p>
        </div>
        <div className="btn-div-update">
          <button className='no-btn' onClick={(e) => props.setShowUpdateTransactionModal(false, e)}>No</button>
          <button className='yes-btn' onClick={(e) => props.handleSubmit(props.certificateId, e)}>Yes</button>
        </div>
      </div>
    </div>
  )
}
export default UpdateTransactionModal;