import React from 'react';
import './EditModal.css'

const EditModal = (props) => {
    return (
        <div className='EditPopup'>
            <div className='EditPopup_inner'>
                <br />
                <br />
                <p className='firstText-update'>{props.firstTextUser}</p>
                <p className='secondText-update'>{props.secondTextUser}</p>
                <p className='userName-update'>{props.name}?</p>
                <br />
                <div className="text-center">
                    <button className='closeDelete-update' style={{ marginLeft: '0px' }} onClick={props.closeDelete}>No</button>
                    <button className='confirm-update' onClick={props.confirm}>Yes, update</button>
                </div>
            </div>
        </div>
    )
}
export default EditModal;