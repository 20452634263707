import BannersForm from "./BannersForm"
import HeaderReturn from "./BannersReturnHeader"
import './style.scss'
const BannersCreate = () => {
  return (
    <div className="page-container">
      <div className="mb-4">
        <HeaderReturn />
        <h3 className="align-middle ms-3 d-inline">Create banner</h3>
      </div>
      <BannersForm actionString="Create" />
    </div>
  )
}

export default BannersCreate;