const CertificateDeletion = (props) => {
  let adminLog = props.adminLog;
  let id = adminLog.object_id;
  let reason = adminLog.column_affected.certificate_deletion.reason;
  return (
    <div key={Math.random()}>
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>Certificate Deletion</strong>
      </p>
      <p className="al-column-affected-p">
        Reason: {" " + reason}<br />
      </p>
    </div>
  )
}

export default CertificateDeletion;