import './BankQuery.scss'
import icon_search from '../../../assets/images/search_icon.svg'
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAllCountry } from "../../../Redux/Actions/Countries";

const BankStatusFilter = ({ updateQuery, timeFrameHandler }) => {
  const countryBlackList = ["malta", "united states", "india", "portugal"]
  const dispatch = useDispatch();
  const [countries, setCountries] = useState();
  const [query, setQuery] = useState({ country_id: 1, query_string: "", time_frame: "" });
  const createCountryOptions = (countries) => {
    const countriesDropdown = countries.map((country) => {
      if (!countryBlackList.includes(country.name.toLowerCase())) {
        return <option value={country.id} key={country.id}>{country.name}</option>
      }
    })
    return countriesDropdown;
  }
  const getCountries = async () => {
    try {
      const res = await dispatch(getAllCountry());
      setCountries(createCountryOptions(res.data.countries_list));
    } catch (error) {
      console.log(error);
    }
  }
  useState(() => {
    if (!countries) {
      getCountries();
    }

  }, [])


  const sendQuery = (e) => {

    e.preventDefault();
    updateQuery(query);
  }
  return (
    <>

      <div className="d-flex justify-content-end bank-status-query">
        <form className="d-flex flex-row justify-content-end">
          <select
            className="form-select bank-status-query-select me-2"
            name="timeframe"
            value={query.time_frame}
            id=""
            defaultValue={1}
            onChange={(e) => { setQuery({ ...query, time_frame: e.target.value }); updateQuery({ ...query }); timeFrameHandler({ time_frame: e.target.value }); }}
          >
            <option value={1} >24 hours</option>
            <option value={30} >30 days</option>
          </select>
          <select
            className="form-select bank-status-query-select"
            name="country"
            value={query.country_id}
            id=""
            onChange={(e) => { setQuery({ ...query, country_id: e.target.value }); updateQuery({ ...query, country_id: e.target.value }); }}
          >
            {countries}
          </select>
          <span className="bank-status-query-text-container">
            <input
              className="form-control ms-2 bank-status-query-text"
              type="text"
              name="search"
              placeholder="Bank name"
              value={query.query_string}
              onChange={(e) => { setQuery({ ...query, query_string: e.target.value }) }}
            /><button className="bank-status-query-submit" onClick={(e) => { sendQuery(e) }}>
              <img className="bank-status-query-text-icon" src={icon_search} alt="" width={18} height={18} />
            </button>
          </span>

        </form>
      </div>

    </>
  )
}

export default BankStatusFilter;