import React from 'react';

export const pdfInvoice = React.forwardRef((props, ref) => {
    const getProduct = (product) => {
        switch (product) {
            case "web":
                return "Inquiry web";
            case "api":
                return "API";
            default:
                return "";
        }

    }

    const renderTableData = props.result.map((item) => {
        return (<tr key={Math.random()}>
            <td>{getProduct(item.product)}</td>
            <td>{item.type === "corporate" ? "Business" : "Consumer"}</td>
            <td className="capitalize">{item.country}</td>
            <td className="text-right">-</td>
            <td>{item.count}</td>
            <td className="text-right"><strong>-</strong></td>
        </tr>)
    });

    const renderSmsData = props.smsData.map((item) =>
        <tr key={Math.random()}>
            <td>SMS</td>
            <td>SMS</td>
            <td className="capitalize">{Object.keys(item)}</td>
            <td className="text-right">-</td>
            <td>{Object.values(item)}</td>
            <td className="text-right"><strong>-</strong></td>
        </tr>)
    return (
        <div ref={ref} className="pr-main">
            <div className="pr-heading3">{props.name}</div>
            <div className="pr-heading4">{props.month}  {props.year}</div>
            <div className="table-content ">
                <div className="table-content table-content-invoice">
                    <form className="invoice-details-table mr-top">
                        <table>
                            <thead>
                                <tr>
                                    <td>Product</td>
                                    <td>Type</td>
                                    <td>Market</td>
                                    <td className="text-right">Price</td>
                                    <td>Unit</td>
                                    <td className="text-right">Sum</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData}
                                {renderSmsData}
                            </tbody>
                            <tfoot>
                                <tr className="bg-white-td" key={Math.random()}>
                                    <td colSpan="4"></td>
                                    <td>Total</td>
                                    <td className="text-right"><strong>-</strong></td>
                                </tr>
                                <tr className="bg-white-td" key={Math.random()}>
                                    <td colSpan="4"></td>
                                    <td>Tax</td>
                                    <td className="text-right"><strong>-</strong></td>
                                </tr>
                                <tr key={Math.random()}>
                                    <td colSpan="4"></td>
                                    <td>Grand Total</td>
                                    <td className="text-right"><strong>-</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
            </div >
        </div >
    );
});


export default pdfInvoice;