import React, { useState, useEffect } from 'react';
import './Worldmap.scss';
import GenericMapChart from './GenericMapChart';
import { getRequestsWorldmapLiveData } from "../../../Redux/Actions/LiveData";
import { getAllCountry } from "../../../Redux/Actions/Countries";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { processDataForMap } from './utils/processDataForMap';

const Worldmap = () => {
  const [currentSelection, setCurrentSelection] = useState('Europe');
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [processedRequestIds, setProcessedRequestIds] = useState(new Set());
  const [countriesList, setCountriesList] = useState([]);

  const fetchCountries = async () => {
    try {
      const countriesResponse = await dispatch(getAllCountry());
      if (countriesResponse.status) {
        setCountriesList(countriesResponse.data.countries_list);
      } else if (countriesResponse.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
    }
  }

  const fetchLiveData = async () => {
    try {
      const requestsResponse = await dispatch(getRequestsWorldmapLiveData());
      if (requestsResponse.status) {
        const requests = requestsResponse.data.requests;
        const newRequests = requests.filter(request => !processedRequestIds.has(request.id));
        if (newRequests.length > 0) {
          const processedData = processDataForMap(newRequests, countriesList);
          setData(processedData);
          setProcessedRequestIds(prevIds => new Set([
            ...prevIds,
            ...newRequests.map(request => request.id)
          ]));
        }
      } else if (requestsResponse.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchLiveData();
    if (countriesList.length === 0) {
      fetchCountries();
    }
    const intervalId = setInterval(fetchLiveData, 3500);
    return () => clearInterval(intervalId);
  }, [processedRequestIds]);

  const handleSelectionChange = (event) => {
    setCurrentSelection(event.target.value);
  };

  return (
    <div className="page-container live-data-container">
      <div className="ld-container">
        <div className="header-form-container">
          <h3 className="ld-header">Worldmap</h3>
          <div className="map-ld-search-container">
            <form className="ld-form-container" id="ld-form-container">
              <div className="map-custom-select">
                <select
                  className="ld-form-input-field capitalize"
                  id="ld-select"
                  onChange={handleSelectionChange}
                  value={currentSelection}
                >
                  <option className="ld-option" value="Europe">
                    Europe
                  </option>
                  <option className="ld-option" value="Worldwide">
                    Worldwide
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <GenericMapChart key={currentSelection} mapType={currentSelection} data={data} />
      </div>
    </div>
  )
}

export default Worldmap;

