import React, { useState } from 'react';
import moment from 'moment';
import email from '../../../assets/images/email_icon.svg';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotificationEmailTable = ({ columns, data }) => {
  const [visibleTooltip, setVisibleTooltip] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (index) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const showTooltip = (index) => {
    setVisibleTooltip(index);
  };

  const hideTooltip = () => {
    setVisibleTooltip(null);
  };

  const getStatusClass = (status) => {
    return status === 'rejected' ? 'status-failed' : status === 'sent' ? 'status-sent' : '';
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <table className="notification-emails-table mt-4" width="100%">
      <thead className="notification-emails-thead">
        <tr>
          {columns.map((column) => (
            <th key={column.accessor} className='notification-emails-th'>{column.Header}</th>
          ))}
        </tr>
      </thead>
      <tbody className="notification-emails-tbody">
        {data.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <tr>
              {columns.map((column) => (
                <td key={column.accessor} className={`org-td ${column.accessor === 'status' ? getStatusClass(row[column.accessor]) : ''}`}>
                  {column.accessor === 'error_message' ? (
                    <div>
                      {capitalizeFirstLetter(row[column.accessor])}
                    </div>
                  ) : column.accessor === 'email_to' ? (
                    <div className='notification-emails-hover-target'
                         onMouseEnter={() => showTooltip(rowIndex)}
                         onMouseLeave={hideTooltip}>
                      <img className='notification-emails-email-icon' src={email} alt="email" />
                      {visibleTooltip === rowIndex && (
                        <span className='notification-emails-tooltip'>{row[column.accessor]}</span>
                      )}
                    </div>
                  ) : column.accessor === 'email_sent_at' ? (
                    moment(row[column.accessor]).format('DD-MM-YYYY hh:mm A')
                  ) : column.accessor === 'subject' ? (
                    <div className="notification-emails-subject">
                      <span>
                        {expandedRows[rowIndex] 
                          ? row[column.accessor] 
                          : truncateText(row[column.accessor], 15)}
                      </span>
                      {row[column.accessor].length > 20 && (
                        <button onClick={() => toggleRow(rowIndex)} className="notification-emails-collapse-button">
                          {expandedRows[rowIndex] ? <FontAwesomeIcon icon={faChevronUp} className='notification-emails-arrow-icon' /> : <FontAwesomeIcon icon={faChevronDown} className='notification-emails-arrow-icon'/>}
                        </button>
                      )}
                    </div>
                  ) : column.accessor === 'status' || column.accessor === 'email_type' ? (
                    capitalizeFirstLetter(row[column.accessor])
                  ) : (
                    row[column.accessor]
                  )}
                </td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default NotificationEmailTable;
