import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import "./banners.css";
import BannersEdit from "../../Components/Banners/BannersEdit";

const EditBanners = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Update banner";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <BannersEdit />
      </div>
    </div>
  );
};

export default EditBanners;
