import { SET_USER_MANAGEMENT_LIST } from '../Actions/Types/Types';

const initialState = {
    users: [],
    current_page: 0,
    total_count: 0,
    total_pages: 0,
    next_page: 0,
    prev_page: null,
    user_management_response: {}
}

const UserManagement = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_USER_MANAGEMENT_LIST:
            return {
                users: action.users_list.users,
                user_management_response: action.users_list,
                current_page: action.users_list.current_page,
                total_count: action.users_list.total_count,
                total_pages: action.users_list.total_pages,
                next_page: action.users_list.next_page,
                prev_page: action.users_list.prev_page
            }
        default: return state;
    }
}

export default UserManagement;