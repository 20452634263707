import React from 'react';
import './scss/deleteModal.scss'

const DeleteModal = (props) => {
    return (
        <div className='transaction1'>
            <div className='transaction_inner1'>
                <div className="main">
                    <p className='certificate-no'>Delete report</p>
                    <p className='title'>Are you sure you want to delete this report?</p>
                </div>
                <div className='tableContainer'>
                    <table>
                        <tbody>
                            <tr><td>Report</td><td className="wordBreak">{props.document_key}</td></tr>
                            <tr><td>Type</td><td>{props.report.report_type}</td></tr>
                            <tr><td>Creator</td><td>{props.report.user_name}</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="btn-div">
                    <button className='no-btn' onClick={() => props.setShowConfirmationModal(false)}>Cancel</button>
                    <button className='yes-btn' onClick={() => props.deleteRecord(props.report.id)}>Delete</button>
                </div>
            </div>
        </div>
    )
}
export default DeleteModal;