import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import CustomerActivityMtd from "../../Components/Statistics/CustomerActivityMtd/CustomerActivityMtd";
const CustomerActivityMtdPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Accepted requests";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <CustomerActivityMtd />
      </div>
    </div>
  );

}
export default CustomerActivityMtdPage;