import React from "react";
import tick from '../../assets/images/tick.png';
import alert from '../../assets/images/alert_image.svg';
import close from '../../assets/images/icon_close.png';
import './FlashMessage.css';

const NewFlash = (props) => {
    return (
        <div className='dangerpopup'>
            <div className={(props.data.iconImage === "tick" ? "successpopup_inner" : "dangerpopup_inner")}>
                <img src={props.data.iconImage === "tick" ? tick : alert} className='tick' alt="tick"></img>
                <div className="flashNameHead">
                    <h4 className='flash-message-heading'>{props.data.firstMsg}</h4>
                    <p className='flash-message-info-text'>{props.data.secondMsg}</p>
                </div>
                <img src={close} alt="close" onClick={props.closeFlash} className='close'/>                
            </div>
        </div >
    );
}

export default NewFlash;