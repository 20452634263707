import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import ApiDocumentation from "../../Components/ApiDocumentation/ApiDocumentation";
const ApiDocumentationPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - API Documentation";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <ApiDocumentation />
      </div>
    </div>
  )
}

export default ApiDocumentationPage;