import React from "react";

const Dropdown = ({ options, selected, onSelect, className, placeholder, disabled, tooltipText }) => {
  const handleChange = (event) => {
    onSelect(event.target.value === "" ? null : event.target.value);
  };

  const dropdownClass = `ld-form-input-field ${disabled ? 'disabled' : ''}`;

  return (
    <div className={`healthedit-ld-search-container ${className}`}>
      <div className="healthedit-custom-select">
        <div className="select-width-control">
          <select
            className={dropdownClass}
            id="ld-select"
            value={selected}
            onChange={handleChange}
            disabled={disabled}
          >
            <option value="">{placeholder}</option>
            {options.map((option, index) => (
              <option key={index} className="ld-option" value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {disabled && <span className="custom-tooltip">{tooltipText}</span>}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
