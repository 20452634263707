import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import BankStatusHeader from "../../Components/BankStatus/utilComponents/BankStatusHeader";
import BankStatusContainer from "../../Components/BankStatus/BankStatusContainer";
import './style.scss'
const BankStatus = () => {


  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="main-container">
          <Sidebar />
          <div className="bank-status-page-container d-flex flex-column mw-100 me-0 pe-0">
            <BankStatusHeader />
            <div className="bank-status-page-container-main w-100 mw-100">
              <BankStatusContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BankStatus;