import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createUser } from "../../Redux/Actions/UserManagement";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import FlashMessage from "../FlashMessage/FlashMessage";
import { AccessSettings } from "../EditSomeUser/AccessSettings";

// STYLING
import "./CreateNewUser.scss";

// ASSETS
import backIcon from "../../assets/images/icon_back.png";
import infoIcon from "../../assets/images/info.svg";
import { getAllCountry } from "../../Redux/Actions/Countries";

const CreateNewUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_country_code, setPhone_Country_Code] = useState("+46");
  const [phone, setPhone] = useState("");
  const [selectedrole, setSelectedrole] = useState("");
  const [userAccessSettings, setUserAccessSettings] = useState({});
  const [userSettingsRole, setUserSettingsRole] = useState();

  // FLASH MESSAGE STATE
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const [countries, setCountries] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parameters = {
      users: {
        name,
        email,
        phone_country_code,
        phone,
        role: selectedrole,
        access_settings: userAccessSettings
      },
    };
    const { status, message } = await dispatch(createUser(parameters));
    if (status) {
      setdisplayFlash(true);
      history.push("/user_management");
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      } else {
        openFlash("alert", "Error!", message);
      }
    }
  };

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }
  const updateRole = (e) => {
    setSelectedrole(e.target.value);
    setUserSettingsRole(e.target.value);
  }
  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    const { data } = await dispatch(getAllCountry());
    const { countries_list } = data;
    setCountries(countries_list);
  };

  const updateUserAccessSettings = (accessSettings) => {
    setUserAccessSettings(accessSettings);
  }

  return (
    <div className="container_fluid">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <h3 className="basic-head01 basic-flex-start">
        <div className="back-heading">
          <span onClick={() => history.push("/user_management")}>
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} />
          </span>
          Create new user
        </div>
      </h3>
      <div className="bg-white-createUser">
      <div className="edit-user-container">
        <form onSubmit={handleSubmit}>
          <div>
            <div>
          <div className="row">
            <div className="form-group" style={{ maxWidth: "300px" }}>
              <label className="labelCreateFormUser">Name</label>
              <input
                type="text"
                required
                style={{ fontSize: "14px" }}
                className="form-control"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="form-group cnu-input-wrapper"
              style={{ maxWidth: "300px" }}
            >
              <label className="labelCreateFormUser">Email</label>
              <input
                required
                type="email"
                style={{ fontSize: "14px" }}
                className="form-control"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div
              className="form-group cnu-input-wrapper"
              style={{ maxWidth: "300px" }}
            >
              <label className="labelCreateFormUser">Phone</label>
              <div className="flex-select">
                <select className="form-control org-excludeDropdown"
                  id="sel1"
                  value={phone_country_code}
                  name="phone_country_code"
                  onChange={(e) => setPhone_Country_Code(e.target.value)} >
                  {countries.map((_country, index) =>
                    <option
                      className='admin-option'
                      key={`admin - option - ${index + 1}`}
                      value={_country.phone_country_code}>
                      {_country.phone_country_code}
                    </option>
                  )};
                </select>
                <input
                  type="Phone"
                  style={{ fontSize: "14px" }}
                  className="form-control"
                  placeholder="Enter phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group cnu-input-wrapper" style={{ maxWidth: "300px" }} >
              <label className="labelCreateFormUser">Role</label>
              <select
                type="text"
                className="excludeDropdown"
                style={{ fontSize: "14px" }}
                required
                onChange={updateRole}
              >
                <option value="">Select Role</option>
                <option value="admin">Admin</option>
                <option value="sales">Sales</option>
                <option value="operations">Operations</option>
                <option value="it">IT</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <button
                type="submit"
                className="create-button-user"
              >
                Create
              </button>
            </div>
          </div>
          </div>
            </div>
        </form>
        <div>
              <AccessSettings userAccessSettings={userAccessSettings} role={selectedrole} roleUpdater={userSettingsRole} updateUserAccessSettings={updateUserAccessSettings} />
          </div>
          </div>
        <div className="row">
          <div className="col-md-12 mb-5">
            <div
              className="note-modal"
              style={{ margin: "50px 0px 70px 0px !important" }}
            >
              <img src={infoIcon} alt="infoIcon" />
              <div className="note-info">
                <p>
                  <span>Admin</span> **
                </p>
                <p>
                  <span>Sales</span> **
                </p>
                <p>
                  <span>Operations</span> **
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUser;
