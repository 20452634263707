import './accessCodePopup.css';

const AccessCodePopup = ({ generateCodeAgain, setShowPopup }) => {

  const closeModal = () => {
    setShowPopup(false)
  }

  const generateNewCode = () => {
    setShowPopup(false)
    generateCodeAgain();
  }

  return (
    <div className="access-modal-overlay">
      <div className="access-code-popup-container">
        <p></p>
        <h5 className="modal-title-access">Attention!</h5>
        <p className="modal-text-access">You have alreade generated 1 access code and have not sent <br /> it with email!</p>
        <p className="modal-text-access">Do you want to generate a new access code?<br /> </p>
        <div className="modal-btns-wrapper-access-code">
          <button
            className="cancel-btn"
            onClick={() => {
              closeModal();
            }}>Cancel</button>
          <button
            className="yes-btn"
            onClick={() => {
              generateNewCode();
              closeModal()
            }}
          >Yes</button>
        </div>
      </div>
    </div>
  )
}

export default AccessCodePopup;