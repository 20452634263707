import { SET_CERT_LOGS_BYSEARCH_CERT_ID } from '../Actions/Types/Types';

const initialState = {
    event_logs: [],
    organization_user: [],
    organization: [],
    request: [],
    consent_credentials: [],
    service_token: [],
    column_affected: [],
    bank: '',
    transaction: '',
    // status: '',
    certificate_logs_response: {}
}

const CertificateLogs = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CERT_LOGS_BYSEARCH_CERT_ID:
            return {
                // status: action.certificatelogs_list.status,
                event_logs: action.certificatelogs_list.data.event_logs,
                organization_user: action.certificatelogs_list.data.organization_user,
                organization: action.certificatelogs_list.data.organization,
                request: action.certificatelogs_list.data.request,
                consent_credentials: action.certificatelogs_list.data.request.consent_credentials,
                service_token: action.certificatelogs_list.data.organization_user.service_token,
                column_affected: action.certificatelogs_list.data.event_logs.column_affected,
                bank: action.certificatelogs_list.data.bank,
                transaction: action.certificatelogs_list.data.transaction,
                certificate_logs_response: action.certificatelogs_list.data,
            }
        default: return state;
    }
}

export default CertificateLogs;