import Select from 'react-select'
const ReactSelect = (props) => {
  let orgOptions

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#2e70d0' : 'white',
      color: state.isSelected ? 'white !important' : '#5B6A83',
      ':hover': {
        backgroundColor: '#2e70d0',
        color: '#fff',
      },
    }),
  };

  if (props.pageRender === "customers") {
    let newListOne = [{ "value": "", "label": "Select" }]
    let newList = props.orgList.map(org => ({ "value": org[0], "label": `${org[1]}` }))
    orgOptions = [...newListOne, ...newList]
  }
  else if (props.pageRender === "banks") {
    let newListOne = [{ "value": "", "label": "All" }]
    let newList = props.orgList.map(org => ({ "value": org, "label": `${org}` }))
    orgOptions = [...newListOne, ...newList]
  }
  else {
    let newListOne = [{ "value": "", "label": "Select" }]
    let newList = props.orgList.map(org => ({ "value": org.id, "label": `${org.name} (${org.id})` }))
    orgOptions = [...newListOne, ...newList]
  }

  const selectHandler = (selected) => {
    if (props.pageRender === "logs") {
      props.setOrganizationId(selected.value)
    } else if (props.pageRender === "customers") {
      props.setSelectedCustomer(selected.value)
    } else if (props.pageRender === "edit org") {
      props.setSelectedOrgSelect(selected)
      let obj = { target: { "name": "parent_organization_id", "value": selected.value } }
      props.handleChange(obj)
    } else if (props.pageRender === "select cert") {
      props.handleSelectOrg(selected)
    } else if (props.pageRender === "banks") {
      props.setSelectedBank(selected.value)
    } else if (props.pageRender === "organization user") {
      props.handleChange('org_id', selected.value)
    } else {
      props.handleChange('organization_id', selected.value)
    }
  }

  return (
    <>
      <Select
        className="react-select-custom"
        options={orgOptions}
        placeholder={props.pageRender === "customers" ? "customers" : props.pageRender === "banks" ? "Banks" : "Select"}
        styles={customStyles}
        onChange={selectHandler}
        value={props.value}
      />
    </>
  )
}

export default ReactSelect;