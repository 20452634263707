import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import ReportList from "../../Components/Report/report";

import "./reports.scss";
const Sms = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Reports";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <ReportList />
      </div>
    </div>
  );
};

export default Sms;
