import React, { useState } from "react";
import eyeIcon from '../../assets/images/eye_icon.svg';
import eyeIconWithWhiteBg from '../../assets/images/eye_close.svg';
import searchIcon from '../../assets/images/search.svg';
import sort from '../../assets/images/sort.svg';
import ReactPaginate from 'react-paginate';
import close from '../../assets/images/icon_close.png';
import { Link } from 'react-router-dom';

const List = ({
  organizationUserList = [],
  paginationData = {},
  searchKey = "",
  orgId = "",
  selectPageHandler = () => { },
  applySorting = () => { },
  searchKeyValue = () => { },
  setOrgId = () => { },
}) => {
  
  const [search, setSearch] = useState(searchKey);
  const handleClick = (e, id) => {
    e.preventDefault();
    setOrgId(id)
  };

  const closeSearch = () => {
    searchKeyValue("")
    setSearch("")
  }

  return (
    <div className="clients-container">
      <div className="input-group search-input-group">
        <input onChange={(e) => setSearch(e.target.value)} value={search} className="search-control" placeholder="Search"
          onKeyPress={event => {
            if (event.key === 'Enter') {
              searchKeyValue(search);
            }
          }}
        />
        {searchKey.length === 0 ?
          <img className="search-icon" src={searchIcon} alt="search" />
          :
          <img className="close-icon" src={close} alt="close" onClick={() => closeSearch()} />
        }
      </div>
      <div className="user-category-thread-table">
        <table className="main-table">
          <thead className="user-category-thread">
            <tr className="category-thread">
              <th className="category-head" onClick={(e) => applySorting('name')}>Clients <img className="sort-icon" src={sort} alt="sort" /></th>
              <th className="category-head" onClick={(e) => applySorting('total_requests')}>Total Requests <img className="sort-icon" src={sort} alt="sort" /></th>
              <th className="category-head" onClick={(e) => applySorting('accepted')}>Accepted Requests <img className="sort-icon" src={sort} alt="sort" /></th>
              <th className="category-head" > % Accepted</th>
              <th className="category-head">Action</th>
            </tr>
          </thead>
          <tbody className="user-data">
            {organizationUserList.map((item) => {
              return (
                <tr className={`user-box ${orgId === item.id && 'activeOrgBackgraound'}`} key={item.id} >
                  <td className="user-info">{item.name}</td>
                  <td className="user-info">{item.total_requests}</td>
                  <td className="user-inforole">{item.accepted}</td>
                  <td className="user-inforole">{item.accepted !== 0 ? ((item.accepted / item.total_requests) * 100).toFixed(2) : "0.00"} %</td>
                  <td className="user-inforole" >
                    {orgId ?
                      <a href="!#" onClick={(e) => handleClick(e, item.id)}>
                        <img src={orgId === item.id ? eyeIconWithWhiteBg : eyeIcon} alt="eyeIcon" />
                      </a>
                      :
                      <Link to={{ pathname: `/dashboard`, state: { orgId: item.id } }}>
                        <img src={eyeIcon} alt="eyeIcon" />
                      </Link>
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div >
      <div className="pagination-wrapper">
        <ReactPaginate
          previousLabel="Previous"
          activePage={Math.ceil(paginationData.current_page)}
          nextLabel="Next"
          breakLabel={"..."}
          pageCount={Math.ceil(paginationData.total_pages)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          onPageChange={(event) => selectPageHandler(event)}
        />
      </div>
    </div >
  )
}

export default List;