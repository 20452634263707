import { useEffect, useState } from "react";
import './BankErrorModal.scss'

const ErrorContainer = (props) => {
  const [errorLog, seterrorLog] = useState(props.errorLog);
  useEffect(() => {
    seterrorLog(props.errorLog);
  }, [props]);
  const formatLeadingZeros = (number) => {
    if (number < 10) {
      return `0${number}`
    }
    return number;
  }
  const dateFormat = (created_at) => {
    const time = new Date(Date.parse(created_at));

    const dateString = `${formatLeadingZeros(time.getHours())}:${formatLeadingZeros(time.getMinutes())}:${formatLeadingZeros(time.getSeconds())}`
    return dateString;
  }
  if (!errorLog) {
    return <></>
  }
  return (<>
    <div className="bank-status-error-modal-error-list-container">
      <div className="bank-status-error-modal-error-list-container-header">
        <span className="bank-status-error-modal-error-list-container-header-error-type">
          {errorLog.error_type}
        </span>
        <span className="bank-status-error-modal-error-list-container-header-id">
          Log id: {errorLog.id}
        </span>
      </div>
      <div className="bank-status-error-modal-error-list-container-expanded">
        <p>{dateFormat(errorLog.created_at)}</p>
        <p>{errorLog.error_message}</p>
        <p>{errorLog.message}</p>

        <p className="text-muted">Organization request id: {errorLog.organization_request_id}</p>
      </div>

    </div>
  </>)
}

const ErrorModal = (props) => {
  const [errorLogs, seterrorLogs] = useState(props.errorLogs);
  const [blockData, setBlockData] = useState(props.blockData);
  useEffect(() => {
    seterrorLogs(props.errorLogs);
    setBlockData(props.blockData);
  }, [props]);
  return (
    <>
      <div className="bank-status-error-modal-backdrop" onClick={props.toggleVisibility}>
      </div>
      <div className="bank-status-error-modal-container">
        <span className="bank-status-error-modal-close"><button className="bank-status-error-modal-close-button" onClick={props.toggleVisibility}>X</button></span>
        <div className="bank-status-error-modal-main-text">
          <h3>{blockData.time}</h3>
          <h4>Total requests: {blockData.count} </h4>
          {blockData.processing ? <h4>{blockData.processing} request{blockData.processing > 1 ? "s" : ""} still in process.</h4> : <></>}
        </div>
        {errorLogs && <div className="bank-status-error-modal-error-list">
          {errorLogs.map((errorLog, index) => { return <ErrorContainer key={index} errorLog={errorLog} /> })}
        </div>}
      </div>

    </>
  )
}

export default ErrorModal;