import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getLiveDataFilter() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.FILTER()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error'
      return { status: success, message: res.data.message, data: res.data.data }
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}
export function getLiveData(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.DETAILS()}` + data;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getCountryRequestsData(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.REQUEST_BY_COUNTRY()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getLiveDataRecentActivities() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.RECENT_ACTIVITIES()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getOrganizationLiveData(countryId) {
  let apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.REQUEST_COUNT_ORGANIZATION()}`;
  if (countryId) {
    apiURL += `?country_id=${countryId}`;
  }
  return dispatch => {
    return axios.get(apiURL)
      .then(res => {
        const success = res.data.status !== 'error';
        if (success) {
          return { status: true, message: res.data.status, data: res.data.data };
        }
      })
      .catch(err => {
        (err.response.status === 401) && removeHeaderWithLocalstorage();
        return { status: false, message: err.response.data.error || err.response.data.message }
      });
  };
}


export function getMarketsLiveData() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.REQUEST_COUNT_MARKETS()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getRequestsWorldmapLiveData() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.REQUEST_WORLDMAP()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      if (res.data.status === 'success') {
        const message = res.data.message;
        const organizationCount = res.data.organization_count;
        const requests = res.data.requests;
        return { status: true, message: message, data: { organizationCount, requests } };
      } else {
        return { status: false, message: res.data.message || 'An error occurred' };
      }
    })
    .catch(err => {
      if (err.response) {
        (err.response.status === 401) && removeHeaderWithLocalstorage();
        return { status: false, message: err.response.data.message || 'An error occurred' };
      } else {
        return { status: false, message: err.message || 'Network error' };
      }
    })
}

export function getCustomersLiveData(params) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.CUSTOMERS()}` + params;

  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function getBanksLiveData(params) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.BANKS()}` + params;

  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function getMarketsData(params) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.MARKETS()}` + params;

  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function getCustomersFilterData() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.LIVE_DATA.FILTER_CUSTOMERS()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        return { status: true, message: res.data.status, data: res.data.data };
      }
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}