import React from 'react';
import './serviceSettingAction.css';
import { useState } from "react";

const ServiceSettingAction = (props) => {

  const [labelization, setLabelization] = useState(props.data.labelization.toString());
  const [active, setActive] = useState(props.data.active.toString());

  const submitHandle = async () => {
    const data = {
      id : props?.id,
      labelization:JSON.parse(labelization.toString().replace(/ /g," ")),
      active : JSON.parse(active.toString().replace(/ /g," "))
    };
    props.handleUpdate(data)
    }

  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <h3>Update Service Setting</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="service-settings-filter-container">
              <label>Active</label>
              <select value={active} className="select-filter-service-settings" onChange={e=>setActive(e.target.value)}>
                <option value="true" >True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="service-settings-filter-container">
              <label>Labelization</label>
               <select value={labelization} className="select-filter-service-settings" onChange={e=>setLabelization(e.target.value)}>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
        </div>
        <div className="modal-btns">
          <button className='closeDelete-update' onClick={props.closeDelete}>Cancel</button>
          <button className='confirm-update' onClick={() => submitHandle()}>Update</button>
        </div>
      </div>
    </div>
  )}
export default ServiceSettingAction;