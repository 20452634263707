import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";


export function getCertificateData(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ITMENU.LIST()}` + data
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getOrganizationListCertificates(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ITMENU.FILTER()}`
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getCertificateDelete(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ITMENU.DELETE()}` + data
  return dispatch => axios.delete(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, result: res.data };
    })
    .catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}