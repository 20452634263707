import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import CreateNewOrganization from "../../Components/CreateOrganization/createOrganization";

const CreateOrganization = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Create Organization";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <CreateNewOrganization />
      </div>
    </div>
  );
};

export default CreateOrganization;
