import SwaggerEditorBundle from "swagger-editor-dist/swagger-editor-bundle";
import SwaggerEditorStandalonePreset from "swagger-editor-dist/swagger-editor-standalone-preset";
import "swagger-editor-dist/swagger-editor.css";
import { useEffect } from "react";
import "./swaggerEditor.scss"

const SwaggerEditor = () => {
  const getSwaggerEditor = async () => {
    const editor = await SwaggerEditorBundle({
      dom_id: "#swagger-editor",
      layout: "StandaloneLayout",
      swagger2GeneratorUrl: "https://generator.swagger.io/api/swagger.json",
      oas3GeneratorUrl: "https://generator3.swagger.io/openapi.json",
      swagger2ConverterUrl: "https://converter.swagger.io/api/convert",
      presets: [SwaggerEditorStandalonePreset],
      queryConfigEnabled: false,
    });
    window.editor = editor;
  };
  useEffect(() => {
    getSwaggerEditor();
  }, []);
  return (
    <div id="swagger-editor"></div>
  )

};
export default SwaggerEditor;