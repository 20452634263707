import React from 'react';
import "./styles/ActiveSubscribers.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import trashIcon from './assets/kreditz-trash-icon.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: 254,
  bgcolor: '#fff',
  borderRadius: '20px',
  boxShadow: '0px 0px 10px rgba(13, 27, 51, 0.1)',
  p: 4,
};

const SubscriberDeleteModal = (props) => {
  return (
    <div>
      <Modal open={props.isOpen} onClose={props.handleClose}>
        <Box sx={style}>
          <img
            src={trashIcon}
            className='as-delete-icon'
            alt='trash icon'
          />
          <p className='as-modal-p'>
            Are you sure you want <br /> to delete this Subscriber? </p>
          <div className='as-modal-btns'>
            <span className='as-cancel-btn-wrapper'>
              <button
                onClick={props.onClose}
                className='as-cancel-btn'
                type='button'>
                Cancel
              </button>
            </span>
            <span className='as-delete-btn-wrapper'>
              <button
                onClick={props.onDelete}
                className='as-delete-btn'
                type='button'>
                Yes, Delete
              </button>
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SubscriberDeleteModal;
