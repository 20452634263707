import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import ReactPaginate from "react-paginate";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import UploadFilesPopup from "../UploadFilesPopup/UploadFilesPopup.jsx";
import { createUploadFiles, getUploadFiles, updateUploadFiles } from "../../Redux/Actions/UploadFiles";
import updateIcon from '../../assets/images/edit.svg';
import moment from "moment";
import EditUploadFilesPopup from "../UploadFilesPopup/EditUploadFilesPopup.jsx";
import NewFlash from "../FlashMessage/NewFlash";

const UploadFiles = () => {
  const dispatch = useDispatch();
  const textConfirmation1 = ("Are you sure you want")
  const textConfirmation2 = ("to update organization");
  const [uploadFiles, setUploadFiles] = useState(false)
  const [publicUploads, setPublicUploads] = useState([])
  const [directories, setDirectories] = useState([])
  const [editUploadFiles, setEditUploadFiles] = useState(false)
  const [editId, setEditId] = useState()
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_count: '', total_pages: '' })
  const [currentPage, setCurrentPage] = useState()
  let userId = localStorage.getItem('id')
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({
    iconImage: "",
    firstMsg: "",
    secondMsg: "",
  });

  const closeFlash = () => {
    setFlashMessage(false);
  };

  const openFlashFn = async (iconImg, firstMessage, message) => {
    await dispatch(setLoaderSpinner(false));
    setFlashMessage(true);
    setFlashData((prevState) => ({
      ...prevState,
      iconImage: iconImg,
      firstMsg: firstMessage,
      secondMsg: message,
    }));
  }

  const uploadFileHandler = () => {
    setUploadFiles(false)
  }

  const togglePopup = () => {
    setUploadFiles(false)
    setEditUploadFiles(false)
  }

  const uploadHandler = async (fileData, directoryName = "uploads/") => {
    let query = `?file_path=${directoryName}&uploaded_by=${userId}`
    const res = await dispatch(createUploadFiles(query, fileData))
    setUploadFiles(false)
    if (res.status) {
      openFlashFn("tick", "Success", res.message)
      getListUploadFiles()
    } else {
      openFlashFn("", "Error", res.message)
    }
  }

  const getListUploadFiles = async (page = 1) => {
    let query = `?page=${page}&per_page=20`
    const { result } = await dispatch(getUploadFiles(query))
    const { public_uploads, directory, current_page, total_count, total_pages } = result.data;
    let dirNameData = directory.map(dir => ({ "label": dir, "value": dir }))
    setDirectories(dirNameData)
    setPublicUploads(public_uploads)
    setpaginationData(prevState => ({
      ...prevState,
      current_page: current_page,
      total_count: total_count,
      total_pages: total_pages
    }))

  }

  useEffect(() => {
    getListUploadFiles();
  }, [])

  const editUploadFilesHandler = async (file) => {
    const { result, message } = await dispatch(updateUploadFiles(editId, file))
    getListUploadFiles(currentPage)
    setEditUploadFiles(false)
    if (message === "success") {
      openFlashFn("tick", message, result.message)
    } else {
      openFlashFn("", message, result.message)
    }
  }

  const openEditPopup = (id) => {
    setEditUploadFiles(true)
    setEditId(id)
  }

  const pageChange = (selectedPage) => {
    let newPage = selectedPage.selected + 1;
    getListUploadFiles(newPage);
    setCurrentPage(newPage)
  }

  return (
    <div className="service-settings-container page-container">
      {flashMessage && (
        <div
          className={`alert - popup alert - success - bar ${flashMessage
            ? setTimeout(() => setFlashMessage(false), 3000) & "alert-shown"
            : "alert-hidden"
            } `}
        >
          <NewFlash data={flashData} closeFlash={() => closeFlash()} />
        </div>
      )}
      {
        uploadFiles && <UploadFilesPopup
          firstTextUser={textConfirmation1}
          secondTextUser={textConfirmation2}
          h3={"Upload File"}
          uploadHandler={uploadHandler}
          directories={directories}
          confirm={uploadFileHandler}
          closeDelete={togglePopup}
        />
      }
      {
        editUploadFiles && <EditUploadFilesPopup
          firstTextUser={textConfirmation1}
          secondTextUser={textConfirmation2}
          h3={"Update File"}
          uploadHandler={uploadHandler}
          directories={directories}
          confirm={editUploadFilesHandler}
          closeDelete={togglePopup}
        />
      }
      <div className="lower-header-service-settings service-settings-head-bg lower-header-it-certificates">
        <h3>Upload Objects to AWS</h3>
        <button className="service-button" onClick={() => setUploadFiles(true)}>Upload Files</button>
      </div>
      <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <div className="organizations-thead-scroll-y upload-object-table">
          <table className="table" width="100%">
            <thead className="organizations-thead">
              <tr>
                <td className="org-head">Name</td>
                <td className="org-head">Size</td>
                <td className="org-head">Uploaded by</td>
                <td className="org-head">Date Created</td>
                <td className="org-head">Date Modified</td>
                <td className="org-head">Src</td>
                <td className="org-head">Directory</td>
                <td><center>Action</center></td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                publicUploads && publicUploads.length > 0 && publicUploads.map((item) => {
                  return (
                    <tr key={`kreditz-providers-${item.id + 1} `}
                      id={`${item.id}`}>
                      <td className="org-td">{item.name}</td>
                      <td className="org-td">{item.size} bytes</td>
                      <td className="org-td">{item.user_name}</td>
                      <td className="org-td">{moment(item.created_at).format("DD-MM-YYYY h:mm:ss")}</td>
                      <td className="org-td">{moment(item.updated_at).format("DD-MM-YYYY h:mm:ss")}</td>
                      <td className="org-td"><a href={item?.attachment?.url} target="_blank">{item?.attachment?.url}</a></td>
                      <td className="org-td">{item.file_path}</td>
                      <td className="org-td">
                        <center>
                          <a href="javascript:void(0)"><img src={updateIcon} alt="view" width="20" onClick={(e) => openEditPopup(item.id)} /></a>
                        </center>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div className="pagination-box">
          {paginationData.total_count > 30 &&
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel={"..."}
              pageCount={Math.ceil(paginationData.total_pages)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pageChange}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={paginationData.current_page - 1}
            />
          }
        </div>
      </div>
    </div>
  );
};
export default UploadFiles;