import {SET_LOADER_SPINNER} from '../Actions/Types/Types'

const initialState = {
loading: false
}

    const loader_spin = (state = initialState, action = {}) => {
        switch(action.type) {
            case SET_LOADER_SPINNER:
                return {
                    loading: action.value
                }
             default: return state;
        }
    }

export default loader_spin;
