import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateBackgroundJob } from "../../../Redux/Actions/BackgroundJobs";

const BackgroundJobsDescription = ({ jobId, description, onDescriptionUpdate }) => {
  const [editMode, setEditMode] = useState(false);
  const [jobDescription, setJobDescription] = useState(description || '');
  const dispatch = useDispatch();

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    const response = await dispatch(updateBackgroundJob(jobId, jobDescription));
    if (response.status) {
      setEditMode(false);
      onDescriptionUpdate(jobDescription);
    } else {
      console.error('Error updating description:', response.message);
    }
  };
  
  const handleChange = event => {
    setJobDescription(event.target.value);
  };

  return (
    <>
      <div className="description-card">
        <h2 className="description-title">Description:</h2>
        {editMode ? (
          <textarea
            className="description-textarea"
            value={jobDescription}
            onChange={handleChange}
          />
        ) : (
          <p className="description-text">{description || 'No description available.'}</p>
        )}
        {editMode ? (
          <>
            <button className="save-button" onClick={handleSave}>
              Save
            </button>
          </>
        ) : (
          <>
            <button className="edit-button" onClick={handleEdit}>
              Edit
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default BackgroundJobsDescription;
