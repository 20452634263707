import React, { useState, useEffect } from 'react';
import './OrganizationsBrokerFlow.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import BrokerFlowTable from './misc/BrokerFlowTable';
import moment from 'moment';
import { getOrganizationBrokerDetails, getBrokerFlowXlsx } from '../../Redux/Actions/OrganizationManagement';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import excelIcon from '../../assets/images/excel_icon.png'
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import FlashMessage from '../FlashMessage/FlashMessage';
import ConfirmationMessage from './misc/ConfirmationMessage';

function OrganizationsBrokerFlow() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [brokers, setBrokers] = useState([]);
  const [displayedBrokers, setDisplayedBrokers] = useState([]);
  const [dateRange, setDateRange] = useState(() => {
    const savedRange = sessionStorage.getItem('dateRange');
    if (savedRange) {
      const { startDate, endDate } = JSON.parse(savedRange);
      return { startDate: moment(startDate, 'YYYY/MM/DD'), endDate: moment(endDate, 'YYYY/MM/DD') };
    }
    return { startDate: moment(), endDate: moment() }; 
  });
  const [pickerKey, setPickerKey] = useState(0);
  const [isTotalRequestsActive, setIsTotalRequestsActive] = useState(false);
  const [inputDisplayValue, setInputDisplayValue] = useState('');
  const [flash, setFlash] = useState({ show: false, type: '', message: '' });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const fetchOrganizationBrokerDetails = async (customDateRange) => {
    const params = {};
    
    if (customDateRange && customDateRange.startDate && customDateRange.endDate) {
      const formattedStartDate = moment.isMoment(customDateRange.startDate) ? customDateRange.startDate.format('YYYY/MM/DD') : moment(customDateRange.startDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
      const formattedEndDate = moment.isMoment(customDateRange.endDate) ? customDateRange.endDate.format('YYYY/MM/DD') : moment(customDateRange.endDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
      params.date_range = `${formattedStartDate}-${formattedEndDate}`;
    }
    
    dispatch(getOrganizationBrokerDetails(params))
      .then(response => {
        if (response && response.status === true) {
          const sortedData = sortBrokersByTotalSharedRequests(response.data.organizations);
          setBrokers(sortedData); 
        } else {
          if (response.message === 'Unauthorized') {
            history.push('/login');
          } 
        }
      })
      .catch(error => {
        console.error('Error fetching organization broker details:', error);
      });
  };

  const generateBrokerFlowXlsx = async (startDate, endDate) => {
    try {
      await dispatch(setLoaderSpinner(true));
      const response = await dispatch(getBrokerFlowXlsx(startDate, endDate));
      if (response.status) {
        setFlash({ show: true, type: 'success', message: 'Excel file downloaded successfully!' });
        setShowConfirmDialog(true);
      } else {
        setFlash({ show: true, type: 'error', message: `Failed to download excel file: ${response.message}` });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setFlash({ show: true, type: 'error', message: `An error occurred: ${error.message}` });
    }
    dispatch(setLoaderSpinner(false));
  };
  
  const handleGenerateReport = () => {
    const formattedStartDate = dateRange.startDate ? dateRange.startDate.format('YYYY/MM/DD') : null;
    const formattedEndDate = dateRange.endDate ? dateRange.endDate.format('YYYY/MM/DD') : null;
    generateBrokerFlowXlsx(formattedStartDate, formattedEndDate);
  };

  const setDateRangeSafely = (startDate, endDate) => {
    setDateRange({
      startDate: moment(startDate, 'YYYY/MM/DD'),
      endDate: moment(endDate, 'YYYY/MM/DD')
    });
  };

  const handleDateRangeChange = (event, picker) => {
    const startDate = picker.startDate.format('YYYY/MM/DD');
    const endDate = picker.endDate.format('YYYY/MM/DD');
  
    sessionStorage.setItem('dateRange', JSON.stringify({ startDate, endDate }));
    setInputDisplayValue(`${startDate} - ${endDate}`);
    setDateRangeSafely(startDate, endDate);
    setIsTotalRequestsActive(false);
    fetchOrganizationBrokerDetails({ startDate: picker.startDate, endDate: picker.endDate });
  };
  
  useEffect(() => {
    setDisplayedBrokers(brokers);
  }, [brokers]);

  const sortBrokersByTotalSharedRequests = (brokers, direction) => {
    return brokers.sort((a, b) => {
      if (direction === 'asc') {
        return a.total_shared_certificate - b.total_shared_certificate;
      } else {
        return b.total_shared_certificate - a.total_shared_certificate;
      }
    });
  };

  useEffect(() => {
    const savedTotalActive = sessionStorage.getItem('totalActive');
    const savedRange = sessionStorage.getItem('dateRange');
    let startDate, endDate;
  
    if (savedTotalActive === 'true') {
      setIsTotalRequestsActive(true);
      setInputDisplayValue('-/-/-');
      setDateRange({ startDate: null, endDate: null });
      fetchOrganizationBrokerDetails();
    } else if (savedRange) {
      const { startDate: savedStartDate, endDate: savedEndDate } = JSON.parse(savedRange);
      startDate = moment(savedStartDate, 'YYYY/MM/DD');
      endDate = moment(savedEndDate, 'YYYY/MM/DD');
      setDateRange({ startDate, endDate });
      setInputDisplayValue(`${startDate.format('YYYY/MM/DD')} - ${endDate.format('YYYY/MM/DD')}`);
      fetchOrganizationBrokerDetails({ startDate: savedStartDate, endDate: savedEndDate });
    } else {
      startDate = endDate = moment();
      setDateRange({ startDate, endDate });
      setInputDisplayValue(`${startDate.format('YYYY/MM/DD')} - ${endDate.format('YYYY/MM/DD')}`);
      fetchOrganizationBrokerDetails({ startDate: startDate.format('YYYY/MM/DD'), endDate: endDate.format('YYYY/MM/DD') });
    }
  }, []); 

  const resetDateRange = () => {
    const today = moment();
    const formattedToday = today.format('YYYY/MM/DD'); 
    sessionStorage.setItem('dateRange', JSON.stringify({ startDate: today.format('YYYY/MM/DD'), endDate: today.format('YYYY/MM/DD') }));

    sessionStorage.removeItem('totalActive');
    setIsTotalRequestsActive(false);
    setDateRange({ startDate: today, endDate: today });
    setInputDisplayValue(`${formattedToday} - ${formattedToday}`);
    setPickerKey(prevKey => prevKey + 1);
    fetchOrganizationBrokerDetails({ startDate: today, endDate: today });
  };

  useEffect(() => {
    setDisplayedBrokers(brokers);
  }, [brokers]);

  const handleTotalRequestsClick = () => {
    setIsTotalRequestsActive(true);
    sessionStorage.setItem('totalActive', JSON.stringify(true));
    sessionStorage.removeItem('dateRange');
    setDateRange({ startDate: null, endDate: null });
    setInputDisplayValue('-/-/-');
    fetchOrganizationBrokerDetails();
  };

  const showResetIcon = isTotalRequestsActive || (dateRange.startDate && dateRange.endDate && (
    moment().format('YYYY/MM/DD') !== moment(dateRange.startDate).format('YYYY/MM/DD') ||
    moment().format('YYYY/MM/DD') !== moment(dateRange.endDate).format('YYYY/MM/DD')));

  useEffect(() => {
    if (flash.show) {
        const timeoutId = setTimeout(() => {
            setFlash({ ...flash, show: false });
            setShowConfirmDialog(false);
        }, 5000);

        return () => {
            clearTimeout(timeoutId);
        };
      }
  }, [flash]);

  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Broker', accessor: 'name' },
    { Header: 'Partners', accessor: 'partners_count' },
    { Header: 'Total shared requests', accessor: 'total_shared_certificate' },
  ];

  return (
    <div className="broker-flow-container">
        {flash.show && (
          <FlashMessage
            iconImage={flash.type === 'success' ? 'tick' : 'alert'}
            firstMsg={flash.type === 'success' ? 'Success' : 'Error'}
            secondMsg={flash.message}
            closeFlash={() => {
              setFlash({ ...flash, show: false });
              setShowConfirmDialog(false);
            }}
          />
        )}
    {showConfirmDialog && <ConfirmationMessage setShowConfirmDialog={setShowConfirmDialog} />}
      <div className="ld-container">
        <h3 className="ld-header-broker-flow">Organizations - Broker flow</h3>
      </div>
      <div className="broker-flow-table-top bg-white">
      <div className="broker-flow-flex-header customer-activity-header">
        <button
          className={`broker-flow-total-button ${isTotalRequestsActive ? 'active' : ''}`}
          onClick={handleTotalRequestsClick}
        >
          Total shared requests
        </button>
        <div className="broker-flow-date-picker-wrapper">
          <DateRangePicker 
                key={pickerKey + (isTotalRequestsActive ? "_totalRequests" : "_dateRange")} 
                onApply={handleDateRangeChange} 
                startDate={isTotalRequestsActive ? null : (dateRange.startDate ? dateRange.startDate.format('YYYY/MM/DD') : moment().format('YYYY/MM/DD'))} 
                endDate={isTotalRequestsActive ? null : (dateRange.endDate ? dateRange.endDate.format('YYYY/MM/DD') : moment().format('YYYY/MM/DD'))}
                initialSettings={{
                  startDate: dateRange.startDate ? dateRange.startDate.toDate() : moment().toDate(),
                  endDate: dateRange.endDate ? dateRange.endDate.toDate() : moment().toDate(),
                  locale: { format: 'YYYY/MM/DD' },
                }}>
                <input
                type="text"
                className="date-form-box"
                id="date-range-picker"
                value={inputDisplayValue}
                readOnly
                style={{ cursor: "pointer" }}
              />
                      </DateRangePicker>
              {showResetIcon && (
                <button onClick={resetDateRange} className="broker-flow-reset-date-button">
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              )}
              <div className="broker-flow-customer-activity-icon">
                <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
              </div>
						<span className='invoice-excel-icon' onClick={handleGenerateReport}><img className='invoice-excel-icon' src={excelIcon} alt="excel icon" /></span>
          </div>
        </div>
        <div className="broker-flow-thead-scroll-y">
          <BrokerFlowTable columns={columns} data={displayedBrokers} linkPath="/org_broker_flow/details"/>
        </div>
      </div>
    </div>
  );
}

export default OrganizationsBrokerFlow;
