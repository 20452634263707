import React, { useState } from 'react';
// STYLES
import './styles/testCenterView.css';
import './styles/MaterialUI.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

// LIBRARIES
import moment from 'moment';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import _ from 'lodash';

const Row = ({ subscriber, countries }) => {
  const organizationRequest = _.orderBy(subscriber.organization_request, 'created_at', 'desc')
  // const [organizationRequest, setOrganizationRequest] = useState(subscribersRequestToDisplay)
  const [isOpen, setIsOpen] = useState(false);
  const country = countries.find((country) => country.id === subscriber.country_id);
  const countryName = country?.name;

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : "h, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : "m, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  return (
    <React.Fragment>
      <tr onClick={() => setIsOpen(!isOpen)}>
        <td className='td-name'>{subscriber.full_name}</td>
        <td className='td-source'>{subscriber.send_by}</td>
        <td className='td-product'>
          {subscriber.request_type
            ?.replace('customer', 'Customer')
            .replace('corporate', 'Corporate')}
        </td>
        <td className='td-type'>
          {subscriber.product
            ?.replace('certificate', 'Certificate')
            .replace('account_only', 'Account only')}
        </td>
        <td className='td-market'>
          {countryName
            ?.replace('denmark', 'Denmark')
            .replace('finland', 'Finland')
            .replace('germany', 'Germany')
            .replace('india', 'India')
            .replace('netherland', 'Netherland')
            .replace('norway', 'Norway')
            .replace('portugal', 'Portugal')
            .replace('spain', 'Spain')
            .replace('united kingdom', 'United Kingdom')
            .replace('united states', 'United States')
            .replace('sweden', 'Sweden')}
        </td>
        <td className='td-from-to'>
          {(() => {
            if (subscriber.continuously) {
              return 'Continuously';
            }

            const todayDate = moment(new Date());
            const subscriberEndDate = moment(new Date(subscriber.end_date));
            const difference = todayDate.diff(subscriberEndDate, 'days');
            if (difference > 0) {
              return (
                <>
                  <p className='tc-inactive-p'>
                    Inactive
                  </p>
                </>
              );
            }

            return (
              <div>
                {moment(subscriber.start_date).format('YYYY-MM-DD')}
                <span> to </span>
                {moment(subscriber.end_date).format('YYYY-MM-DD')}
              </div>
            )
          })()}
        </td>
        <td className='td-requests'>
          {subscriber.total_accepted_count}
          <span>/</span>
          {subscriber.request_per_day}

          {isOpen ?
            <KeyboardArrowUpIcon className='mui-dropdown-icon' />
            :
            <KeyboardArrowDownIcon className='mui-dropdown-icon' />
          }
        </td>
      </tr>
      <tr>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={10}>
          <Collapse
            in={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            timeout='auto'>
            <main className='mui-collapse-container'>
              <div className='mui-section-container'>
                <section className='mui-section-wrapper'>
                  <div className='mui-column-one'>
                    <p className='mui-p-top'>
                      Requests:
                      <span> </span>
                      <span className='mui-span-req-amount'>
                        {subscriber.total_requests_count}
                      </span>
                    </p>
                  </div>
                  <div className='mui-column-two'>
                    <p className='mui-p-top mui-top-row-two'>
                      Accepted:
                      <span> </span>
                      <span className='mui-span-acc-amount'>
                        {subscriber.total_accepted_count}
                      </span>
                    </p>
                  </div>
                  <div className='mui-column-three'>
                    <p className='mui-p-top mui-top-row-three'>
                      Pending:
                      <span> </span>
                      <span className='mui-span-pen-amount'>
                        {subscriber.total_pending_count}
                      </span>
                    </p>
                  </div>
                  <div className='mui-column-four'>
                    <p className='mui-p-top mui-top-row-four'>
                      Average time to accept request:
                      <span> </span>
                      <span className='mui-span-ave-amount'>
                        {subscriber.avg_processing_time && secondsToHms(subscriber.avg_processing_time)}
                      </span>
                    </p>
                  </div>
                </section>
                <section className='mui-section-wrapper mui-bg'>
                  <div className='mui-column-one'>
                    <p className='mui-p' style={{ borderBottom: 0 }}>
                      Requests ID
                    </p>
                  </div>
                  <div className='mui-column-two'>
                    <p className='mui-p mui-middle-row-two' style={{ borderBottom: 0 }}>
                      Request sent at
                    </p>
                  </div>
                  <div className='mui-column-three'>
                    <p className='mui-p mui-middle-row-three' style={{ borderBottom: 0 }}>
                      Request accepted at
                    </p>
                  </div>
                  <div className='mui-column-four'>
                    <p className='mui-p mui-middle-row-four' style={{ borderBottom: 0 }}>
                      Time to answer
                    </p>
                  </div>
                </section>
                {
                  organizationRequest.map((item) => {
                    return item.status !== 'past_due_date' ? (
                      <section className='mui-section-wrapper' key={subscriber.id}>
                        <div className='mui-column-one'>
                          <p className='mui-p' style={{ borderBottom: 0 }}>
                            {item.id}
                          </p>
                        </div>
                        <div className='mui-column-two'>
                          <p className='mui-p' style={{ borderBottom: 0 }}>
                            {item.created_at && moment(item.created_at).format('YYYY-MM-DD')} {item.created_at && moment(item.created_at).format('HH:mm')}
                          </p>
                        </div>
                        <div className='mui-column-three'>
                          <p className='mui-p' style={{ borderBottom: 0 }}>
                            {item.accepted_at ? moment(item.accepted_at).format('YYYY-MM-DD') : 'Not Accepted'} {item.accepted_at ? moment(item.accepted_at).format('HH:mm') : ''}
                          </p>
                        </div>
                        <div className='mui-column-four'>
                          <p className='mui-p' style={{ borderBottom: 0 }}>
                            {item.processing_time && secondsToHms(item.processing_time.request_created_to_accepted)}
                          </p>
                        </div>
                      </section>
                    ) : ('')
                  })
                }

              </div>
            </main>
          </Collapse>
        </TableCell>
      </tr>
    </React.Fragment >
  );
};

export default Row;
