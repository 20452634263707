import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_CERT_LOGS_BYSEARCH_CERT_ID } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';


export function getcertlogssearchList(query) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SEARCH_CERTIFICATEID.SEARCHID()}`;
    const searchURL = apiURL + query;
    return dispatch => axios.get(searchURL)
        .then(res => {
            const success = res.data.status !== 'error'
            if (success) {
                if(!Array.isArray(res?.data?.data))
                {
                  dispatch(setCertificatelogsList(res.data));
                }
                return { status: true, message: res.data.status, data: res.data.data };
            } else {
                return { status: false, message: res.data.message };
            }
        }).catch(err => {
            (err?.response?.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err?.response?.data?.message };
        });
}

export function getcertlogssearchListByOrg(query) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.SEARCH_CERTIFICATEID.SEARCH_ORG()}`;
    const searchURL = apiURL + query;
    return dispatch => axios.get(searchURL)
        .then(res => {
            const success = res.data.status !== 'error'
            if (success) {
                return { status: true, message: res.data.status, data: res.data.data };
            } else {
                return { status: false, message: res.data.message };
            }
        }).catch(err => {
            (err?.response?.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response?.data?.message };
        });
}

export function refetchTransactions(id, refetchDays) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ADMIN_LOGS.LIST()}/${id}${API_CONFIG.END_POINTS.ADMIN_LOGS.REFETCH_EB_TRANSACTIONS()}` + `?refetch_days=${refetchDays}`;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.message, data: res.data.data };
        }).catch(err => {
            if (err.response.status === 404) {
                return { status: false, message: err.response.data.message };
            } else if (err.response.status === 401) {
                (err.response.message === "Unauthorize") && removeHeaderWithLocalstorage();
                return { status: false, message: err.response.data.message };
            }
        });
}

export function updateTransactions(id) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ADMIN_LOGS.LIST()}/${id}${API_CONFIG.END_POINTS.ADMIN_LOGS.UPDATE_EB_TRANSACTIONS()}`;
    return dispatch => axios.put(apiURL)
        .then(res => {
            return { status: true, message: res.data.message };
        }).catch(err => {
            if (err.response.status === 404) {
                return { status: false, message: err.response.data.message };
            } else if (err.response.status === 401) {
                (err.response.message === "Unauthorize") && removeHeaderWithLocalstorage();
                return { status: false, message: err.response.data.message };
            }
        });
}


export function setCertificatelogsList(response) {
    return {
        type: SET_CERT_LOGS_BYSEARCH_CERT_ID,
        certificatelogs_list: response,
    }
}