import "./conversion.css";
const BanksTable = (props) => {

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div>
      <table
        id="table-conversion-bank"
        className="table table-conversion"
        width="100%"
      >
        <thead className="conversion-thead">
          <tr className="conversion-bank-table-row">
            <th className="table-head-conversion onversion-bank-table-month">
              Month
            </th>
            <th
              id="th-bank"
              className="table-head-conversion conversion-table-bank"
            >
              Bank
            </th>

            <th
              id="th-connections"
              className="table-head-conversion conversion-table-bank-right conversion-table-connections"
            >
              Successful connections
            </th>

            <th
              id="conversion-percentage"
              className="table-head-conversion conversion-table-bank-right conversion-bank-percentage"
            >
              Percentage
            </th>
          </tr>
        </thead>
        <tbody>
          {props.bankData.map((bank, i) => (
            <tr key={i}>
              <td
                className="conversion-td conversion-bank-table-month "
                key={Math.random()}
              >
                {bank.months}
              </td>

              <td
                className="conversion-td conversion-table-bank"
                key={Math.random()}
              >
                {bank.bank_name.map((name) => (
                  <span key={Math.random()}>
                    {name} <br></br>
                  </span>
                ))}
              </td>

              <td
                className="conversion-td conversion-table-bank-right conversion-table-connections"
                key={Math.random()}
              >
                {bank.sum.map((sum) => (
                  <span key={Math.random()}>
                    {/* {sum}  */}
                    {numberWithSpaces(sum)}
                    <br></br>
                  </span>
                ))}
              </td>
              <td
                className="conversion-td conversion-table-bank-right conversion-bank-percentage"
                key={Math.random()}
              >
                {bank.percentage.map((percent) => (
                  <span key={Math.random()}>
                    {percent} <br></br>
                  </span>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BanksTable;
