import { useState } from "react"
import { useDispatch } from "react-redux";
import { geterrorLogs } from "../../../Redux/Actions/BankStatus";
import BankStatusHoverPopup from "./BankStatusHoverPopup";
import ErrorModal from "./BankStatusErrorModal";
import { filterError } from "../utilities/dataFormatting";

const BankBlob = ({ currentBlock, timeFrame }) => {
  const [visibleHover, setVisibleHover] = useState(false);
  const [serverErrors, setServerErrors] = useState();
  const [loadingError, setLoadingError] = useState(false);
  const [errorModalVisibility, setErrorModalVisibility] = useState(false);
  const dispatch = useDispatch();

  const toggleErrorModal = () => {
    setErrorModalVisibility(!errorModalVisibility);
  }
  const getErrors = async () => {
    if (!currentBlock) return;
    if (!serverErrors && currentBlock.organization_request_id.length > 0) {
      try {
        await setLoadingError(true);
        const res = await dispatch(geterrorLogs(currentBlock.organization_request_id, timeFrame));
        const logs = filterError(res.data.bank_logs)
        await setServerErrors(logs);
        await setLoadingError(false);

      } catch (error) {
        console.log(error);
      } finally {

      }

    }
  }

  const toggleVisibility = () => {
    setVisibleHover(!visibleHover);
  }
  return (
    <><BankStatusHoverPopup visible={visibleHover} data={currentBlock} errorMessages={serverErrors} loadingError={loadingError} />

      {/* <div className="bank-status-bank-label-current-status-container"> */}
      {errorModalVisibility && <ErrorModal blockData={currentBlock} errorLogs={serverErrors} toggleVisibility={toggleErrorModal} />}


      <div className={`bank-status-bank-label-current-status bank-status-bank-label-current-status-color-${currentBlock?.status}`}
        onClick={toggleErrorModal}
        onMouseOver={() => { toggleVisibility(); getErrors() }}
        onMouseLeave={toggleVisibility}
      ></div>
      {/* </div> */}
    </>
  );
}

export default BankBlob;