const PaginationBar = ({ nPages, currentPage, setCurrentPage }) => {
  const pageWindow = 2;

  let start = Math.max(currentPage - pageWindow, 1);
  let end = Math.min(currentPage + pageWindow, nPages);

  const showFirst = start > 1;
  const showLast = end < nPages;

  const pageNumbers = [];
  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <nav>
        <ul className="pagination justify-content-center paging-comparison">
          <li className="page-item" onClick={prevPage}>
            <button className="page-link">{"<"}</button>
          </li>

          {showFirst && (
            <>
              <li className="page-item" onClick={() => setCurrentPage(1)}>
                <button className="page-link">1</button>
              </li>
              {start > 2 && <li className="page-item">...</li>}
            </>
          )}

          {pageNumbers.map((pgNumber) => (
            <li
              key={pgNumber}
              className={`page-item ${currentPage === pgNumber ? "active" : ""}`}
              onClick={() => setCurrentPage(pgNumber)}
            >
              <button className="page-link">{pgNumber}</button>
            </li>
          ))}

          {showLast && (
            <>
              {end < nPages - 1 && <li className="page-item">...</li>}
              <li className="page-item" onClick={() => setCurrentPage(nPages)}>
                <button className="page-link">{nPages}</button>
              </li>
            </>
          )}

          <li className="page-item" onClick={nextPage}>
            <button className="page-link">{">"}</button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationBar;
