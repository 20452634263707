import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import { getTotalClientsData } from "../../Redux/Actions/TotalClients";
import ReactPaginate from 'react-paginate'

import "./totalClients.css"

const TotalClient = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [clientsData, setClientsData] = useState([]);
  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(0);
  const [groupedData, setGroupedData] = useState([])

  useEffect(() => {
    const calculateDuplicates = () => {
      const groupedDataArr = Object.values(clientsData.reduce((acc, obj) => {
        const parentId = obj.parent_organization_id;
        if (!acc[parentId]) {
          acc[parentId] = [];
        }
        acc[parentId].push(obj);
        return acc;
      }, {}));

      setGroupedData(groupedDataArr)
    };

    calculateDuplicates();
  }, [clientsData]);

  useEffect(() => {
    fetchClientsList();
  }, []);

  const fetchClientsList = async () => {
    await dispatch(setLoaderSpinner(true))
    const data = await dispatch(getTotalClientsData())
    await dispatch(setLoaderSpinner(false))
    if (data.status) {
      setClientsData(data.data)
    } else if (!data.status) {
      setClientsData([]);
      history.push('/login');
    }
  }

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;

    switch (selectedOption) {
      case 'Clients current week':
        history.push('/new_clients_week');
        break;
      case 'Clients current month':
        history.push('/new_clients_month');
        break;
      case 'Clients current year':
        history.push('/new_clients_year');
        break;
      default:
        break;
    }
  };

  return (
    <div className="page-container">
      <h3>Total clients</h3>
      <div className="select-container-clients">
        <select onChange={handleOptionChange} defaultValue="Total clients">
          <option value="Total clients">Total clients</option>
          <option value="Clients current week">Clients - current week</option>
          <option value="Clients current month">Clients - current month</option>
          <option value="Clients current year">Clients - current year</option>
        </select>
      </div >
      <div className="bg-white-organizations bg-white-org-list bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <table className="table total-clients-table" width="100%">
          <thead>
            <tr className="total-clients-table-head">
              <th>Id</th>
              <th>Organization</th>
              <th>Main account</th>
              <th>Contact person</th>
              <th>Total users</th>
            </tr>
          </thead>
        </table>
        {clientsData && clientsData.length > 0 ? (
          <>
            <div className="organizations-scroll-y" style={{ maxHeight: "400px", overflowY: "auto" }}>
              <table className="table" width="100%">
                <tbody className="total-clients-table-body">
                  {groupedData.map((data, index) => (
                    <React.Fragment key={index}>
                      {data.map(item => (
                        <tr key={item.id} className={data.length > 1 ? "light-grey-row" : ""}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.main_account ? item.main_account : '-'}</td>
                          <td>{item.company_contact}</td>
                          <td>{item.total_users}</td>
                        </tr>
                      ))}
                      <tr key={`hr-${index}`}>
                        <td className="td-line"></td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <p></p>
        )}
        <div className="pagination-box">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel={"..."}
            pageCount={Math.ceil(clientsData.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div >
  )
}
export default TotalClient;