import { SET_STATISTICS_LIST, SET_STATISTICS_FILTER } from '../Actions/Types/Types'

const initialState = {
  total_request_count: 0,
  accepted_request_count: 0,
  accepted_perday_count: {},
  total_perday_count: {},
  statistics_logs: [],
  statistics_response: {},
  suppliers: {},
  market: [],
  certificate_types: {},
  product_types: {},
  source_types: {},
  customers: []
}
const StatisticsData = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_STATISTICS_LIST:
      return {
        ...state,
        total_request_count: action.statistics_list.total_request_count,
        accepted_request_count: action.statistics_list.accepted_request_count,
        accepted_perday_count: action.statistics_list.accepted_perday_count,
        total_perday_count: action.statistics_list.total_perday_count,
        statistics_response: action.statistics_list,
        statistics_logs: action.statistics_list.statistics_logs,
      }
    case SET_STATISTICS_FILTER:
      return {
        ...state,
        suppliers: action.statistics_list.suppliers,
        market: action.statistics_list.market,
        certificate_types: action.statistics_list.certificate_types,
        product_types: action.statistics_list.product_types,
        source_types: action.statistics_list.source_types,
        customers: action.statistics_list.customers
      }
    default: return state;
  }
}

export default StatisticsData;
