import React, { useEffect, useState } from "react";
import NewFlash from '../FlashMessage/NewFlash';
import { getApiAccessCodes, sendApiAccessCode } from "../../Redux/Actions/ApiAccessCodes";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setdisplayFlash } from '../../Utils/Common';
import './accessCodes.css'
import menuArrow from '../../assets/images/icon_arrow_down.png';
import AccessCodesTable from "./AccessCodesTable";
import GenerateApiAccessCode from "./GenerateApiAccessCode";
import APIDocumentationPopup from "./APIDocumentationPopup/APIDocumentationPopup";

const initialAccessCode = { email: '', code: '' };
const initialDetails = { company_name: '', receiver_name: '', details: '' }

const AccessCodes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [newAccessCode, setNewAccessCode] = useState("");
  const [accessCodeData, setAccessCodeData] = useState(initialAccessCode)
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })
  const [isDisabled, setIsDisabled] = useState(true); //button disable
  const [show, setShow] = useState(false);
  const [showApiDoc, setShowApiDoc] = useState(true);
  const [activeArrow, setActiveArrow] = useState(true);
  const [accessCodes, setAccessCodes] = useState();
  const [updateTable, setUpdateTable] = useState(false);
  const [apiDocumentationPopup, setApiDocumentationPopup] = useState(false)
  const [userDetails, setUserDetails] = useState(initialDetails)

  useEffect(() => {
    setUpdateTable(false)
    fetchAccessCodes()
  }, [updateTable]);

  const fetchAccessCodes = async () => {
    const accessData = await dispatch(getApiAccessCodes())
    if (accessData.status) {
      setAccessCodes(accessData.data)
    }
  }

  const closeFlash = () => {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  const generator = require('generate-password');

  const generateNewAccessCode = () => {
    setNewAccessCode(generator.generate({
      length: 12,
      numbers: true,
      lowercase: false
    }))
    setIsDisabled(false)

  }

  const handleChange = (e) => {
    setAccessCodeData((prevState) => ({ //setFormValues
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }


  const handleShow = () => {
    show ? setShow(false) : setShow(true);
  }

  const showVistaApiDoc = () => {
    setShowApiDoc(true);
    setShow(false);
  }

  const toggleMenuArrow = () => {
    if (activeArrow) {
      const arrowIcon = document.querySelector(".menu-icon");
      arrowIcon.style.transform = "rotate(-180deg) scaleX(-1)";
      setActiveArrow(!activeArrow);
    } else {
      const arrowIcon = document.querySelector(".menu-icon");
      arrowIcon.style.transform = "none";
    }
    setActiveArrow(!activeArrow);
  };

  const sendAccessCode = async () => {
    setApiDocumentationPopup(false)
    let data = { receiver_email: accessCodeData.email, access_code: newAccessCode, company_name: userDetails.company_name, receiver_name: userDetails.receiver_name, details: userDetails.details }
    const sendData = await dispatch(sendApiAccessCode(data));
    if (sendData.status) {
      setIsDisabled(true)
      setAccessCodeData((prevState) => ({
        ...prevState,
        email: ""
      }))
      setNewAccessCode("")
      setUpdateTable(true);
      setFlashMessage(true);
      setFlashData(prevState => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: sendData.status,
        secondMsg: sendData.message
      }))
    }
    else {
      if (sendData.message === "Unauthorize") {
        history.push("/login")
      } else {
        setFlashMessage(true);
        setFlashData(prevState => ({
          ...prevState,
          iconImage: "",
          firstMsg: "Error",
          secondMsg: sendData.message
        }))
      }
    }

  }

  const openPopupHandler = () => {
    setApiDocumentationPopup(true)
  }

  const togglePopup = () => {
    setApiDocumentationPopup(false)
    setUserDetails({ company_name: '', receiver_name: '', details: '' })
  }

  const detailsHandler = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const btnEnableHandler = () => (userDetails?.company_name?.length > 0) && (userDetails?.receiver_name?.length > 0) && (userDetails?.details?.length > 0) ? true : false;

  return (
    <div className="demo-aggregation-container">
      {apiDocumentationPopup &&
        <APIDocumentationPopup
          detailsHandler={detailsHandler}
          btnEnableHandler={btnEnableHandler}
          accessCodeData={accessCodeData}
          sendAccessCode={sendAccessCode}
          togglePopup={togglePopup}
        />
      }

      <div className="lower-header-access-codes">
        {showApiDoc ? (
          <h3>Vista - API Documentation</h3>
        ) : (<h3>Demo Aggregation</h3>)}
      </div>
      <div className="access-codes-container">
        {flashMessage &&
          <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
            <NewFlash
              data={flashData}
              closeFlash={() => closeFlash()}
            />
          </div>
        }
        <div className="demo-wrapper">
          <GenerateApiAccessCode
            accessCodeData={accessCodeData}
            handleChange={handleChange}
            isDisabled={isDisabled}
            newAccessCode={newAccessCode}
            generateNewAccessCode={generateNewAccessCode}
            openPopupHandler={openPopupHandler}
          />
          <AccessCodesTable accessCodes={accessCodes} />
        </div>
      </div >
    </div >
  )
}

export default AccessCodes;