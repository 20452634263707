import {
  SET_CONVERSION_RATE_LIST,
  SET_CONVERSION_RATE_FILTER,
} from "../Actions/Types/Types";

const initialState = {
  total_requests: 0,
  accepted_requests: 0,
  bank_data: 0,
  market: [],
  customers: [],
  certificate_types: {},
  source_types: {},
  suppliers: {},
};
const ConversionRateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CONVERSION_RATE_LIST:
      return {
        ...state,
        total_requests: action.conversion_rate.total_requests,
        accepted_requests: action.conversion_rate.accepted_requests,
      };
    case SET_CONVERSION_RATE_FILTER:
      return {
        ...state,
        market: action.conversion_rate.market,
        customers: action.conversion_rate.customers,
        certificate_types: action.conversion_rate.certificate_types,
        source_types: action.conversion_rate.source_types,
        suppliers: action.conversion_rate.suppliers,
      };
    default:
      return state;
  }
};

export default ConversionRateReducer;
