import OptionTabTooltip from "../OptionTab/OptionTabTooltip";

const ToggleField = ({ keys, checked, toggleFunction, capitalizeFirstLetter, enable_back_button, showIcon, description }) => {
  return (
    <h3 className="headeing-switch-light">
      <div className="keys-and-icon">
        {capitalizeFirstLetter(keys)}
        {showIcon && (<OptionTabTooltip showIcon={showIcon} description={description} />)}
      </div>
      <div className="adjust-switcher">
        <div className="select-switch">
          <label className="switch-box">
            <input type="checkbox" name={keys} checked={checked} onChange={(e) => toggleFunction(e, keys, enable_back_button)} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </h3>
  )
}

export default ToggleField;