import axios from 'axios';
import { API_CONFIG } from "../../config/api";

export function getBanksData(query) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANK_STATUS.LIST()}`;
    return (dispatch) =>
        axios
            .get(apiURL, query)
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}

export function getBanksDataFromArray(array, query, time_frame) {
    const params = `country_id=${query.country_id}&time_frame=${time_frame}&${array.map((n) => `identifier[]=${n}`).join('&')}`
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANK_STATUS.LIST()}?${params}`;
    return (dispatch) =>
        axios
            .get(apiURL)
            .then((res) => {
                console.log(res.data.data);
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}
export function getRequestsFromBanks(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANK_STATUS.LIST()}/get_requests`;
    return (dispatch) =>
        axios
            .get(apiURL, data)
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}
export function geterrorLogs(array, time_frame) {
    const params = "time_frame=" + time_frame + "&" + array.map((n, index) => `organization_request_id[]=${n}`).join('&');
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BANK_STATUS.LIST()}/get_error_logs?${params}`;
    return (dispatch) =>
        axios
            .get(apiURL)
            .then((res) => {
                return { status: true, message: res.data.status, data: res.data.data };
            })
            .catch((err) => {
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}