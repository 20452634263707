import React from 'react';
import Select from 'react-select';

const CustomReactSelect = ({ orgList, onChange, value, placeholder, disabled, tooltipText }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: disabled ? '#f2f2f2' : provided.backgroundColor,
      pointerEvents: disabled ? 'none' : 'auto',
      borderColor: disabled ? '#ccc' : provided.borderColor,
    }),
    option: (provided, state) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#white' : 'white',
      color: state.isSelected ? 'white' : 'white',
      ':hover': {
        backgroundColor: '#2e70d0',
        color: 'white',
      },
    }),
  };

  const options = orgList.map(org => ({
    value: org.value,
    label: org.label,
  }));

  return (
    <div className={`custom-select-wrapper ${disabled ? 'disabled' : ''}`} style={{ position: 'relative' }}>
      <Select
        className="react-select-custom-org"
        classNamePrefix="react-select"
        options={options}
        styles={customStyles}
        onChange={(selected) => onChange(selected ? selected.value : null)}
        value={options.find(option => option.value === value)}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      <span className="custom-tooltip">
        {tooltipText}
      </span>
    </div>
  );
};

export default CustomReactSelect;
