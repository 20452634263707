import React, { useState } from 'react';
import '../EditModal/EditModal.css'

const CertificateSearchModal = (props) => {
  const [btnEnabled, setBtnEnabled] = useState(false)
  const [selectedCertificateId, setSelectedCertificateId] = useState()

  const changeHandler = (e, id) => {
    if (selectedCertificateId === id) {
      setSelectedCertificateId(null)
      setBtnEnabled(false)
    } else {
      setSelectedCertificateId(id)
      setBtnEnabled(true)
    }
  }

  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner certificate-modal'>
        <h5>Matching certificates found!</h5>
        <p className='secondText-update'><span>{props.matchedData.length} {" "}</span>{`certificates found with the searched ${props.clientReference ? "client reference id" : "partial client reference id"} `}</p>
        <div className='certificatesFound'>
          {
            props.matchedData.map(cert => <div className='firstText-update' key={cert.certificate_id}>
              <input onChange={(e) => changeHandler(e, cert.certificate_id)} type="checkbox" checked={selectedCertificateId === cert.certificate_id} />
              <label>{cert.certificate_id} - {cert.status} - {cert.env} - {cert.date.split("T")[0]} - {cert.type} - {cert.organization_name} - {cert.organization_id} - <strong>{cert.client_reference_id}</strong></label>
            </div>)
          }
        </div>
        <br />
        <div>
        </div>
        <div className="text-center">
          <button className='closeDelete-update' style={{ marginLeft: '0px' }} onClick={props.closeDelete}>Cancel</button>
          <button className={btnEnabled ? "confirm-update" : "open_submit_disabled confirm-update"} onClick={() => {
            props.confirm(selectedCertificateId)
            setBtnEnabled(false)
          }}>Open</button>
        </div>
      </div>
    </div>
  )
}
export default CertificateSearchModal;
