import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { getDynamicLiveData } from "../../../Redux/Actions/DynamicLiveData";
import AcceptedRequestsGraph from "./AcceptedRequestsGraph";
import AcceptedRequestsTrendGraph from "./AcceptedRequestsTrendGraph";

const AcceptedRequests = () => {

  const [previousYearRequests, setPreviousYearRequests] = useState([]);
  const [previousYear, setPreviousYear] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [currentYearRequests, setCurrentYearRequests] = useState([]);
  const [showTrend, setShowTrend] = useState();
  const [graphType, setGraphType] = useState("column");
  const dispatch = useDispatch();
  const history = useHistory();

  const d = new Date();
  d.setFullYear(d.getFullYear() - 1);
  let prevYear = d.toString();

  const today = new Date()
  const date = today.getDate()
  const month = today.toLocaleString('default', { month: 'short' });
  const shortYear = currentYear.toString().slice(2);

  let indexWithoutValues = [];
  Object.values(currentYearRequests).forEach((value, index) => {
    if (value === 0) {
      indexWithoutValues.push(index)
    }
  })
  const slicedNumber = 12 - indexWithoutValues.length;

  let dateEnding = "";
  if (date === 1 || date === 21 || date === 31) {
    dateEnding = "st"
  }
  else if (date === 2 || date === 22) {
    dateEnding = "nd"
  }
  else if (date === 23 || date === 3) {
    dateEnding = "rd"
  }
  else {
    dateEnding = "th"
  }

  const year = new Date(prevYear).getFullYear();

  useEffect(() => {
    fetchAcceptedRequestsData()
  }, []);

  const fetchAcceptedRequestsData = async () => {
    const params = "?year=" + year;

    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getDynamicLiveData(params));
    const { comparing_year, current_year } = data;
    setPreviousYearRequests(comparing_year.accepted_requests);
    setPreviousYear(comparing_year.year)
    setCurrentYearRequests(current_year.accepted_requests);
    setCurrentYear(current_year.year)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };
  const handleShowTrend = (() => {
    setShowTrend(!showTrend)
  })

  return (
    <div className="statistics-container">
      <div className="lower-header">
        <h3>Accepted Requests</h3>
      </div>
      <h5>Overall - Accepted Requests (Monthly Comparision 1st Jan - {date + dateEnding} {month} {previousYear}-{shortYear})</h5>
      <section className="accepted-requests-section">
        {Object.keys(previousYearRequests).length > 0 && !showTrend ? <AcceptedRequestsGraph previousYearRequests={previousYearRequests} previousYear={previousYear} currentYearRequests={currentYearRequests} currentYear={currentYear} slicedNumber={slicedNumber} graphType={graphType} />
          : <AcceptedRequestsTrendGraph previousYearRequests={previousYearRequests} previousYear={previousYear} currentYearRequests={currentYearRequests} currentYear={currentYear} graphType={graphType} slicedNumber={slicedNumber} />}
      </section>
      <div className="graph-btns">
        <div>
          <span>
            <button onClick={() => setGraphType("column")} className={graphType === "column" ? 'actve-graph-type graph-btn-first graph-btn' : 'graph-btn-first graph-btn'}>
              Column
            </button>
            <button onClick={() => setGraphType("spline")} className={graphType === "spline" ? 'actve-graph-type graph-btn graph-btn-middle' : 'graph-btn graph-btn-middle'}>
              Line
            </button>
            <button onClick={() => setGraphType("bar")} className={graphType === "bar" ? 'actve-graph-type graph-btn graph-btn-last' : 'graph-btn graph-btn-last'}>
              Bar
            </button>
          </span>
          <button onClick={handleShowTrend} className="trend-btn">
            {showTrend ? <>Hide trend</> : <>Show trend</>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AcceptedRequests;