import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import "./createOrganization.scss";
import arrow from '../../assets/images/greater_than2x.png';

import { createOrganization, getOrganizationAccountList } from '../../Redux/Actions/OrganizationManagement';
import NewFlash from '../FlashMessage/NewFlash';
// LIBRARIES
import { useHistory } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';

import { setdisplayFlash, getdisplayFlash } from '../../Utils/Common';
import FlashMessage from '../../Components/FlashMessage/FlashMessage';

import { getAllCountry } from "../../Redux/Actions/Countries";

const initialClientDetails = {
  bank_connection: 'default',
  billing_email: '',
  company_contact: '',
  corporate_enabled: false,
  corporate_market_ids: [],
  corporate_overview: false,
  country_id: 1,
  customer_enabled: false,
  customized_response: false,
  customized_result_view: false,
  customized_view: false,
  email: '',
  email_template_enabled: false,
  end_user_display_name: '',
  epost_for_request_response: '',
  first_name: '',
  homepage_address: '',
  last_name: '',
  market_ids: [],
  name: '',
  organization_number: '',
  organization_type: '',
  partial_name: '',
  phone: '',
  sms_notification: false,
  sms_reminder: false,
  sms_sender_name: '',
  sms_template_enabled: false,
  password: '',
  terms: '1',
  language: [],
  phone_country_id: '+46',
  contact_email: '',
  contact_phone: '',
  account_type: 'main_account',
  parent_organization_id: null,
  sso_enabled:false
}


const CreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [clientDetails, setClientDetails] = useState(initialClientDetails)
  // FLASH MESSAGE STATE
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })

  const [addFlashMessage, setAddFlashMessage] = useState(getdisplayFlash());
  const [addFlashData, setAddFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })

  const [orgAccountList, setOrgAccountList] = useState([])

  const [countries, setCountries] = useState([]);

  const fetchOrgAccountTypes = async () => {
    const response = await dispatch(getOrganizationAccountList())
    if (response && response.data.organizations_list) {
      setOrgAccountList(response.data.organizations_list)
    }
    let orgArr = response.data.organizations_list
    orgArr.sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
    });
    setOrgAccountList(orgArr)
  }

  useEffect(() => {
    fetchOrgAccountTypes()
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    const { data } = await dispatch(getAllCountry());
    const { countries_list } = data;
    setCountries(countries_list);
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    setClientDetails(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const selectCountryHandler = (event) => {
    setClientDetails(prevState => ({
      ...prevState,
      country_id: event.target.value
    }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    await dispatch(setLoaderSpinner(true))
    let trimedKeys = clientDetails;
    let keysToTrim = ['email', 'company_contact', 'organization_number', 'name'];

    keysToTrim.forEach(key => {
      if (trimedKeys.hasOwnProperty(key) && typeof trimedKeys[key] === 'string') {
        trimedKeys[key] = trimedKeys[key].trim();
      }
    });

    const response = await dispatch(createOrganization(trimedKeys))
    if (response.status) {
      await dispatch(setLoaderSpinner(false))
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        openFlash("tick", "Done!", response.message)
        setTimeout(() => history.push('/organizations'), 1000)
      }
    } else {
      await dispatch(setLoaderSpinner(false))
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        setFlashMessage(true);
        setFlashData(prevState => ({
          ...prevState,
          iconImage: "",
          firstMsg: "Error",
          secondMsg: response.message
        }))
      }
    }
  }

  const closeFlash = () => {
    setFlashMessage(false);
  }

  function closeAddFlash() {
    setdisplayFlash(false);
    setAddFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setAddFlashMessage(true);
    setAddFlashData(prevState => ({
      ...prevState,
      iconImage: iconImage,
      firstMsg: firstMsg,
      secondMsg: secondMsg
    }))
  }
  return (
    <div className="main-body">
      <div className="main-section">
        <div className={`alert-popup alert-success-bar ${addFlashMessage ? setTimeout(() => setAddFlashMessage(false), 2000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
          <FlashMessage
            iconImage={addFlashData.iconImage}
            firstMsg={addFlashData.firstMsg}
            secondMsg={addFlashData.secondMsg}
            closeFlash={() => closeAddFlash()}
          />
        </div>

        {flashMessage &&
          <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
            <NewFlash
              data={flashData}
              closeFlash={() => closeFlash()}
            />
          </div>
        }
        <h3 className="basic-head01">
          <div className="add-breadcrum">Create New Client<p><a href="/organizations">Organization Management</a> <img src={arrow} alt="arrow" />Create New Client</p></div>
        </h3>
        <form onSubmit={submitHandle}>
          <div className="bg-white-org bg-20-top-zero">
            <h3 className="h3-basic-heading-create">Client details</h3>
            <div className="row create-client-table">
              <div className="col-md-3 form-group">
                <label>Company Name</label>
                <input type="text" className="form-control" placeholder="Company name" name="name" value={clientDetails.name} onChange={(e) => handleChange(e)} required />
              </div>
              <div className="col-md-3 form-group">
                <label>Organization Number</label>
                <input type="text" className="form-control" placeholder="Organization number" name="organization_number" value={clientDetails.organization_number} onChange={(e) => handleChange(e)} />
              </div>
              <div className="col-md-3 form-group">
                <label>Choose Country</label>
                <select className="form-control org-excludeDropdown" id="choose_country" value={clientDetails.country_id} onChange={(e) => selectCountryHandler(e)}>
                  <option value="">Select</option>
                  {
                    countries && countries.map((_country, index) => (
                      <option value={_country.id}
                        key={`country-option-${index + 1}`}>
                        {_country.name}
                      </option>
                    ))
                  }
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label>Contact Person First and Last Name</label>
                <input type="text" className="form-control" placeholder="Enter first and last name" name="company_contact" value={clientDetails.company_contact} onChange={(e) => handleChange(e)} />
              </div>
              <div className="col-md-3 form-group">
                <label>End-user Display Name</label>
                <input type="text" className="form-control" placeholder="End-user display name" name="end_user_display_name" value={clientDetails.end_user_display_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="col-md-3 form-group">
                <label>Homepage Address</label>
                <input type="text" className="form-control" placeholder="Homepage address" name="homepage_address" value={clientDetails.homepage_address} onChange={(e) => handleChange(e)} />
              </div>
              <div className="col-md-3 form-group">
                <label>Choose Access</label>
                <select className="form-control org-excludeDropdown" id="organization_type" name='organization_type' value={clientDetails.organization_type} onChange={(e) => handleChange(e)}>
                  <option value=''>Select Organization Type</option>
                  <option value='client' >Portal Only</option>
                  <option value='dev'>Portal+API</option>
                  <option value='api'>API only</option>
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label>Contact Person Email</label>
                <input type="text" className="form-control" name='email' placeholder="Contact person email" value={clientDetails.email} onChange={(e) => handleChange(e)} required />
              </div>
              <div className="col-md-3 form-group">
                <label>SMS Name</label>
                <input type="text" className="form-control" placeholder="SMS name" name="sms_sender_name" value={clientDetails.sms_sender_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="col-md-3 form-group">
                <label>Contact Person Phone Number</label>
                <div className="d-flex">
                  <div className="d-flex-select">
                    <select className="form-control org-excludeDropdown" id="phone_number" value={clientDetails.phone_country_id} name="phone_country_id" onChange={(e) => handleChange(e)}>
                      {countries.map((_country, index) =>
                        <option
                          className='admin-option'
                          key={`admin - option - ${index + 1}`}
                          value={_country.id}>
                          {_country.phone_country_code}
                        </option>
                      )};
                    </select>
                  </div>
                  <input type="number" className="form-control country-input" placeholder="Contact Person Phone" name='phone' value={clientDetails.phone} onChange={(e) => handleChange(e)} />
                </div>
              </div>
              <div className="col-md-3 form-group">
                <label>Customized Responses</label>
                <select className="form-control org-excludeDropdown" id="customized_response" name='customized_response' value={clientDetails.customized_response} onChange={(e) => handleChange(e)}>
                  <option value='false'>Raw Data</option>
                  <option value='true'>Certificate Data</option>
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label>Password</label>
                <input type="password" className="form-control" placeholder="**********" name="password" value={clientDetails.password} onChange={(e) => handleChange(e)} autoComplete="off" />
              </div>
              <div className="col-md-3 form-group">
                <label>Contact email for end user-Emails</label>
                <input type="email" className="form-control" placeholder="Email address" name="contact_email" value={clientDetails.contact_email} onChange={(e) => handleChange(e)} />
              </div>
              <div className="col-md-3 form-group">
                <label>Contact phone number for end user-Emails</label>
                <input type="number" className="form-control" placeholder="Phone number" name="contact_phone" value={clientDetails.contact_phone} onChange={(e) => handleChange(e)} />
              </div>
              <div className="col-md-3 form-group">
                <label>Account Type</label>
                <select className="form-control org-excludeDropdown" id="account_type" name='account_type' value={clientDetails.account_type} onChange={(e) => handleChange(e)}>
                  <option value='main_account'>Main account</option>
                  <option value='sub_account'>Sub-Account / Brand</option>
                  <option value='partner'>Partner</option>
                </select>
              </div>
              {
                clientDetails.account_type && clientDetails.account_type !== 'main_account' && (
                  <div className="col-md-3 form-group">
                    <label>Select Main Account</label>
                    <select className="form-control org-excludeDropdown" id="sel1" name='parent_organization_id' value={clientDetails.parent_organization_id} onChange={(e) => handleChange(e)}>
                      {orgAccountList && orgAccountList.map((orgTemp, index) =>
                        <option
                          className='admin-option'
                          key={`admin - option - ${index + 1}`}
                          value={orgTemp.id}>
                          {orgTemp.name}
                        </option>
                      )}
                    </select>
                  </div>
                )
              }
            </div>
            <div className="create-client-button">
              <button type="submit">Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateOrganization;

