import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./profileView.scss";
import email from '../../assets/images/email_icon.svg';
import password from '../../assets/images/password_icon.svg';
import userIcon from '../../assets/images/user_icon.svg';
import arrowRight from '../../assets/images/icon_arrow_right.png';
import FlashMessage from '../FlashMessage/FlashMessage';
import { getdisplayFlash, setdisplayFlash } from '../../Utils/Common';
import phone from '../../assets/images/icon_phone_profile.svg';
import {
	getusermanagementList
} from "../../Redux/Actions/UserManagement";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";

const ProfileView = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
	const [iconImage, setIconImage] = useState("tick")
	const [firstMsg, setFirstMsg] = useState("Done!")
	const [secondMsg, setSecondMsg] = useState("Password changed successfully")
	const [currUser, setCurrUser] = useState({ email: "", name: "", phone_number: "", phone_country_code: "" });

	useEffect(() => {
		fetchusermanagementList();
	}, []);

	const fetchusermanagementList = async (typeSearch) => {
		let query = "?without_pagination=" + true;
		await dispatch(setLoaderSpinner(true))
		const data = await dispatch(getusermanagementList(query));
		if (data.status) {
			let user = data.data.users.filter((x) => x.id === Number(window.localStorage.getItem('id')))
			window.localStorage.setItem('email', user[0].email)
			window.localStorage.setItem('name', user[0].name)
			window.localStorage.setItem('phone_country_code', user[0].phone_country_code)
			window.localStorage.setItem('phone_number', user[0].phone_number)
			setCurrUser({
				email: user[0].email,
				name: user[0].name,
				phone_country_code: user[0].phone_country_code,
				phone_number: user[0].phone_number
			})
		} else {
			history.push("/login");
		}
		await dispatch(setLoaderSpinner(false))
	};

	const closeFlash = () => {
		setdisplayFlash(false);
		setFlashMessage(false);
	}
	const openFlash = () => {
		setdisplayFlash(true);
		setFlashMessage(true);
		setIconImage(iconImage);
		setFirstMsg(firstMsg);
		setSecondMsg(secondMsg);
	}

	return (
		<div className="container_fluid col-9">
			<div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
				<FlashMessage
					iconImage={iconImage}
					firstMsg={firstMsg}
					secondMsg={secondMsg}
					openFlash={() => openFlash()}
					closeFlash={() => closeFlash()}
				/>
			</div>
			<h3 className="basic-head">Profile</h3>
			<div className="bg-white bg-20-top">
				<ul className="name-value-list">
					<li>
						<img src={userIcon} alt="userIcon"></img>
						<div className="name-value">
							<label>Name:</label>
							<p>{currUser.name}</p>
						</div>
					</li>
					<li>
						<img src={email} alt="email"></img>
						<div className="name-value">
							<label>Email:</label>
							<p>{currUser.email}</p>
						</div>
					</li>
					<li>
						<img src={phone} alt="email"></img>
						<div className="name-value">
							<label>Phone:</label>
							<p>{currUser.phone_number != null && currUser.phone_country_code != null ? currUser.phone_country_code + currUser.phone_number : ''}</p>
						</div>
					</li>
					<li>
						<img src={password} alt="password"></img>
						<span
							onClick={() => history.push('/update_password')}
							className="change-pass"
							style={{ cursor: 'pointer' }}>
							Change Password
							<img
								src={arrowRight}
								alt="arrowRight"
							/>
						</span>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default ProfileView;