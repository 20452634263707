const ApiAccessCode = (props) => {
  const adminLog = props.adminLog;
  let id = adminLog.object_id;
  let companyName = adminLog.column_affected.api_access_code.company_name;
  let email = adminLog.column_affected.api_access_code.email;
  let details = adminLog.column_affected.api_access_code.details;
  let receiverName = adminLog.column_affected.api_access_code.receiver_name;
  return (
    <div
      key={Math.random()}
    >
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>API Documentation Access Codes</strong>
      </p>
      <p className="al-column-affected-p">
        Receiver email: {" " + email}<br />
        Company name: {" " + companyName}<br />
        Receiver name: {" " + receiverName}<br />
        Details: {" " + details}
      </p>
    </div>
  )
}
export default ApiAccessCode;