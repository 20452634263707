import React from 'react'
import { useEffect } from 'react';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import HealthcheckEdit from '../../Components/Healthcheck/HealthcheckEdit';


const HealthcheckPage = () => {

  useEffect(() => {
    document.title = "Kreditz | Admin portal - Healthcheck";
  }, []);

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="main-container">
          <Sidebar />
          <HealthcheckEdit />
        </div>
      </div>
    </>
  )
}

export default HealthcheckPage