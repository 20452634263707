import './OrganizationNotificationEmails.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import NotificationEmailTable from './misc/NotificationEmailTable';
import ReactSelect from 'react-select';
import { getOrganizationListCertificates } from "../../Redux/Actions/ItMenu";
import { getEmailList } from "../../Redux/Actions/EmailList";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import ReactPaginate from 'react-paginate';
import search from "../../assets/images/search.svg";
import close from "../../assets/images/icon_close.png";

const OrganizationNotificationEmails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [organizationsList, setOrganizationsList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [inputDisplayValue, setInputDisplayValue] = useState('');
  const [emailLogsList, setEmailLogsList] = useState([]);
  const [filteredEmailLogsList, setFilteredEmailLogsList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedEmailType, setSelectedEmailType] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [pageData, setPageData] = useState({
    current_page: 1,
    total_pages: "",
    total_count: "",
    next_page: "",
    prev_page: "",
  });
  const [dateRange, setDateRange] = useState({
    startDate: moment(),
    endDate: moment()
  });
  const [searchIcon, setSearchIcon] = useState(search);

  const fetchOrganizationList = async () => {
    try {
      const response = await dispatch(getOrganizationListCertificates());
      if (response.status) {
        setOrganizationsList(response.result.data.organizations_list);
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch organizations', error);
    }
  };

  const fetchEmailLogs = useCallback(async () => {
    await dispatch(setLoaderSpinner(true));
  
    const dateRangeParams = (dateRange.startDate && dateRange.endDate) ? `email_date_range=${dateRange.startDate.format('DD/MM/YYYY')}-${dateRange.endDate.format('DD/MM/YYYY')}` : '';
    const statusParams = selectedStatus ? `status=${selectedStatus}` : '';
    const organizationParams = selectedOrgId ? `organization_id=${selectedOrgId}` : '';
    const emailTypeParams = selectedEmailType ? `email_type=${selectedEmailType}` : '';
    const pageParams = selectedPage ? `page=${selectedPage}` : '';
    const queryParams = searchQuery ? `query=${searchQuery}` : '';
  
    const params = `${dateRangeParams}&${statusParams}&${organizationParams}&${emailTypeParams}&${pageParams}&${queryParams}`;
  
    const { status, message, data } = await dispatch(getEmailList(`?${params}`));
    if (status) {
      setEmailLogsList(data.email_logs.length > 0 ? data.email_logs : []);
      setFilteredEmailLogsList(data.email_logs.length > 0 ? data.email_logs : []);
      setStatusList(data.status.length > 0 ? data.status : []);
      data.pagination_data && setPageData(data.pagination_data);
      await dispatch(setLoaderSpinner(false));
    } else {
      await dispatch(setLoaderSpinner(false));
      if (message === "Unauthorized") {
        history.push("/login");
      }
    }
  }, [dispatch, dateRange, selectedStatus, selectedOrgId, selectedPage, searchQuery, selectedEmailType, history]);
  
  useEffect(() => {
    fetchOrganizationList();
    const today = moment().format('YYYY/MM/DD');
    setInputDisplayValue(`${today} - ${today}`);
  }, [dispatch, history]);

  useEffect(() => {
    fetchEmailLogs();
  }, [dateRange, selectedStatus, selectedOrgId, selectedPage, triggerSearch, selectedEmailType]);

  useEffect(() => {
    const filteredLogs = selectedEmailType
      ? emailLogsList.filter(log => log.email_type === selectedEmailType)
      : emailLogsList;
    setFilteredEmailLogsList(filteredLogs);
  }, [selectedEmailType, emailLogsList]);  

  const handleOrgChange = (selectedOption) => {
    if (selectedOption) {
      const selectedOrgId = selectedOption.value.toString();
      const selectedOrganization = organizationsList.find(org => org.id.toString() === selectedOrgId);

      if (selectedOrganization) {
        setSelectedOrg(selectedOrganization.name);
        setSelectedOrgId(selectedOrgId);
        document.title = `Emails - ${selectedOrganization.name}`;
        setSelectedPage(1);
      } else {
        setSelectedOrg('');
        setSelectedOrgId('');
      }
    } else {
      setSelectedOrg('');
      setSelectedOrgId('');
      document.title = "Emails";
    }
  };

  const handleEmailTypeChange = (selectedOption) => {
    setSelectedEmailType(selectedOption ? selectedOption.value : '');
    setSelectedPage(1);
  };

  const handlePageClick = (data) => {
    const selectedPageIndex = data.selected;
    const nextPage = selectedPageIndex + 1;
    setSelectedPage(nextPage);
  };

  const orgOptions = organizationsList.map(org => ({
    value: org.id,
    label: `${org.name}: ${org.id}`
  }));

  const emailTypes = [...new Set(emailLogsList.map(email => email.email_type))].map(type => ({
    value: type,
    label: type.charAt(0).toUpperCase() + type.slice(1)
  }));

  const setDateRangeSafely = (startDate, endDate) => {
    setDateRange({
      startDate: moment(startDate, 'YYYY/MM/DD'),
      endDate: moment(endDate, 'YYYY/MM/DD')
    });
  };

  const handleDateRangeChange = (event, picker) => {
    const startDate = picker.startDate.format('YYYY/MM/DD');
    const endDate = picker.endDate.format('YYYY/MM/DD');

    setInputDisplayValue(`${startDate} - ${endDate}`);
    setDateRangeSafely(startDate, endDate);
    setSelectedPage(1);
  };

  const resetDateRange = () => {
    const today = moment();
    const formattedToday = today.format('YYYY/MM/DD');

    setDateRange({ startDate: today, endDate: today });
    setInputDisplayValue(`${formattedToday} - ${formattedToday}`);
    setSelectedPage(1);
  };

  const showResetIcon = () => {
    const today = moment().format('YYYY/MM/DD');
    return dateRange.startDate && dateRange.endDate && (
      today !== moment(dateRange.startDate).format('YYYY/MM/DD') ||
      today !== moment(dateRange.endDate).format('YYYY/MM/DD')
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setSearchIcon(e.target.value ? close : search);
  };

  const handleSearchSubmit = () => {
    setSelectedPage(1);
    setTriggerSearch(prev => !prev);
  };

  const handleSearchClear = () => {
    setSearchQuery('');
    setSearchIcon(search);
  };

  const columns = [
    { Header: 'Email', accessor: 'email_type' },
    { Header: 'Subject', accessor: 'subject' },
    { Header: 'Email', accessor: 'email_to' },
    { Header: 'Sent date', accessor: 'email_sent_at' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Error message', accessor: 'error_message' },
  ];

  return (
    <div className="notification-emails-container">
      <div className="ld-container">
        <h3 className="ld-header-notification-emails">Organizations - Notification emails</h3>
      </div>
      <div className="notification-emails-table-top bg-white">
        <div className="notification-emails-flex-header customer-activity-header">
          <div className='notification-emails-name'>
            <p className='notification-emails-text-header'>
              Emails - {selectedOrg}
            </p>
          </div>
        </div>
        <div className='notification-emails-under-header'>
          <div className='notification-emails-selects-under-header'>
            <ReactSelect
              value={orgOptions.find(option => option.value.toString() === selectedOrgId)}
              onChange={handleOrgChange}
              options={orgOptions}
              placeholder="Select organization"
              isClearable={true}
              noOptionsMessage={() => "No organizations found"}
              className='notification-emails-select-org-dropdown'
            />
            <ReactSelect
              value={emailTypes.find(option => option.value === selectedEmailType)}
              onChange={handleEmailTypeChange}
              options={emailTypes}
              placeholder="Select email type"
              isClearable={true}
              noOptionsMessage={() => "No types found"}
              className='notification-emails-select-org-dropdown'
            />
            <div className="notification-emails-search-container">
              <input
                className="notification-emails-search-input"
                name="search"
                type="text"
                placeholder="Search email address"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearchSubmit();
                  }
                }}
              />
              <img
                className={searchQuery ? "notification-emails-search-close-icon" : "notification-emails-search-icon"}
                src={searchIcon}
                alt="Search Icon"
                onClick={searchQuery ? handleSearchClear : handleSearchSubmit}
              />
            </div>
          </div>
          <div className="notification-emails-date-picker-wrapper">
            <DateRangePicker 
              onApply={handleDateRangeChange} 
              startDate={dateRange.startDate.format('YYYY/MM/DD')}
              endDate={dateRange.endDate.format('YYYY/MM/DD')}
              initialSettings={{
                startDate: dateRange.startDate.toDate(),
                endDate: dateRange.endDate.toDate(),
                locale: { format: 'YYYY/MM/DD' },
              }}>
              <input
                type="text"
                className="date-form-box"
                id="date-range-picker"
                value={inputDisplayValue}
                readOnly
                style={{ cursor: "pointer" }}
              />
            </DateRangePicker>
            {showResetIcon() && (
              <button className="notification-emails-reset-date-button">
                <FontAwesomeIcon onClick={resetDateRange} icon={faTimes} />
              </button>
            )}
            <div className="notification-emails-customer-activity-icon">
              <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
            </div>
          </div>
        </div>
        <div className="broker-flow-thead-scroll-y">
          <NotificationEmailTable columns={columns} data={filteredEmailLogsList} />
        </div>
        <div className="notification-emails-pagination-box">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel={'...'}
            pageCount={pageData.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={selectedPage - 1}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationNotificationEmails;
