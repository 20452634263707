import demoIcon from '../../../assets/images/demoaggregation_icon.svg'
const DemoAccessForm = ({ generateNewAccessCode, generateCode, accessCodeData, handleChange, handleChangeCountryCode, sendDemoAggregation, countryCode }) => {
  return (
    <div className="left-boxes">
      <div className="generate-box">
        <h5 className="generate-header">Generate access code for "Login with code" to Demo Aggregation</h5>
        <a className="demo-link" href="https://vista.kreditz.com/aggregation_demo_login"><img className="demo-icon" src={demoIcon} alt="login_page"></img> Demo aggregation webpage</a>
        <div className="input-demo-box"><input className="demo-input" defaultValue={generateCode}></input><button className="generate-button" onClick={generateNewAccessCode}> Generate code</button></div>
      </div>
      <div className="sendcode-box">
        <h5 className="generate-header">Send generated access code?</h5>
        <div className="sendcode-div">
          <label className="sendcode-label">Access code</label><br></br>
          <input type="text" className="sendcode-form" placeholder="Enter access code" value={accessCodeData.code} name="code" onChange={(e) => handleChange(e)}></input><br></br>
          <label className="sendcode-label">Email Address</label><br></br>
          <input type="text" className="sendcode-form" placeholder="Enter email address" value={accessCodeData.email} name="email" onChange={(e) => handleChange(e)} /><br></br>
          <label className="sendcode-label">Phone number</label><br></br>
          <select className="pre-phone" value={countryCode} onChange={(e) => handleChangeCountryCode(e)}>
            <option value="+45">+45</option>
            <option value="+46">+46</option>
            <option value="+47">+47</option>
            <option value="+48">+48</option>
          </select><input type="text" className="sendcode-phone" placeholder="Enter phone number" value={accessCodeData.phone} name="phone" onChange={(e) => handleChange(e)} />
          <button className="send-button" onClick={sendDemoAggregation}>Send</button>
        </div>
      </div>
    </div>
  )
}

export default DemoAccessForm;