const APIDocumentationPopup = ({ detailsHandler, btnEnableHandler, accessCodeData, sendAccessCode,togglePopup }) => {
  return (
    <div className='certificate-removal-hard-deletion'>
      <div className='inner-hard-deletion'>
        {/* <img src={vectorImg} alt="img" /> */}
        <div className="apiDocumentation">
          <h3>API documentation access codes</h3>
          <p>Enter required details below for to whom access will be sent to!</p>
          <ul>
            <li><span>Company name :</span><input name='company_name' onChange={(e) => detailsHandler(e)} type="text" placeholder="Enter company name" /></li>
            <li><span>Name of the receiver :</span><input name='receiver_name' onChange={(e) => detailsHandler(e)} type="text" placeholder="Enter name" /></li>
          </ul>
          <div className="emailDetails"><span>Email :</span>{accessCodeData.email}</div>
          <form>
            <div className="label-form">
              <label>Enter the details as to why you want to send access codes for API documentation</label>
              <textarea name='details' placeholder="Enter details" onChange={(e) => detailsHandler(e)}></textarea>
            </div>
            <div className="label-form-btns">
              <button onClick={togglePopup} className='cancel-b'>Cancel</button>
              <button className={btnEnableHandler() ? 'delete-b' : 'delete-b delete-disabled'} type='button' disabled={!btnEnableHandler()} onClick={sendAccessCode}>Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}


export default APIDocumentationPopup