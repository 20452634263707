import moment from "moment";

const CertificateActivityLog = (props) => {
  const sortedTime = props.logData.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  })

  const capitalizeReplaceUnderScore = ((string) => {
    let removedUnderscore = string.replace(/_/g, " ");
    let arr = removedUnderscore.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  })

  return (
    <>
      <div className="lower-header">
        <h3>Certificate activity log</h3>
      </div>
      <section className="accepted-requests-section">
        <table width="100%">
          <thead className="cert-info-thead">
            <tr>
              <th className="table-head-cert-info table-head-cert-info-date">Date/Time</th>
              <th className="table-head-cert-info table-head-cert-info-event">Event Type</th>
              <th className="table-head-cert-info">Column Affected</th>
              <th className="table-head-cert-info">Errors</th>
            </tr>
          </thead>
          <tbody>
            {sortedTime.map((data, index) => (
              <tr key={index}>
                <td className="cert-info-td cert-info-date-td">{moment(data.created_at).format("D MMM, YYYY h:mm:ss A")}</td>
                <td className="cert-info-td cert-info-event-td">
                  {['banks_selected', 'bank_data_connected', 'bank_removed', 'bank_flow_started'].includes(data.event_type) ? (`${capitalizeReplaceUnderScore(data.event_type)}${data.display_name ? `-${data.display_name}` : ''}`) : data.event_type === "update_certificate_button_clicked" ? (
                    <span className="cert-info-td">User: - {data.user_name}</span>
                  ) : data.event_type ===
                    "refetch_transactions_button_clicked" ? (
                    <span className="cert-info-td">User: - {data.user_name}</span>
                  ) : (
                    capitalizeReplaceUnderScore(data.event_type)
                  )}
                </td>
                {data.event_type === "bank_flow_completed" ? (
                  <td className="cert-info-td">
                    Bank flow completed by end user
                  </td>
                ) : data.event_type === "bank_data_connected" ? (
                  <td className="cert-info-td">{data.display_name} data connected</td>
                ) : data.event_type === "change_in_status" ? (
                  <td className="cert-info-td">
                    Status {data.column_affected.status[0]} changed to{" "}
                    {data.column_affected.status[1]}
                  </td>
                ) : data.event_type === "continue_authentication_clicked" ? (
                  <td className="cert-info-td">
                    End user clicked "Continue Authentication" button
                  </td>
                ) : data.event_type === "banks_selected" ? (
                  <td className="cert-info-td">{data.display_name}</td>
                ) : data.event_type === "link_visited" ? (
                  <td className="cert-info-td">Link Visited by Email</td>
                ) : data.event_type === "link_visited_cellphone" ? (
                  <td className="cert-info-td">Link Visited by SMS</td>
                ) : data.event_type === "certificate_created" ? (
                  <td className="cert-info-td">
                    {data.certificate_request_sent_by_email_or_sms}
                  </td>
                ) : data.event_type === "bank_flow_started" ? (
                  <td className="cert-info-td">
                    Fetching data from {data.display_name}
                  </td>
                ) : data.event_type === "refetch_transactions_button_clicked" ? (
                  <td className="cert-info-td">
                    Refetch data from {data.column_affected.bank_name}
                  </td>
                ) : data.event_type === "update_certificate_button_clicked" ? (
                  <td className="cert-info-td">
                    Updating certificate with refetched transactions
                  </td>
                ) : data.event_type === "bank_removed" ? (
                  <td className="cert-info-td">
                    {JSON.stringify(data.column_affected)}
                  </td>
                ) : (
                  <td className="cert-info-td">N/A</td>
                )}
                <td className="cert-info-td">N/A</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  )
};

export default CertificateActivityLog;