import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import UploadFiles from "../../Components/UploadFiles/UploadFiles";

const UploadFilesPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Upload Files";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <UploadFiles />
      </div>
    </div>
  );
};

export default UploadFilesPage;
