import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import BankConnection from "../../Components/BankConnectionFrequency/BankConnection";
const BankConnectionFrequency = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Bank Connection Frequency";
  }, []);
  return(
      <div className="wrapper">
        <Header />
        <div className="main-container">
          <Sidebar />
          <BankConnection />
        </div>
      </div>
    );
}

export default BankConnectionFrequency;