import Flag from 'react-world-flags'
import circleIconGrey from '../../../assets/images/icon_circle.svg';
import circleIconGreen from '../../../assets/images/icon_status_active.svg';
import circleIconYellow from '../../../assets/images/icon_status_pending.svg';
import userCancelledIcon from '../../../assets/images/icon_user_cancelled.svg';
import bagIcon from '../../../assets/images/icon_bag.svg';
import './customersDataTableStyle.scss';

const getCircleStyleByStatus = (status) => {
  let icon;

  switch (status) {
    case 'accepted':
      icon = circleIconGreen;
      break;
    case 'pending':
      icon = circleIconYellow;
      break;
    case 'processing':
      icon = circleIconGrey;
      break;
    default:
      icon = circleIconGrey;
      break;
  }

  return icon;
};

const CustomersDataTable = ({ newData, headerName }) => {

  function formatErrorMessage(errors) {
    if (errors && errors.length > 0) {
      const validErrors = [...new Set(errors.filter(error => error && error.trim() !== ''))];
      if (validErrors.length > 0) {
        return validErrors.map((message, index) => {
          let newMessage = message ? message.replace(/_/g, ' ') : '';
          if (newMessage.toUpperCase() === newMessage) {
            newMessage = newMessage.toLowerCase();
          }
          newMessage = newMessage.charAt(0).toUpperCase() + newMessage.slice(1);
          if (index === 0) {
            return (
              <span key={index} className='error-msg-icon'>
                <img src={userCancelledIcon} alt="error" />
                {newMessage}
              </span>
            );
          } else {
            return (
              <span key={index} className='error-msg-icon'>
                {newMessage}
              </span>
            );
          }
        });
      }
    }
    return [' '];
  }

  function formatTableDate(date) {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.toLocaleString('en-US', { month: 'long' });
    let hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    hours = hours % 24;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${day} ${month} ${formattedHours}:${formattedMinutes}`;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatRequestText(text) {
    if (text === 'manual_request') {
      return 'MR';
    } else if (text === 'external_payload') {
      return 'EP';
    }

    let result = text ? text.replace(/_/g, ' ').toLowerCase() : '';
    result = capitalizeFirstLetter(result);
    return result;
  }

  function getCountryCode(code) {
    if (code.toUpperCase() === 'UK') return 'GB';
    return code;
  }

  return (
    <div className='data-grid'>
      {newData && newData.length > 0 ?
        newData.map((item, key) => (
          <div className='customers-data-table-container' key={key}>
            <div className='table-data-wrapper'>
              <p className='customers-table-name'>{item.name ? capitalizeFirstLetter(item.name) : "N/A"}</p>
              <div className='table-wrapper-customers'>
                <table className="customers-data-table">
                  <thead>
                    <tr>
                      <th className="id-column">Id</th>
                      {headerName !== 'Market' && <th className="market-column">Market</th>}
                      <th className="date-column">Date</th>
                      <th className="status-column">Status</th>
                      <th className="via-column">Via</th>
                      <th className="transactions-column">Transactions</th>
                      {headerName === 'Market' && <th className="bank-column">Customers</th>}
                      <th className="bank-column">{headerName === 'Bank' ? 'Customers' : 'Bank'}</th>
                      <th className="empty-column"></th>
                      <th className="deviation-column">Deviation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.stats && item.stats.length > 0 ?
                      item.stats.map((index, key) => (
                        <tr key={key}>
                          <td className='id-scroll-column'>
                            <div className="scroll-content">{index.id}</div>
                            <span class="tooltip-id-column">{index.id}</span>
                          </td>
                          {headerName !== 'Market' && (
                            <td className='market-flag'>
                              <Flag code={getCountryCode(index.country_code)} className='flag-icon' />
                            </td>
                          )}
                          <td>{formatTableDate(index.date)}</td>
                          <td className='status-circle'>
                            <img src={getCircleStyleByStatus(index.status)} alt={`${index.status} icon`} />
                          </td>
                          <td>{formatRequestText(index.via)}</td>
                          <td className='transactions-columns'>{index.transactions_count}</td>
                          {headerName === 'Market' && <td title={index.name}>{index.name}</td>}
                          <td title={headerName === 'Bank' ? index.name : index.bank}>{headerName === 'Bank' ? index.name : index.bank || ''}</td>
                          <td>{index.type === 'corporate' ? <img className='bag-icon' src={bagIcon} alt="Error" /> : null}</td>
                          <td className='error-message-column'>{formatErrorMessage(index.errors)}</td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan="8">No data available</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )) : null}
    </div >
  )
}

export default CustomersDataTable;