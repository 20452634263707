const ToggleOrgCertificate = ({ keys, checked, toggleFunction, certificateKey, capitalizeFirstLetter }) => {
  return (
    <div className="select-switch">
      <label className="switch-box">
        <input type="checkbox" checked={checked} onChange={(e) => toggleFunction
          (e, keys, certificateKey)} />
        <span className="slider round"></span>
      </label>
      <p>{capitalizeFirstLetter(keys)}</p>
    </div>
  )
}

export default ToggleOrgCertificate