import { Link } from "react-router-dom";
import { verifyAccess } from "./verifyAccess";

export const NavigationLink = ({ menuItem, children, menuClass = "" }) => {
  const regex = new RegExp(`${menuItem.path}`);
  const active = window.location.pathname.match(regex) ? "active-menu" : "aside-link";

  if (!verifyAccess(menuItem.path, menuItem.role, menuItem.roleExclusion)) {
    return null;
  }

  return (
    <li className={`${active} ${menuClass}`}>
      <Link className={`${active}`} to={menuItem.path}>
        {
          menuItem.icon &&
          <img
            src={menuItem.icon}
            alt=""
            className="imgIconSidebar"
            width="18" />
        }
        {children}</Link>
    </li>
  )
};
