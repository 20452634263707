import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import backIcon from '../../assets/images/icon_back.png';
import editIcon from '../../assets/images/edit.svg';
import './Healthcheck.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIconWhite from '../../assets/images/calendar_icon_white.svg';
import { updateHealthcheck } from '../../Redux/Actions/Healthcheck';
import FlashMessage from '../FlashMessage/FlashMessage';
import EditDropdown from '../Healthcheck/misc/EditDropdown';

const HealthcheckEdit = (props) => {
  const [flash, setFlash] = useState({
    show: false,
    type: '',
    message: '',
  });
  const history = useHistory();
  const getUser = history.location.state?.user;
  const id = getUser?.id || '';
  const name = getUser?.bankName || getUser?.type || '';
  const [status, setStatus] = useState(getUser && getUser.status ? getUser.status.charAt(0).toUpperCase() + getUser.status.slice(1) : '');
  const [errorMessage, setErrorMessage] = useState(getUser?.errorMessage || '');
  const [description, setDescription] = useState(getUser?.description || '');
  const [selectedDate, setSelectedDate] = useState(getUser?.lastCheck ? new Date(getUser.lastCheck) : "");
  const dispatch = useDispatch();

  if (!getUser) {
    return <Redirect to="/healthcheck" />;
  }

  const handleSelectStatus = (value) => {
    setStatus(value);
    if (value === 'Ok') {
      setErrorMessage('');
      setDescription('');
      setSelectedDate(new Date());
    }
  };

  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="custom-date-input-container">
      <button type="button" className="custom-date-input" onClick={onClick} ref={ref}>
        {value || "Select Date and Time"}
      </button>
      {value && (
        <button type="button" className="clear-date" onClick={() => setSelectedDate(undefined)}>
          Clear
        </button>
      )}
    </div>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();

    const healthcheckData = {
      status: status,
      error_message: errorMessage,
      description: description,
      last_successful_check: selectedDate ? selectedDate.toISOString() : null,
    };

    dispatch(updateHealthcheck(id, healthcheckData))
      .then(response => {
        if (response.status) {
          setFlash({
            show: true,
            type: 'success',
            message: "Update Success: " + response.message,
          });
          setTimeout(() => {
            history.push('/healthcheck');
          }, 1000);
        } else {
          console.error("Update Failed:", response.message);
          setFlash({
            show: true,
            type: 'error',
            message: "Update Failed: " + response.message,
          });
        }
      });
  };
  return (
    <div className="page-container healthcheck-container">
      {flash.show && (
        <FlashMessage
          iconImage={flash.type === 'success' ? 'tick' : 'alert'}
          firstMsg={flash.type === 'success' ? 'Success' : 'Error'}
          secondMsg={flash.message}
          closeFlash={() => setFlash({ ...flash, show: false })}
        />
      )}
      <h3 className="basic-head01 basic-flex-start">
        <div className="back-heading">
          <span
            onClick={() => history.push("/healthcheck")}
            className="esu-icon-wrapper"
          >
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer", marginRight: '-2rem' }} />
          </span>
          Bank - {name}
        </div>
      </h3>
      <div className="full-width-container bg-white">
        <form className="password-form" onSubmit={handleSubmit}>
          <h4>Edit bank status </h4>
          <div className="form-group-container">
            <div className="form-group-healthcheckedit" style={{ maxWidth: "600px" }}>
              <label style={{ fontSize: "15px" }} className="labelFormUser">Status</label>
              <EditDropdown
                className="dropdown-edit"
                id="drop1"
                options={[
                  { value: 'Ok', label: 'Ok' },
                  { value: 'Error', label: 'Error' }
                ]}
                placeholder={"Select"}
                selected={status}
                onSelect={handleSelectStatus}
              />
            </div>
          </div>
          <div className="form-group-container">
            <div className="form-group-healthcheckedit" style={{ maxWidth: "300px" }}>
              <label style={{ fontSize: "15px" }} className="labelFormUser">Error message:</label>
              <input
                style={{ fontSize: "14px" }}
                className="form-control"
                type="text"
                placeholder='Error message'
                value={errorMessage}
                onChange={(e) => setErrorMessage(e.target.value)}
              />
            </div>
            <span className="edit-icon-editpage">
              <img src={editIcon} alt="edit" />
            </span>
          </div>
          <div className="form-group-container">
            <div className="form-group-healthcheckedit" style={{ maxWidth: "300px" }}>
              <label style={{ fontSize: "15px" }} className="labelFormUser">Description</label>
              <input
                style={{ fontSize: "14px" }}
                className="form-control"
                placeholder="Description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <span className="edit-icon-editpage">
              <img src={editIcon} alt="edit" />
            </span>
          </div>
          <div className="form-group-container">
            <div className="form-group-healthcheckedit">
              <label style={{ fontSize: "15px" }} className="labelFormUser">Last successful check:</label>
              <div className="input-sets">
                <div className="input-group date k-datepickerhealthcheck">
                  <div className="form">
                    <DatePicker
                      selected={selectedDate}
                      onChange={date => setSelectedDate(date)}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      showTimeSelect
                      customInput={<CustomDateInput />}
                    />
                  </div>
                  <div className="calender-icon-field">
                    <span className="input-group-addon org-datepicker org-calendar-icon">
                      <img src={calendarIconWhite} alt="calendarIcon" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <button type="submit" className="custom-button">
                Update
              </button>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
}
export default HealthcheckEdit


