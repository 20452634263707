import { useState, useRef } from "react";
import backIcon from "../../../assets/images/icon_back.png";
import copyIcon from "../../../assets/images/copy_clip.svg";
import { useHistory, useLocation } from "react-router-dom";

const CertificateInfoConnections = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [showData, setShowData] = useState(false);
  const bankDataIdRef = useRef();

  const capitalizeReplaceUnderScore = ((string) => {
    let removedUnderscore = string.replace(/_/g, " ");
    let arr = removedUnderscore.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  })

  const backToCertInfoView = async () => {
    history.push({
      pathname: "/certificate_information",
      state: {
        selectedMarket: props.selectedMarket,
        selectedOrg: props.selectedOrg,
        selectedDateRange: props.selectedDateRange,
        selectedOrgSelect: props.selectedOrgSelect,
        currentPage: location.state.currentPage,
        showAcceptedOnly: props.showAcceptedOnly,
      },
    });
  };

  const toggleShowData = () => {
    setShowData(!showData);
  };

  const copyCode = () => {
    if (!bankDataIdRef.current) return;
    navigator.clipboard
      .writeText(bankDataIdRef.current?.innerHTML)
  };

  return (
    <>
      <div className="lower-header">
        <h3>
          <div className="back-heading">
            <span
              onClick={backToCertInfoView}
            >
              <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} className="cert-info-back-arrow" />
            </span>
            Certificate {props.certId}
          </div>
        </h3>
      </div>
      <section className="accepted-requests-section">
        <table width="100%">
          <thead className="cert-info-thead">
            <tr>
              <th className="table-head-cert-info">Banks(s) connected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cert-info-td"> {props.bank && <> 1. {props.bank}</>} {props.data?.member_request_banks.map((item, index) => (`${index + 2}. ${item.display_name} (${item.id}) `))}</td>
            </tr>
          </tbody>
        </table>
        <table width="100%">
          <thead className="cert-info-thead">
            <tr>
              <th className="table-head-cert-info">Connection type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cert-info-td">{capitalizeReplaceUnderScore(props.data.connection.name)}</td>
            </tr>
          </tbody>
        </table>
        <table width="100%">
          <thead className="cert-info-thead">
            <tr>
              <th className="table-head-cert-info">
                Bank data id
                <span className="hide-show" onClick={toggleShowData}>
                  {showData ? " Hide" : " Show"}
                </span>
              </th>
            </tr>
          </thead>
          {showData && <tbody>
            <tr>
              <td className="cert-info-td">
                <span onClick={copyCode}>
                  <img src={copyIcon} alt="Copy icon" className="cert-info-copy-icon" />
                </span>
                <span ref={bankDataIdRef}>
                  {props.data.request.bank_data_id}
                </span>
              </td>
            </tr>
          </tbody>}
        </table>
      </section>
    </>
  )
}

export default CertificateInfoConnections;