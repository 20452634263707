import React, { useEffect } from 'react';
import LiveDataBanks from '../../Components/LiveData/LiveDataBanks/LiveDataBanks';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import "./livedata.css";

const LiveDataCustomersPage = () => {

  useEffect(() => {
    document.title = "Kreditz | Admin portal - Banks";
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <LiveDataBanks />
      </div>
    </div>
  );
};

export default LiveDataCustomersPage;