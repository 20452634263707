import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import ApiDocumentationClone from "../../Components/ApiDocumentationClone/ApiDocumentationClone";
const ApiDocumentationClonePage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - API Documentation Editor";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <ApiDocumentationClone />
      </div>
    </div>
  )
}

export default ApiDocumentationClonePage;