const AuthLogTable = () => {
  return (
    <div>
      <table
        id="table-conversion-bank"
        className="table table-conversion"
        width="100%"
      >
        <thead className="conversion-thead">
          <tr className="conversion-bank-table-row row">
            <th className="table-head-conversion conversion-auth-table col-md-1 conversion-auth-id">
              ID
            </th>
            <th
              id="conversion-auth-case-th"
              className="table-head-conversion conversion-auth-table col-md-2 conversion-auth-case"
            >
              Case ID
            </th>

            <th
              id="conversion-auth-client-th"
              className="table-head-conversion conversion-auth-table col-md-2 "
            >
              Client reference ID
            </th>

            <th
              id="conversion-auth-status-th"
              className="table-head-conversion conversion-auth-table col-md-1 conversion-auth-status"
            >
              Status
            </th>
            <th className="table-head-conversion conversion-auth-table col-md-2 conversion-auth-table-right">
              Created at
            </th>
            <th className="table-head-conversion conversion-auth-table col-md-2 conversion-auth-table-right">
              Accepted at
            </th>
            <th
              id="conversion-auth-bank-th"
              className="table-head-conversion conversion-auth-table col-md-1 conversion-auth-bank conversion-auth-table-right"
            >
              Bank
            </th>
          </tr>
        </thead>
      </table>

      <table key={Math.random()} className="table" width="100%">
        <tbody>
          <tr className="row">
            <td className="conversion-td conversion-auth-table col-md-1 conversion-auth-id">
              Test
            </td>
            <td
              id="conversion-auth-case-td"
              className="conversion-td conversion-auth-table col-md-2 conversion-auth-case"
            >
              Test
            </td>

            <td
              id="conversion-auth-client-td"
              className="conversion-td conversion-auth-table col-md-2 "
            >
              Test
            </td>
            <td
              id="conversion-auth-status-td"
              className="conversion-td conversion-auth-table col-md-1 conversion-auth-status"
            >
              Test
            </td>
            <td className="conversion-td conversion-auth-table col-md-2 conversion-auth-table-right">
              Test
            </td>
            <td className="conversion-td conversion-auth-table col-md-2 conversion-auth-table-right">
              Test
            </td>
            <td
              id="conversion-auth-bank-td"
              className="conversion-td conversion-auth-table col-md-1 conversion-auth-bank conversion-auth-table-right"
            >
              Test
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AuthLogTable;
