import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route } from 'react-router-dom';
import Routes from './Routes';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import 'jquery/src/jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/css/common.css';
import rootReducer from './Redux/Reducers/rootReducer';
import { setHeaderFromLocalStorage, removeUserDataToLocalstorage } from './Redux/Actions/Auth';
import AutoLogout from './Components/AutoLogout/AutoLogout';

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

function timeOut() {
	if (localStorage.authTokenExpairyTime < (new Date()).getTime()) {
		localStorage.clear()
		store.dispatch(removeUserDataToLocalstorage())
	}
}
setInterval(timeOut, 1000)
store.dispatch(setHeaderFromLocalStorage())

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<AutoLogout />
			<Route component={Routes} />
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();