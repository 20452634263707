import React, { useEffect } from 'react'
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import Markets from '../../Components/LiveData/Markets/Markets';
import "./livedata.css";

const MarketsPage = () => {

  useEffect(() => {
    document.title = "Kreditz | Admin portal - Markets";
  }, []);

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="main-container">
          <Sidebar />
          <Markets />
        </div>
      </div>
    </>
  )
}

export default MarketsPage;