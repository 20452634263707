import sort from "../../assets/images/sort.svg";
import edit from "../../assets/images/edit.svg";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { DeletePopup } from "./DeletePopup/DeletePopup";
import cloneIcon from "../../assets/images/clone-icon.svg"
import NewFlash from "../FlashMessage/NewFlash";
import { useDispatch } from "react-redux";
import { updateApiDocumentationSetting } from "../../Redux/Actions/ApiDocumentation";

const ApiDocumentationList = (props) => {
  const [selectedDoc, setSelectedDoc] = useState();
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("swagger-editor-content");
  }, []);

  let documentationArray = props.data;

  const editSelectedDoc = async (id) => {
    setSelectedDoc(id);
    history.push({
      pathname: "/api_update",
      state: {
        documentation: documentationArray.filter(
          (x) => x.id === id
        ),
        update: props.update
      },
    });
  };
  const cloneSelectedDoc = async (id) => {
    setSelectedDoc(id);
    history.push({
      pathname: "/api_clone",
      state: {
        documentation: documentationArray.filter(
          (x) => x.id === id
        ),
        update: props.update
      },
    });
  };

  const updateApiDocsSetting = async () => {

    const data = {
      enable_api_documentation: !props.enableSetting
    };

    const { status, message } = await dispatch(updateApiDocumentationSetting(props.enableId, data));
    if (status) {
      setFlashMessage(true);
      setFlashData(prevState => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: "Success",
        secondMsg: message
      }))
    }
  }
  const array = [...documentationArray];

  const closeFlash = () => {
    setFlashMessage(false);
  }

  const toggleEnable = () => {
    props.setEnableSetting(!props.enableSetting)
  }

  const updateSetting = () => {
    toggleEnable()
    updateApiDocsSetting(props.enableSetting)
  }
  return (
    <>
      {flashMessage &&
        <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
          <NewFlash
            data={flashData}
            closeFlash={() => closeFlash()}
          />
        </div>
      }
      <div className="users-container documentation-container">
        <div className="adjust-switcher api-switch-container">
          <div className="select-switch">
            <label className="switch-box enable-switch-api">
              <span className="enable-span"> Enable</span>
              <input type="checkbox" name="enable-api" checked={props.enableSetting} onChange={updateSetting} value={props.enableSetting} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="table-scroll-y">
          <table className="main-table">
            <thead className="user-category-thread">
              <tr className="category-thread">
                <th className="category-head" onClick={() => props.applySorting("title")}>
                  Title
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head" onClick={() => props.applySorting("version")}>
                  Version
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head" onClick={() => props.applySorting("created_at")}>
                  Created at
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head" onClick={() => props.applySorting("updated_at")}>
                  Updated at
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head" onClick={() => props.applySorting("section_header")}>
                  Section header
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head" onClick={() => props.applySorting("section_name")}>
                  Section name
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head" onClick={() => props.applySorting("section_order")}>
                  Section order
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head" onClick={() => props.applySorting("active")}>
                  Active
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head">Action</th>
              </tr>
            </thead>
            <tbody className="user-data">
              {documentationArray.map((x, index) => (
                <tr key={Math.random()} className="user-box">
                  <td key={Math.random()} className="user-info">
                    {x.title}
                  </td>
                  <td key={Math.random()} className="user-info">
                    {x.version}
                  </td>
                  <td key={Math.random()} className="user-inforole">
                    {x.created_at.substr(0, 10)}
                  </td>
                  <td key={Math.random()} className="user-inforole">
                    {x.updated_at.substr(0, 10)}
                  </td>
                  <td key={Math.random()} className="user-inforole">
                    {x.section_header}
                  </td>
                  <td key={Math.random()} className="user-inforole">
                    {x.section_name}
                  </td>
                  <td key={Math.random()} className="user-inforole">
                    {x.section_order}
                  </td>
                  <td key={Math.random()} className="user-inforole">
                    {x.active ?
                      <span className="active-true">True</span>
                      : <span className="active-false">False</span>
                    }
                  </td>
                  <td key={Math.random()} className="user-info">
                    <span
                      className="api-edit-icon"
                      onClick={() =>
                        editSelectedDoc(x.id)
                      }
                    >
                      <img src={edit} className="api-edit-icon" alt="edit" />
                    </span>
                    <span className="api-clone-icon" onClick={() => cloneSelectedDoc(x.id)}>
                      <img src={cloneIcon} alt="clone icon" />
                    </span>
                    <DeletePopup
                      id={x.id}
                      array={array}
                      update={props.setUpdate}
                      version={x.version}
                      setFlashData={setFlashData}
                      setFlashMessage={setFlashMessage} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApiDocumentationList;