import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { left } from "@popperjs/core";


const MonthlyGraph = ({ acceptedRequestDay = {}, acceptedRequestLastMonth = {} }) => {
  const acceptedRequestDayKeys = Object.keys(acceptedRequestDay);
  let acceptedRequestDayValues = Object.values(acceptedRequestDay);
  let acceptedRequestLastMonthValues = Object.values(acceptedRequestLastMonth);

  const d = new Date();
  const currentMonth = d.toLocaleString('default', { month: 'short' }) + " " + d.getFullYear().toString();
  const lastMonthDate = new Date(d.getFullYear(), d.getMonth() - 1, 1);
  const lastMonth = lastMonthDate.toLocaleString('default', { month: 'short' }) + " " + lastMonthDate.getFullYear().toString();

  if (acceptedRequestDayValues.length === 30) {
    acceptedRequestLastMonthValues = acceptedRequestLastMonthValues.slice(0, 30);
  }

  const initialOptions = {
    chart: {
      type: "column",
      height: 300,
      style: {
        fontFamily: "SF Pro Display",
      },
    },
    title: {
      text: "Accepted requests current month vs accepted requests last month",
      align: left,
      margin: 20,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    accessibility: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const monthToDisplay = this.series.name === currentMonth ? currentMonth : lastMonth;
        return `${monthToDisplay}<br/>requests: ${Highcharts.numberFormat(this.y, 0, ' ')}`
      },
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0,
        pointWidth: 10,
        dataLabels: {
          style: { fontSize: "9px" },
          enabled: true,
          padding: 0,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
          rotation: 270,
          y: -20,
          color: "#596980",
          crop: false,
          inside: false,
        },
      },
      series: {
        minPointLength: 2,
      },
    },
    colors: ["#43BF58", "#3e85f9"],
    xAxis: {
      categories: acceptedRequestDayKeys || 0,
      title: {
        text: "Month",
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total counts",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: lastMonth,
        data: acceptedRequestLastMonthValues || 0,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
      {
        name: currentMonth,
        data: acceptedRequestDayValues || 0,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
        pointPlacement: 0.1,
        dataLabels: {
          y: -30,
        }
      },
    ],


  };

  return (
    <div className="ld-main-data-container">
      <div className="ld-requests-container">
        <div className="ld-request-chart-container">
          <div className="ld-request-chart">
            <HighchartsReact
              containerProps={{ style: { height: "280px" } }}
              highcharts={Highcharts}
              options={initialOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyGraph;