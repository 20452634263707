import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getOrganizationConsentTexts(page = '') {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_CONSENT_TEXTS.INDEX()}?page=${page}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      console.error("Error fetching all SMS templates:", err);
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function createOrganizationConsentTexts(content) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_CONSENT_TEXTS.CREATE()}`;
  return dispatch => axios.post(apiURL, content)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { data: { status: err.response.data.status, message: err.response.data.error || err.response.data.message } };
    });
}

export function deleteOrganizationConsentTexts(id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_CONSENT_TEXTS.DELETE()}/${id}`;
  return dispatch => axios.delete(apiURL)
    .then(res => {
      return { data: res.data };
    }).catch(err => {
      console.error("Error fetching all SMS templates:", err);
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function editOrganizationConsentTexts(editData = {}, dataContent) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_CONSENT_TEXTS.UPDATE()}/${editData.id}`;
  return dispatch => axios.put(apiURL, dataContent)
    .then(res => {
      return { data: res.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { data: { status: err.response.data.status, message: err.response.data.error || err.response.data.message } };
    });
}

export function getOrganizationConsentTextsFilters() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_CONSENT_TEXTS.FILTERS()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data };
    }).catch(err => {
      console.error("Error fetching all SMS templates:", err);
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function getOrganizationConsentTextsFilterData(filters, page = '', selectedOrgId) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_CONSENT_TEXTS.INDEX()}?service_id=${filters?.service_id}&locale=${filters?.locale}&display_name=${filters?.display_name}&key=${filters?.keys}&organization_id=${selectedOrgId}&page=${page}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      console.error("Error fetching all SMS templates:", err);
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function getOrganizationConsentTextsShow(textId) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_CONSENT_TEXTS.SHOW()}/${textId}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      console.error("Error fetching all SMS templates:", err);
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}
