import { React, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { getAuditReport, getAuditReportPdf } from '../../Redux/Actions/AuditReport';

import ReactPaginate from 'react-paginate';
import QueryHeader from './SubComponents/AuditReportQuery';

import eyeIcon from '../../assets/images/eye_icon.svg';
import sort from '../../assets/images/sort.svg';
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import FlashMessage from "../FlashMessage/FlashMessage";


import './scss/auditReport.scss';


const AuditReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const [reportsData, setReportsData] = useState();
  const location = useLocation();
  const originalDateRange = location.state ? location.state.originalDateRange : null;
  const originalEventType = location.state ? location.state.eventType : null;

  const table_headers = [
    ['Certificates viewed'],
    ['Certificates deleted'],
    ['Downloaded files']
  ]
  const [paginationData, setPaginationData] = useState({
    total_pages: '',
    total_count: '',
    current_page: 1,
    prev_page: '',
    next_page: '',
    sort_order: 'desc',
    sort_column: 'events',
    sort_index: 0
  });
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState();
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  useEffect(() => {
    if (!query) {
      return;
    }
    fetchAuditReport();

  }, [page, query, paginationData.sort_order]);

  const updateQuery = (query) => {
    setPage(1);
    setQuery(query);
  }

  const generateAuditReport = async (file_type) => {
    createAuditReport(file_type);
  }
  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  const createAuditReport = async (fileType) => {
    let params = `?event_type=${query.eventType}&query=${query.queryString}&audit_date_range=${query.selectedDateRange}&file_type=${fileType}`
    const res = await dispatch(getAuditReportPdf(params));
    if (res.status) {
      openFlash("tick", "Done!", res.message);
    } else {
      openFlash("alert", "Error!", res.message);
    }
  }

  const fetchAuditReport = async () => {
    await setIsLoading(true);
    await setIsLoading(true);
    try {
      let params = `?event_type=${query.eventType}&query=${query.queryString}&page=${page}&audit_date_range=${query.selectedDateRange}&sort_order=${paginationData.sort_order}&sort_column=${paginationData.sort_column}&sort_index=${paginationData.sort_index}`;
      const res = await dispatch(getAuditReport(params));

      if (res.status === 200) {
        setPaginationData((prevState) => ({
          ...prevState,
          total_pages: res.data.pagination_data.total_pages,
          total_count: res.data.pagination_data.total_count,
          current_page: res.data.pagination_data.current_page,
          prev_page: res.data.pagination_data.prev_page,
          next_page: res.data.pagination_data.next_page,
        }));
        setError(null);
        setReportsData(res.data.users);

      } else {
        setError(res.message);
      }

    } catch (err) {
      console.error(err);
    } finally {
      await setIsLoading(false);
    }
  };
  // Kept as reference for sorting functionality if required in future
  const applySorting = (column, index = null) => {
    const sortOrder = paginationData.sort_order === 'asc' ? 'desc' : 'asc'
    setPaginationData(prevState => ({
      ...prevState,
      sort_order: sortOrder,
      sort_column: column,
      sort_index: index
    }))
  }

  const renderTable = () => {


    if (!reportsData || reportsData.length === 0) {
      return <></>;
    }
    return reportsData.map((user) => {
      return (
        <tr className="reports-tr" key={user.user_id}>
          <td className="capitalize"> {user.name}</td>
          <td>{user.email}</td>
          {user.events.map((count, index) => {
            return (
              <td key={index}>{count}</td>
            )
          }
          )}
          <td className="last-th">
            <Link
              to={{
                pathname: `/audit_report/details/${user.user_id}`,
                state: {
                  userId: user.user_id,
                  queryData: { selectedDateRange: query.selectedDateRange, queryString: '' },
                  eventType: query.eventType,
                  eventCount: user.events.reduce((a, b) => a + b, 0),
                },
              }}
            >
              <img src={eyeIcon} alt="eye icon" className="eye-icon" />
            </Link>
          </td>
        </tr >
      );
    });
  }
  return (
    <div className="page-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className="reports-container">
        <header className="reports-header">
          <h3>Audit Report</h3>
          <QueryHeader updateQuery={updateQuery} recoupedEventType={originalEventType} recoupedDateRange={originalDateRange} generateAuditReport={generateAuditReport} />
        </header>
        <div className="reports-table-container">
          <table className="reports-table">
            <thead className="reports-th">
              <tr className="reports-tr capitalize">
                <th onClick={() => { applySorting("name") }}>
                  <span className='reports-tr-text'>
                    Name
                    <img className="sort-icon" src={sort} alt="sort" />
                    <span className='reports-tr-underline'></span>
                  </span>
                </th>
                <th>
                  <span className='reports-tr-text' onClick={() => { applySorting("email") }}>
                    Email
                    <img className="sort-icon" src={sort} alt="sort" />
                    <span className='reports-tr-underline'></span>
                  </span>
                </th>
                {query && table_headers[query.eventType].map((title, index) => {
                  if (!title) {
                    return <th></th>;
                  }
                  return (
                    <th key={index} onClick={() => { applySorting("events", index) }}>
                      <span className='reports-tr-text'>
                        {title}
                        <img className="sort-icon" src={sort} alt="sort" />
                        <span className='reports-tr-underline'></span>
                      </span>
                    </th>
                  )
                })
                }
                <th className="last-th">
                  View details
                </th>
              </tr>
            </thead>
            <tbody className="audit-report-tbody">{
              error ?
                <>
                  <tr className="audit-report-table-skeleton d-flex align-items-start">
                    <td className="m-4" >
                      <h4 className=''>{error}</h4>
                    </td>
                  </tr>
                </> :
                renderTable()
            }

            </tbody>
          </table>
          {isLoading &&
            <div className={reportsData ? "audit-report-table-skeleton-overlay" : "audit-report-table-skeleton"}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>}
          {reportsData && reportsData.length === 0 && <>
            <div className="audit-report-table-skeleton">
              <h4 className=''>No data found</h4>
            </div>
          </>
          }
          <div className="reports-pagination-box">
            {paginationData.total_pages > 1 &&
              <ReactPaginate
                previousLabel="Previous"
                activePage={paginationData.current_page}
                nextLabel="Next"
                breakLabel={'...'}
                pageCount={Math.ceil(paginationData.total_pages)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
                onPageChange={(e) => setPage(e.selected + 1)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditReport;
