import { useEffect, useState } from "react"
import './style.scss'
import { topBanksArray } from "./utilities/TopBanksList"
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import BankStatusBar from "./utilComponents/BankStatusBar"
import BankStatusLabel from "./utilComponents/BankStatusBankLabel"
import { getBanksDataFromArray } from "../../Redux/Actions/BankStatus";
import { blockSorting } from "./utilities/dataFormatting";
const BankContainer = (props) => {
  const [bank, setBank] = useState(props.data);
  const [dataBlocks, setDataBlocks] = useState();
  const [recentStatus, setRecentStatus] = useState();
  const [loading, setLoading] = useState(false);
  const getRequests = async (time_frame) => {
    if (loading) return;
    setLoading(true);
    try {
      const { blocks, recentRequests } = blockSorting(props.requests || [], time_frame)
      await setDataBlocks(blocks);
      await setRecentStatus(recentRequests);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }

  }
  useEffect(() => {
    if (props.data && props.requests && props.timeFrame) {
      getRequests(props.timeFrame);
    }
    setBank(props.data);
  }, [props.data, props.requests, props.timeFrame]);

  return (
    <>
      <div className="mw-100 pe-3 pt-3 bank-status-top">
        <div className="mw-100 bank-status-top-label-container">
          <BankStatusLabel bank={bank} status={dataBlocks} showUptime={true} timeFrame={props.timeFrame} recentRequests={recentStatus} />
        </div>
        <div className="bank-status-top-bar">
          <BankStatusBar blocks={dataBlocks} timeFrame={props.timeFrame} />
        </div>
      </div>
    </>
  )
}

const BankStatusTopBanks = ({ query, timeFrame }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchBankStatus(query, timeFrame);
  }, [query])


  const fetchBankStatus = async (params, time_frame) => {
    if (loading) return;
    await setLoading(true);

    try {
      await dispatch(setLoaderSpinner(true));
      const res = await dispatch(getBanksDataFromArray(topBanksArray[params.country_id], params, time_frame));

      await setData(res.data.banks);
      await setRequests(res.data.request_list);
    } catch (error) {
      console.log("Error", error);
    } finally {
      await dispatch(setLoaderSpinner(false));
      setLoading(false);
    }
  }
  if (!data || !requests) {
    return (<>
      <div>Loading banks</div>

    </>)
  }
  return (
    <>
      <div className="w-100 mw-100 container bg-white bank-status-top-container">
        {!loading && data[0] && requests ? data.map(((bank, key) => { return <BankContainer data={bank} key={key} timeFrame={timeFrame} requests={requests[bank.kreditz_provider_id.toString()]} /> }))
          :
          <>
            <p className="text-center m-4 mb-1 text-muted">
              {!loading ? "No top banks found for this market." : "Loading top banks..."}
            </p>
          </>
        }
      </div>
    </>
  )


}

export default BankStatusTopBanks;

