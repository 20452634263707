export const templatesName = () => {

  const smsTemplateNameBase = {
    income_request_sms: "Income request SMS",
    reminder_income_request_sms: "Reminder income request SMS",
    additional_income_request_sms: "Additional income request SMS",
    income_request_sms_corporate: "Income request SMS corporate",
    reminder_income_request_sms_corporate: "Reminder income request SMS corporate",
    additional_income_request_sms_corporate: "Additional income request SMS corporate"
};

const templateDisplayNameBase = {
    income_request_email: "Certificate request email",
    reminder_income_request_email: "Reminder certificate income request email",
    send_wages_request_email: "Send wages request email",
    additional_income_request_email: "Additional income request email",
    income_request_email_corporate: "Certificate request email corporate",
    reminder_income_request_email_corporate: "Reminder certificate income request email corporate",
    send_wages_request_email_corporate: "Send wages request email corporate",
    additional_income_request_email_corporate: "Additional income request email corporate"
};

return {
    smsTemplateNameBase,
    templateDisplayNameBase
}};
