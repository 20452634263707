import { SET_IT_SIDEBAR } from '../Actions/Types/Types'

const initialState = {
  toggleItSidebar: false
}

const it_sidebar = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_IT_SIDEBAR:
      return {
        toggleItSidebar: action.value
      }
    default: return state;
  }
}

export default it_sidebar;
