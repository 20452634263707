import React from "react";

const RecordCount = (props) => {
  return (
    <div className="record-container">
      <div className="record-box">
        <div className="rd-box">
          <p className="mr-bt">Total requests</p>
          <h2 className="h2-font">{props.totalRecordCount.total_requests || 0}</h2>
        </div>
      </div>
      <div className="record-box">
        <div className="rd-box">
          <p className="mr-bt">Accepted Requests</p>
          <h2 className="h2-font">{props.totalRecordCount.total_accepted_requests || 0}</h2>
        </div>
      </div>
      <div className="record-box">
        <div className="rd-box">
          <p className="mr-bt">Pending Requests</p>
          <h2 className="h2-font">{props.totalRecordCount.total_pending_requests || 0}</h2>
        </div>
      </div>
      <div className="record-box">
        <div className="rd-box">
          <p className="mr-bt">Expired Requests</p>
          <h2 className="h2-font">{props.totalRecordCount.total_past_due_date_requests || 0}</h2>
        </div>
      </div>
      <div className="record-box">
        <div className="rd-box">
          <p className="mr-bt">Cancelled Requests</p>
          <h2 className="h2-font">{props.totalRecordCount.total_cancelled_requests || 0}</h2>
        </div>
      </div>
      <div className="record-box">
        <div className="rd-box">
          <p className="mr-bt">Deleted Requests</p>
          <h2 className="h2-font">{props.totalRecordCount.total_deleted_requests || 0}</h2>
        </div>
      </div>
    </div>
  )
}

export default RecordCount;