import React from "react";
import { left } from "@popperjs/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ModesChart = ({ graphDataCount = {}, manualReq = {}, iframeReq = {}, apiReq = {}, externalReq = {}, emailReqs = {}, smsReqs = {} }) => {
  const apiRequest = apiReq.reduce((accumulator, currentValue) => accumulator + currentValue, 0);;
  const manualRequests = manualReq.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const iframeRequest = iframeReq.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const externalPayloadMonthly = externalReq.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  //const smsReqMonthly = smsReqs.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  //const emailReqMonthly = emailReqs.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const options = {
    chart: {
      type: "pie",
      height: 300,
      style: {
        fontFamily: "SF Pro Display",
      },
    },
    title: {
      text: "Modes - Monthly",
      align: left,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    accessibility: {
      enabled: false,
      point: {
        valueSuffix: "%"
      }
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Total counts",
      },
    },
    xAxis: {
      categories: "months",
      title: {
        text: "Test",
      },
      crosshair: true,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b> : {point.percentage: .2f} %"
        }
      },
    },
    legend: {
      layout: 'horizontal',
      verticalAlign: 'bottom'
    },
    series: [
      {
        name: "Current month",
        data: [{
          name: 'Api request',
          y: apiRequest || 0,
          //sliced: true,
          color: "#3e85f9",
        }, {
          name: 'Iframe request',
          y: iframeRequest || 0,
        }, {
          name: 'Manual request',
          y: manualRequests || 0,
          color: '#43BF58'
        }, {
          name: 'Payload request',
          y: externalPayloadMonthly || 0,
        }
          //, {
          //   name: 'SMS request',
          //   y: smsReqMonthly || 0,
          // }, {
          //   name: 'Email request',
          //   y: emailReqMonthly || 0,
          // }
        ],
        showInLegend: true,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
    ],
  };
  return (
    <div className="ld-main-data-container">
      <div className="ld-requests-container">
        <div className="ld-request-chart-container ">
          <div className="ld-request-chart yearGraph">
            <HighchartsReact
              containerProps={{ style: { height: "280px" } }}
              highcharts={Highcharts}
              options={options}
            ></HighchartsReact>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModesChart;