import React from 'react'
import { useEffect } from 'react';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import OrganizationConsentTextsDiff from '../../Components/OrganizationConsentTexts/OrganizationConsentTextsDiff';

const OrganizationConsentTextsDiffPage = () => {

  useEffect(() => {
    document.title = "Kreditz | Admin portal - Organizations Consent Texts Difference";
  }, []);

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="main-container">
          <Sidebar />
          <OrganizationConsentTextsDiff />
        </div>
      </div>
    </>
  )
}

export default OrganizationConsentTextsDiffPage