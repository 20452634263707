import React from "react";
import "./loaderspinner.css";
import { useSelector } from 'react-redux'

const LoaderSpinner = () => {

	let loading = useSelector(state => state.loader_spin.loading)

	return (
		<>
			{loading ?
				(<div className="loader-wrapper">
					<div className="loader"></div>
				</div>)

				: null
			}
		</>
	)
}

export default LoaderSpinner;