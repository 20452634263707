import React, { useState, useEffect } from "react";
import expandIcon from "../../../assets/images/expand_icon.svg";
import CustomersDataTable from "../LiveDataCustomers/CustomersDataTable";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMarketsData, getCustomersFilterData } from "../../../Redux/Actions/LiveData";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import LiveDataFilters from "../LiveDataFilters/LiveDataFilters";
import "../LiveDataCustomers/customersDataTableStyle.scss";

const LiveDataMarkets = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [type, setType] = useState([]);
  const [via, setVia] = useState([]);
  const [mode, setMode] = useState([]);
  const [status, setStatus] = useState([]);
  const [organizationId, setOrganizationId] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [connectionType, setConnectionType] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [apiVersion, setApiVersion] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedEnviroment, setSelectedEnviroment] = useState("");
  const [selectedRequestType, setSelectedRequestType] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const [selectedVia, setSelectedVia] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedProviderId, setSelectedProviderId] = useState("");
  const [selectedConnectionType, setSelectedConnectionType] = useState("");
  const [selectedApiVersion, setSelectedApiVersion] = useState("");
  const [newData, setNewData] = useState([]);
  const [error, setError] = useState(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    let isMounted = true;

    fetchMarketsLiveData(isMounted);
    fetchFilterData(isMounted);

    const intervalId = setInterval(() => {
      if (isMounted) {
        fetchMarketsLiveData(isMounted);
        fetchFilterData(isMounted);
      }
    }, 120000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [selectedEnviroment, selectedRequestType, selectedMode, selectedVia, selectedStatus, selectedOrganizationId, selectedCountryId, selectedProviderId, selectedConnectionType, selectedApiVersion]);

  const fetchMarketsLiveData = async (isMounted) => {
    try {
      let params = `?env=${selectedEnviroment}&type=${selectedRequestType}&mode=${selectedMode}&via=${selectedVia}&status=${selectedStatus}&organization_id=${selectedOrganizationId}&country_id=${selectedCountryId}&kreditz_provider_id=${selectedProviderId}&connection_type=${selectedConnectionType}&api_version=${selectedApiVersion}`;

      await dispatch(setLoaderSpinner(true));
      const res = await dispatch(getMarketsData(params));

      if (res.status && isMounted) {
        await dispatch(setLoaderSpinner(false));
        const requests = res.message.data;
        setData(requests.map((market) => (market.stats)));
        setNewData(requests);
      } else if (res.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    }
  };

  const fetchFilterData = async (isMounted) => {
    try {
      const res = await dispatch(getCustomersFilterData());
      if (res.status && isMounted) {
        const requests = res.message.data;
        setType(requests.certificate_type);
        setVia(requests.via);
        setMode(requests.mode);
        setStatus(requests.status);
        setOrganizationId(requests.organization_id);
        setCountryId(requests.country_id);
        setConnectionType(requests.banks);
        setApiVersion(requests.api_version);
        setSuppliers(requests.connection_type);
      } else if (res.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    }
  };

  const handleChange = (type, value) => {
    setFilters(prevState => ({
      ...prevState,
      [type]: value
    }))
    setFilters(true)
  };

  return (
    <div className="kreditz-provider-container page-container">
      <h3>Markets</h3>
      <div className="bg-white-live-data-customers bg-20-top">
        <div className={`inner-content-customers ${isExpanded ? 'expanded' : ''}`}>
          <div className={`customers-table-inner-header ${isExpanded ? 'expanded' : ''}`}>
            {isExpanded ? <h3>Markets</h3> : <h5>Filters</h5>}
            <button className="expand-button-customers" onClick={toggleExpand}>
              <img src={expandIcon} alt="Expand customers" className="expand-icon-customers" />
            </button>
          </div>
          {!isExpanded && (
            <LiveDataFilters
              certificate_type={type}
              via={via}
              mode={mode}
              status={status}
              organizationId={organizationId}
              countryId={countryId}
              connectionType={connectionType}
              suppliers={suppliers}
              apiVersion={apiVersion}
              handleChange={handleChange}
              handleSelectedEnvironment={(event) => setSelectedEnviroment(event.target.value)}
              handleSelectedRequestType={(event) => setSelectedRequestType(event.target.value)}
              handleSelectedMode={(event) => setSelectedMode(event.target.value)}
              handleSelectedVia={(event) => setSelectedVia(event.target.value)}
              handleSelectedStatus={(event) => setSelectedStatus(event.target.value)}
              handleSelectedOrganizationId={(event) => setSelectedOrganizationId(event.target.value)}
              handleSelectedCountryId={(event) => setSelectedCountryId(event.target.value)}
              handleSelectedProviderId={(event) => setSelectedProviderId(event.target.value)}
              handleSelectedConnectionType={(event) => setSelectedConnectionType(event.target.value)}
              handleSelectedApiVersion={(event) => setSelectedApiVersion(event.target.value)}
              selectedCountryId={selectedCountryId}
            />
          )}
          {error && <div className="error">{error}</div>}
          <div className={`data-container-wrapper ${isExpanded ? 'expanded' : ''}`}>
            {data.length > 0 ? (
              <>
                <CustomersDataTable newData={newData} headerName="Market" />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveDataMarkets;