import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Actions/Auth";

const INACTIVITY_TIMEOUT = 10 * 60 * 1000; // 10 minutes

const AutoLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const timeoutRef = useRef(null);
  const excludedRoutes = ["/live_data_overview", "/live_data_markets", "/live_data_organizations", "/live_data_worldmap", "/live_data_customers", "/live_data_banks", "/markets"];

  useEffect(() => {
    const resetInactivityTimer = () => {
      if (!excludedRoutes.includes(location.pathname)) {
        clearTimeout(timeoutRef.current);
        startInactivityTimer();
      }
    };

    if (!excludedRoutes.includes(location.pathname)) {
      startInactivityTimer();
    }

    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
    };
  }, [location.pathname]);

  const startInactivityTimer = () => {
    timeoutRef.current = setTimeout(() => {
      logoutUser();
    }, INACTIVITY_TIMEOUT);
  };

  const logoutUser = async () => {
    await dispatch(logout());
    localStorage.clear();
    history.push("/login");
  };

  return null;
};

export default AutoLogout;