import { useState } from "react";
import searchIcon from "../../assets/images/icon_search.svg";
import "./comparison.css";

const SearchFilter = ({ callback, setIsSearched }) => {
  const [searchKey, setSearchKey] = useState("");

  const handleSearchSubmit = (e) => {
    if (searchKey !== "") {
      e.preventDefault();
      callback(searchKey);
      setIsSearched(true)
    }
  };

  return (
    <div className="buttons-and-search-container search-wrapper">
      <form onSubmit={handleSearchSubmit}>
        <input
          type="text"
          placeholder="Search"
          className="search-input-comparison"
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        ></input>
        <button className="search-btn-comparison">
          <img src={searchIcon} alt="search Icon" width="18" />
        </button>
      </form>
    </div>
  );
};

export default SearchFilter;
