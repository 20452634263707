import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './BrokerFlowChart.scss';
import more from 'highcharts/highcharts-more';

more(Highcharts);

function BrokerFlowChart({ data, chartType }) {
  const totalRequests = data.reduce((total, item) => total + item.requests_shared, 0);
  const sortedData = [...data].sort((a, b) => b.requests_shared - a.requests_shared);
  const categoriesWithPercentages = sortedData.map(item => {
  const percentage = ((item.requests_shared / totalRequests) * 100).toFixed(1);

    return `<b>${item.name}</b>: (${percentage}%)`;
  });

  let series = [];
  switch (chartType) {
    case 'bar':
      series = [{
        pointWidth: 50,
        name: 'Requests received',
        data: sortedData.map(item => ({
          y: item.requests_shared,
          name: item.name
        })),
        colorByPoint: true,
        showInLegend: false,
      }];
      break;
    case 'pie':
      series = [{
        name: 'Requests received',
        colorByPoint: true,
        data: sortedData.map(item => ({
        name: item.name,
        y: item.requests_shared
        })),
        showInLegend: true,
      }];
      break;
    case 'column':
      series = [{
        pointWidth: 50,
        name: 'Requests received',
        data: sortedData.map(item => ({
          y: item.requests_shared,
          name: item.name
        })),
        colorByPoint: true,
        showInLegend: false,
      }];
      break;
    default:
      break;
  }

  const options = {
    chart: {
      type: chartType,
      width: chartType === 'bar' || chartType === 'column' ? (window.innerWidth < 1500 ? 900 : 1300) : null,
    },
    title: {
      text: `Shared requests <strong style="font-size: 21px">${totalRequests}</strong>`,
      useHTML: true,
      style: {
        color: '#3e85f9',
        fontFamily: 'SF Pro Display',
      },
    },
    xAxis: chartType === 'bar' || chartType === 'column' ? {
      categories: categoriesWithPercentages,
      title: {
        text: null,
      }, labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'SF Pro Display',
        }
      }
    } : {
      categories: sortedData.map(item => item.name),
    },
    yAxis: {
      title: {
        text: chartType === 'pie'? null : 'Requests received',
        style: {
          fontFamily: 'SF Pro Display',
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'SF Pro Display',
        }
      },
      min: 0,
    },
    series: series,
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<p>{point.name}</p>: {point.y} ({point.percentage:.1f}%)',
          style: {
            fontFamily: 'SF Pro Display',
            fontSize: '14px',
          },
        },
      },
      bar: {
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: 'SF Pro Display',
          },
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: 'SF Pro Display',
          },
        },
      },
    },
    legend: {
      itemStyle: {
        fontWeight: 'normal',
        fontSize: '14px', 
        fontFamily: 'SF Pro Display',
      }
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div className='broker-flow-chart'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default BrokerFlowChart;
