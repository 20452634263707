import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ModesPieChart = (props) => {

  const modesData = [];

  props.modesData.forEach((data) => {
    if (data.y !== 0) {
      modesData.push({ name: data.name, y: data.y })
    }
  })

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      spacing: [0, 0, 15, 15],
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
      margin: 40,
    },
    legend: {
      margin: 50,
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#596980",
        fontWeight: "regular",
      },
      symbolRadius: 0
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.2f} %',

        }
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
        },
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    xAxis: {
      categories: "test",
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "",
        x: -20,
        style: {
          color: "#8591A6",
        },
      },
      labels: {
        style: {
          color: "#8591A6",
          formatter: function () {
            return Highcharts.numberFormat(this.value, 0, ' ');
          },
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "test",
        data: modesData,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        showInLegend: true,
        tooltip: {
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default ModesPieChart;