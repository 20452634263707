import OptionTabTooltip from "../OptionTab/OptionTabTooltip";

const ToggleOrgWithKey = ({ keys, id, checked, toggleFunction, showIcon, description, isOptionsTabSelected }) => {
  return (
    <h3 className={isOptionsTabSelected ? "headeing-switch-light" : "headeing-switch-light capitalize"}>
      <div className="keys-and-icon">
        {keys}
        {showIcon && (<OptionTabTooltip showIcon={showIcon} description={description} />)}
      </div>
      <div className="adjust-switcher">
        <div className="select-switch">
          <label className="switch-box">
            <input type="checkbox" name={keys} checked={checked} onChange={(e) => toggleFunction(e, id)} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </h3>
  )
}

export default ToggleOrgWithKey;