import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import "./banners.css";
import BannersCreate from "../../Components/Banners/BannersCreate";
const CreateBanner = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Create new banner";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <BannersCreate />
      </div>
    </div>
  );
};

export default CreateBanner;
