import { useHistory } from "react-router-dom";
import "./style.css";

const Summary = ({ graphDataCount = {}, acceptedRequestDay = {} }) => {
  const history = useHistory();
  const acceptedRequestDayValues = Object.values(acceptedRequestDay);
  const totalAcceptedRequestsCurrentMonth = acceptedRequestDayValues.reduce((acc, current) => acc + current, 0);
  const consumers = graphDataCount.consumer;
  const corporate = graphDataCount.corporate;

  function decimalDivider(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  if (graphDataCount.consumer && graphDataCount.corporate) {
    decimalDivider(Object.values(consumers));
    decimalDivider(Object.values(corporate));
  }

  const redirect = (path) => { history.push(path); }

  return (
    <div className="requests-box">
      <div className="each-requests-box mr-top-0">
        <div className="request-info clients-table" onClick={() => redirect('/total_clients')}>
          <p>Total clients</p>
          {graphDataCount.total_clients ? (
            <h4>{decimalDivider(graphDataCount.total_clients)}</h4>
          ) : <h4>0</h4>}
        </div>
      </div>
      <br></br>
      <div className="each-requests-box clients-table" onClick={() => redirect('new_clients_week')}>
        <div className="request-info">
          <p>New clients - current week</p>
          {graphDataCount.new_clients_weekly ? (
            <h4>{decimalDivider(graphDataCount.new_clients_weekly)}</h4>
          ) : <h4>0</h4>}
        </div>
      </div>
      <br></br>
      <div className="each-requests-box clients-table" onClick={() => redirect('/new_clients_month')}>
        <div className="request-info">
          <p>New clients - current month</p>
          {graphDataCount.new_clients_monthly ? (
            <h4>{decimalDivider(graphDataCount.new_clients_monthly)}</h4>
          ) : <h4>0</h4>}
        </div>
      </div>
      <br></br>
      <div className="each-requests-box clients-table" onClick={() => redirect('/new_clients_year')}>
        <div className="request-info">
          <p>New clients - current year</p>
          {graphDataCount.new_clients_yearly ? (
            <h4>{decimalDivider(graphDataCount.new_clients_yearly)}</h4>
          ) : <h4>0</h4>}
        </div>
      </div>
      <br></br>
      <div className="each-requests-box">
        <div className="request-info">
          <p>Total accepted request - current month</p>
          <h4>{decimalDivider(totalAcceptedRequestsCurrentMonth) || 0}</h4>
        </div>
      </div>
      <br></br>
      <div className="each-requests-box">
        <div className="request-info">
          <p>Consumer request - current month</p>
          {graphDataCount.consumer ?
            (<h4>{decimalDivider(Object.values(consumers))}</h4>)
            : (<h4>0</h4>)
          }
        </div>
      </div>
      <br></br>
      <div className="each-requests-box">
        <div className="request-info">
          <p>Corporate request - current month</p>
          {graphDataCount.corporate ?
            (<h4>{decimalDivider(Object.values(corporate))}</h4>)
            : (<h4>0</h4>)
          }
        </div>
      </div>
      <br></br>
    </div >
  )
}

export default Summary;