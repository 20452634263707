import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { SET_DEMO_AGGREGATION } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getDemoData(data) {
    const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DEMO_AGGREGATION.LIST()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            const success = res.data.status !== 'error'
            if (success) {
                dispatch(setDemoAggregation(res.data.data));
            }
            return { status: true, message: res.data.status, data: res.data.data };
        }).catch(err => {
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}

export function generateAccessCode(data) {
    const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DEMO_AGGREGATION.GENERATE()}`;
    return dispatch => axios.post(apiURL, data)
        .then(res => {
            const success = res.data.status !== 'error'
            if (success) {
                dispatch(setDemoAggregation(res.data.data));
            }
            return { status: true, message: res.data.message, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}

export function sendAccessCode(data) {
    const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DEMO_AGGREGATION.SEND()}`;
    return dispatch => axios.post(apiURL, data)
        .then(res => {
            const success = res.data.status !== 'error'
            if (success) {
                dispatch(setDemoAggregation(res.data.data));
            }
            return { status: true, data: res.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.message || err.response.data.error };
        });
}

export function setDemoAggregation(response) {
    return {
        type: SET_DEMO_AGGREGATION,
        demo_list: response
    }
}