import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import ConversionRateBanks from "../../Components/ConversionRate/Banks/ConversionRateBanks"
const ConversionRateBanksPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Conversion Rate";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <ConversionRateBanks />
      </div>
    </div>
  );
};

export default ConversionRateBanksPage;