import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEmailList } from "../../Redux/Actions/EmailList";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { left } from "@popperjs/core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import sendagain from "../../assets/images/sendagain.svg";
import viewagain from "../../assets/images/viewagain.svg";
import viewcrossed from "../../assets/images/viewcrossed.svg";
import search from "../../assets/images/search.svg";
import close from "../../assets/images/icon_close.png";
import ReactPaginate from "react-paginate";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./emailList.css";
import ReactSelect from "../ReactSelect/ReactSelect";

const API_PAGE_START_INDEX = 1;
const START_DATE = moment(new Date()).format("DD/MM/YYYY");
const END_DATE = moment(new Date()).format("DD/MM/YYYY");
const EmailList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailDateRange, setEmailDateRange] = useState(
    `${START_DATE}-${END_DATE}`
  );
  const [expand, setExpand] = useState({ id: null, open: null });
  const [emailLogsList, setEmailLogsList] = useState([]);
  const [searchEmail, setSearchEmailData] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [graphDataNames, setGraphDataNames] = useState([]);
  const [graphDataValues, setGraphDataValues] = useState([]);
  const [pageData, setPageData] = useState({
    current_page: 1,
    total_pages: "",
    total_count: "",
    next_page: "",
    prev_page: "",
  });
  const [selectedPage, setSelectedPage] = useState(API_PAGE_START_INDEX);
  const [query, setQuery] = useState("");

  useEffect(() => {
    fetchEmailLogs();
  }, [query, selectedPage]);

  const fetchEmailLogs = async () => {
    await dispatch(setLoaderSpinner(true));
    if (selectedPage !== "") {
      let params =
        "?email_date_range=" +
        emailDateRange +
        "&status=" +
        selectedStatus +
        "&organization_id=" +
        organizationId +
        "&page=" +
        selectedPage +
        "&query=" +
        searchEmail;


      const { status, message, data } = await dispatch(getEmailList(params));
      if (status) {
        if (data.email_logs.length > 0) {
          setEmailLogsList(data.email_logs);
        } else {
          setEmailLogsList([]);
        }

        let statusArr = [];
        if (data.status.length > 0) {
          data.status.forEach((item) => {
            statusArr.push(item);
          });
          setStatusList(statusArr);
        }
        // set Organizations list
        if (data.organizations) {
          let organizationName = [];
          Object.entries(data.organizations).forEach(([key, value]) => {
            organizationName.push({
              name: value,
              id: key,
            });
          });
          setOrganizationList(organizationName);
        }

        // set graph data

        if (data.graph_data) {
          let graphNames = Object.keys(data.graph_data);
          setGraphDataNames(graphNames);
          let graphValues = Object.values(data.graph_data);
          setGraphDataValues(graphValues);
        }

        //  Set pagination
        data.pagination_data && setPageData(data.pagination_data);

        await dispatch(setLoaderSpinner(false));
      } else {
        dispatch(setLoaderSpinner(false));
        if (message === "Unauthorize") {
          history.push("/login");
        }
      }
    }
  };

  const handleEmailDatePicker = (start, end) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setEmailDateRange(`${startDateValue}-${endDateValue}`);
  };

  const handleFilter = (event) => {
    fetchEmailLogs();
  };

  // const searchEmailHandler = (event) => {
  // 	const inputData = event.target.value
  // 	setSearchEmailData(event.target.value);
  // }

  // const searchEmailSubmit = () => {
  // 	fetchEmailLogs("Yes");
  // }

  const closeIconSearch = () => {
    setSearchEmailData("");
    setQuery("");
  };

  const capitalizeFirst = (sent) => {
    return sent.charAt(0).toUpperCase() + sent.slice(1);
  };

  const ExpandSetting = (itemId) => {
    const { id, open } = expand;
    if (id === itemId && open) {
      setExpand({ id: itemId, open: false });
    } else {
      setExpand({ id: itemId, open: true });
    }
  };

  const selectPageHandler = (event) => {
    const choosenPage = event.selected + 1;
    setSelectedPage(choosenPage);
  };

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Top Clients",
      align: left,
      margin: 40,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.44,
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
      },
      series: {
        pointWidth: 11,
      },
    },
    colors: ["#3e85f9"],
    xAxis: {
      categories: graphDataNames,
      title: {
        text: "Clients",
      },
      crosshair: true,
    },
    yAxis: {
      type: "logarithmic",
      min: 1,
      title: {
        text: "Total counts",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Top Clients",
        data: graphDataValues,
        showInLegend: false,
      },
    ],
  };
  const renderTable = () => {
    return (
      <div>
        <table className="table" width="100%">
          <thead className="email-thead">
            <tr>
              <th id="status">Status</th>
              <th id="organization">Organization</th>
              <th id="sender">Sender</th>
              <th id="email">Email</th>
              <th id="subject">Subject</th>
              <th id="opens">Opens</th>
              <th id="clicks">Clicks</th>
              <th id="resend">Actions</th>
            </tr>
          </thead>
        </table>
        {emailLogsList &&
          emailLogsList.length > 0 &&
          emailLogsList.map((item, i) => {
            return (
              <table key={Math.random()} className="table" width="100%">
                <tbody>
                  <tr>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td email-span"
                      id="status"
                    >
                      <img
                        className="email-img"
                        src="https://upload.wikimedia.org/wikipedia/commons/1/11/Pan_Green_Circle.png"
                        alt="green circle"
                        width="8"
                        height="8"
                      />
                      {capitalizeFirst(item.status)}
                      <br></br>
                      <p className="sent-date">
                        {moment(item.email_sent_at).format("DD-MM-YYYY")}
                      </p>
                      {expand.id === item.id && expand.open ? (
                        <div className="expanded-row-certificate">
                          {" "}
                          Certificate ID: {item.organization_request_id}{" "}
                        </div>
                      ) : null}
                    </td>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td"
                      id="organization"
                    >
                      {item.organization_name}
                    </td>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td"
                      id="sender"
                      value="from"
                    >
                      {item.email_from}
                      {expand.id === item.id && expand.open ? (
                        <div className="expanded-row">
                          {" "}
                          Time:{" "}
                          {moment(item.email_sent_at).format(
                            "DD-MM-YYYY h:mm:ss a"
                          )}
                        </div>
                      ) : null}{" "}
                    </td>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td"
                      id="email"
                      value="to"
                    >
                      {item.email_to}
                    </td>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td"
                      id="subject"
                      value="subject"
                    >
                      {item.subject}
                    </td>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td opens"
                      id="opens"
                      value="opened"
                    >
                      {item.open_email_logs_count}
                    </td>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td clicks"
                      id="clicks"
                      value="clicked"
                    >
                      {item.click_email_logs_count}
                    </td>
                    <td
                      onClick={() => ExpandSetting(item.id)}
                      className="email-td send-view"
                      id="resend"
                    >
                      <img src={sendagain} alt="send-again-icon" width="21" />{" "}
                      {expand.id === item.id && expand.open ? (
                        <img
                          className="email-img"
                          src={viewcrossed}
                          alt="view-icon"
                          width="22"
                        />
                      ) : (
                        <img
                          className="email-img"
                          src={viewagain}
                          alt="view-icon"
                          width="22"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div className="page-container">
        <div className="top">
          <h3 className="email-h3">Email Logs</h3>
        </div>
        <div className="email-header">
          <div className="email-filter-container">
            <label>Status</label>
            <select
              className="select-filter-email capitalize"
              onChange={(e) => setSelectedStatus(e.target.value)}
              value={selectedStatus}
            >
              <option value="">Select</option>
              {statusList &&
                statusList.length > 0 &&
                statusList.map((status) => {
                  return (
                    <option key={Math.random()} value={status}>
                      {status}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="email-filter-container overflow-inherit">
            <label>Organization</label>
            <ReactSelect
              orgList={organizationList}
              pageRender={"logs"}
              setOrganizationId={setOrganizationId}
            />
          </div>
          <DateRangePicker
            initialSettings={{ locale: { format: "DD/MM/YYYY" } }}
            onCallback={handleEmailDatePicker}
          >
            <input
              type="text"
              id="date-range-picker"
              className="email-date-box"
            />
          </DateRangePicker>
          <div className="calendar-icon-email">
            <FontAwesomeIcon icon={faCalendar} size="lg" color="#e8edfa" />{" "}
          </div>
          <button className="email-filter-button" onClick={handleFilter}>
            {" "}
            Apply{" "}
          </button>
        </div>
        <div className="email-graph">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          ></HighchartsReact>
        </div>
        <div className="resent-logs-box">
          <div className="table-wrapper">
            <h2 className="resent-logs-h2">Logs</h2>

            <div className="input-group search-field">
              <input
                className="search-email"
                placeholder="Enter email adress"
                value={searchEmail}
                onChange={(event) => setSearchEmailData(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setQuery(searchEmail);
                  }
                }}
              />
              {searchEmail.length === 0 ? (
                <img className="search-email-icon" src={search} alt="search" />
              ) : (
                <img
                  className="close-email-icon"
                  src={close}
                  alt="close"
                  onClick={closeIconSearch}
                />
              )}
            </div>
            {renderTable()}

            {pageData.total_pages > 1 && (
              <div className="pagination-box">
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  breakLabel={"..."}
                  pageCount={Math.ceil(pageData.total_pages)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={selectPageHandler}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailList;
