export const topBanksArray = [
  [],                 // no country selected
  [                   //Sweden
    // 15,   //Nordea
    // 30,   //Swedbank
    // 7,   //Handelsbanken
    // 8,   //ICA Banken
    "nordea",
    "swedbank",
    "handelsbanken",
    "se-seb-ob",
  ],
  [                   //Norway
    "DNB",
    "SparebankenM",
    "Handelsbanken",
    "Storebrand",
    "SpareBank1smn"
  ],
  [                   //Finland
    "nordea",
    "danskebank",
    "aland",
    "aktia"
  ],
  [                   //Denmark
    "nordea",
    "danskebank",
    "sydbank",
    "sparnord",
    "nykredit",
  ],
  [                   //UK
    "barclays",
    "hsbc",
    "lloyds",
    "natwest",
    "santander",
  ],
  [                   //US

  ],
  [                   //India

  ],
  [                   //Germany
    "commerzbank",
    "deutschebank",
    "revolut",
    "kfw"
  ],
  [                   //Spain

  ],
  [                   //Portugal

  ],
  [                   //Netherlands
    "revolut",
    "ing",
    "rabobank",
    "abnamro",
  ],
  [                   //Malta

  ],
  [                   //Belgium
    "kbc",
    "argenta",
    "axa",
    "ing"
  ],

]