import React, { useState, useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactPaginate from 'react-paginate';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { getVistaAccountUsersData } from "../../../Redux/Actions/Statistics";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

import "./accountUsers.css";

const AccountUsers = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [accountUsers, setAccountUsers] = useState([]);
  const [filteredAccountUsers, setFilteredAccountUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 30;


  const fetchVistaUsersData = async () => {
    dispatch(setLoaderSpinner(true));
    const response = await dispatch(getVistaAccountUsersData());
    dispatch(setLoaderSpinner(false));

    if (response.status) {
      const sortedAccountUsers = response.data.slice();
      sortedAccountUsers.sort((a, b) => {
        if (a.last_activity_at && b.last_activity_at) {
          return new Date(b.last_activity_at) - new Date(a.last_activity_at);
        } else if (a.last_activity_at) {
          return -1;
        } else if (b.last_activity_at) {
          return 1;
        } else {
          return 0;
        }
      });
      setAccountUsers(sortedAccountUsers);
      setFilteredAccountUsers(sortedAccountUsers);
    } else {
      if (!response.status) {
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    fetchVistaUsersData();
  }, []);

  const sortByTotalUsers = () => {
    const sortedByTotalUsers = [...filteredAccountUsers].sort((a, b) => b.total_users - a.total_users);
    setFilteredAccountUsers(sortedByTotalUsers);
  }

  const sortByTotaActivelUsers = () => {
    const sortedByTotalActiveUsers = [...filteredAccountUsers].sort((a, b) => b.active_users - a.active_users);
    setFilteredAccountUsers(sortedByTotalActiveUsers);
  }

  const handleCallback = (start, end) => {
    const filteredData = accountUsers.filter((user) => {
      if (user.last_activity_at) {
        const userActivityDate = moment(user.last_activity_at).format("YYYY-MM-DD");
        return moment(userActivityDate).isBetween(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"), null, "[]");
      }
      return false;
    });
    setFilteredAccountUsers(filteredData);
  };

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + usersPerPage;
  const currentUsers = filteredAccountUsers.slice(startIndex, endIndex);

  return (
    <div className="kreditz-provider-container page-container" id="kreditz_provider_page">
      <div className="lower-header-a-u">
        <h3>Vista - Account users</h3>
        <div className="flex-header-account-users">
          <DateRangePicker
            onCallback={handleCallback}
            initialSettings={{
              locale: {
                format: "DD/MM/YYYY",
              },
            }}
          >
            <input
              type="text"
              id="date-range-picker"
              className="date-form-box"
            />
          </DateRangePicker>
          <div className="calendar-icon-statistics">
            <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
          </div>
        </div>
      </div>
      <div className="white-bg" style={{ margin: "20px 0 0 0" }}>
        <div>
          <table className="table" width="100%" style={{ margin: "20px 0px 0px 0px" }}>
            <thead className="vista-users-table-header">
              <tr className="vista-account-users-table-header users-list-grid">
                <th className="table-header-au header-column-one">ID</th>
                <th className="table-header-au header-column-two">Client name</th>
                <th className="table-header-au header-column-three" onClick={sortByTotalUsers}>Total users</th>
                <th className="table-header-au header-column-four" onClick={sortByTotaActivelUsers}>Total active users</th>
                <th className="table-header-au header-column-five">Avarage engagement time per user/day</th>
                <th className="table-header-au header-column-six users-id-column-six">Activity</th>
              </tr>
            </thead>
          </table>
          <div className="organizations-thead-scroll-y">
            <table className="table" >
              <tbody className="vista-users-table" width="100%">
                {currentUsers.length > 0 && (
                  currentUsers.map((index, key) => (
                    <tr className="users-list-grid" key={key}>
                      <td className="users-id-column-one">{index.id}</td>
                      <td className="users-id-column-two">{index.name}</td>
                      <td className="users-id-column-four">{index.total_users}</td>
                      <td className="users-id-column-three">{index.active_users}</td>
                      <td className="users-id-column-five">N/A</td>
                      <td className="users-id-column-six">
                        {index.last_activity_at ? (
                          format(new Date(index.last_activity_at), 'yyyy-MM-dd HH:mm')
                        ) : (
                          "No activity"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination-box">
            {accountUsers.length > usersPerPage && (
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                breakLabel={"..."}
                pageCount={Math.ceil(filteredAccountUsers.length / usersPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(selected) => setCurrentPage(selected.selected)}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={currentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div >
  );
}

export default AccountUsers;