import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";

const OptionTabTooltip = ({ description, showIcon }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="tooltip-container-for-option-tab">
      {showIcon && (
        <span
          className="info-icon-wrapper"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
          {showTooltip && (
            <div className="tooltip-container tooltip-container-option-tab">
              <p>{description}</p>
            </div>
          )}
        </span>
      )}
    </div>
  )
}

export default OptionTabTooltip;