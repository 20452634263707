import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import DemoAggregation from "../../Components/DemoAggregation/demoAggregation";

import "./demoAggregation.css";
const DemoAggregationPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Demo Access Codes";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <DemoAggregation />
      </div>
    </div>
  );
};

export default DemoAggregationPage;
