import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function reportListAPI(params) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.REPORT.LIST()}` + params;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.status, result: res.data };
        })
        .catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message }
        })
}

export function getDownloadedUrl(id) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.REPORT.LIST()}/` + id;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.status, result: res.data };
        })
        .catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message }
        })
}

export function addReportToAdminLog(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.REPORT.LIST()}/${data.id}`;
    return (dispatch) =>
        axios
            .put(apiURL, data)
            .then((res) => {
                return { status: true, message: res.data.status, renderMessage: res.data.message, data: res.data.data };
            })
            .catch((err) => {
                err.response.status === 401 && removeHeaderWithLocalstorage();
                return {
                    status: false,
                    message: err.response.data.error || err.response.data.message,
                };
            });
}

// deleteRecordAPI

export function deleteRecordAPI(id) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.REPORT.LIST()}/` + id;
    return dispatch => axios.delete(apiURL)
        .then(res => {
            return { status: true, message: res.data.message };
        })
        .catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message }
        })
}