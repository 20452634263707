import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { getBankConnectionFrequency } from "../../Redux/Actions/BankConnectionFrequency";
import { useHistory } from "react-router-dom";
import BankConnectionGraph from "./BankConnectionGraph";
import BankConnectionTables from "./BankConnectionTables";
import "./bankConnectionFrequency.css";

const BankConnection = () => {
  const [requestsCreated, setRequestsCreated] = useState({});
  const [requestsAccepted, setRequestsAccepted] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    fetchBankConnectionFrequency();
  }, []);

  const fetchBankConnectionFrequency = async () => {
    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getBankConnectionFrequency());
    const {
      requests_created,
      requests_accepted,
      created_at,
      organization_list,
    } = data;

    setRequestsCreated(requests_created);
    setRequestsAccepted(requests_accepted);
    setCreatedAt(created_at);
    setOrganizationList(organization_list);
    setData(data);

    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };
  let allRequests = [];
  organizationList.map((x) => {
    return allRequests.push({
      id: `${x[0]}`,
      organizationName: x[1],
      requestsAccepted: 0,
      requestsCreated: 0,
    });
  });

  let acceptedRequestData = [];

  allRequests.forEach((x) => {
    if (Object.entries(requestsAccepted) !== 0) {
      for (const [key, value] of Object.entries(requestsAccepted)) {
        if (key === x.id) {
          acceptedRequestData.push({
            id: x.id,
            organizationName: x.organizationName,
            requestsAccepted: value,
            requestsCreated: 0,
          });
        }
      }
    }
  });

  let createdRequestData = [];
  allRequests.forEach((x) => {
    if (Object.entries(requestsCreated) !== 0) {
      for (const [key, value] of Object.entries(requestsCreated)) {
        if (key === x.id) {
          createdRequestData.push({
            id: x.id,
            organizationName: x.organizationName,
            requestsAccepted: 0,
            requestsCreated: value,
          });
        }
      }
    }
  });

  let requestData = [];

  for (let x = 0; x < acceptedRequestData.length; x++) {
    for (let y = 0; y < createdRequestData.length; y++) {
      if (acceptedRequestData[x].id === createdRequestData[y].id) {
        requestData.push({
          id: acceptedRequestData[x].id,
          organizationName: `${acceptedRequestData[x].organizationName} (${acceptedRequestData[x].id})`,
          requestsAccepted: acceptedRequestData[x].requestsAccepted,
          requestsCreated: createdRequestData[y].requestsCreated,
        });
      }
    }
  }

  for (let i = 0; i < acceptedRequestData.length; i++) {
    let match = false;
    for (let j = 0; j < createdRequestData.length; j++) {
      if (acceptedRequestData[i].id === createdRequestData[j].id) {
        match = true;
        break;
      }
    }
    if (!match) {
      requestData.push({
        id: acceptedRequestData[i].id,
        organizationName: `${acceptedRequestData[i].organizationName} (${acceptedRequestData[i].id})`,
        requestsAccepted: acceptedRequestData[i].requestsAccepted,
        requestsCreated: 0,
      });
    }
  }

  for (let i = 0; i < createdRequestData.length; i++) {
    let match = false;
    for (let j = 0; j < acceptedRequestData.length; j++) {
      if (createdRequestData[i].id === acceptedRequestData[j].id) {
        match = true;
        break;
      }
    }
    if (!match) {
      requestData.push({
        id: createdRequestData[i].id,
        organizationName: `${createdRequestData[i].organizationName} (${createdRequestData[i].id})`,
        requestsAccepted: 0,
        requestsCreated: createdRequestData[i].requestsCreated,
      });
    }
  }

  let sortedRequests = [...requestData];
  sortedRequests.sort((a, b) => {
    return b.requestsAccepted - a.requestsAccepted;
  });

  let graphRequests = [...sortedRequests]
  let tableRequests = []

  if (graphRequests.length > 10) {
    graphRequests.splice(10);
  }

  if (sortedRequests.length > 10) {
    tableRequests = sortedRequests.slice(10)
  }

  return (
    <div className="page-container bank-connection-container">
      <div className="lower-header">
        <h3>Bank Connection Frequency</h3>
      </div>
      <section className="bank-connection-graph-container">
        {data && (
          <BankConnectionGraph
            requestsCreated={requestsCreated}
            requestsAccepted={requestsAccepted}
            createdAt={createdAt}
            organizationList={organizationList}
            requestData={graphRequests}
          />
        )}
      </section>
      <section className="bank-connection-tables-container">
        <BankConnectionTables requestData={tableRequests} />
      </section>
    </div>
  );
};

export default BankConnection;
