import { useState } from "react";
import remove from "../../../assets/images/remove.svg";
import { deleteApiDocumentation } from "../../../Redux/Actions/ApiDocumentation";
import { useDispatch } from "react-redux";

export const DeletePopup = (props) => {
  const dispatch = useDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  let id = props.id;
  const array = props.array;

  const deleteApiDocumentationVersion = async () => {
    const { status, message } = await dispatch(deleteApiDocumentation(id));
    if (status) {
      props.setFlashMessage(true);
      props.setFlashData(prevState => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: "Success",
        secondMsg: message
      }))
    }
    props.update(true);
    closePopup()
  }

  const errorCheck = () => {
    if (array.length > 1) {
      if (!togglePopup) {
        setTogglePopup(true);
      }
    } else {
      if (!showErrors) {
        setShowErrors(true);
      }
    }
  };

  const closePopup = () => {
    if (togglePopup || showErrors) {
      setTogglePopup(false);
      setShowErrors(false);
    }
  };

  return (
    <>
      <span
        className="remove-icon"
        onClick={errorCheck}
      >
        <img src={remove} alt="remove" />

      </span>
      {togglePopup && (
        <div className="api-popup">
          <div className="conversion-confirm-box">
            <p>Do you want to delete version {props.version} from the API Documentation?</p>
            <div className="confirm-btn-container">
              <button onClick={closePopup} className="conversion-close-btn">
                Close
              </button>
              <button
                className="delete-api-btn"
                onClick={deleteApiDocumentationVersion}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {showErrors && (
        <div className="api-popup">
          <div className="conversion-confirm-box">
            <p>
              You need to have at least one version of API documentation
            </p>
            <div className="confirm-btn-container">
              <button onClick={closePopup} className="conversion-close-btn">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};