import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getAuditReport(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUDIT_REPORT.LIST()}` + data;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: res.status, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: err.response.status, message: err.response.data.error || err.response.data.message }
    })
}

export function getAuditReportDetails(id, data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUDIT_REPORT.DETAILS()}/${id}/` + data;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: res.status, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: err.response.status, message: err.response.data.error || err.response.data.message }
    })
}

export function createAuditReportFile(id, data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUDIT_REPORT.PDF_AUDIT_REPORT()}/${id ? `${id}/` : ""}` + data;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: res.status, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: err.response.status, message: err.response.data.error || err.response.data.message }
    })
}

export function getAuditReportPdf(params) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUDIT_REPORT.PDFREPORT()}` + params;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}