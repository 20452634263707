import { useEffect, useState } from 'react';
import moment from 'moment';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import searchIcon from '../../../assets/images/search.svg';
import closeIcon from '../../../assets/images/icon_close_reports.svg';
import pdfIcon from '../../../assets/images/pdfIcon.png';
import CsvIcon from '../../../assets/images/CsvIcon.png';
const END_DATE = moment().endOf('day')
const START_DATE = END_DATE.clone().subtract(1, 'weeks')

const formatDateToRange = (start_date, end_date) => {
  const date_range = `${start_date.format('DD/MM/YYYY')}-${end_date.format('DD/MM/YYYY')}`
  return date_range;
}

const QueryUserHeader = ({ updateQuery, initialQuery, generateAuditReport }) => {

  const [startDateValue, setStartDateValue] = useState(moment(initialQuery.selectedDateRange.split('-')[0], 'DD/MM/YYYY').format('DD-MM-YYYY') || START_DATE.format('DD-MM-YYYY'));
  const [endDateValue, setEndDateValue] = useState(moment(initialQuery.selectedDateRange.split('-')[1], 'DD/MM/YYYY').format('DD-MM-YYYY') || END_DATE.format('DD-MM-YYYY'));
  const [selectedDateRange, setSelectedDateRange] = useState(formatDateToRange(START_DATE, END_DATE));
  const [searchKey, setSearchKey] = useState('');
  const [queryString, setQueryString] = useState(searchKey);
  const [query, setQuery] = useState({
    selectedDateRange: selectedDateRange,
    searchKey: searchKey,
  });

  const handleDateRangePicker = (start, end) => {
    setSelectedDateRange(formatDateToRange(start, end));
    setStartDateValue(moment(start._d).format('DD-MM-YYYY'));
    setEndDateValue(moment(end._d).format('DD-MM-YYYY'));
  };

  const onQueryChange = () => {
    const oldQuery = query;
    const newQuery = {
      selectedDateRange: selectedDateRange,
      queryString: queryString,
    };
    if (JSON.stringify(oldQuery) !== JSON.stringify(newQuery)) {
      setQuery(newQuery);
      updateQuery(newQuery);
    }
  }

  const handleSubmit = () => {
    setQueryString(searchKey);
  };

  const handleKey = (e) => {
    setSearchKey(e.target.value);
  };

  const handleClose = () => {
    setSearchKey('');
    setQueryString('');
  };

  const handleFileGeneration = (file_type) => {
    generateAuditReport(file_type);
  }

  useEffect(() => {
    if (initialQuery) {
      setSelectedDateRange(initialQuery.selectedDateRange);
    };
    updateQuery(query);
  }, []);

  useEffect(() => {
    onQueryChange();
  }, [queryString, selectedDateRange]);

  return (<>
    <div className="reports-search-field push-right">
      <input
        name="seach"
        className="reports-search"
        placeholder="Enter organization name"
        autoComplete="off"
        type="text"
        value={searchKey}
        onChange={handleKey}
        onBlur={handleSubmit}
      />
      {searchKey.length === 0 ? (
        <img
          src={searchIcon}
          alt="search"
          className="reports-search-icon"
        />
      ) : (
        <img
          src={closeIcon}
          alt="close"
          onClick={handleClose}
          className="reports-close-icon"
        />
      )}
    </div>
    <DateRangePicker
      onCallback={handleDateRangePicker}
      initialSettings={{
        startDate: startDateValue,
        endDate: endDateValue,
        locale: { format: 'DD/MM/YYYY' }
      }}
      onApply={handleSubmit}
    >
      <input
        type="text"
        id="date-range-picker"
        className="reports-date-box"
      />
    </DateRangePicker>
    <div className="reports-calendar-icon">
      <FontAwesomeIcon icon={faCalendar} size="lg" color="#e8edfa" />{' '}
    </div>
    <img
      src={pdfIcon}
      alt="download icon"
      className="reports-download-icon"
      href="#!"
      onClick={() => handleFileGeneration('pdf')}
    />
    <img
      src={CsvIcon}
      alt="download csv icon"
      className="reports-download-icon"
      href="#!"
      onClick={() => handleFileGeneration('csv')}
    />
  </>)

}


export default QueryUserHeader