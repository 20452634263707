import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";
import { SET_API_DOCUMENTATION_LIST, SET_CREATE_API_DOCUMENTATION, SET_API_DOCS_SETTING } from "./Types/Types";

export function getApiDocumentation(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.API_DOCUMENTATION.LIST()}` + data;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function createApiDocumentation(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.API_DOCUMENTATION.LIST()}`;

  return (dispatch) =>
    axios
      .post(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.message, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function showApiDocumentation(id) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.API_DOCUMENTATION.LIST()}/${id}`;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function deleteApiDocumentation(id) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.API_DOCUMENTATION.LIST()}/${id}`;

  return (dispatch) =>
    axios
      .delete(apiURL)
      .then((res) => {
        return { status: true, message: res.data.message, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function updateApiDocumentation(id, data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.API_DOCUMENTATION.LIST()}/${id}`;

  return (dispatch) =>
    axios
      .put(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.message, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function getApiDocsSettings() {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.API_DOCUMENTATION.SETTING()}`;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function updateApiDocumentationSetting(id, data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN
    }${API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.API_DOCUMENTATION.SETTING()}/${id}`;

  return (dispatch) =>
    axios
      .put(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.message, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function setApiDocumentationList(response) {
  return {
    type: SET_API_DOCUMENTATION_LIST,
    api_documentation: response,
  };
}

export function setCreateApiDocumentation(response) {
  return {
    type: SET_CREATE_API_DOCUMENTATION,
    api_documentation: response,
  };
}

export function setApiDocumentationSetting(response) {
  return {
    type: SET_API_DOCS_SETTING,
    api_documentation_setting: response,
  };
}