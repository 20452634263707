import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import ApiDocumentationNew from "../../Components/ApiDocumentationNew/ApiDocumentationNew";
const ApiDocumentationNewPage = () => {
    useEffect(() => {
        document.title = "Kreditz | Admin portal - API Documentation Editor";
    }, []);
    return (
        <div className="wrapper">
            <Header />
            <div className="main-container">
                <Sidebar />
                <ApiDocumentationNew />
            </div>
        </div>
    )
}

export default ApiDocumentationNewPage;