

const BankStatusHeader = () => {

  return (
    <>
      <h3>Bank Status</h3>
    </>
  )
}

export default BankStatusHeader;