const PreviewViewer = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(); 
    onClose(); 
  };

  return (
    <div className="preview-modal-backdrop">
      <div className="preview-modal">
        <h5 className="preview-modal-h5">Confirm for income request email</h5>
        <p>Loading default content will remove your current work from editor and replace it with default preview content (for editing purpose).</p>
        <div className="preview-modal-actions">
          <button className='preview-viewer-no' onClick={() => onClose()}>No</button>
          <button className='preview-viewer-yes' onClick={handleConfirm}>Yes</button>
        </div>
      </div>
    </div>
  );
};

export default PreviewViewer;
