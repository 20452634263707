import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../Worldmap.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faSortUp } from '@fortawesome/free-solid-svg-icons';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { getAllCountry } from "../../../../Redux/Actions/Countries";
import { getOrganizationLiveData, getMarketsLiveData } from "../../../../Redux/Actions/LiveData";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const ShowDetails = () => {
  const [selectedRequestType, setSelectedRequestType] = useState('All Requests');
  const [selectedCountry, setSelectedCountry] = useState('All Countries');
  const [showDetails, setShowDetails] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [allRequests, setAllRequests] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [filteredCountriesList, setFilteredCountriesList] = useState([]);
  const [totalAcceptedRequests, setTotalAcceptedRequests] = useState(0);
  const [totalOnlineCustomers, setTotalOnlineCustomers] = useState(0);
  const dispatch = useDispatch();
  const showDetailsRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [orgResponse, countryResponse, marketResponse] = await Promise.all([
          dispatch(getOrganizationLiveData()),
          dispatch(getAllCountry()),
          dispatch(getMarketsLiveData()),
        ]);

        [orgResponse, countryResponse, marketResponse].forEach(response => {
          if (response.message === 'Unauthorized') {
            history.push('/login');
          }
        });

        // Process organization data
        if (orgResponse.status) {
          const totalCount = orgResponse.data.request_count_by_organization.reduce((acc, current) => acc + current.count, 0);
          setTotalAcceptedRequests(totalCount);
          setTotalOnlineCustomers(orgResponse.data.request_count_by_organization.length);
        }

        // Process country data
        if (countryResponse.status) {
          const excludedCountries = ['india', 'united states', 'portugal', 'malta'];
          const filteredCountries = countryResponse.data.countries_list.filter(country => !excludedCountries.includes(country.name.toLowerCase()));
          setCountriesList(filteredCountries);
        }

        // Process market data
        if (marketResponse.status) {
          const liveData = marketResponse.data.data;
          setAllRequests(liveData);
        }
      } catch (error) {
      }
      
    };

    fetchData();
    // Interval to fetch live data
    const intervalId = setInterval(async () => {
      try {
        const marketResponse = await dispatch(getMarketsLiveData());
        const orgResponse = await dispatch(getOrganizationLiveData());

        if (marketResponse.status) {
          setAllRequests(marketResponse.data.data);
        }

        if (orgResponse.status) {
          const totalCount = orgResponse.data.request_count_by_organization.reduce((acc, current) => acc + current.count, 0);
          setTotalAcceptedRequests(totalCount);
          setTotalOnlineCustomers(orgResponse.data.request_count_by_organization.length);
        }
      } catch (error) {
      }
    }, 3500);
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, history]);

  const filterData = useCallback(() => {
    let filteredRequests = allRequests;
    if (selectedRequestType && selectedRequestType !== 'All Requests') {
      filteredRequests = filteredRequests.filter(request =>
        request?.request_type?.toLowerCase() === selectedRequestType.toLowerCase()
      );
    }
  
    let filteredCountries = countriesList.map(country => {
      const acceptedRequestsCount = filteredRequests.reduce((acc, request) => {
        if (request?.market?.toLowerCase() === country?.name?.toLowerCase()) {
          return acc + (request?.accepted_requests_count || 0);
        }
        return acc;
      }, 0);
  
      return {
        ...country,
        accepted_requests_count: acceptedRequestsCount
      };
    });
  
    if (selectedCountry && selectedCountry !== 'All Countries') {
      filteredCountries = filteredCountries.filter(country =>
        country?.name?.toLowerCase() === selectedCountry.toLowerCase()
      );
    }
  
    setFilteredCountriesList(filteredCountries);
  }, [allRequests, countriesList, selectedRequestType, selectedCountry]);

  useEffect(() => {
    filterData();
  }, [allRequests, countriesList, selectedRequestType, selectedCountry,filterData]);

  useEffect(() => {
    const filteredCountries = countriesList.map(country => {
      const liveCountryData = allRequests.filter(liveCountry => {
        return liveCountry.market.toLowerCase() === country.name.toLowerCase() &&
          (selectedRequestType === 'All Requests' || liveCountry.request_type.toLowerCase() === selectedRequestType.toLowerCase());
      });
      const totalAcceptedRequests = liveCountryData.reduce((acc, current) => acc + current.accepted_requests_count, 0);
      return { ...country, accepted_requests_count: totalAcceptedRequests };
    });

    setFilteredCountriesList(filteredCountries);
  }, [allRequests, countriesList, selectedRequestType]);

  const handleRequestTypeChange = (event) => {
    setSelectedRequestType(event.target.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const toggleDetails = () => {
    setShowDetails(prevShowDetails => !prevShowDetails);
  };

  const toggleFilters = () => {
    setShowFilters(prevShowFilters => !prevShowFilters);
  };

  const formatCountryName = (countryName) => {
    return countryName === 'united kingdom' ? 'United Kingdom' : countryName.charAt(0).toUpperCase() + countryName.slice(1);
  };

  const getFilteredCountriesList = () => {
    return filteredCountriesList
      .filter(country => selectedCountry === 'All Countries' || country.name.toLowerCase() === selectedCountry.toLowerCase())
      .filter(country => country.accepted_requests_count > 0)
      .sort((a, b) => b.accepted_requests_count - a.accepted_requests_count);
  };

  return (
    <>
      <div className={`show-details-toggle ${showDetails ? 'hidden' : ''}`} onClick={toggleDetails}>
        Show Details
        <div className="show-details-icon">
          <FontAwesomeIcon icon={faChevronRight} size="xs" />
        </div>
      </div>
      {showDetails && (
        <div ref={showDetailsRef} className="show-details-container">
          <div className='show-filters-button-position'>
          <button className="show-filters-button" onClick={toggleFilters}>
            {showFilters ? <FontAwesomeIcon icon={faSortUp} size="1x" />  : <FontAwesomeIcon icon={faSortDown} size="1x"/>}
          </button>
          </div>
          {showFilters && (
            <>
              <select className='show-details-select' value={selectedRequestType} onChange={handleRequestTypeChange}>
                <option value="All Requests">All Requests</option>
                <option value="Customer">Consumer</option>
                <option value="Corporate">Corporate</option>
              </select>
              <select className='show-details-select' value={selectedCountry} onChange={handleCountryChange}>
                <option value="All Countries">All Countries</option>
                {countriesList.map(country => (
                  <option key={country.name} value={country.name}>
                    {formatCountryName(country.name)}
                  </option>
                ))}
              </select>
            </>
          )}
          <h5 className='mt-4 show-details-today-title'>Today</h5>
          <p style={{ color: '#76fb4d' }}>Accepted requests: {totalAcceptedRequests}</p>
          <p style={{ color: '#55b9f9' }}>Online customers: {totalOnlineCustomers}</p>
          <div className='border-showDetails'></div>
          <div className="country-list">
            {getFilteredCountriesList().map((country, index) => (
              <div key={index}>
                <span>{formatCountryName(country.name)}</span>
                <span>{country.accepted_requests_count}</span>
              </div>
            ))}
          </div>
          <div className='show-details-toggle-up'>
            <div className="show-details-icon-up" onClick={toggleDetails}>
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowDetails;