import React from 'react';
import './transactionModal.scss'

const TransactionModal = (props) => {
    return (
        <div className='transaction'>
            {props.bankName.toLowerCase() === "swedbank" ?
                <div className='transaction_inner inner'>
                    <div className="main1">
                        <div className="bank-title">SWEDBANK</div>
                        <div className="bank-title">SWEDBANK allows refetching 89 days data only 1 time!</div>
                        <p className='title'>Are you sure you want to refetch transactions for certificate</p>
                        <p className='certificate-no'>{props.certificateId}</p>
                    </div>
                    <div className="btn-div">
                        <button className='no-btn' onClick={() => props.setShowTransactionModal(false)}>No</button>
                        <button className='yes-btn' onClick={() => props.handleSubmit(props.certificateId)}>Yes</button>
                    </div>
                </div>
                :
                <div className='transaction_inner'>
                    <div className="main">
                        <p className='title'>Are you sure you want to refetch transactions for certificate</p>
                        <p className='certificate-no'>{props.certificateId}</p>
                    </div>
                    <div className="btn-div">
                        <button className='no-btn' onClick={() => props.setShowTransactionModal(false)}>No</button>
                        <button className='yes-btn' onClick={() => props.handleSubmit(props.certificateId)}>Yes</button>
                    </div>
                </div>
            }
        </div>
    )
}
export default TransactionModal;