import OptionTabTooltip from "../OptionTab/OptionTabTooltip";

const OptionOrgInput = ({ type, name, keys, value, changeHandler, showIcon, description }) => {
  return (
    <h3 className="headeing-switch-light">
      <div className="keys-and-icon">
        {keys}
        {showIcon && (
          <OptionTabTooltip showIcon={showIcon} description={description} />
        )}
      </div>
      <label className="form-group" style={{ width: "216px" }}>
        <input name={name} className="form-control w-100" type={type} min="1" value={value} onChange={(e) => changeHandler(e)} />
      </label>
    </h3>)
}

export default OptionOrgInput