import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const CustomerActivityMtdGraph = (props) => {

  let firstMonthRequests = [];
  Object.values(props.acceptedRequestsFirst).map((x) => {
    return firstMonthRequests.push(x);
  });

  let lastMonthRequests = [];
  Object.values(props.acceptedRequestsLast).map((x) => {
    return lastMonthRequests.push(x);
  });

  let dates = []

  Object.keys(props.acceptedRequestsFirst).map((x) => {
    let date1 = x;
    let dayFirst = date1.slice(8, 10);
    let monthFirst = date1.slice(5, 7);
    let yearFirst = date1.slice(0, 4);
    let monthName1 = new Date(`${yearFirst}, ${monthFirst}, ${dayFirst} `).toLocaleString('default', { month: 'short' }).slice(0, 3);
    return dates.push(dayFirst + " " + monthName1);
  });

  const currentMonth = props.selectedCurrentMonth + " " + props.currentYear;
  const prevMonth = props.selectedPrevMonth + " " + props.yearLast;

  const options = {
    chart: {
      type: props.graphType,
      spacing: [15, 0, 15, 15],
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
      margin: 40,
    },

    legend: {
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#596980",
        fontWeight: "regular",
      },
      symbolRadius: 0
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        groupPadding: 0.15,
        dataLabels: {
          enabled: true,
          rotation: 270,
          y: -15,
          allowOverlap: true,
          inside: false,
          crop: false,
        },
        formatter: function () {
          return Highcharts.numberFormat(this.y, 0, ' ');
        },
      },
      line: {
        dashStyle: "LongDash",
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
        },
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    colors: ["#3e85f9", "#43BF58"],
    xAxis: {
      categories: dates,
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "",
        x: -20,
        style: {
          color: "#8591A6",
        },
      },
      labels: {
        style: {
          color: "#8591A6",
          formatter: function () {
            return Highcharts.numberFormat(this.value, 0, ' ');
          },
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: prevMonth,
        data: lastMonthRequests,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          headerFormat:
            "<span>" + prevMonth + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        categories: "test",
        marker: {
          symbol: "square",
        }
      },
      {
        name: currentMonth,
        data: firstMonthRequests,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          headerFormat:
            "<span>" + currentMonth + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        marker: {
          symbol: "square",
        }
      },
    ],
  };
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div >
  )
}

export default CustomerActivityMtdGraph;