// Set Flash Message
export const setdisplayFlash = (flashValue) => {
    localStorage.setItem('flash', flashValue);
}
// Get Flash Message
export const getdisplayFlash = () => {
    return localStorage.getItem('flash') === "true" || false;
}
// Get User Name
export const userName = () => {
    return localStorage.getItem('name')
}

// Get UserId
export const userId = () => {
    return localStorage.getItem('id')
}