import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";

export function getEventLogs(page, organization_id, user_id = '', event_type = '', entity='', start_date = '', end_date = '') {

  let apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.EVENT_LOGS.LIST()}?page=${page}&organization_id=${encodeURIComponent(organization_id)}`;
  
  if (user_id) {
    apiURL += `&user_id=${encodeURIComponent(user_id)}`;
  }
  if (event_type) {
    apiURL += `&event_type=${encodeURIComponent(event_type)}`;
  }
  if (start_date) {
    apiURL += `&start_date=${encodeURIComponent(start_date)}`;
  }
  if (end_date) {
    apiURL += `&end_date=${encodeURIComponent(end_date)}`;
  }
  if (entity) {
    apiURL += `&entity=${encodeURIComponent(entity)}`;
  }

  return dispatch => axios.get(apiURL)
  
    .then(res => {
      if (res.status === 200) {
        return {
          status: res.status,
          data: res.data.data,
          pagination: res.data.pagination 
        };
      } else {
        console.error("Non-200 status code received", res.status);
        return { status: res.status };
      }
    }).catch(err => {
      if (err.response) {
        console.error("API Error with status:", err.response.status, err.response.data);
        if (err.response.status === 401) {
          removeHeaderWithLocalstorage();
        }
        return {
          status: err.response.status,
          message: err.response.data.error || err.response.data.message
        };
      } else {
        console.error("API Error:", err);
        return { status: 500, message: "Internal Server Error" };
      }
    });
}
