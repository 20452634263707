export const verifyAccess = (accessSetting, roleRequirement, roleExclusion) => {
  const accessValue = accessSetting.replace(/^\/|\/$/g, '');

  const access = localStorage.getItem("access-settings");
  const role = localStorage.getItem("role");
  if (access) {
    const accessSettings = JSON.parse(access);
    if (accessSettings.hasOwnProperty(accessValue)) {
      if (accessSettings[accessValue] === true) {
        return true;
      } else {
        return false;
      }
    } else if (roleRequirement && roleRequirement.length > 0) {
      if (roleRequirement.includes(role)) {
        return true;
      } else {
        return false;
      }
    } else if (roleExclusion && roleExclusion.includes(role)) {
      return false;
    } else {
      return true;
    }
  }
}

export const verifySubmenuAccess = (menuItem) => {
  if (!menuItem.hasOwnProperty("children")) {
    return verifyAccess(menuItem.path, menuItem.role, menuItem.roleExclusion);
  }
  const access = localStorage.getItem("access-settings");
  const role = localStorage.getItem("role");

  if (access) {
    const accessSettings = JSON.parse(access);

    const childrenAccessSettings = menuItem.children.map((child) => {
      const path = sanitizeValue(child.path);
      if (accessSettings.hasOwnProperty(path)) {
        return accessSettings[path];
      } else if (child.role && child.role.length > 0) {
        if (child.role.includes(role)) {
          return true;
        } else {
          return false;
        }
      } else if (child.roleExclusion && child.roleExclusion.includes(role)) {
        return false;
      } else {
        return true;
      }
    })
    const hasAccess = childrenAccessSettings.some((access) => {
      return access === true;
    })
    return hasAccess;
  }
  return false;

}

const sanitizeValue = (string) => {
  return string.replace(/^\/|\/$/g, '');
}