import { useState } from "react";
import downloadIcon from "../../assets/images/download_icon.svg";
export const ExportXlsx = (props) => {
  const [toggleConfirm, setToggleConfirm] = useState(false);

  const showConfirm = () => {
    if (!toggleConfirm) {
      setToggleConfirm(true);
    }
  };

  const closeConfirm = () => {
    if (toggleConfirm) {
      setToggleConfirm(false);
    }
  };

  const downloadXlsx = (e) => {
    setToggleConfirm(false);
    props.generateXlsxFile()
  };

  return (
    <>
      <button className="conversion-download-btn" onClick={showConfirm}>
        {" "}
        <img src={downloadIcon} alt="download-icon" className="download-icon" />
      </button>
      {toggleConfirm && (
        <div className="conversion-confirm">
          <div className="conversion-confirm-box">
            <p>Do you want to generate xlsx file?</p>
            <div className="confirm-btn-container">
              <button onClick={closeConfirm} className="conversion-close-btn">
                Close
              </button>
              <button
                className="conversion-continue-btn"
                onClick={downloadXlsx}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
