import React from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import NewClientsWeekly from "../../Components/NewClientsWeek/NewClientsWeekly";


const NewClientsWeek = () => {
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <NewClientsWeekly />
      </div>
    </div>
  );
};

export default NewClientsWeek;