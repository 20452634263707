import React from 'react';
import { Link } from "react-router-dom";
import eyeIcon from '../../../assets/images/eye_icon.svg';

const JobsTable = ({ availableJobs, userData, formatDateTime }) => {
  const statusToClass = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'success-result';
      case 'failed':
        return 'failed-result';
      case 'idle':
        return 'idle-result';
      default:
        return '';
    }
  };

  return (
    <div className="backgroundJobs-thead-scroll-y">
      <table className="backgroundJobs-table" width="100%">
        <thead className="backgroundJobs-thead">
          <tr>
            <th className="backgroundJobs-head">Name</th>
            <th className="backgroundJobs-head">Last success</th>
            <th className="backgroundJobs-head">Last runner</th>
            <th className="backgroundJobs-head">Last duration</th>
            <th className="backgroundJobs-head">Status</th>
            <th className="backgroundJobs-head"></th>
          </tr>
        </thead>
        <tbody className="backgroundJobs-tbody">
          {availableJobs.map((job) => {
            const lastRunner = userData.find(user => user.id === job.last_runner_id);
            const jobStatusClass = statusToClass(job.status);
            const isIdle = job.status.toLowerCase() === 'idle';

            return (
              <tr key={job.id}>
                <td>{job.name}</td>
                <td>{!isIdle && job.last_successful_run ? formatDateTime(job.last_successful_run) : 'N/A'}</td>
                <td>{lastRunner ? lastRunner.name : 'N/A'}</td>
                <td>{!isIdle && job.execution_time !== null ? `${job.execution_time}s` : 'N/A'}</td>
                <td className={jobStatusClass}>
                  {job.status.toLowerCase() === 'running' ? (
                    <div className="spinner-border text-primary" role="status">
                    </div>
                  ) : (
                    <div className="capitalize-first-letter">{job.status}</div>
                  )}
                </td>
                <td>
                  {job.id && (
                    <Link to={{
                      pathname: `/background_jobs_detail/${job.id}`,
                      state: { job, userData }
                    }}>
                      <img src={eyeIcon} alt="View Details" />
                    </Link>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default JobsTable;