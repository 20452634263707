import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import BackgroundJobs from "../../Components/BackgroundJobs/BackgroundJobs"

const BackgroundJobsPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Background Jobs";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <BackgroundJobs />
      </div>
    </div>
  );
};

export default BackgroundJobsPage;
