const ReportLog = (props) => {
  const adminLog = props.adminLog;
  let id = adminLog.object_id;
  let documentKey = adminLog.column_affected.report.document_key;
  let reportType = adminLog.column_affected.report.report_type;
  let reportAction = adminLog.column_affected.report.report_action;
  return (
    <div key={Math.random()} >
      <p className="al-column-affected-p">
        <strong>ID:</strong> {" " + id}
      </p>
      <p className="al-column-affected-p">
        <strong>Report</strong>
      </p>
      <p className="al-column-affected-p">
        Report action: {" " + reportAction}<br />
        Report type: {" " + reportType}<br />
        Report name: {" " + documentKey}<br />
      </p>
    </div>
  )
}
export default ReportLog;