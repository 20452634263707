import React from "react";
import './style.scss';
const PageNotFound = () => {
	return(
		<div className="grid-container">
			<div className="grid-x grid-padding-x align-center">
				<div className="cell large-8">

					<div id="notfound">
						<div className="notfound">
							<div className="notfound-404">
								<h2>Page Not Found</h2>
							</div>
							<a href="/">Back</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export default PageNotFound;
