import { useHistory } from 'react-router-dom'
import './style.scss'
const HeaderReturn = () => {
  const history = useHistory();
  const navigateToOverview = () => {
    history.push("/banners")
  }
  return (
    <>
      <div className='d-inline banner-header-arrow' onClick={navigateToOverview}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#596980" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </>
  )
}

export default HeaderReturn