import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getAllSmsTemplates() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.LIST_ALL_SMS_TEMPLATES()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const modifiedData = res.data.data.map(template => {
        const name = template.name.replace(/_da|_sw/g, match => (match === '_da' ? '_sw' : '_da'));
        return { ...template, name };
      });
      return { status: true, message: res.data.status, data: modifiedData };
    }).catch(err => {
      console.error("Error fetching all SMS templates:", err);
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function fetchSmsTemplateByName(templateName, organizationId) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.FETCH_SMS_TEMPLATE()}`;
  return dispatch => axios.post(apiURL, {
    name: templateName, 
    current_organization_id: organizationId
  })
    .then(res => {
      const payload = res.data.data;
      return { status: true, message: 'Sms template fetched successfully', data: payload };
    }).catch(err => {
      console.error("Error fetching SMS template by name:", err);
      (err.response && err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function fetchSmsTemplatePreview(name, current_organization_id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.FETCH_SMS_TEMPLATE_PREVIEW()}`;
  return (dispatch) => {
    return axios.post(apiURL, { name, current_organization_id })
      .then((response) => {
        const payload = response.data.data;
        dispatch({ type: 'FETCH_SMS_TEMPLATE_PREVIEW_SUCCESS', payload });
        return payload; 
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_SMS_TEMPLATE_PREVIEW_FAILURE', payload: error });
        throw error;
      });
  };
}

export function createSmsTemplate(templateData) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.CREATE_SMS_TEMPLATE()}`;
  return dispatch => axios.post(apiURL, templateData)
    .then(res => {
      dispatch({ type: 'CREATE_SMS_TEMPLATE_SUCCESS', payload: res.data.data });
      return { status: true, message: 'SMS Template created successfully', data: res.data.data };
    }).catch(err => {
      console.error("Error creating SMS template:", err);
      dispatch({ type: 'CREATE_SMS_TEMPLATE_FAILURE', payload: err });
      return { status: false, message: err.response ? err.response.data.error || err.response.data.message : err.message };
    });
}

export function updateSmsTemplate(templateId, templateData) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.UPDATE_SMS_TEMPLATE(templateId)}`;
  return dispatch => axios.put(apiURL, templateData)
    .then(res => {
      dispatch({ type: 'UPDATE_SMS_TEMPLATE_SUCCESS', payload: res.data.data });
      return { status: true, message: 'SMS Template updated successfully', data: res.data.data };
    }).catch(err => {
      console.error("Error updating SMS template:", err);
      dispatch({ type: 'UPDATE_SMS_TEMPLATE_FAILURE', payload: err });
      return { status: false, message: err.response ? err.response.data.error || err.response.data.message : err.message };
    });
}

export function getAllEmailTemplates() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.LIST_ALL_EMAIL_TEMPLATES()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const modifiedData = res.data.data.map(template => {
        let name = template.name.replace(/_da|_sw/g, match => (match === '_da' ? '_sw' : '_da'));
        let localized_name = template.localized_name.replace(/_da|_sw/g, match => (match === '_da' ? '_sw' : '_da'));
        return { ...template, name, localized_name };
      });

      return { status: true, message: res.data.status, data: modifiedData };
    }).catch(err => {
      console.error("Error fetching all Email templates:", err);
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function fetchEmailTemplateByName(templateName, organizationId) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.FETCH_EMAIL_TEMPLATE()}`;
  return dispatch => axios.post(apiURL, { name: templateName, current_organization_id: organizationId })
    .then(res => {
      return { status: true, message: 'Email template fetched successfully', data: res.data.data };
    }).catch(err => {
      console.error("Error fetching Email template by name:", err);
      (err.response && err.response.status === 401) && removeHeaderWithLocalstorage();
      const errorMessage = err.response ? err.response.data.error || err.response.data.message : err.message;
      return { status: false, message: errorMessage };
    });
}


export function fetchEmailTemplatePreview(templateName, organizationId) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.FETCH_EMAIL_TEMPLATE_PREVIEW()}?name=${templateName}`;
  return async dispatch => {
    try {
      const response = await axios.post(apiURL, { name: templateName, current_organization_id: organizationId });
      dispatch({ type: 'FETCH_EMAIL_TEMPLATE_PREVIEW_SUCCESS', payload: response.data.data });
      return { status: true, message: 'Email template preview fetched successfully', data: response.data.data };
    } catch (err) {
      console.error("Error fetching the email template preview:", err);
      dispatch({ type: 'FETCH_EMAIL_TEMPLATE_PREVIEW_FAILURE', payload: err.response ? err.response.data : "Error fetching email template preview" });
      if (err.response && err.response.status === 401) {
        removeHeaderWithLocalstorage();
      }
      const errorMessage = err.response ? err.response.data.error || err.response.data.message : err.message;
      return { status: false, message: errorMessage };
    }
  };
}

export function createEmailTemplate(templateData) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.CREATE_EMAIL_TEMPLATE()}`;
  return dispatch => axios.post(apiURL, templateData)
    .then(res => {
      dispatch({ type: 'CREATE_EMAIL_TEMPLATE_SUCCESS', payload: res.data.data });
      return { status: true, message: 'Email Template created successfully', data: res.data.data };
    }).catch(err => {
      console.error("Full Error for Email Template Creation Failure:", err);
      if (err.response) {
        console.error("Error Response Data for Email Template Creation:", err.response.data);
      }
      dispatch({ type: 'CREATE_EMAIL_TEMPLATE_FAILURE', payload: err });
      return { status: false, message: err.response ? err.response.data.error || err.response.data.message : err.message };
    });
}


export function updateEmailTemplate(templateId, templateData) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_EMAIL_SMS.UPDATE_EMAIL_TEMPLATE(templateId)}`;
  return dispatch => axios.put(apiURL, templateData)
    .then(res => {
      dispatch({ type: 'UPDATE_EMAIL_TEMPLATE_SUCCESS', payload: res.data.data });
      return { status: true, message: 'Email Template updated successfully', data: res.data.data };
    }).catch(err => {
      console.error("Error updating Email template:", err);
      dispatch({ type: 'UPDATE_EMAIL_TEMPLATE_FAILURE', payload: err });
      return { status: false, message: err.response ? err.response.data.error || err.response.data.message : err.message };
    });
}


