import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getusermanagementList,
  deleteUser,
} from "../../Redux/Actions/UserManagement";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
import FlashMessage from "../FlashMessage/FlashMessage";

// ASSETS
import sort from "../../assets/images/sort.svg";
import edit from "../../assets/images/edit.svg";
import remove from "../../assets/images/remove.svg";
import search from "../../assets/images/search.svg";
import close from "../../assets/images/icon_close.png";

// STYLING
import "./userManagementView.css";

const UserManagementList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userData, setUserData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");

  // FLASH MESSAGE STATE
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("tick");
  const [firstMsg, setFirstMsg] = useState("Done!");
  const [secondMsg, setSecondMsg] = useState("User created successfully");
  const [page, setPage] = useState(1);

  const [paginationData, setpaginationData] = useState({
    current_page: 1,
    total_pages: "",
    total_count: "",
    sort_column: "name",
    sort_order: "asc",
    sort_executed: false,
  });

  useEffect(() => {
    fetchusermanagementList();
  }, [page, paginationData.sort_order, paginationData.sort_column]);

  const fetchusermanagementList = async (typeSearch) => {
    await dispatch(setLoaderSpinner(true));

    let query = "";
    let search = typeSearch && typeSearch === "Yes" ? searchKey : "";

    query =
      "?page=" +
      page +
      "&query=" +
      search.toString() +
      "&sort_column=" +
      paginationData.sort_column +
      "&sort_order=" +
      paginationData.sort_order +
      "&without_pagination=" +
      true;

    const data = await dispatch(getusermanagementList(query));

    await dispatch(setLoaderSpinner(false));

    if (data.status) {
      setUserData(data.data.users);
      setpaginationData((prevState) => ({
        ...prevState,
        current_page: data.data.current_page,
        total_count: data.data.total_count,
        total_pages: data.data.total_pages,
      }));
    } else {
      history.push("/login");
    }
  };

  const searchUserSubmit = () => {
    fetchusermanagementList("Yes");
  };

  const confirmationPopup = async (id) => {
    setShowPopup(true);
    const user = userData.filter((x) => x.id === id);
    setSelectedUserName(user[0].name);
    setSelectedUserId(id);
  };

  const deleteSelectedUser = async () => {
    const { status, message } = await dispatch(deleteUser(selectedUserId));
    if (status) {
      togglePopup();
      fetchusermanagementList();
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      }
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const editSelecteduser = async (id) => {
    setSelectedUserId(id);
    history.push({
      pathname: "/edit",
      state: {
        user: userData.filter((x) => x.id === id),
      },
    });
  };

  const closeIconSearchbar = () => {
    setSearchKey("");
    fetchusermanagementList();
  };

  const closeFlash = () => {
    setdisplayFlash(false);
    setFlashMessage(false);
  };

  const openFlash = () => {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  };

  const applySorting = (type) => {
    const sortOrder =
      paginationData.sort_column === type
        ? paginationData.sort_order === "asc"
          ? "desc"
          : "asc"
        : "asc";
    setpaginationData((prevState) => ({
      ...prevState,
      sort_column: type,
      sort_order: sortOrder,
      sort_executed: true,
    }));
  };

  let defaultUserData = [...userData];
  defaultUserData.sort((a, b) => b.active - a.active);

  return (
    <div className="page-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          openFlash={() => openFlash()}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className="basic-header">
        <h3>User Management</h3>
        <button
          onClick={() => history.push("/new")}
          className="costum-link-btn"
          type="submit"
        >
          Create new user
        </button>
      </div>

      {showPopup ? (
        <DeleteModal
          firstTextUser={"Are you sure you want"}
          secondTextUser={"to delete user"}
          userId={selectedUserId}
          name={selectedUserName}
          confirm={deleteSelectedUser}
          closeDelete={togglePopup}
        />
      ) : null}

      <div className="users-container">
        <div className="input-group search-input-group">
          <input
            onChange={(e) => setSearchKey(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                searchUserSubmit();
              }
            }}
            value={searchKey}
            className="search-control"
            placeholder="Search"
          />
          {searchKey.length === 0 ? (
            <img className="search-icon" src={search} alt="search" />
          ) : (
            <img
              className="close-icon"
              src={close}
              alt="close"
              onClick={closeIconSearchbar}
            />
          )}
        </div>
        <div className="table-scroll-y">
          <table className="main-table">
            <thead className="user-category-thread">
              <tr className="category-thread">
                <th
                  className="category-head"
                  onClick={() => applySorting("name")}
                >
                  Name
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th
                  className="category-head"
                  onClick={() => applySorting("email")}
                >
                  Email
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th
                  className="category-head"
                  onClick={() => applySorting("role")}
                >
                  Role
                  <img className="sort-icon" src={sort} alt="sort" />
                </th>
                <th className="category-head">Action</th>
              </tr>
            </thead>
            <tbody className="user-data">
              {userData && userData.length > 0 && paginationData.sort_executed
                ? userData.map((user) => {
                  return user.active ? (
                    <tr key={user.id} className="user-box">
                      <td className="user-info">{user.name}</td>
                      <td className="user-info">{user.email}</td>
                      <td className="user-inforole">
                        {user.role.replace("_", " ")}
                      </td>
                      <td className="user-info">
                        <span
                          className="edit-icon"
                          onClick={() => editSelecteduser(user.id)}
                        >
                          <img
                            src={edit}
                            className="edit-icon"
                            onClick={() => editSelecteduser(user.id)}
                            alt="edit"
                          />
                        </span>
                        <span
                          key={user.id}
                          onClick={() => confirmationPopup(user.id)}
                          className="remove-icon"
                        >
                          <img src={remove} alt="remove" />
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <tr key={user.id} className="user-box">
                      <td className="user-info">
                        <del style={{ textDecorationColor: "red" }}>
                          {user.name}
                        </del>
                      </td>
                      <td className="user-info">
                        <del style={{ textDecorationColor: "red" }}>
                          {user.email}
                        </del>
                      </td>
                      <td className="user-inforole">
                        <del style={{ textDecorationColor: "red" }}>
                          {user.role.replace("_", " ")}
                        </del>
                      </td>
                      <td style={{ textDecorationColor: "red" }}></td>
                    </tr>
                  );
                })
                : defaultUserData.map((user) => {
                  return user.active ? (
                    <tr key={user.id} className="user-box">
                      <td className="user-info">{user.name}</td>
                      <td className="user-info">{user.email}</td>
                      <td className="user-inforole">
                        {user.role.replace("_", " ")}
                      </td>
                      <td className="user-info">
                        <span
                          className="edit-icon"
                          onClick={() => editSelecteduser(user.id)}
                        >
                          <img
                            src={edit}
                            className="edit-icon"
                            onClick={() => editSelecteduser(user.id)}
                            alt="edit"
                          />
                        </span>
                        <span
                          key={user.id}
                          onClick={() => confirmationPopup(user.id)}
                          className="remove-icon"
                        >
                          <img src={remove} alt="remove" />
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <tr key={user.id} className="user-box">
                      <td className="user-info">
                        <del style={{ textDecorationColor: "red" }}>
                          {user.name}
                        </del>
                      </td>
                      <td className="user-info">
                        <del style={{ textDecorationColor: "red" }}>
                          {user.email}
                        </del>
                      </td>
                      <td className="user-inforole">
                        <del style={{ textDecorationColor: "red" }}>
                          {user.role.replace("_", " ")}
                        </del>
                      </td>
                      <td style={{ textDecorationColor: "red" }}></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="pagination-wrapper">
          {paginationData.next_page && (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel={"..."}
              pageCount={Math.ceil(paginationData.total_pages)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(e) => setPage(e.selected + 1)}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserManagementList;
