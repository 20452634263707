import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";
import { SET_COMPARISON_REPORT_LIST } from "./Types/Types";

export function getComparisonReportList(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${
      API_CONFIG.ADMIN
    }${
      API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.COMPARISON_REPORT.LIST()}` + data; //data===params

  return () =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.message, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function setComparisonReportList(response) {
  return {
    type: SET_COMPARISON_REPORT_LIST,
    comparison_report: response,
  };
}
