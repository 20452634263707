import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getAvailableJobs(page = 1, perPage = 10) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BACKGROUND_JOBS.GET()}?page=${page}&per_page=${perPage}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      if (res.data.data && res.data.data.available_jobs) {
        return { status: true, data: res.data.data };
      } else {
        return { status: false, message: "Unexpected response structure" };
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 401) {
        removeHeaderWithLocalstorage();
      }
      return { status: false, message: err.message };
    });
}

export function getBackgroundJobs(jobId, page = 1, perPage = 10) {
  let apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BACKGROUND_JOBS.GET_HISTORY(jobId)}?page=${page}&per_page=${perPage}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      if (res.data.data && res.data.data.background_jobs) {
        return { status: true, data: res.data.data };
      } else {
        return { status: false, message: "Unexpected response structure" };
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 401) {
        removeHeaderWithLocalstorage();
      }
      return { status: false, message: err.message };
    });
}

export function postBackgroundJobs(jobFile, method, jobParams) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BACKGROUND_JOBS.POST()}`;
  const requestBody = {
    job_file: jobFile,
    method,
    job_params: jobParams,
  };

  return dispatch => axios.post(apiURL, requestBody, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(res => {
    const success = res.data.status !== 'error';
    return { status: success, message: res.data.message, data: res.data.data };
  }).catch(err => {
    console.error('Error posting background job:', err);
    const message = err.response ? err.response.data.error || err.response.data.message : err.message;
    (err.response && err.response.status === 401) && removeHeaderWithLocalstorage();
    return { status: false, message };
  });
}

export function updateBackgroundJob(jobId, jobDescription) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.BACKGROUND_JOBS.UPDATE(jobId)}`;

  const requestData = { available_job: { description: jobDescription } };

  return dispatch => axios.put(apiURL, requestData, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(res => {
    const success = res.data.status === 'success';
    return { status: success, message: res.data.message, data: res.data.data };
  })
  .catch(err => {
    const message = err.response ? err.response.data.error || err.response.data.message : err.message;
    if (err.response && err.response.status === 401) {
      dispatch(removeHeaderWithLocalstorage());
    }
    return { status: false, message };
  });
}

