import { SET_BANK_CONNECTION_SIDEBAR } from '../Actions/Types/Types'

const initialState = {
  toggleBankConnectionSidebar: false
}

const bank_connection_sidebar = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_BANK_CONNECTION_SIDEBAR:
      return {
        toggleBankConnectionSidebar: action.value
      }
    default: return state;
  }
}

export default bank_connection_sidebar;