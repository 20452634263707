import { DASHBOARD_DATA } from '../Actions/Types/Types';

const initialState = {
    graphData: {},
    graphDataCount: {},
    totalRecordCount: {},
    organizations: [],
    pagination: {}
}

const Dashboard = (state = initialState, action = {}) => {
    switch (action.type) {
        case DASHBOARD_DATA:
            const {
                total_clients,
                total_requests,
                total_accepted_requests,
                total_pending_requests,
                total_past_due_date_requests,
                total_cancelled_requests,
                total_deleted_requests,
                total_organization_with_request,
                current_page,
                total_pages,
                next_page,
                total_count,
                prev_page
            } = action.data
            const {
                accepted_requests_month,
                active_customers_month,
                accepted_requests_day,
                active_customers_day,
                new_clients_monthly,
                new_clients_weekly,
                new_clients_yearly,
                accepted_requests_last_month,
                accepted_requests_last_week,
                accepted_requests_last_year,
                active_customers_last_month,
                active_customers_last_week,
                active_customers_last_year,
                active_customers_weekly,
                api_requests,
                manual_requests,
                iframe_requests,
                external_payload_monthly,
                sms_request_monthly,
                email_request_monthly,
                tink_provider_monthly,
                enable_banking_provider_monthly,
                klarna_provider_monthly,
                services,
                consumer,
                corporate,

            } = action.data.graph_data
            return {
                graphData: {
                    accepted_requests_month, active_customers_month, accepted_requests_day, active_customers_day, accepted_requests_last_month, accepted_requests_last_week, accepted_requests_last_year, active_customers_last_month, active_customers_last_week, active_customers_last_year, active_customers_weekly, api_requests, manual_requests, iframe_requests, external_payload_monthly, services, consumer, corporate,
                },
                graphDataCount: { total_clients, new_clients_monthly, new_clients_weekly, new_clients_yearly, api_requests, manual_requests, iframe_requests, external_payload_monthly, sms_request_monthly, email_request_monthly, tink_provider_monthly, enable_banking_provider_monthly, klarna_provider_monthly, consumer, corporate },
                totalRecordCount: { total_requests, total_accepted_requests, total_pending_requests, total_past_due_date_requests, total_cancelled_requests, total_deleted_requests },
                organizations: total_organization_with_request,
                pagination: { current_page, total_pages, next_page, total_count, prev_page }
            }
        default: return state;
    }

}

export default Dashboard;