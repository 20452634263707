import { useState } from "react";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";

export const ClonePopup = (props) => {

    const errors = props.errors;
    const dispatch = useDispatch();
    const [togglePopup, setTogglePopup] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [length, setLength] = useState(false);
    const [message, setMessage] = useState("");

    const errorCheck = () => {
        dispatch(setLoaderSpinner(true))
        setTimeout(() => {
            dispatch(setLoaderSpinner(false))
            if (errors.length >= 1) {
                setMessage("Please correct the errors before cloning API documentation.");
                if (!showErrors) {
                    setShowErrors(true);
                }
            } else if (props.description === "" || props.title === "") {
                if (!showErrors) {
                    setShowErrors(true);
                    if (props.description === "" && props.title === "") {
                        setMessage(
                            "You need to add a title and description to clone API documentation."
                        );
                    } else if (props.description === "") {
                        setMessage("You need to add a description to clone API documentation.");
                    } else if (props.title === "") {
                        setMessage("You need to add a title to clone API documentation.");
                    }
                }
            } else if (
                errors.length <= 0
            ) {
                if (!togglePopup) {
                    setTogglePopup(true);
                }
            }
        }, 2000);
    };

    const closePopup = () => {
        if (togglePopup || showErrors || length) {
            setTogglePopup(false);
            setShowErrors(false);
            setLength(false);
        }
    };

    const addDocumentation = () => {
        setTogglePopup(false);
        props.addDocumentation();
        props.update = true;
    };

    return (
        <>
            <button className="update-api-btn" onClick={errorCheck}>
                Clone API documentation
            </button>
            {togglePopup && (
                <div className="api-popup">
                    <div className="conversion-confirm-box ">
                        <p>Do you want to clone this version of API documentation?</p>
                        <div className="confirm-btn-container">
                            <button onClick={closePopup} className="conversion-close-btn">
                                Close
                            </button>
                            <button onClick={addDocumentation}>Continue</button>
                        </div>
                    </div>
                </div>
            )}
            {showErrors && (
                <div className="api-popup">
                    <div className="popup-box new-popup">
                        <p>{message}</p>
                        <div className="confirm-btn-container">
                            <button onClick={closePopup} className="conversion-close-btn">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};