import React from "react";
import "./header.scss";
import { useHistory } from "react-router-dom";
import flag from "../../assets/images/img_flag.png";
import icon_down from "../../assets/images/icon_down.png";
import logo from "../../assets/images/new_kreditz_logo.png"
import mark from "../../assets/images/new_k_logo.svg"
import { userId, userName } from "../../Utils/Common";

const Header = () => {
  const history = useHistory();
  return (
    <div className="inner-header">
      <div>
        <a href="/dashboard" onClick={() => history.push("/dashboard")}>
          <img className="header-logo"
            src={logo}
            alt="logo"
          ></img>
        </a>
      </div>

      <div className="right-side">
        <div id="lang-div">
          <button className="dropdown default-language" type="button">
            <img className="flag" src={flag} alt="flag" /> English
            <img src={icon_down} alt="iconDown" />
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href="#something">
                <img src={flag} alt="flag" /> English
              </a>
            </li>
          </ul>
        </div>

        <div className="profile-user">
          <div className="uner-info">
            <p className="user-relation">Kreditz</p>
            <p className="user-name titleize">{userName()}</p>
            <p className="user-id">ID - {userId()}</p>
          </div>
          <img
            className="mark"
            src={mark}
            alt="mark"
            style={{ backgroundColor: "#f4f6fb" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
