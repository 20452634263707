import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import ActiveSubscribers from "../../Components/TestCenter/ActiveSubscribers";
import "./style.css";

const ActiveSubscribersPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Active Subscribers";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <ActiveSubscribers />
      </div>
    </div>
  );
};

export default ActiveSubscribersPage;
