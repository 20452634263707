import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import logo_new from "../../assets/images/new_kreditz_logo.png"
import lefttopcloud from "../../assets/images/left-top-cloud.png";
import cloudrightotp from "../../assets/images/cloud_rightotp.png";
import Menleft from "../../assets/images/Men_left.png";
import righttopuse from "../../assets/images/right_top_use.png";
import leftwoman from "../../assets/images/left_bottom.png";
import rightbottomman from "../../assets/images/Men_Right_Bottom.png";
import bottpmleaves from "../../assets/images/right_bottom_leaves.png";
import { setdisplayFlash } from "../../Utils/Common";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import IconMail from "../../assets/images/icon_mail.svg";
import IconPhone from "../../assets/images/icon_phone.svg";
import "./login.css";
import { verify_tfa } from "../../Redux/Actions/Auth";
import { get_otp_via } from "../../Redux/Actions/Auth"
import { Link } from "react-router-dom";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState(false);
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const [openPopup, setOpenPopup] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isRemember, setRemember] = useState(false);
  const [isPhoneNumber, setIsPhoneNumber] = useState(false)
  const [authToken, setAuthToken] = useState('')
  let localVar = false

  const getItem = key =>
    document.cookie.split("; ").reduce((total, currentCookie) => {
      const item = currentCookie.split("=");
      const storedKey = item[0];
      const storedValue = item[1];
      if (storedKey === 'user_id') {
        let val = key === storedKey ? decodeURIComponent(storedValue) : total;
        if (val === localStorage.id) {
          setRemember(true)
          localVar = true;
        } else {
          setRemember(false)
          localVar = false
        }
        return val;
      }
      return total;
    }, "");

  useEffect(() => {
    document.title = "Kreditz | Admin portal - Login";
  }, []);



  const handleChange = async (e) => {
    e.preventDefault();
    const userData = {
      email: email.replace(/ /g, ""),
      password: password.replace(/ /g, ""),
      remembered: isRemember ? "true" : "false"
    };
    const { data, message, status } = await dispatch(verify_tfa(userData));
    setAuthToken(data?.user?.auth_token)
    getItem('user_id')
    localStorage.setItem('authTokenForOtp', data?.user?.auth_token)
    localStorage.removeItem('authToken')
    if (status && message === "Authorized") {
      if (localVar) {
        localStorage.setItem('authToken', data?.user?.auth_token)
        props.history.push({ pathname: '/dashboard' })
      } else {
        if (data.user.phone_number) {
          setIsPhoneNumber(true)
          setOpenPopup(true);
        } else {
          setOpenPopup(true);
        }
      }
    } else {
      openFlash("alert", "Error!", message)
    }
  };

  const handleSubmit = async (e) => {
    const loginData = {
      type: selectedDevice === 1 ? "email" : "phone",
    };
    localStorage.setItem("device", JSON.stringify(selectedDevice));
    const response = await dispatch(get_otp_via(loginData));
    localStorage.setItem('authToken', authToken)
    if (response?.status) {
      setdisplayFlash(true);
      props.history.push({ pathname: "/verify_tfa", state: email });
    } else {
      setOpenPopup(false)
      openFlash("alert", "Error!", response?.message);
    }
  };

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }
  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  return (
    <div>
      {openPopup &&
        <div className="authenticate-modal">
          <div className="inner-auth-modal">
            <div className="choose-device-auth">
              <div className="device-gap">
                <h2>How you want to authenticate?</h2>
                <p>Please select the way you want to authenticate your account.</p>
                <div className="select-device">
                  <a name="email" href="#email">
                    <img name="mail" className={`select-device${selectedDevice === 1 ? " active" : ''}`}
                      onClick={() => setSelectedDevice(1)} src={IconMail} alt="" />
                  </a>
                  {isPhoneNumber &&
                    <a name="phone" href="#phone">
                      <img name="phone" className={`select-phone select-device${selectedDevice === 2 ? " active" : ''}`}
                        onClick={() => setSelectedDevice(2)} src={IconPhone} alt="" />
                    </a>}
                </div>
              </div>
              <div className="device-submittion">
                <a name="cancel_btn" href="#cancel_btn" className="cancel-btn" onClick={() => setOpenPopup(false)}>Cancel</a>
                <a name="continue" href="#continue" className={selectedDevice ? "continue-btn" : "continue-btn login_submit_disabled"} onClick={() => handleSubmit()}>Continue</a>
              </div>
            </div>
          </div>
        </div>
      }
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className="login_form">
        <div className="login-logo">
          <img src={logo_new} alt="logo_new" style={{ width: "162px" }} />
        </div>
        <div className="left-cloud">
          <img src={lefttopcloud} alt="left-top-cloud" />
        </div>
        <div className="right-cloud">
          <img src={cloudrightotp} alt="cloud_rightotp" />
        </div>
        <div className="left-man">
          <img src={Menleft} alt="Men_left" />
        </div>
        <div className="right-man">
          <img src={righttopuse} alt="right_top_use" />
        </div>
        <div className="login_form_set">
          <div className="left-woman">
            <img src={leftwoman} alt="left_bottom" />
          </div>
          <div className="right-bottom-man">
            <img src={rightbottomman} alt="Men_Right_Bottom" />
          </div>
          <div className="bottom-leaves">
            <img src={bottpmleaves} alt="right_bottom_leaves" />
          </div>
          <h2 className="welcomeHeader">
            Welcome to Kreditz
            <p className="plzLogin">Please login to your account</p>
          </h2>
          <form className="loginForm" onSubmit={handleChange}>
            <input
              className="form-group credentialsLogin"
              style={{ marginTop: "-520px", border: "1px solid #e3e7ee" }}
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <br />
            <input
              className="form-group credentialsLogin"
              style={{ marginTop: "0px", border: "1px solid #e3e7ee" }}
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="certify-list-count">
              <Link to={"/forget_password"} className="forget-password">
                Forgot Password
              </Link>
            </div>

            <div type="submit" className="form-group">
              <button type="submit" className="w-100">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
