import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_SUBSCRIBERS_LIST, SET_BASIC_VALUES } from '../../Redux/Actions/Types/Types';
import { removeUserDataToLocalstorage } from '../../Redux/Actions/Auth';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

const API_URL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}`;
const TEST_CENTER_URL = `${API_URL}${API_CONFIG.END_POINTS.TEST_CENTER.LIST()}`;
const TEST_CENTER_VALUES_URL = `${API_URL}${API_CONFIG.END_POINTS.TEST_CENTER.VALUES()}`;
const TEST_CENTER_DATE_PICKER = `${API_URL}${API_CONFIG.END_POINTS.TEST_CENTER.DATE()}`;
const TEST_CENTER_EDIT_SUBSCRIBER = `${API_URL}${API_CONFIG.END_POINTS.TEST_CENTER.EDIT()}`;
const TEST_CENTER_DELETE_SUBSCRIBER = `${API_URL}${API_CONFIG.END_POINTS.TEST_CENTER.DELETE()}`;

// CREATE SUBSCRIBER
export function getCreateSubscriber(subscriberData) {
  let bodyFormData = new FormData();
  bodyFormData = {
    tester: {
      email: subscriberData.email,
      phone: subscriberData.phoneNumber,
      full_name: subscriberData.fullName,
      ssn: subscriberData.ssn,
      language: subscriberData.language,
      request_type: subscriberData.certificateType,
      country_id: subscriberData.countryID,
      request_per_day: subscriberData.request,
      continuously: subscriberData.continuously,
      start_date: subscriberData.startDate,
      end_date: subscriberData.endDate,
      product: subscriberData.product,
      timezone: subscriberData.timezone,
    },
    request_times: subscriberData.time
  };
  return dispatch => axios.post(TEST_CENTER_URL, bodyFormData)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        dispatch(getSubscribersList());
      }
      return {
        requestSuccess: true,
        message: res.data.status
      }
    }).catch(err => {
      if (err.response?.status === 401) {
        removeHeaderWithLocalstorage();
        return {
          requestSuccess: false,
          message: err.response.data.message,
        }
      } else if (err.response?.status === 422) {
        return {
          requestSuccess: false,
          message: err.response.data.message
        }
      }
      return dispatch(removeUserDataToLocalstorage())
    });
};

// EDIT SUBSCRIBER
export function editSubscriber(subscriberData, id) {
  const bodyFormData = {
    tester: {
      email: subscriberData.subscribers?.email,
      phone: subscriberData.subscribers?.phoneNumber,
      full_name: subscriberData.subscribers?.fullName,
      ssn: subscriberData.subscribers?.ssn,
      language: subscriberData.subscribers?.language,
      request_type: subscriberData.subscribers?.certificateType,
      country_id: subscriberData.subscribers?.countryID,
      request_per_day: subscriberData.subscribers?.request,
      continuously: subscriberData.subscribers?.continuously,
      start_date: subscriberData.subscribers?.startDate,
      end_date: subscriberData.subscribers?.endDate,
      product: subscriberData.subscribers?.product,
      timezone: subscriberData.subscribers?.timezone
    },
    request_times: subscriberData.subscribers?.time
  };
  return dispatch => axios.put(TEST_CENTER_EDIT_SUBSCRIBER + id, bodyFormData)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        dispatch(getSubscribersList());
      }
      return {
        requestSuccess: true,
        message: res.data.status
      }
    }).catch(err => {
      if (err.response.status === 422) {
        return {
          requestSuccess: false,
          message: err.response.data.message
        }
      }
      (err.response.status === 401) && removeHeaderWithLocalstorage();
    });
};

// DELETE SUBSCRIBER
export function deleteSubscriber(id) {
  return dispatch => axios.delete(TEST_CENTER_DELETE_SUBSCRIBER + id)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        dispatch(getSubscribersList());
      }
      return {
        requestSuccess: true,
        message: res.data.status
      }
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return {
        requestSuccess: false,
        message: err.response.data.message
      };
    });
};

// SUBSCRIBERS LIST
export function getSubscribersList(param) {
  let apiURL = TEST_CENTER_URL
  if (param && param !== '') {
    apiURL = apiURL + param
  }
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
        dispatch(setSubscribersList(res.data));
        return {
          status: true,
          message: res.data.message,
          data: res.data.data
        }
      } else {
        return {
          status: false,
          message: res.data.message,
          data: res.data.data
        }
      }
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      if (err.response) {
      }
      return {
        requestSuccess: false,
        message: err.response?.data.error || err.response?.data.message
      }
    });
};

// DATE RANGE PICKER
export function getDates(dateRange) {
  const dateURL = TEST_CENTER_DATE_PICKER + dateRange;
  return dispatch => axios.get(dateURL)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
        dispatch(setSubscribersList(res.data));
      }
      return {
        requestSuccess: true,
        message: res.data.message
      }
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      if (err.response) {
      }
      return {
        requestSuccess: false,
        message: err.response.data.error || err.response.data.message
      }
    });
};

export function setSubscribersList(responseData) {
  return {
    type: SET_SUBSCRIBERS_LIST,
    subscribers_list_response: responseData,
    subscribers_list: responseData.data,
    total_pages: responseData.pagination,
    total_count: responseData.pagination,
    current_page: responseData.pagination,
    prev_page: responseData.pagination,
    next_page: responseData.pagination,
  };
};

// GET BASIC VALUES
export function getBasicValues() {
  return dispatch => axios.get(TEST_CENTER_VALUES_URL)
    .then(res => {
      const success = res.data.status !== 'error';
      if (success) {
        dispatch(setBasicValues(res.data.data));
        return {
          status: true,
          message: res.data.message,
          data: res.data.data
        }
      } else {
        return {
          status: false,
          message: res.data.message,
          data: res.data.data
        }
      }
    }).catch(err => {
      // (err.response.status === 401) && removeHeaderWithLocalstorage();
      return {
        requestSuccess: false,
        message: err.response?.data.message
      }
    });
};

export function setBasicValues(response) {
  return {
    type: SET_BASIC_VALUES,
    basic_values: response
  };
};