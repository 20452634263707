import { useState } from "react";
import "./apiPopup.scss";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";

export const ApiPopup = (props) => {
  const errors = props.errors;
  const dispatch = useDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const errorCheck = () => {
    dispatch(setLoaderSpinner(true))
    setTimeout(() => {
      dispatch(setLoaderSpinner(false))

      if (errors.length >= 1) {
        if (!showErrors) {
          setShowErrors(true);
        }
      } else if (errors.length <= 0) {
        if (!togglePopup) {
          setTogglePopup(true);
        }
      }
    }, 2000);
  };

  const closePopup = () => {
    if (togglePopup || showErrors) {
      setTogglePopup(false);
      setShowErrors(false);
    }
  };

  const updateDocumentation = (e) => {
    setTogglePopup(false);
    props.updateDocumentation();
    props.update = true;
  };

  return (
    <>
      <button className="update-api-btn" onClick={errorCheck}>
        Update API documentation
      </button>
      {togglePopup && (
        <div className="api-popup">
          <div className="conversion-confirm-box">
            <p>Do you want to update the API documentation?</p>
            <div className="confirm-btn-container">
              <button onClick={closePopup} className="conversion-close-btn">
                Close
              </button>
              <button
                className="conversion-continue-btn"
                onClick={updateDocumentation}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {showErrors && (
        <div className="api-popup">
          <div className="conversion-confirm-box">
            <p>
              Please correct the errors before updating the API documentation
            </p>
            <div className="confirm-btn-container">
              <button onClick={closePopup} className="conversion-close-btn">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
