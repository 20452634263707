import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import InvoicingList from "../../Components/Invoicing/invoicingList";
import InvoicingDetailsPage from "../../Components/Invoicing/invoicingDetailsPage";
import MoreDetailsPage from "../../Components/Invoicing/moreDetailsPage";
import "./style.css";

const Invoicing = (props) => {
  const { orgId, orgName, year, month, parentOrgId, parentOrgName, summary } =
    props.location?.state || "";
  const { pathname } = props.location;

  useEffect(() => {
    document.title = "Kreditz | Admin portal - Invoicing";
  }, []);

  const renderSwitch = () => {
    switch (pathname) {
      case "/invoicing/details":
        return (
          <InvoicingDetailsPage
            parentOrgId={parentOrgId}
            parentOrgName={parentOrgName}
            orgId={orgId}
            orgName={orgName}
            month={month}
            year={year}
          />
        );
      case "/invoicing/more_details":
        return (
          <MoreDetailsPage
            parentOrgId={parentOrgId}
            parentOrgName={parentOrgName}
            orgId={orgId}
            orgName={orgName}
            year={year}
            month={month}
            summary={summary}
          />
        );
      default:
        return <InvoicingList />;
    }
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        {renderSwitch()}
      </div>
    </div>
  );
};

export default Invoicing;
