import { useState } from "react";
import ReactPaginate from 'react-paginate';

const AccessCodesTable = (props) => {

  const [itemsPerPage] = useState(20);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  let accessCodes = props.accessCodes;
  const currentItems = accessCodes?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(accessCodes?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % accessCodes?.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="accescode-overview">
      <table className="table-demo" width="100%">
        <thead className="demo-headers">
          <tr className="demo-rows">
            <th className="code-status">User</th>
            <th className="code-status">Receiver email</th>
            <th className="code-status demo-head-accesscode">Access Code</th>
            <th className="code-status demo-head-date">Expiration date</th>
            <th className="code-status">Status</th>
          </tr>
        </thead>
        <tbody className="demo-info">
          {accessCodes && accessCodes.length > 0 && currentItems.map((item, index) => {
            return (
              <tr key={Math.random()}>
                <td className="code-info demo-user">{item.user}</td>
                <td className="code-info demo-receiver-email">{item.receiver_email}</td>
                <td className="code-info demo-accesscode">{item.access_code}</td>
                <td className="code-info demo-date">{item.expiration_date.substr(0, 10)}</td>
                {new Date(item.expiration_date) > new Date() ? <td className="code-info demo-status">Active</td> : <td className="code-info demo-status-red">Expired</td>
                }
              </tr>
            )
          })}
        </tbody>
      </table>
      {(accessCodes && accessCodes.length > 20) &&
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center pagination-access-codes"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      }
    </div>
  )
}

export default AccessCodesTable;