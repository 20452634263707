import React from 'react';

const ViewDefaultTemplateViewer = ({ isOpen, templateBody, onLoadDefaultTemplate, onClose, active }) => {
  if (!isOpen) return null;

  const isSmsTemplate = active === 'SMS';

  const formatForDisplay = (text) => {
    return text.replace(/\n/g, '<br/>');
  };

  const formattedSmsBody = isSmsTemplate && typeof templateBody === 'string' ? formatForDisplay(templateBody) : 'No preview available.';
  
  return (
    <div className="view-default-modal-backdrop">
      <div className="view-default-modal">
        <button className="view-default-modal-close" onClick={onClose}>&times;</button>
        <h2 className='view-default-modal-h2'>
          Preview of Default Template
        </h2>
        <div className="view-default-modal-content">
          {isSmsTemplate ? (
            <div dangerouslySetInnerHTML={{ __html: formattedSmsBody }} className='view-default-modal-content-sms'></div>
          ) : (
            <>
              <p className="email-sms-p"><strong>Email Subject:</strong> {templateBody.subject || 'No subject available'}</p>
              <p className="email-sms-p"><strong>Invite link text:</strong> {templateBody.invite_link_text || 'No invite link text available'}</p>
              <div style={{marginTop: '1rem'}}>
                <div dangerouslySetInnerHTML={{ __html: templateBody.body || 'No body available' }} className="email-sms-p" />
              </div>
            </>
          )}
        </div>
        <div className="view-default-modal-actions">
          <button className='view-default-load' onClick={onLoadDefaultTemplate}>Load Default Template</button>
          <button className='view-default-close' onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ViewDefaultTemplateViewer;
