import moment from "moment";
import { useState } from "react";
import ReactPaginate from 'react-paginate';
const DemoAccessTable = ({ demoAccess }) => {

  const [itemsPerPage] = useState(20);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = demoAccess?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(demoAccess?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % demoAccess?.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="accescode-overview">
      <table className="table-demo" width="100%">
        <thead className="demo-headers">
          <tr className="demo-rows">
            <th className="code-status demo-head-accesscode">Access Code</th>
            <th className="code-status">Status</th>
            <th className="code-status">User</th>
            <th className="code-status demo-head-date">Date</th>
          </tr>
        </thead>
        <tbody className="demo-info">
          {demoAccess && demoAccess.length > 0 && currentItems.map((item) => {
            return (
              <tr key={Math.random()}>
                <td className="code-info demo-accesscode">{item.access_code}</td>
                {item.active ? <td className="code-info demo-status">Active</td> : <td className="code-info demo-status-red">Used-{item.demo_certificate_id}</td>}
                <td className="code-info demo-user">{item.kreditz_admin && item.kreditz_admin.name}</td>
                <td className="code-info demo-date">{moment(item.created_at).format('YYYY-MM-DD')}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {(demoAccess && demoAccess.length > 20) &&
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center pagination-access-codes"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      }
    </div>
  )
}

export default DemoAccessTable;