import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";
import {
  SET_BANK_CONNECTION_FREQUENCY_LIST
} from "./Types/Types";

export function getBankConnectionFrequency(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${
      API_CONFIG.ADMIN
    }${
      API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.BANK_CONNECTION_FREQUENCY.LIST()}`;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}


export function setBankConnectionFrequencyList(response) {
  return {
    type: SET_BANK_CONNECTION_FREQUENCY_LIST,
    bank_connection_frequency: response,
  };
}

