import React from 'react';
import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import OrganizationsBrokerFlow from '../../Components/OrganizationsBrokerFlow/OrganizationsBrokerFlow';
import OrganizationsBrokerFlowDetails from '../../Components/OrganizationsBrokerFlow/OrganizationsBrokerFlowDetails';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";

function OrganizationsBrokerFlowPage() {
    useEffect(() => {
        document.title = "Kreditz | Admin portal - Organizations Broker Flow";
    }, []);

    return (
        <div className="wrapper">
            <Header />
            <div className="main-container">
                <Sidebar />
                <Switch>
                    <Route exact path="/org_broker_flow" component={OrganizationsBrokerFlow} />
                    <Route path="/org_broker_flow/details/:id" component={OrganizationsBrokerFlowDetails} />
                </Switch>
            </div>
        </div>
    );
};

export default OrganizationsBrokerFlowPage;
