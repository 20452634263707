import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";

export function getAllCountry(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.COUNTRIES.LIST()}`;
  const countries_list = getCountriesFromSessionStorage();
  if (countries_list) {
    return (dispatch) => {
      return {
        status: true,
        message: "success",
        data: countries_list,
      };
    }
  }
  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        saveCountriesToSessionStorage(res.data.data);
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}
function saveCountriesToSessionStorage(countries_list) {
  sessionStorage.setItem("countries_list", JSON.stringify(countries_list));
}
function getCountriesFromSessionStorage() {
  return JSON.parse(sessionStorage.getItem("countries_list"));
}