
import { useEffect, useState } from "react";
import iconImg from "../../assets/images/icon_arrow_down.png"
import { NavigationLink } from "./LinkComponent";
import { setActiveSideBar } from "../../Redux/Actions/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { verifySubmenuAccess } from "./verifyAccess";
const getAllRoutes = (menuItem) => {
  const routes = [];
  if (menuItem.hasOwnProperty("children")) {
    menuItem.children.forEach((child) => {
      routes.push(child.path);
      if (child.hasOwnProperty("subroutes")) {
        child.subroutes.forEach((subroute) => {
          routes.push(subroute.path);
        });
      }

    });

  }
  return routes;
}
export const Menu = ({ menuItem }) => {
  const dispatch = useDispatch();
  if (menuItem.hasOwnProperty("children")) {
    const hasAccess = verifySubmenuAccess(menuItem);
    if (!hasAccess) {
      return null;
    }
    return <SubMenu menuItem={menuItem} />
  } else {
    return (
      <NavigationLink menuItem={menuItem}>
        <span className="side-bar-link"
          onClick={() => dispatch(setActiveSideBar(""))}>{menuItem.displayName}</span>
      </NavigationLink>
    )
  }
}

const SubMenu = ({ menuItem }) => {
  const dispatch = useDispatch();
  const activeSideBar = useSelector(state => state.sidebar.activeSidebar);
  const [arrow, setArrow] = useState(activeSideBar === menuItem.displayName ? "arrow-up" : "arrow-down");
  const [showSubMenu, setShowSubMenu] = useState(activeSideBar === menuItem.displayName);

  const toggleSubMenu = () => {
    if (activeSideBar !== menuItem.displayName) {
      dispatch(setActiveSideBar(menuItem.displayName));
    } else {
      dispatch(setActiveSideBar(""));
    }
    setShowSubMenu(!showSubMenu);
    if (arrow === "arrow-down") {
      setArrow("arrow-up");
    } else {
      setArrow("arrow-down");
    }
  }

  useEffect(() => {
    if (activeSideBar === menuItem.displayName) {
      setShowSubMenu(true);
      setArrow("arrow-up");
    } else {
      setShowSubMenu(false);
      setArrow("arrow-down");
    }
  }, [activeSideBar]);

  useEffect(() => {
    const routes = getAllRoutes(menuItem);
    const isCurrentPathInMenu = routes.map((route) => {
      const regex = new RegExp(`${route}`);
      return window.location.pathname.match(regex) === null ? false : true;
    }
    );
    if (isCurrentPathInMenu.includes(true)) {
      dispatch(setActiveSideBar(menuItem.displayName));
    }
  }, []);

  return (
    <>
      <li>
        <span className="sidebar" onClick={toggleSubMenu}>
          <span>
            {
              menuItem.icon && <img
                src={menuItem.icon}
                alt="dashboardIcon"
                className="imgIconSidebar"
                width="18"
              />
            }
            <span className="side-bar-link">{menuItem.displayName}</span>
            <span className="side-bar-arrow-container">
              <img src={iconImg} alt="img" className={`dropdown-arrow ${arrow}`} />
            </span>
          </span>
        </span>
      </li>
      {
        showSubMenu &&
        <li className="sidebar-submenu">
          <ul className="submenu-ul">
            {menuItem.children.map((subMenuItem, index) => {
              return (
                <NavigationLink
                  key={index}
                  menuItem={subMenuItem}
                  menuClass="side-bar-link"
                >
                  {subMenuItem.displayName}
                </NavigationLink>
              )
            })}
          </ul>
        </li>
      }
    </>
  )
}