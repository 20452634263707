const EditOrgFormSelect = ({ label, name, value, handleChange, firstOption, selectOption }) => {
  return (
    <div className="col-md-3 form-group">
      <label>{label}</label>
      <select className="form-control org-excludeDropdown" id="sel1" name={name} value={value} onChange={(e) => handleChange(e)}>
        {firstOption !== "" && <option value=''>{firstOption}</option>}
        {
          selectOption.map(access => <option value={access.id} key={access.id} >{access.name}</option>)
        }
      </select>
    </div>
  )
}

export default EditOrgFormSelect;