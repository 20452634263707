import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from "react-select";
import infoImg from '../../assets/images/info.svg'

const UploadFilesPopup = ({ h3, createUploadFiles, directories, ...props }) => {
  const { register, handleSubmit, reset } = useForm();
  const [customValue, setCustomValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const ApiData = async (data) => {
    const formData = new FormData();
    const post = { ...data, attachment: data.file_name[0] }
    formData.append('attachment', post.attachment)
    props.uploadHandler(formData, customOption?.value === "" ? selectedOption?.value : `uploads/${customOption?.value?.toLowerCase()}`)
    reset()
  }

  const changeHandler = (e) => {
    setSelectedOption(e)
  }

  const onInputChange = (e, { action }) => {
    if ('input-change' === action) {
      setCustomValue(e);
    }
  }

  const customOption = {
    value: customValue,
    label: customValue,
  };

  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner EditPopupUploads'>
        <h3>{h3}</h3>
        <form onSubmit={handleSubmit(ApiData)}>
          <div className="row">
            <div className="col-md-12">
              <div className="upload-object-popup">
                <div className="setting-filter-container input-credentials">
                  <div className="provider-input-file">
                    <input type="file" {...register('file_name')} required />
                    <div className='directory-parent'>
                      <h5 className='directory-upload'>Directory</h5>
                      <img className='upload-tooltip' data-toggle="tooltip" data-placement="top" title="File will upload in selected directory. By default it will be upload in uploads folder." src={infoImg} alt="info_img" />
                    </div>
                    <Select
                      options={directories}
                      onChange={changeHandler}
                      onInputChange={onInputChange}
                      isSearchable
                      value={selectedOption || customOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' onClick={props.closeDelete} style={{ marginLeft: '0px' }}>Cancel</button>
            <button className='confirm-update' type='submit'>Create</button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default UploadFilesPopup;