import React, { useState, useEffect } from "react";
import GenericBarChart from '../GenericBarChart';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { getMarketsLiveData } from "../../../Redux/Actions/LiveData";
import "./LiveDataMarkets.scss";

const LiveDataMarkets = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const aggregateDataByMarket = (marketData) => {
    const aggregatedData = {};

    marketData.forEach((data) => {
      if (aggregatedData[data.market]) {
        aggregatedData[data.market] += data.accepted_requests_count;
      } else {
        aggregatedData[data.market] = data.accepted_requests_count;
      }
    });

    return Object.entries(aggregatedData).map(([market, accepted_requests_count]) => ({
      market,
      accepted_requests_count
    }));
  };

  const fetchMarketsLiveData = async () => {
    dispatch(setLoaderSpinner(true));
    try {
      const response = await dispatch(getMarketsLiveData());
      if (response && response.status === true) {
        const aggregatedData = aggregateDataByMarket(response.data.data);
        setData(aggregatedData);
      } else {
        if (response.message === 'Unauthorized') {
          history.push('/login');
        }
      }
    } catch (error) {
      console.error('Error fetching market live data:', error);
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  };

  useEffect(() => {
    fetchMarketsLiveData();
    const intervalId = setInterval(fetchMarketsLiveData, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="page-container live-data-container">
      <div className="ld-container">
        <h3 className="ld-header-markets">Markets - Accepted request</h3>
        <GenericBarChart data={data} />
      </div>
    </div>
  );
}

export default LiveDataMarkets;
