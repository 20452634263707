import { useState } from "react";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";

export const NewPopup = (props) => {

  const errors = props.errors;
  const dispatch = useDispatch();
  const documentation = props.documentation;

  const [togglePopup, setTogglePopup] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [length, setLength] = useState(false);
  const [message, setMessage] = useState("");
  const [versionExists, setVersionExists] = useState(false);


  const errorCheck = () => {
    dispatch(setLoaderSpinner(true))

    setTimeout(() => {
      dispatch(setLoaderSpinner(false))
      if (errors.length >= 1) {
        setMessage("Please correct the errors before adding the new API documentation.");
        if (!showErrors) {
          setShowErrors(true);
        }
      } else if (props.title === "") {
        if (!showErrors) {
          setShowErrors(true);
          if (props.title === "") {
            setMessage(
              "You need to add a title to add a new API documentation."
            );
          } else if (props.title === "") {
            setMessage("You need to add a title to add a new API documentation.");
          }
        }
      } else if (
        errors.length <= 0
      ) {
        if (!togglePopup) {
          setTogglePopup(true);
        }
      }
    }, 2000);
  };

  const closePopup = () => {
    if (togglePopup || showErrors || length) {
      setTogglePopup(false);
      setShowErrors(false);
      setLength(false);
    }
  };

  const addDocumentation = () => {
    setTogglePopup(false);
    props.addDocumentation();
    props.update = true;
  };

  return (
    <>
      <button className="update-api-btn" onClick={errorCheck}>
        Add a new API documentation
      </button>
      {togglePopup && (
        <div className="api-popup">
          <div className="conversion-confirm-box ">
            <p>Do you want to add a new version of API documentation?</p>
            <div className="confirm-btn-container">
              <button onClick={closePopup} className="conversion-close-btn">
                Close
              </button>
              <button onClick={addDocumentation}>Continue</button>
            </div>
          </div>
        </div>
      )}
      {showErrors && (
        <div className="api-popup">
          <div className="popup-box new-popup">
            <p>{message}</p>
            <div className="confirm-btn-container">
              <button onClick={closePopup} className="conversion-close-btn">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};