import React, { useState, useEffect } from 'react';
import ToggleOrgCertificate from '../ToggleOrgCertificate/ToggleOrgCertificate';
import MarketTab from '../MarketTab/MarketTab';
import AnalyzingTab from '../AnalyzingTab/Analyzing';

function CertificateTab({
  setClientDetails,
  clientDetails,
  capitalizeFirstLetter,
  submitHandle,
  bankFilterCountries,
  setBankFilterCountries,
  setBankFilters,
  bankFilterCountriesCorporate,
  setBankFilterCountriesCorporate,
  country,
  handleSetting
}) {
  const [incomeTabAll, setIncomeTabAll] = useState(false)
  const [creditDecisionTabAll, setCreditDecisionTabAll] = useState(false)
  const [spendingTabAll, setSpendingTabAll] = useState(false)
  const [assetsTabAll, setAssetsTabAll] = useState(false)
  const [debetsTabAll, setDebtsTabAll] = useState(false)
  const [cashflowTabAll, setCashflowTabAll] = useState(false)
  const [creditDecisionTabAllCorporate, setCreditDecisionTabAllCorporate] = useState(false)


  useEffect(() => {
    if (clientDetails.certificate.consumer.income_tab.average_income && clientDetails.certificate.consumer.income_tab.income_graph && clientDetails.certificate.consumer.income_tab.monthly_view && clientDetails.certificate.consumer.income_tab.source_of_income && clientDetails.certificate.consumer.income_tab.income_vs_spending && clientDetails.certificate.consumer.income_tab.cashflow_net_graph && clientDetails.certificate.consumer.income_tab.category_expansion && clientDetails.certificate.consumer.income_tab.salary_burn_rate && clientDetails.certificate.consumer.income_tab.income_ratio && clientDetails.certificate.consumer.income_tab.income_transactions) {
      setIncomeTabAll(true)
    } else {
      setIncomeTabAll(false)
    }
    if (clientDetails.certificate.consumer.credit_decision_tab.overview_decision_box && clientDetails.certificate.consumer.credit_decision_tab.credit_policy_rules_box && clientDetails.certificate.consumer.credit_decision_tab.credit_scoring_box && clientDetails.certificate.consumer.credit_decision_tab.affordability_box && clientDetails.certificate.consumer.credit_decision_tab.category_expansion) {
      setCreditDecisionTabAll(true)
    }
    else {
      setCreditDecisionTabAll(false)
    }
    if (clientDetails.certificate.consumer.spending_tab.spending_categories_risk && clientDetails.certificate.consumer.spending_tab.spending_categories_loans && clientDetails.certificate.consumer.spending_tab.spending_categories_savings && clientDetails.certificate.consumer.spending_tab.spending_categories_temporary_expenses && clientDetails.certificate.consumer.spending_tab.spending_categories_housing_costs && clientDetails.certificate.consumer.spending_tab.spending_categories_transportation && clientDetails.certificate.consumer.spending_tab.spending_categories_food_and_drinks && clientDetails.certificate.consumer.spending_tab.spending_categories_shopping && clientDetails.certificate.consumer.spending_tab.spending_categories_hobbies && clientDetails.certificate.consumer.spending_tab.spending_categories_health_and_beauty && clientDetails.certificate.consumer.spending_tab.spending_categories_children && clientDetails.certificate.consumer.spending_tab.spending_categories_transfers && clientDetails.certificate.consumer.spending_tab.spending_graph && clientDetails.certificate.consumer.spending_tab.monthly_view && clientDetails.certificate.consumer.spending_tab.category_expansion && clientDetails.certificate.consumer.spending_tab.highest_cost_last_month && clientDetails.certificate.consumer.spending_tab.spending_ratio) {
      setSpendingTabAll(true)
    } else {
      setSpendingTabAll(false)
    }
    if (clientDetails.certificate.consumer.asset_tab.assets_graph && clientDetails.certificate.consumer.asset_tab.balance_before_salary_graph && clientDetails.certificate.consumer.asset_tab.total_assets && clientDetails.certificate.consumer.asset_tab.monthly_savings_ratio && clientDetails.certificate.consumer.asset_tab.asset_allocation && clientDetails.certificate.consumer.asset_tab.asset_ratio && clientDetails.certificate.consumer.asset_tab.monthly_savings) {
      setAssetsTabAll(true)
    }
    else {
      setAssetsTabAll(false)
    }
    if (clientDetails.certificate.consumer.debts_tab.summary_of_debts && clientDetails.certificate.consumer.debts_tab.credit_institutions && clientDetails.certificate.consumer.debts_tab.debts_categories_boxes && clientDetails.certificate.consumer.debts_tab.debt_involvement && clientDetails.certificate.consumer.debts_tab.disposition_of_repayments && clientDetails.certificate.consumer.debts_tab.paidout_vs_repayments_detailed && clientDetails.certificate.consumer.debts_tab.noteworthy_liabilities && clientDetails.certificate.consumer.debts_tab.paidout_vs_repayments_accumulated) {
      setDebtsTabAll(true)
    }
    else {
      setDebtsTabAll(false)
    }
    if (clientDetails.certificate.corporate.cashflow_tab.average_inflow_boxes && clientDetails.certificate.corporate.cashflow_tab.average_outflow_boxes && clientDetails.certificate.corporate.cashflow_tab.cashflow_12_months_graph_or_accordion && clientDetails.certificate.corporate.cashflow_tab.category_expansion) {
      setCashflowTabAll(true)
    }
    else {
      setCashflowTabAll(false)
    }
    if (clientDetails.certificate.corporate.credit_decision_tab.cashflow_box && clientDetails.certificate.corporate.credit_decision_tab.credit_scoring_box && clientDetails.certificate.corporate.credit_decision_tab.overview_decision_box && clientDetails.certificate.corporate.credit_decision_tab.credit_policy_rules_box) {
      setCreditDecisionTabAllCorporate(true)
    }
    else {
      setCreditDecisionTabAllCorporate(false)
    }
  }, [clientDetails])


  const consumerCertificateHandler = (event, type, key) => {
    if (type === 'bank_sources_box') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            bank_sources_box: event.target.checked
          },
        }
      }))
    }

    setClientDetails(prevState => ({
      ...prevState,
      certificate: {
        ...prevState?.certificate,
        consumer: {
          ...prevState?.certificate?.consumer,
          [key]: {
            ...prevState?.certificate?.consumer[key],
            [type]: event.target.checked
          }
        },
      }
    }))


    if (type === 'income_tab_all') {
      setIncomeTabAll(event.target.checked)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            income_tab: {
              ...prevState?.certificate?.consumer?.income_tab,
              average_income: event.target.checked,
              income_graph: event.target.checked,
              monthly_view: event.target.checked,
              source_of_income: event.target.checked,
              primary_income: event.target.checked,
              income_transaction: event.target.checked,
              income_vs_spending: event.target.checked,
              cashflow_net_graph: event.target.checked,
              income_ratio_salary_burn_rate: event.target.checked,
              category_expansion: event.target.checked,
              income_transactions: event.target.checked,
              primary_income_transactions: event.target.checked,
              salary_burn_rate: event.target.checked,
              income_ratio: event.target.checked,
            },
          }
        },
      }))
    }

    if (type === "spending_tab_all") {
      setSpendingTabAll(event.target.checked)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            spending_tab: {
              ...prevState?.certificate?.consumer?.spending_tab,
              spending_categories_risk: event.target.checked,
              spending_categories_loans: event.target.checked,
              spending_categories_savings: event.target.checked,
              spending_categories_temporary_expenses: event.target.checked,
              spending_categories_housing_costs: event.target.checked,
              spending_categories_transportation: event.target.checked,
              spending_categories_food_and_drinks: event.target.checked,
              spending_categories_shopping: event.target.checked,
              spending_categories_hobbies: event.target.checked,
              spending_categories_health_and_beauty: event.target.checked,
              spending_categories_children: event.target.checked,
              spending_categories_transfers: event.target.checked,
              spending_graph: event.target.checked,
              monthly_view: event.target.checked,
              category_expansion: event.target.checked,
              spending_ratio: event.target.checked,
              highest_cost_last_month: event.target.checked
            },
          },
        }
      }))
    }

    if (type === 'spending_monthly_view') {
      setSpendingTabAll(false)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            spending_tab: {
              ...prevState?.certificate?.consumer?.spending_tab,
              monthly_view: event.target.checked
            }
          },
        }
      }))
    }

    if (type === "assets_tab_all") {
      setAssetsTabAll(event.target.checked)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            asset_tab: {
              assets_graph: event.target.checked,
              balance_before_salary_graph: event.target.checked,
              monthly_savings: event.target.checked,
              total_assets: event.target.checked,
              asset_ratio: event.target.checked,
              asset_allocation: event.target.checked,
              monthly_savings_ratio: event.target.checked,
            },
          }
        }
      }))
    }


    if (type === "debts_tab_all") {
      setDebtsTabAll(event.target.checked)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            debts_tab: {
              summary_of_debts: event.target.checked,
              debts_categories_boxes: event.target.checked,
              credit_institutions: event.target.checked,
              debt_involvement: event.target.checked,
              disposition_of_repayments: event.target.checked,
              noteworthy_liabilities: event.target.checked,
              paidout_vs_repayments_detailed: event.target.checked,
              paidout_vs_repayments_accumulated: event.target.checked,
            },
          }
        }
      }))
    }


    if (type === 'bussiness_rules_section') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            debts_tab: {
              ...prevState?.certificate?.consumer?.debts_tab,
              bussiness_rules_section: event.target.checked
            }
          }
        }
      }))
    }

    if (type === "credit_decision_tab_all") {
      setCreditDecisionTabAll(event.target.checked)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            credit_decision_tab: {
              overview_decision_box: event.target.checked,
              credit_policy_rules_box: event.target.checked,
              credit_scoring_box: event.target.checked,
              affordability_box: event.target.checked,
              category_expansion: event.target.checked
            },
          }
        }
      }))
    }
  }

  const corporateCertificatesHandler = (event, type, key) => {
    if (type === 'bank_sources_box_corporate') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            source_tab: {
              ...prevState?.certificate?.corporate?.source_tab,
              bank_sources_box: event.target.checked
            }
          },
        }
      }))
    }

    if (type === 'bank_accounts') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            source_tab: {
              ...prevState?.certificate?.corporate?.source_tab,
              bank_accounts: event.target.checked
            }
          },
        }
      }))
    }

    if (type === 'cashflow_tab_all') {
      setCashflowTabAll(event.target.checked)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            cashflow_tab: {
              average_inflow_boxes: event.target.checked,
              average_outflow_boxes: event.target.checked,
              cashflow_12_months_graph_or_accordion: event.target.checked,
              category_expansion: event.target.checked
            },
          }
        }
      }))
    }

    setClientDetails(prevState => ({
      ...prevState,
      certificate: {
        ...prevState?.certificate,
        corporate: {
          ...prevState?.certificate?.corporate,
          [key]: {
            ...prevState?.certificate?.corporate[key],
            [type]: event.target.checked
          }
        },
      }
    }))

    if (type === 'credit_decision_corprate_tab_all') {
      setCreditDecisionTabAllCorporate(event.target.checked)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            credit_decision_tab: {
              overview_decision_box: event.target.checked,
              credit_policy_rules_box: event.target.checked,
              credit_scoring_box: event.target.checked,
              cashflow_box: event.target.checked,
              category_expansion: event.target.checked
            },
          }
        }
      }))
    }

    if (type === 'credit_policy_rules_box_corporate') {
      setCreditDecisionTabAllCorporate(false)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            credit_decision_tab: {
              ...prevState?.certificate?.corporate?.credit_decision_tab,
              credit_policy_rules_box: event.target.checked
            }
          },
        }
      }))
    }
  }
  return (
    <div id="inside_tab_panel">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="consumercertificate-tab" data-bs-toggle="tab" data-bs-target="#consumercertificate" type="button" role="tab" aria-controls="consumercertificate" aria-selected="true">Consumer Certificate</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="corporatecertificate-tab" data-bs-toggle="tab" data-bs-target="#corporatecertificate" type="button" role="tab" aria-controls="corporatecertificate" aria-selected="false">Corporate Certificate</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="market-tab" data-bs-toggle="tab" data-bs-target="#markets_details" type="button" role="tab" aria-controls="markets_details" aria-selected="false">Markets</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="analyzing-tab" data-bs-toggle="tab" data-bs-target="#analyzing_details" type="button" role="tab" aria-controls="analyzing_details" aria-selected="false">Analyzing</button>
        </li>
      </ul>
      <div className="tab-content" id="tab_content_certificate">
        <AnalyzingTab
          setClientDetails={setClientDetails}
          country={country}
          clientDetails={clientDetails}
          handleSetting={handleSetting}
          submitHandle={submitHandle}
        />
        <MarketTab
          setClientDetails={setClientDetails}
          bankFilterCountries={bankFilterCountries}
          setBankFilterCountries={setBankFilterCountries}
          setBankFilters={setBankFilters}
          bankFilterCountriesCorporate={bankFilterCountriesCorporate}
          setBankFilterCountriesCorporate={setBankFilterCountriesCorporate}
          country={country}
          clientDetails={clientDetails}
          submitHandle={submitHandle}
        />
        <div className="tab-pane fade show active" id="consumercertificate" role="tabpanel" aria-labelledby="consumercertificate-tab">
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Overview</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['sources', 'business_rules', 'primary_income', 'spending', 'assets', 'debts'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.overview[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"overview"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Account information</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['balance_column', 'transaction_column'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.account_information[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"account_information"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Income tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {/*===*/}
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={incomeTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'income_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['average_income', 'income_graph', 'monthly_view', 'source_of_income', 'primary_income_transactions', 'income_transactions', 'income_vs_spending', 'cashflow_net_graph', 'income_ratio', 'salary_burn_rate', 'category_expansion'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.income_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"income_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Spending tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={spendingTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'spending_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['spending_categories_risk', 'spending_categories_loans', 'spending_categories_savings', 'spending_categories_temporary_expenses', 'spending_categories_housing_costs', 'spending_categories_transportation', 'spending_categories_food_and_drinks', 'spending_categories_shopping', 'spending_categories_hobbies', 'spending_categories_health_and_beauty', 'spending_categories_children', 'spending_categories_transfers', 'spending_graph', 'category_expansion', 'highest_cost_last_month', 'spending_ratio'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.spending_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"spending_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.consumer?.spending_tab?.monthly_view} onChange={(e) => consumerCertificateHandler
                      (e, 'spending_monthly_view')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Monthly view - Accordion</p>
                </div>
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Assets tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={assetsTabAll} onChange={(e) => consumerCertificateHandler(e, 'assets_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['total_assets', 'asset_allocation', 'assets_graph', 'asset_ratio', 'monthly_savings_ratio', 'monthly_savings', 'balance_before_salary_graph',].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.asset_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"asset_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Debts tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={debetsTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'debts_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['summary_of_debts', 'debts_categories_boxes', 'debt_involvement', 'credit_institutions', 'disposition_of_repayments', 'noteworthy_liabilities', 'paidout_vs_repayments_detailed', 'paidout_vs_repayments_accumulated'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.debts_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"debts_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          {/*===*/}
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Credit decision - tab - Application/Surveillance</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={creditDecisionTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'credit_decision_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['overview_decision_box', 'credit_policy_rules_box', 'credit_scoring_box', 'affordability_box', 'category_expansion'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.credit_decision_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"credit_decision_tab"}
                    capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="corporatecertificate" role="tabpanel" aria-labelledby="corporatecertificate-tab">
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Overview</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['cash_and_bank_balances', 'cashflow', 'largest_incoming_payments', 'largest_outgoing_payments', 'sources', 'tax_authority'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.overview[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"overview"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Account information</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['balance_column', 'transaction_column'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.account_information[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"account_information"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Source tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.source_tab?.bank_sources_box} onChange={(e) => corporateCertificatesHandler(e, 'bank_sources_box_corporate')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Bank sources - Box</p>
                </div>
              </div>
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.source_tab?.bank_accounts} onChange={(e) => corporateCertificatesHandler(e, 'bank_accounts')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Bank Accounts</p>
                </div>
              </div>
            </div>

          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Cashflow tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={cashflowTabAll} onChange={(e) => corporateCertificatesHandler(e, 'cashflow_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['average_inflow_boxes', 'average_outflow_boxes', 'cashflow_12_months_graph_or_accordion', 'category_expansion'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.cashflow_tab[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"cashflow_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Business rules tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.bussiness_rule_tab?.bussiness_rules_section} onChange={(e) => corporateCertificatesHandler(e, 'bussiness_rules_section', 'bussiness_rule_tab')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Business rules - Section</p>
                </div>
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Credit decision</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={creditDecisionTabAllCorporate} onChange={(e) => corporateCertificatesHandler(e, 'credit_decision_corprate_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.credit_decision_tab?.overview_decision_box} onChange={(e) => corporateCertificatesHandler(e, 'overview_decision_box', 'credit_decision_tab')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Overview of decision - Box</p>
                </div>
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.credit_decision_tab?.credit_policy_rules_box} onChange={(e) => corporateCertificatesHandler(e, 'credit_policy_rules_box_corporate')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Credit policy rules - Box</p>
                </div>
                {
                  ['credit_scoring_box', 'cashflow_box', 'category_expansion'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.credit_decision_tab[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"credit_decision_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="create-client-button"><button onClick={() => submitHandle()}>Update</button></div>
    </div>
  );
}

export default CertificateTab;
