import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";
import {
  SET_DYNAMIC_LIVE_DATA_LIST, SET_DYNAMIC_LIVE_DATA_FILTER
} from "./Types/Types";

export function getDynamicLiveData(data) {
  const apiURL =
    `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${
      API_CONFIG.ADMIN
    }${
      API_CONFIG.API_VERSION_PATH
    }${API_CONFIG.END_POINTS.DYNAMIC_LIVE_DATA.LIST()}` + data;

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function getDynamicLiveDataFilter(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${
    API_CONFIG.ADMIN
  }${
    API_CONFIG.API_VERSION_PATH
  }${API_CONFIG.END_POINTS.DYNAMIC_LIVE_DATA.FILTER()}`;
  return (dispatch) =>
    axios
      .get(apiURL, data)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function setDynamicLiveDataList(response) {
  return {
    type: SET_DYNAMIC_LIVE_DATA_LIST,
    dynamic_live_data: response,
  };
}

export function setDynamicLiveDataFilter(response) {
  return {
    type: SET_DYNAMIC_LIVE_DATA_FILTER,
    dynamic_live_data: response,
  };
}