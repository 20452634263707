import { SET_LIVE_DATA_FILTER, SET_LIVE_DATA, SET_RECENT_ACTIVITIES } from '../Actions/Types/Types';

const initialState = {
  live_data_filter_response: {},
  countries: [],
  customers: [],
  request_type: {},
  live_data_response: {},
  request_by_countries: [],
  total_request_box: {},
  accepted_request_box: {},
  online_customers: {},
  total_countries: {},
  first_graph: {},
  second_graph: {},
  recent_activities: [],
}

const LiveData = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIVE_DATA_FILTER:
      return {
        ...state,
        live_data_filter_response: action.data,
        countries: action.data.countries,
        customers: action.data.customers,
        request_type: action.data.request_type
      }
      case SET_LIVE_DATA:
        return {
          ...state,
          live_data_response: action.data,
          request_by_countries: action.data.request_by_countries,
          total_request_box: action.data.total_request_box,
          accepted_request_box: action.data.accepted_request_box,
          online_customers: action.data.online_customers,
          total_countries: action.data.total_countries,
          first_graph: action.data.first_graph,
          second_graph: action.data.second_graph
        }
    case SET_RECENT_ACTIVITIES:
      return {
        ...state,
        recent_activities: action.data,
      }
    default: return state;
  }

}

export default LiveData;