import { SET_CERTIFICATES_SIDEBAR } from '../Actions/Types/Types'

const initialState = {
  toggleCertificatesSidebar: false
}

const certificates_sidebar = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CERTIFICATES_SIDEBAR:
      return {
        toggleCertificatesSidebar: action.value
      }
    default: return state;
  }
}

export default certificates_sidebar;