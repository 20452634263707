const BankConnectionTables = (props) => {
  return (
    <section className="bank-connection-tables-section">
      <div className="bank-connection-table-div-left">
        <table className="table">
          <thead className="bank-connection-thead">
            <tr>
              <th className="bank-connection-th">Organization (ID)</th>
              <th className="bank-connection-th bank-connection-right">
                Accepted Requests
              </th>
            </tr>
          </thead>
        </table>

        <table className="table">
          <tbody>
            {props.requestData.map((x, key) => (
              <tr key={key}>
                <td className="bank-connection-td">{x.organizationName}</td>

                <td className="bank-connection-td bank-connection-right">
                  {x.requestsAccepted}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bank-connection-table-div-right">
        <table className="table">
          <thead className="bank-connection-thead">
            <tr>
              <th className="bank-connection-th">Organization (ID)</th>
              <th className="bank-connection-th bank-connection-right">
                Created Requests
              </th>
            </tr>
          </thead>
        </table>

        <table className="table">
          <tbody>
            {props.requestData.map((x, key) => (
              <tr key={key}>
                <td className="bank-connection-td">{x.organizationName}</td>

                <td className="bank-connection-td bank-connection-right">
                  {x.requestsCreated}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default BankConnectionTables;
