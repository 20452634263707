import "swagger-editor-dist/swagger-editor.css";
import { useHistory } from "react-router-dom";
import { ApiPopup } from "./Popup/Popup";
import backIcon from "../../assets/images/icon_back.png";
import SwaggerEditor from "../SwaggerEditor/SwaggerEditor";
import "./apiDocumentationEdit.scss";
import { updateApiDocumentation } from "../../Redux/Actions/ApiDocumentation";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import NewFlash from "../FlashMessage/NewFlash";

const ApiDocumentationEdit = () => {

  const [active, setActive] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const getDocumentation = history.location.state?.documentation[0];
  const id = getDocumentation.id;
  const [title, setTitle] = useState(getDocumentation.title);
  const [sectionName, setSectionName] = useState(getDocumentation.section_name);
  const [sectionHeader, setSectionHeader] = useState(getDocumentation.section_header);
  const [sectionOrder, setSectionOrder] = useState(getDocumentation.section_order);
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })

  const updateApiDocumentationVersion = async () => {
    let myTitle = "";
    let myActive = "";
    let mySectionName = "";
    let mySectionHeader = "";
    let mySectionOrder = "";
    if (title === "") {
      myTitle = getDocumentation.title
    }
    else {
      myTitle = title;
    }
    if (active === undefined) {
      myActive = getDocumentation.active;
    }
    else {
      myActive = active;
    }

    if (sectionName === "") {
      mySectionName = getDocumentation.section_name;
    }
    else {
      mySectionName = sectionName;
    }

    if (sectionHeader === "") {
      mySectionHeader = getDocumentation.section_header;
    }
    else {
      mySectionHeader = sectionHeader;
    }

    if (sectionOrder === null) {
      mySectionOrder = getDocumentation.section_order;
    }
    else {
      mySectionOrder = sectionOrder;
    }


    let jsonData = localStorage.getItem("swagger-editor-content");
    const data = {
      title: myTitle,
      version: getDocumentation.version,
      documentation: jsonData,
      active: myActive,
      section_name: mySectionName,
      section_header: mySectionHeader,
      section_order: mySectionOrder
    };
    dispatch(setLoaderSpinner(true))
    const { status, message } = await dispatch(updateApiDocumentation(id, data));
    if (status) {
      setFlashMessage(true);
      setFlashData(prevState => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: "Success",
        secondMsg: message
      }))
    }
    setTimeout(() => {
      history.push("/api_documentation");
    }, 2000);
  }
  let update = history.location.state?.update;

  let documentationData = getDocumentation.documentation;

  localStorage.setItem(
    "swagger-editor-content",
    documentationData
  );
  const errors = document.getElementsByClassName("errors-wrapper");

  const closeFlash = () => {
    setFlashMessage(false);
  }


  return (
    <div className="service-settings-container page-container api-documentation-container">
      {flashMessage &&
        <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
          <NewFlash
            data={flashData}
            closeFlash={() => closeFlash()}
          />
        </div>
      }
      <div className="lower-header-api back-heading">
        <span className="arrow-heading-wrapper-editor">
          <span
            onClick={() => history.push("/api_documentation")}
            className="esu-icon-wrapper"
          >
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} />
          </span>
          <h3>Update API documentation</h3>
        </span>
      </div>
      <div className="documentation-new">
        <form className="documentation-form">
          <span className="input-container">
            <span className="new-documentation-wrapper">
              <span className="input-wrapper">
                <label htmlFor="title-edit" className="add-label">
                  Title
                </label>
                <input
                  id="title-edit"
                  value={title}
                  className="add-input"
                  onChange={(x) => setTitle(x.target.value)}
                  required
                />
              </span>

              <span className="input-wrapper">
                <label htmlFor="api-new-section-name" className="add-label">
                  Section name
                </label>
                <input
                  id="api-new-section-name"
                  value={sectionName}
                  placeholder=" Section name"
                  className="add-input"
                  onChange={(x) => setSectionName(x.target.value)}
                />
              </span>


            </span>
            <span className="new-documentation-wrapper">
              <span className="input-wrapper">
                <label htmlFor="select-documentation-edit" className="add-label">
                  Active
                </label>
                <select
                  id="select-documentation-edit"
                  placeholder={`${getDocumentation.active}`}
                  onChange={(x) => setActive(x.target.value)}
                  className="add-input"
                  required>
                  <option disabled selected>{`${getDocumentation.active}`}</option>
                  <option value={false}>False</option>
                  <option value={true}>True</option>
                </select>
              </span>
              <span className="input-wrapper">
                <label htmlFor="api-new-section-order" className="add-label">
                  Section order
                </label>
                <input
                  id="api-new-section-order"
                  type="number"
                  placeholder=" Section order"
                  onChange={(x) => setSectionOrder(x.target.value)}
                  className="add-input"
                  value={sectionOrder}
                />
              </span>
              
            </span>
            <span className="new-documentation-wrapper">
              <span className="input-wrapper">
                <label htmlFor="api-new-section-header" className="add-label">
                  Section header
                </label>
                <input
                  id="api-new-section-header"
                  placeholder=" Section header"
                  onChange={(x) => setSectionHeader(x.target.value)}
                  className="add-input"
                  value={sectionHeader}
                />
              </span>
              
            </span>
          </span>
        </form>
        <div className="api-popup-container">
          <ApiPopup errors={errors} updateDocumentation={updateApiDocumentationVersion} update={update} />
        </div>
        <SwaggerEditor />
      </div>
    </div>
  );
};
export default ApiDocumentationEdit;