import React from 'react'
import { Link } from "react-router-dom";
import eyeIcon from '../../../assets/images/eye_icon.svg';

const BrokerFlowTable = ({ columns, data, linkPath }) => {
    return (
        <table className="broker-flow-table mt-4" width="100%">
      <thead className="broker-flow-thead">
            <tr>
              {columns.map((column) => (
                column.accessor === 'id' ? 
                <th key={column.accessor} className='broker-flow-th-id'>{column.Header}</th> :
                <td key={column.accessor} className='broker-flow-th'>{column.Header}</td>
              ))}
              {linkPath && <td></td>} 
            </tr>
          </thead>
          <tbody className="broker-flow-tbody">
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column) => (
                  <td key={column.accessor} className="org-td">{row[column.accessor]}</td>
                ))}
                {linkPath && (
                  <td className="org-td broker-flow-view-details-icon">
                    <Link to={{
                      pathname: `${linkPath}/${row.id}`,
                      state: { broker: row }
                    }}>
                      <img src={eyeIcon} alt="View Details" />
                    </Link>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

export default BrokerFlowTable