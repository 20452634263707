import { useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { deleteBanner } from '../../Redux/Actions/Banners';
import { useEffect } from 'react';
import icon_trashcan from '../TestCenter/assets/kreditz-trash-icon.png';

const BannerDeleteModal = (props) => {
  const dispatch = useDispatch();
  const [bannerData, setBannerData] = useState();
  const [actionButton, setActionButton] = useState("Yes, delete");
  useEffect(() => {
    setBannerData(props.data);
  }, [props.data]);
  const toggleVisibility = () => {
    props.visibleToggle();
  }
  const confirmDeletion = async () => {
    setActionButton(<div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>)

    dispatch(deleteBanner(bannerData)).then((res) => {
      toggleVisibility();
      if (res.status) {
        props.fetchBanners();
      }
    })

    toggleVisibility();
    setActionButton("Yes, delete");

  }

  if (!props.visible) {
    return <></>
  }
  return (<>
    <div className="banners-modal-backdrop" onClick={toggleVisibility}></div>
    <div className='banners-modal-popup p-4 text-center d-flex flex-column justify-content-between'>
      <div>
        <img className='m-3' src={icon_trashcan} alt="Trashcan Icon" />
      </div>
      <div>
        <h5 className='text-wrap'>
          Are you sure you want to delete this template?
        </h5>
        <p className='m-0 text-wrap'>{props.data.template}</p>
      </div>
      <div>
        <button className='btn btn-outline-primary m-4 banners-btns-delete' onClick={toggleVisibility}>Cancel</button>
        <button className='btn btn-danger m-4 banners-btns-delete' onClick={confirmDeletion}>{actionButton}</button>
      </div>

    </div>
  </>
  )
}

export default BannerDeleteModal;