//props object from Comparison.jsx
const PaginationComparison = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <nav>
        <ul className="pagination justify-content-center paging-comparison">
          <li className="page-item">
            <a name="prev_page" href="#prev_page" className="page-link" onClick={prevPage}>
              {"<"}
            </a>
          </li>
          <li className="page-item">
            <a name="prev_page_1" href="#prev_page_1" className="page-link" onClick={prevPage}>
              Prev
            </a>
          </li>
          {pageNumbers.map((pgNumber) => (
            <li
              key={pgNumber}
              className={`page-item ${currentPage === pgNumber ? "active" : ""}`}
            >
              <a name="page_number" href="#page_number" className="page-link" onClick={() => setCurrentPage(pgNumber)}>
                {pgNumber}
              </a>
            </li>
          ))}
          <li className="page-item">
            <a name="next_page" href="#next_page" className="page-link" onClick={nextPage}>
              Next
            </a>
          </li>
          <li className="page-item">
            <a name="next_page_1" href="#next_page_1" className="page-link" onClick={nextPage}>
              {">"}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationComparison;
