import { SET_O_M_SIDEBAR } from '../Actions/Types/Types'

const initialState = {
  toggleOrganizationManagementSidebar: false
}

const organization_management_sidebar = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_O_M_SIDEBAR:
      return {
        toggleOrganizationManagementSidebar: action.value
      }
    default: return state;
  }
}

export default organization_management_sidebar;
