import SwaggerEditor from "../SwaggerEditor/SwaggerEditor";
import backIcon from "../../assets/images/icon_back.png";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { createApiDocumentation } from "../../Redux/Actions/ApiDocumentation";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner"
import { useDispatch } from "react-redux";
import { ClonePopup } from "./Popup/ClonePopup";
import NewFlash from "../FlashMessage/NewFlash";

const ApiDocumentationClone = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let update = history.location.state?.update;
  let documentation = history.location.state?.documentation[0];

  const [title, setTitle] = useState(documentation.title);
  const [description, setDescription] = useState(documentation.description);
  const [version, setVersion] = useState(documentation.version);
  const [active, setActive] = useState(documentation.active);
  const [sectionName, setSectionName] = useState(documentation.section_name);
  const [sectionHeader, setSectionHeader] = useState(documentation.section_header);
  const [sectionOrder, setSectionOrder] = useState(documentation.section_order);
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })

  let documentationData = documentation.documentation;
  localStorage.setItem(
    "swagger-editor-content",
    documentationData
  );

  const errors = document.getElementsByClassName("errors-wrapper");

  const cloneApiDocumentation = async () => {
    let myVersion = "";
    if (localStorage.getItem("swagger-editor-content") === "" || version !== "") {
      myVersion = version
    }
    else {
      myVersion = JSON.parse(localStorage.getItem("swagger-editor-content")).info.version
    }
    let jsonData = localStorage.getItem("swagger-editor-content");
    const data = {
      title: title,
      description: description,
      version: myVersion,
      documentation: jsonData,
      active: active,
      section_name: sectionName,
      section_header: sectionHeader,
      section_order: sectionOrder
    };
    dispatch(setLoaderSpinner(true))
    const { status, message } = await dispatch(createApiDocumentation(data));
    if (status) {
      setFlashMessage(true);
      setFlashData(prevState => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: "Success",
        secondMsg: message
      }))
    }
    setTimeout(() => {
      history.push("/api_documentation");
    }, 2000);
  }

  const closeFlash = () => {
    setFlashMessage(false);
  }

  return (
    <div className="service-settings-container page-container api-documentation-container">
      {flashMessage &&
        <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
          <NewFlash
            data={flashData}
            closeFlash={() => closeFlash()}
          />
        </div>
      }
      <div className="lower-header-api back-heading">
        <span className="arrow-heading-wrapper-editor">
          <span
            onClick={() => history.push("/api_documentation")}
            className="esu-icon-wrapper"
          >
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} />
          </span>
          <h3>Clone API documentation</h3>
        </span>
      </div>
      <div className="documentation-new">
        <form className="documentation-form">
          <span className="input-container">
            <span className="new-documentation-wrapper">
              <span className="input-wrapper">
                <label htmlFor="title" className="add-label">
                  Title
                </label>
                <input
                  value={title}
                  id="title"
                  placeholder=" Title"
                  className="add-input"
                  onChange={(x) => setTitle(x.target.value)}
                  required
                />
              </span>

               <span className="input-wrapper">
                <label htmlFor="select-documentation" className="add-label">
                  Active
                </label>
                <select
                  id="select-documentation"
                  placeholder=" Description"
                  onChange={(x) => setActive(x.target.value)}
                  className="add-input"
                  required>
                  <option disabled selected>{`${active}`}</option>
                  <option value={false}>False</option>
                  <option value={true}>True</option>
                </select>
              </span>
            </span>
            <span className="new-documentation-wrapper">
              <span className="input-wrapper">
                <label htmlFor="version" className="add-label">
                  Version
                </label>
                <input
                  value={version}
                  id="version"
                  placeholder=" Version"
                  className="add-input"
                  onChange={(x) => setVersion(x.target.value)}
                />
              </span>

               <span className="input-wrapper">
                <label htmlFor="api-new-section-header" className="add-label">
                  Section header
                </label>
                <input
                  value={sectionHeader}
                  id="api-new-section-header"
                  placeholder=" Section header"
                  onChange={(x) => setSectionHeader(x.target.value)}
                  className="add-input"
                />
              </span>
             
            </span>
            <span className="new-documentation-wrapper">
              <span className="input-wrapper">
                <label htmlFor="api-new-section-name" className="add-label">
                  Section name
                </label>
                <input
                  value={sectionName}
                  id="api-new-section-name"
                  placeholder=" Section name"
                  className="add-input"
                  onChange={(x) => setSectionName(x.target.value)}
                />
              </span>
             
              <span className="input-wrapper">
                <label htmlFor="api-new-section-order" className="add-label">
                  Section order
                </label>
                <input
                  value={sectionOrder}
                  id="api-new-section-order"
                  type="number"
                  placeholder=" Section order"
                  onChange={(x) => setSectionOrder(x.target.value)}
                  className="add-input"
                />
              </span>
            </span>
          </span>

        </form>
        <div className="api-popup-container">
          <ClonePopup
            errors={errors}
            addDocumentation={cloneApiDocumentation}
            title={title}
            description={description}
            version={version}
            update={update}
            documentation={documentation}
          />
        </div>
        <SwaggerEditor />
      </div>
    </div>
  )
}
export default ApiDocumentationClone;