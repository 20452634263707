import "./serviceSettings.scss";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { getServiceSettings, getServiceSettingsFilter } from "../../Redux/Actions/ServiceSettings";
import ServiceSettingsModal from "../ServiceSettingsModal/ServiceSettingsModal";
import ServiceSettingAction from "../ServiceSettingAction/serviceSettingAction";
import EditServiceSetting from "../EditServiceSetting/editServiceSetting";
import updateIcon from "../../assets/images/edit.svg";
import { UpdateServiceSettingsAction } from "../../Redux/Actions/ServiceSettings";
import FlashMessage from "../FlashMessage/FlashMessage";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import ReactSelect from "../ReactSelect/ReactSelect";

const ServiceSettings = () => {
  const [openEditOrg, setOpenEditOrg] = useState(false);
  const [editServiceSettings, setEditServiceSettings] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [orgId, setOrdId] = useState([]);
  const [serviceId, setServiceId] = useState([]);
  const [country, setCountry] = useState([]);

  const [serviceData, setServiceData] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [serviceActionId, setServiceActionId] = useState([]);

  const [filters, setFilters] = useState({});
  const [sendData, setSendData] = useState({});
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());

  useEffect(() => {
    fetchServiceSettingFilter();
  }, []);

  const fetchServiceSettingFilter = async () => {
    const { data } = await dispatch(getServiceSettingsFilter());
    const { organizations_list, services_list, countries_list } = data;
    setOrdId(organizations_list);
    setServiceId(services_list);
    setCountry(countries_list);
    await dispatch(setLoaderSpinner(false));
  };

  const handleChange = (type, value) => {
    setFilters(prevState => ({
      ...prevState,
      [type]: value
    }))
  };

  const handleSubmit = (event) => {
    setOpenEditOrg(true)
  };

  const handlAction = (item) => {
    let newArrForcredential = []
    if (item.credentials.client_id) {
      newArrForcredential.push({ key: "client_id", value: item.credentials.client_id })
    }
    if (item.credentials.client_secret) {
      newArrForcredential.push({ key: "client_secret", value: item.credentials.client_secret })
    }
    if (item.credentials.pem_file_key) {
      newArrForcredential.push({ key: "pem_file_key", value: item.credentials.pem_file_key })
    }
    setEditServiceSettings(true)
    setSendData({ ...item, newArrForcredential });
    setServiceActionId(item.id)
  }

  const togglePopup = () => {
    setOpenEditOrg(false)
    setOpenPopUp(false)
    setEditServiceSettings(false)
  }

  const submitHandle = async () => {
    await dispatch(setLoaderSpinner(true))
    const data = filters;
    const response = await dispatch(getServiceSettings(data))
    if (response.status) {
      if (response.message === "Unauthorize") {
        history.push("/login")
      }
      else {
        setServiceData(response?.data)
      }
    }
    await dispatch(setLoaderSpinner(false))
  }

  function humanize(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  const handleUpdate = async (data) => {
    const response = await dispatch(UpdateServiceSettingsAction(data))
    if (response.status) {
      // await dispatch(setLoaderSpinner(false))
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        if (response.message === "success") {
          const result = serviceData.findIndex(item => item.id === data.id)
          serviceData[result].active = data.active
          serviceData[result].labelization = data.labelization
          serviceData[result].country_name = data.country_name
          serviceData[result].bank_authentication_approach = data.bank_authentication_approach
          serviceData[result].certificate_type = data.certificate_type
          serviceData[result].country_id = Number(data.country_id)
          serviceData[result].env = data.env
          serviceData[result].organization_id = data.organization_id
          serviceData[result].service_id = data.service_id
          serviceData[result].credentials = data.credentials
          serviceData[result].broker_application = data.broker_application
          serviceData[result].ssn_validation = data.ssn_validation
          openFlash("tick", "Done!", response.renderMessage);
        } else {
          openFlash("alert", "Error!", response.renderMessage)
        }
      }
      togglePopup();
    }
  }

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }


  return (
    <div className="service-settings-container page-container" id="service_setting_page">

      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>

      {editServiceSettings ? <EditServiceSetting openFlash={openFlash} handleUpdate={handleUpdate} closeDelete={togglePopup} id={serviceActionId} data={sendData} /> : null}
      {openEditOrg ? <ServiceSettingsModal openFlash={openFlash} closeDelete={togglePopup} /> : null}
      {openPopUp ? <ServiceSettingAction closeDelete={togglePopup} serviceData={serviceData} id={serviceActionId} data={sendData} handleUpdate={handleUpdate} /> : null}

      <div className="lower-header-service-settings service-settings-head-bg">
        <h3>Service Settings</h3>
        <button className="service-button" onClick={() => handleSubmit()}>Create Service Setting</button>
      </div>

      <div className="filter-container-service-settings service-setting-width">
        <div className="service-settings-filter-container overflow-inherit">
          <label>Organization</label>
          <ReactSelect
            orgList={orgId}
            handleChange={handleChange}
          />
        </div>
        <div className="service-settings-filter-container service-setting-filter">
          <label>Service</label>
          <select className="select-filter-service-settings capitalize" onChange={(e) => handleChange("service_id", e.target.value)}>
            <option value="">Select</option>
            {
              serviceId && serviceId.map((service, index) => (
                <option value={service.id}
                  key={`serviceId-option-${index + 1}`}>
                  {service.name.replaceAll("_", " ")}
                </option>
              ))
            }
          </select>
        </div>
        <div className="service-settings-filter-container">
          <label>Country</label>
          <select className="select-filter-service-settings capitalize" onChange={(e) => handleChange("country_id", e.target.value)}>
            <option value="">Select</option>
            {
              country && country.map((_country, index) => (
                <option value={_country.id}
                  key={`country-option-${index + 1}`}>
                  {_country.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className="service-settings-filter-container">
          <label>Certificate Type</label>
          <select className="select-filter-service-settings" onChange={(e) => handleChange("certificate_type", e.target.value)}>
            <option value="">Select</option>
            <option value="customer">Customer</option>
            <option value="corporate">Corporate</option>
          </select>
        </div>
        <div className="service-settings-filter-container">
          <label>ENV</label>
          <select className="select-filter-service-settings" onChange={(e) => handleChange("env", e.target.value)}>
            <option value="">Select</option>
            <option value="production">Production</option>
            <option value="testing">Testing</option>
          </select>
        </div>
        <div className="service-settings-filter-container">
          <label>Active</label>
          <select className="select-filter-service-settings" onChange={(e) => handleChange("active", e.target.value)}>
            <option value="">Select</option>
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        </div>
        <div className="service-settings-filter-container">
          <label>Labelization</label>
          <select className="select-filter-service-settings" onChange={(e) => handleChange("labelization", e.target.value)}>
            <option value="">Select</option>
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        </div>
        <div className="service-settings-filter-container">
          <label>Broker Application</label>
          <select className="select-filter-service-settings" onChange={(e) => handleChange("broker_application", e.target.value)}>
            <option value="">Select</option>
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        </div>
        <div className="service-settings-filter-container">
          <label>Ssn Validation</label>
          <select className="select-filter-service-settings" onChange={(e) => handleChange("ssn_validation", e.target.value)}>
            <option value="">Select</option>
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        </div>
        <div className="service-settings-filter-container">
          <button className={filters.organization_id && filters.organization_id || filters.service_id && filters.service_id || filters.country_id && filters.country_id || filters.certificate_type && filters.certificate_type || filters.env && filters.env || filters.active && filters.active || filters.labelization && filters.labelization || filters.borker_application && filters.borker_application || filters.ssn_validation && filters.ssn_validation != "" ? "apply-filter-button" : "apply-filter-button login_submit_disabled"} onClick={() => submitHandle()}>
            Filter
          </button>
        </div>
      </div>

      <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <div className="organizations-thead-scroll-y">
          <table className="table td-service-setting" width="100%">
            <thead className="organizations-thead">
              <tr>
                <td className="org-head">Organization</td>
                <td className="org-head">Service</td>
                <td className="org-head">Country</td>
                <td className="org-head">Certificate Type</td>
                <td className="org-head">ENV</td>
                <td className="org-head">Active</td>
                <td className="org-head">Labelization</td>
                <td className="org-head">Broker Application</td>
                <td className="org-head">Ssn Validation</td>
                <td><center>Action</center></td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                serviceData && serviceData.length > 0 && serviceData.map((item) => {
                  return (
                    <tr key={`kreditz-providers-${item.id} `} id={`${item.id}`}>
                      <td className="org-td">{item.organization_name} ({`${item.organization_id}`})</td>
                      <td className="org-td">{humanize(item.service_name)}</td>
                      <td className="org-td">{item?.country_name?.charAt(0)?.toUpperCase() + item?.country_name?.slice(1)}</td>
                      <td className="org-td">{item.certificate_type?.charAt(0)?.toUpperCase() + item?.certificate_type?.slice(1)}</td>
                      <td className="org-td">{item?.env?.charAt(0)?.toUpperCase() + item?.env?.slice(1)}</td>
                      <td className="org-td">{item?.active ? 'True' : 'False'}</td>
                      <td className="org-td">{item?.labelization ? 'True' : 'False'}</td>
                      <td className="org-td">{item?.broker_application ? 'True' : 'False'}</td>
                      <td className="org-td">{item?.ssn_validation ? 'True' : 'False'}</td>
                      <td className="org-td">
                        <center>
                          <button type="button" className="email-img-btn" onClick={() => handlAction(item)}><img src={updateIcon} alt="view" width="20" /></button>
                        </center>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ServiceSettings;