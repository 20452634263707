import React from 'react';

const LanguageTab = ({ setClientDetails, clientDetails, submitHandle }) => {

  const languages = [
    { name: 'English', code: 'en' },
    { name: 'Swedish', code: 'sw' },
    { name: 'Finnish', code: 'fi' },
    { name: 'Danish', code: 'da' },
    { name: 'Norwegian', code: 'nn' }
  ]

  const selectLanguageHandler = (event) => {
    let setting = clientDetails.setting
    setting.default_locale = event.target.value
    setClientDetails(prevState => ({
      ...prevState,
      setting: setting
    }))
  }

  return (
    <div id="language_details" className="hide">
      <div className="detail-two-box">
        <div className="details-left details-full-box">
          <div className="form-group">
            <label>Select Main Language</label>
            <select className="form-control org-excludeDropdown" value={clientDetails.setting.default_locale} onChange={(e) => selectLanguageHandler(e)}>
              <option value=''>Select</option>
              {languages.map((lang, index) => (
                <option className='admin-option' key={`admin-option-${index + 1}`} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="create-client-button">
        <button onClick={() => submitHandle()}>Update</button>
      </div>
    </div>
  );
};

export default LanguageTab;