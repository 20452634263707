export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const DELETE_CURRENT_USER = "DELETE_CURRENT_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_ADMIN_LIST = "SET_ADMIN_LIST";
export const SET_INVOICES_LIST = "SET_INVOICES_LIST";
export const SET_USER_MANAGEMENT_LIST = "SET_USER_MANAGEMENT_LIST";
export const SET_STATISTICS_LIST = "SET_STATISTICS_LIST";
export const SET_STATISTICS_FILTER = "SET_STATISTICS_FILTER";
export const SET_LIVE_DATA_FILTER = "SET_LIVE_DATA_FILTER";
export const SET_LIVE_DATA = "SET_LIVE_DATA";
export const SET_LIVE_DATA_SIDEBAR = "SET_LIVE_DATA_SIDEBAR";
export const SET_RECENT_ACTIVITIES = "SET_RECENT_ACTIVITIES";
export const SET_SMS_LOGS = "SET_SMS_LOGS";
export const SET_CERT_LOGS_BYSEARCH_CERT_ID = "SET_CERT_LOGS_BYSEARCH_CERT_ID";
export const SET_CREATE_SUBSCRIBER = "SET_CREATE_SUBSCRIBER";
export const SET_SUBSCRIBERS_LIST = "SET_SUBSCRIBERS_LIST";
export const SET_BASIC_VALUES = "SET_BASIC_VALUES";
export const SET_TEST_CENTER = "SET_TEST_CENTER";
export const SET_EMAIL_LIST = "SET_EMAIL_LIST";
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const SET_LOADER_SPINNER = "SET_LOADER_SPINNER";
export const SET_BANK_LIST = "SET_BANK_LIST";
export const SET_BANK = "SET_BANK";
export const SET_AUDIT_REPORT = "SET_AUDIT_REPORT";
export const SET_DEMO_AGGREGATION = "SET_DEMO_AGGREGATION"; // ask about this
export const SET_ACCESS_CODES = "SET_DEMO_AGGREGATION";
export const SET_CONVERSION_RATE_LIST = "SET_CONVERSION_RATE_LIST";
export const SET_CONVERSION_RATE_FILTER = "SET_CONVERSION_RATE_FILTER";
export const SERVIVE_SETTINGS = 'SET_SERVIVE_SETTINGS';
export const SET_SERVIVE_SETTINGS_LIST = "SET_SERVIVE_SETTINGS_LIST";
export const SET_SERVIVE_SETTINGS_FILTER = "SET_SERVIVE_SETTINGS_FILTER";
export const SET_BANK_CONNECTION_FREQUENCY_LIST = "SET_BANK_CONNECTION_FREQUENCY_LIST";
export const SET_COMPARISON_REPORT_LIST = "SET_COMPARISON_REPORT_LIST";
export const SET_DYNAMIC_LIVE_DATA_LIST = "SET_DYNAMIC_LIVE_DATA_LIST";
export const SET_DYNAMIC_LIVE_DATA_FILTER = "SET_DYNAMIC_LIVE_DATA_FILTER"
export const SET_IT_SIDEBAR = "SET_IT_SIDEBAR";
export const SET_O_M_SIDEBAR = "SET_O_M_SIDEBAR";
export const SET_API_ACCESS_CODES = "SET_API_ACCESS_CODES";
export const SET_CONVERSION_SIDEBAR = "SET_CONVERSION_SIDEBAR";
export const SET_CONVERSION_BANK_FILTER = "SET_CONVERSION_BANK_FILTER";
export const SET_STATISTICS_SIDEBAR = "SET_STATISTICS_SIDEBAR";
export const SET_ACTIVE_CUSTOMERS = "SET_ACTIVE_CUSTOMERS";
export const SET_PROVIDERS_MODES_DATA = "SET_PROVIDERS_MODES_DATA";
export const SET_VISTA_ACCOUNT_USERS = "SET_VISTA_ACCOUNT_USERS";
export const SET_CERTIFICATES_SIDEBAR = "SET_CERTIFICATES_SIDEBAR";
export const SET_CERTIFICATE_INFORMATION = "SET_CERTIFICATE_INFORMATION";
export const SET_CERTIFICATE_INFORMATION_FILTER = "SET_CERTIFICATE_INFORMATION_FILTER";
export const SET_BANK_CONNECTION_SIDEBAR = "SET_BANK_CONNECTION_SIDEBAR";
export const SET_TOTAL_CLIENTS_DATA = "SET_TOTAL_CLIENTS_DATA";
export const SET_ACTIVE_SIDEBAR = "SET_ACTIVE_SIDEBAR";
export const SET_API_DOCUMENTATION_LIST = "SET_API_DOCUMENTATION_LIST"
export const SET_CREATE_API_DOCUMENTATION = "SET_CREATE_API_DOCUMENTATION"
export const SET_API_DOCS_SETTING = "SET_API_DOCS_SETTING";
