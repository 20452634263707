import { SET_SMS_LOGS } from '../Actions/Types/Types';

const initialState = {
  sms_logs_response: {},
  sms_logs: [],
  pagination_data: {},
}

const SmsList = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SMS_LOGS:
      return {
        ...state,
        sms_logs_response: action.data,
        sms_logs: action.data.sms_logs,
        pagination_data: action.data.pagination_data,
      }
    default: return state;
  }
}

export default SmsList;