import "./conversion.css";

const RequestLogTable = (props) => {
  let index = 11;

  let lastMonths = [...props.allRequestsSorted];

  // show the most recent 12 months
  lastMonths.splice(index + 1, lastMonths.length - (index + 1));

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div>
      <table
        id="table-conversion-request"
        className="table table-conversion"
        width="100%"
      >
        <thead className="conversion-thead">
          <tr className="conversion-request-table-row">
            <th className="table-head-conversion conversion-month">
              Month
            </th>

            <th className="table-head-conversion conversion-table-right">
              Total requests
            </th>
            <th className="table-head-conversion conversion-table-right conversion-accepted-table">
              Accepted requests
            </th>

            <th className="table-head-conversion conversion-table-right">
              Success rate
            </th>
          </tr>
        </thead>
        {/* </table>

      <table key={Math.random()} className="table" width="100%"> */}
        <tbody>
          {props.allRequestsSorted &&
            props.allRequestsSorted.map((request) => (
              <tr
                className="conversion-table-row-container-request"
                key={Math.random()}
              >
                <td className="conversion-td conversion-month">
                  {request.month}
                </td>
                <td className="conversion-td td-total conversion-table-right">
                  {numberWithSpaces(request.total_requests)}
                </td>
                <td className="conversion-td conversion-accepted-table conversion-table-right">
                  {numberWithSpaces(request.accepted_requests)}
                </td>
                <td className="conversion-td conversion-table-right">
                  {request.success_rate}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestLogTable;
