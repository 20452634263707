import axios from 'axios';

export default function setRequestHeader(userData = {}) {
  axios.defaults.headers.common['access-token'] = userData?.user?.auth_token;
  axios.defaults.headers.common['email'] = userData?.user?.email;
  axios.defaults.headers.common['role'] = userData?.user?.role;
  axios.defaults.headers.common['platform'] = "WEB-APP";
  axios.defaults.headers.common['app-version'] = "1.0";
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

export function deleteRequestHeader() {
  delete axios.defaults.headers.common['access-token'];
  delete axios.defaults.headers.common['email'];
  delete axios.defaults.headers.common['role'];
  delete axios.defaults.headers.common['platform'];
  delete axios.defaults.headers.common['app-version'];
  delete axios.defaults.headers.common['Content-Type'];
  delete axios.defaults.headers.common['Access-Control-Allow-Origin'];
}