import React, { useState } from 'react';
// STYLES
import './styles/invoiceView.css';
import './styles/MaterialUI.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Flag from 'react-world-flags'

// LIBRARIES
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import eyeIcon from '../../assets/images/eye_icon.svg';
import tick from '../../assets/images/invoicing_tick.svg'
import "./invoicingList.scss";
import moment from 'moment';

const List = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const darkBackground = item.sub_accounts.length > 0 ? true : false;
  let slicedCountryArray = item.countries.slice(0, 4);

  const numberWithSpaces = ((x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  });

  const deactivatedAt = () => {
    return item.deactivated_at || item.deleted_at ? moment(item.deactivated_at || item.deleted_at).format('DD-MM-YYYY') : '';
  };

  return (
    <React.Fragment>
      <tr style={{ backgroundColor: `${darkBackground ? "#f4f6fb" : ""}` }}>
        {!item.active ? (
          <>
            <td>{item.id}</td>
            <td className={!item.active ? 'inactive-org' : ''}>
              {item.name} {(item.deactivated_at || item.deleted_at) && !item.active && (
                <span className="invoicing-inactive-text">
                  - Inactive - {deactivatedAt()}
                </span>
              )}
            </td>
          </>
        ) : (
          <>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{numberWithSpaces(item.requests_count)}</td>
            <td>{numberWithSpaces(item.accepted_requests_count)}</td>
            <td>{numberWithSpaces(item.continuous_access)}</td>
            <td>{numberWithSpaces(item.refetched)}</td>
            <td>
              {slicedCountryArray.map((x, y) => (
                <span className="invoice-tooltip-wrapper hover-text" key={y}>
                  <Flag code={x.country_code === "UK" ? "GB" : x.country_code} height="16" className='invoicing-flag' key={y} />
                  <span className='invoice-tooltip'>{x.country_name}</span>
                </span>
              ))}
              {item.countries.length > 4 && <span className='country-dots'>...</span>}
            </td>
            <td>{numberWithSpaces(item.total_sent_sms)}</td>
            <td>
              <center>
                <Link to={{ pathname: `/invoicing/details`, state: { parentOrgId: item.id, parentOrgName: item.name, orgId: item.id, orgName: item.name } }}>
                  <img src={eyeIcon} alt="eyeIcon" />
                </Link>
              </center>
            </td>
            <td className="text-center">
              <span className='sub-account-container'>
                {item.sub_accounts.length > 0 ? <img src={tick} /> : <>---</>}
                {(item.countries.length > 1 || item.sub_accounts.length > 0) && (isOpen ? <KeyboardArrowUpIcon onClick={() => setIsOpen(!isOpen)} /> : <KeyboardArrowDownIcon onClick={() => setIsOpen(!isOpen)} />)}
              </span>
            </td>
          </>
        )}
      </tr>
      {item.countries.length > 1 && isOpen && item.countries.map((country, y) => (
        <tr style={{ backgroundColor: `${darkBackground ? "#f4f6fb" : ""}` }} key={Math.random()}>
          <td></td>
          <td></td>
          <td>{numberWithSpaces(country.total_requests)}</td>
          <td>{numberWithSpaces(country.accepted_requests)}</td>
          <td>{numberWithSpaces(country.continuous_access)}</td>
          <td>{numberWithSpaces(country.refetched)}</td>
          <td>{country.country_code === "UK" ?
            <span className="invoice-tooltip-wrapper hover-text" key={y}>
              <Flag code={"GB"} height="16" className='invoicing-flag' />
              <span className='invoice-tooltip'>{country.country_name}</span>
            </span> :
            <span className="invoice-tooltip-wrapper hover-text" key={y}>
              <Flag code={country.country_code} height="16" className='invoicing-flag' />
              <span className='invoice-tooltip'>{country.country_name}</span>
            </span>}</td>
          <td>-</td>
          <td></td>
          <td></td>
        </tr>
      ))}
      <tr className="sub_accountbg">
        <TableCell
          colSpan={10}
          className='invoicing-sub-acc-table'>
          <Collapse
            in={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            timeout='auto'>
            <main className='mui-collapse-container'>
              <div className='mui-section-container'>
                <section className='mui-section-wrapper mui-bg'>
                  <table>
                    <tbody>
                      {item.sub_accounts.map((account) =>
                        <React.Fragment key={Math.random()}>
                          <tr key={account.id}>
                            <td>{account.id}</td>
                            <td>{account.name}</td>
                            <td>{numberWithSpaces(account.requests_count)}</td>
                            <td>{numberWithSpaces(account.accepted_requests_count)}</td>
                            <td>{numberWithSpaces(account.continuous_access)}</td>
                            <td>{numberWithSpaces(account.refetched)}</td>
                            <td>
                              {account.sub_acc_countries.length > 0 && account.sub_acc_countries.slice(0, 4).map((country, y) =>
                              (country.country_code === "UK" ?
                                <span className="invoice-tooltip-wrapper hover-text" key={y}>
                                  <Flag code={"GB"} height="16" className='invoicing-flag' key={Math.random()} />
                                  <span className='invoice-tooltip'>{country.country_name}</span>
                                </span> :
                                <span className="invoice-tooltip-wrapper hover-text" key={y}>
                                  <Flag code={country.country_code} height="16" className='invoicing-flag' key={Math.random()} />
                                  <span className='invoice-tooltip'>{country.country_name}</span>
                                </span>))
                              }
                              {account.sub_acc_countries.length > 4 && <span className='country-dots'>...</span>}
                            </td>
                            <td>{numberWithSpaces(account.total_sent_sms)}</td>
                            <td></td>
                            <td></td>
                          </tr>
                          {
                            account.sub_acc_countries.length > 1 &&
                            account.sub_acc_countries.map((country, y) => (
                              <tr key={Math.random()}>
                                <td></td>
                                <td></td>
                                <td>{numberWithSpaces(country.total_requests)}</td>
                                <td>{numberWithSpaces(country.accepted_requests)}</td>
                                <td>{numberWithSpaces(country.continuous_access)}</td>
                                <td>{numberWithSpaces(country.refetched)}</td>
                                <td>
                                  {country.country_code === "UK" ?
                                    <span className="invoice-tooltip-wrapper hover-text" key={y}>
                                      <Flag code={"GB"} height="16" className='invoicing-flag' />
                                      <span className='invoice-tooltip'>{country.country_name}</span>
                                    </span> :
                                    <span className="invoice-tooltip-wrapper hover-text" key={y}>
                                      <Flag code={country.country_code} height="16" className='invoicing-flag' />
                                      <span className='invoice-tooltip'>{country.country_name}</span>
                                    </span>}
                                </td>
                                <td>-</td>
                                <td></td>
                              </tr>
                            ))
                          }
                        </React.Fragment>
                      )}{item.sub_accounts.length > 0 &&
                        <tr key={Math.random()}>
                          <td>Total</td>
                          <td></td>
                          <td>{numberWithSpaces(item.total_count.total_requests_count)}</td>
                          <td>{numberWithSpaces(item.total_count.total_accepted_requests_count)}</td>
                          <td>{numberWithSpaces(item.total_count.total_continuous_access)}</td>
                          <td>{numberWithSpaces(item.total_count.total_refetched)}</td>
                          <td></td>
                          <td>{numberWithSpaces(item.total_count.total_sms_sent_count)}</td>
                          <td></td>
                          <td></td>
                        </tr>}
                    </tbody>
                  </table>
                </section>
              </div>
            </main>
          </Collapse>
        </TableCell>
      </tr>
    </React.Fragment>
  );
};
export default List;