import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { SET_TOTAL_CLIENTS_DATA } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

// export function getTotalClientsData(data) {
//   const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.TOTAL_CLIENTS.CLIENTS_DATA()}` + data;
//   const fullURL = data ? `${apiURL}${data}` : apiURL;
//   return dispatch => axios.get(fullURL)
//     .then(res => {
//       return { status: true, message: res.data.status, data: res.data.data };
//     }).catch(err => {
//       (err.response.status === 401) && removeHeaderWithLocalstorage();
//       return { status: false, message: err.response.data.error || err.response.data.message };
//     });
// }

export function getTotalClientsData(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.TOTAL_CLIENTS.CLIENTS_DATA()}`;
  return dispatch => axios.get(apiURL, data)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function setTotalClientsData(response) {
  return {
    type: SET_TOTAL_CLIENTS_DATA,
    statistics_list: response
  }
}