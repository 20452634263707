import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { getBanksData } from "../../Redux/Actions/BankStatus";
import { blockSorting } from "./utilities/dataFormatting";
import BankStatusBar from "./utilComponents/BankStatusBar"
import BankStatusLabel from "./utilComponents/BankStatusBankLabel"
const BankContainer = (props) => {
  const [bank, setBank] = useState(props.data);
  const [blocks, setBlocks] = useState();
  const requests = props.requests || [];
  const [recentStatus, setRecentStatus] = useState();


  const getRequests = async (time_frame) => {
    const { blocks, recentRequests } = blockSorting(requests, time_frame);
    await setBlocks(blocks);
    await setRecentStatus(recentRequests);
  }
  useEffect(() => {
    setBank(props.data);
    getRequests(props.timeFrame);
  }, [props.data, props.requests, props.timeFrame]);

  return (
    <>
      <div className="mw-100 pe-3 pt-3 bank-status-top">
        <div className="mw-100 bank-status-top-label-container">
          <BankStatusLabel bank={bank} status={blocks} showUptime={true} recentRequests={recentStatus} />
        </div>
        <div className="bank-status-top-bar">
          <BankStatusBar blocks={blocks} />
        </div>
      </div>
    </>
  )
}

const BankStatusSearchedBanks = ({ query, timeFrame }) => {
  const [banks, setBanks] = useState(null);
  const [visible, setVisible] = useState(true);
  const [requests, setRequests] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchBankStatus = async (params, time_frame) => {
    try {
      if (loading) return;
      setLoading(true);
      await dispatch(setLoaderSpinner(true));
      params = { country_id: params.country_id, query_string: params.query_string, time_frame: time_frame };
      const res = await dispatch(getBanksData({ params }));
      await setBanks(res.data.banks);
      await setRequests(res.data.request_list);
    } catch (error) {
      console.log("Error", error);
    } finally {
      await dispatch(setLoaderSpinner(false));
      setVisible(true);
      setLoading(false);

    }
  }
  useEffect(() => {
    if (query.query_string) {
      fetchBankStatus(query, timeFrame);
    } else {
      setBanks(null);
    };
  }, [query])

  return (banks && visible && !loading) ? <>
    <div className="bank-status-searched-banks-container">
      <div className="bank-status-searched-banks-header">
        <label className="bank-status-searched-banks-header-text">Results</label>
        <button className="bank-status-searched-banks-close" onClick={() => { setVisible(false) }}>X</button>
      </div>
      <div className="bank-status-searched-banks-content">
        {banks[0] && requests ? banks.map(((bank, key) => { return <BankContainer data={bank} key={key} timeFrame={timeFrame} requests={requests[bank.kreditz_provider_id.toString()]} /> }))
          :
          <>
            <p className="text-center m-4 mb-1 text-muted">
              No banks found.
            </p>
          </>
        }
      </div>
    </div>
  </> : <>
  </>
}


export default BankStatusSearchedBanks;