import React, { useState, useEffect } from 'react';
import '../OrganizationsEmailSms/OrganizationsEmailSms.scss';
import { getOrganizationConsentTexts, deleteOrganizationConsentTexts, getOrganizationConsentTextsFilters, getOrganizationConsentTextsFilterData, getOrganizationConsentTextsShow } from '../../Redux/Actions/OrganizationConsentTexts';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FlashMessage from '../FlashMessage/FlashMessage';
import './OrganizationConsentTexts.css';
import edit from '../../assets/images/edit.svg';
import remove from '../../assets/images/remove.svg';
import DeleteModal from "../DeleteModal/DeleteModal";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import ReactPaginate from 'react-paginate';
import eyeIcon from '../../assets/images/eye_icon_gray.svg';
import { getServiceSettingsFilter } from '../../Redux/Actions/ServiceSettings';
import ReactSelect from 'react-select';


const OrganizationConsentTexts = () => {
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [organizationsList, setOrganizationsList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [serviceList, setServiceList] = useState([])
  const [consentTextList, setConsentTextList] = useState([])
  const [showDelete, setShowDelete] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState('');
  const [firstMsg, setFirstMsg] = useState('');
  const [secondMsg, setSecondMsg] = useState('');
  const [locales, setLocales] = useState([])
  const [filters, setFilters] = useState({ service_id: '', locale: '', display_name: '', keys: '' })
  const [keysList, setKeysList] = useState([])
  const [displayTexts, setDisplayTexts] = useState([])
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_count: '', total_pages: '' })


  useEffect(() => {
    fetchServiceSettingFilter()
    fetchOrganizationConsentTexts()
    fetchOrgconsentTextFilters()
  }, [dispatch, history]);

  const fetchOrganizationConsentTexts = async (page) => {
    try {
      const response = await dispatch(getOrganizationConsentTexts(page));
      const { current_page, total_count, total_pages, texts } = response?.data;
      if (response.status) {
        setConsentTextList(texts)
        setpaginationData(prevState => ({
          ...prevState,
          current_page: current_page,
          total_count: total_count,
          total_pages: total_pages
        }))
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch Organization Consent Texts', error);
    }
  }

  const selectStyles = {
    container: (base) => ({
      ...base,
      width: '16rem',
      fontFamily: '"SF Pro Display"',
      fontSize: '12px',
      color: 'white',
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: '1px solid #ccc',
      padding: '10px',
      backgroundColor: state.isSelected ? '#3e85f9' : 'white',
      color: state.isSelected ? 'white' : '#596980',
      ':hover': {
        backgroundColor: '#3e85f9',
        color: 'white',
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    control: (base) => ({
      ...base,
      fontFamily: '"SF Pro Display"',
      fontSize: '12px',
      color: '#596980',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#596980',
    })
  };

  const fetchServiceSettingFilter = async () => {
    const { data } = await dispatch(getServiceSettingsFilter());
    const { organizations_list, services_list, countries_list } = data;
    setServiceList(services_list)
    setOrganizationsList(organizations_list)
  };


  const fetchOrgconsentTextFilters = async () => {
    try {
      const response = await dispatch(getOrganizationConsentTextsFilters());
      const { locales, organizations, service, keys, texts } = response.data;
      if (response.status) {
        let sortedLocales = [...locales].sort()
        setLocales(sortedLocales)
        setKeysList(keys)
        setDisplayTexts(texts)
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch organizations consent text filter list', error);
    }
  }

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  const handleCreateEditor = () => {
    history.push('/manage_organization_consent_texts', { identifier: 'create', textPage: true })
  }

  function humanize(str) {
    var i, frags = str?.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
    }
    return frags?.join(' ');
  }

  const editHandler = (data) => {
    history.push('manage_organization_consent_texts', { identifier: 'edit', editData: data, textPage: true })
  }

  const handleDelete = (id) => {
    setShowDelete(true)
    setDeleteId(id)
  }

  const deletePopup = () => {
    setShowDelete(false)
  }

  const deleteSelected = async () => {
    setShowDelete(false)
    try {
      const response = await dispatch(deleteOrganizationConsentTexts(deleteId));
      const { status, message } = response?.data
      if (status === "success") {
        openFlash("tick", "Done!", message);
        fetchOrganizationConsentTexts()
      } else {
        openFlash("alert", "Error!", message);
      }
    } catch (error) {
      console.error('Error fetching the template preview:', error);
    }
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  const handleChangeFilter = (type, value) => {
    setFilters(prevState => ({
      ...prevState,
      [type]: value
    }))
  };

  const filterConsentTexts = async (page) => {
    try {
      const response = await dispatch(getOrganizationConsentTextsFilterData(filters, page, selectedOrgId));
      const { current_page, total_count, total_pages, texts } = response.data;
      setConsentTextList(texts)
      setpaginationData(prevState => ({
        ...prevState,
        current_page: current_page,
        total_count: total_count,
        total_pages: total_pages
      }))
      if (response.status) {
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch organizations', error);
    }
  }

  const orgOptions = organizationsList.map(org => ({
    value: org.id,
    label: `${org.name}`
  }));

  const pageChange = (selectedPage) => {
    let newPage = selectedPage.selected + 1;
    filterConsentTexts(newPage);
  }

  const showTextsHandler = async (showData, isShowdata) => {
    try {
      const response = await dispatch(getOrganizationConsentTextsShow(showData.id));
      const { text, default_text } = response.data;
      if (isShowdata) {
        history.push({ pathname: '/organization_consent_texts_difference', state: { default_text: default_text, text: text, textPage: true } })
      } else {
        history.push('manage_organization_consent_texts', { identifier: 'edit', editData: text ? text : default_text, textPage: true })
      }
      if (response.status) {
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
      console.error('Failed to fetch organizations', error);
    }
  }

  const handleOrgChange = (selectedOption) => {
    if (selectedOption) {
      const selectedOrgId = selectedOption.value.toString();
      const selectedOrganization = organizationsList.find(org => org.id.toString() === selectedOrgId);

      if (selectedOrganization) {
        setSelectedOrg(selectedOrganization.name);
        setSelectedOrgId(selectedOrgId);
      } else {
        setSelectedOrg('');
        setSelectedOrgId('');
      }
    } else {
      setSelectedOrg('');
      setSelectedOrgId('');
    }
  };

  return (
    <div className="email-sms-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>

      {
        showDelete ?
          <DeleteModal
            firstTextUser={"Are you sure you want"}
            secondTextUser={"to delete"}
            confirm={deleteSelected}
            closeDelete={deletePopup}
          />
          : null
      }
      <div className="ld-container">
        <h3 className="ld-header-email-sms">Texts</h3>
      </div>
      <div className="email-sms-table-top bg-white">
        <div className="email-sms-flex-header customer-activity-header">
          <div className="row email-sms-selects-container">
            <div className='col-md-2'>
              <div className="organization-provider-filter-container">
                <label>Name</label>
                <select className="select-filter-organization-provider" onChange={(e) => handleChangeFilter("display_name", e.target.value)}>
                  <option value="">Select</option>
                  {
                    displayTexts && displayTexts.map((displayTxt, index) => (
                      <option value={displayTxt.display_name}
                        key={`displayTxtId-option-${index + 1}`}>
                        {displayTxt.display_name}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='org-consent-select'>
                <label>Organization</label>
                <ReactSelect
                  value={orgOptions.find(option => option.value.toString() === selectedOrgId)}
                  styles={selectStyles}
                  onChange={handleOrgChange}
                  options={orgOptions}
                  placeholder="Select organization"
                  isClearable={true}
                  noOptionsMessage={() => "No organizations found"}
                  className='email-sms-select-org-dropdown'
                />
              </div>
            </div>
            <div className='col-md-2'>
              <div className="organization-provider-filter-container">
                <label>Service</label>
                <select className="select-filter-organization-provider capitalize" onChange={(e) => handleChangeFilter("service_id", e.target.value)}>
                  <option value="">Select</option>
                  {
                    serviceList && serviceList.map((service, index) => (
                      <option value={service.id}
                        key={`serviceId-option-${index + 1}`}>
                        {humanize(service.name)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='col-md-2'>
              <div className="organization-provider-filter-container">
                <label>Locale</label>
                <select className="select-filter-organization-provider capitalize" onChange={(e) => handleChangeFilter("locale", e.target.value)}>
                  <option value="">Select</option>
                  {
                    locales && locales.map((locale, index) => (
                      <option value={locale}
                        key={`localeId-option-${index + 1}`}>
                        {locale?.toUpperCase()}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='col-md-2'>
              <div className="organization-provider-filter-container">
                <label>Key</label>
                <select className="select-filter-organization-provider capitalize" onChange={(e) => handleChangeFilter("keys", e.target.value)}>
                  <option value="">Select</option>
                  {
                    keysList && keysList.map((keyData, index) => (
                      <option value={keyData}
                        key={`keyDataId-option-${index + 1}`}>
                        {humanize(keyData)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='filter-buttons'>
                <button className={filters.display_name && filters.display_name != "" || filters.service_id && filters.service_id != "" || filters.locale && filters.locale != "" || filters.keys && filters.keys != "" || selectedOrgId != "" ? "service-button big-w" : "service-button big-w login_submit_disabled"} onClick={() => filterConsentTexts(1)}>Filter</button>
                <button className='service-button ms-auto' onClick={() => {
                  handleCreateEditor();
                }}>Create</button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
          <div className="organizations-thead-scroll-y">
            <table className="table" width="100%">
              <thead className="organizations-providers-thead">
                <tr>
                  <td className="org-head">Name</td>
                  <td className="org-head">Organization</td>
                  <td className="org-head">Service</td>
                  <td className="org-head">Locale</td>
                  <td className="org-head width-td-100">Key</td>
                  <td className="org-head">Actions</td>
                </tr>
              </thead>
              <tbody className="organizations-tbody">
                {
                  consentTextList && consentTextList.length > 0 && consentTextList.map((item) => {
                    return (
                      <tr key={`tr-op-${item.id + 1} `} id={`op-${item.id}`}>
                        <td className="org-td">{item?.display_name}</td>
                        <td className="org-td">{item?.organizations_count}</td>
                        <td className="org-td">{humanize(item?.service?.name)}</td>
                        <td className="org-td">{item?.locale?.toUpperCase()}</td>
                        <td className="org-td">{humanize(item?.key)}</td>
                        <td className="org-td org-action">
                          <a href="javascript:void(0)">
                            <img className="email-img" src={edit} alt="edit" width="20" onClick={(e) => showTextsHandler(item, false)} />
                          </a>
                          <a href="javascript:void(0)">
                            <img className="email-img" src={remove} alt="edit" width="20" onClick={(e) => handleDelete(item?.id)} />
                          </a>
                          <span className='cursor-pointer' onClick={() => showTextsHandler(item, true)}>
                            <img src={eyeIcon} alt='eyeIcon' />
                          </span>
                        </td >
                      </tr >
                    )
                  })
                }
              </tbody >
            </table >
          </div >
          <div className="pagination-box">
            {paginationData.total_count > 50 &&
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                breakLabel={"..."}
                pageCount={Math.ceil(paginationData.total_pages)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={pageChange}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={paginationData.current_page - 1}
              />
            }
          </div>
        </div >
      </div>
    </div>
  );
};

export default OrganizationConsentTexts;
