import React from 'react';
import tick from '../../assets/images/tick.png';
import alert from '../../assets/images/alert_image.svg';
import close from '../../assets/images/icon_close.png';
import './FlashMessage.css';

const FlashMessage = (props) => {
  return (
    <div>
      <div className='dangerpopup'>
        <div className={(props.iconImage === 'tick' ? 'successpopup_inner' : 'dangerpopup_inner')}>
          <img
            className='tick'
            src={props.iconImage === 'tick' ? tick : alert}
            alt='tick'
          />
          <div className='flashNameHead'>
            <h4
              className='flash-message-heading'>
              {props.firstMsg}
            </h4>
            <p
              className='flash-message-info-text'>
              {props.secondMsg}
            </p>
          </div>
          <img
            className='close'
            src={close}
            onClick={props.closeFlash}
            alt='close'
          />
        </div>
      </div >
    </div>
  );
}

export default FlashMessage;