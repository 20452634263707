import { SET_INVOICES_LIST } from '../Actions/Types/Types';

const initialState = {
    paginated_orgs: [],
    invoices_logs_response: {}
}

const InvoicesLogs = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_INVOICES_LIST:
            return {
                paginated_orgs: action.data.paginated_orgs,
                invoices_logs_response: action.data
            }
        default: return state;
    }
}

export default InvoicesLogs;