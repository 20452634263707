import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import EmailList from "../../Components/Email/emailList";

import "./style.css";
const Email = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Emails";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <EmailList />
      </div>
    </div>
  );
};

export default Email;
